import * as T from 'graphql/__generated__/types';
import omit from 'lodash-es/omit';
import sortBy from 'lodash-es/sortBy';
import unionBy from 'lodash-es/unionBy';
import { useMemo } from 'react';

export function useTimelineSeries(dataView?: T.TableDataView | null) {
  const timelineSeries = useMemo(
    () =>
      sortBy(
        dataView?.rows?.reduce((series: Omit<T.TimelineSeries, 'data'>[], row) => {
          row?.cells?.forEach((cell) => {
            if (cell && T.isTimeline(cell?.cellContent)) {
              series = unionBy(
                cell.cellContent.series?.map((singleSeries) => omit(singleSeries, ['data'])),
                series,
                'id',
              );
            }
          });

          return series;
        }, []),
        'sortOrder',
      ) ?? [],
    [dataView?.rows],
  );

  const hasTimelineSeries = useMemo(() => timelineSeries.length > 0, [timelineSeries]);

  return { timelineSeries, hasTimelineSeries } as const;
}
