import { RemoveCircleOutline } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';
import clsx from 'clsx';
import * as T from 'graphql/__generated__/types';
import React from 'react';
import Colors from 'theme/colors';
import { getSentimentColor } from 'utils';

interface Props extends SvgIconProps {
  sentiment?: string;
}

const StatusIcon: React.FC<Props> = ({ sentiment, className, ...rest }) => {
  const color = getSentimentColor(sentiment as T.Sentiment, Colors.aqua);
  switch (sentiment) {
    case T.Sentiment.Positive:
      return (
        <SvgIcon
          width="81"
          height="80"
          viewBox="0 0 81 80"
          fill="currentColor"
          style={{ color }}
          {...rest}
          className={clsx(className, 'statusIcon')}
        >
          <path d="M0.500057 40.0353C0.535345 62.0811 18.4974 80 40.5529 80C62.6085 79.9647 80.5352 62.0106 80.5 39.9647C80.4647 17.8836 62.5026 -0.0352198 40.4471 5.54891e-05C18.3915 0.0353308 0.464761 17.9895 0.500057 40.0353ZM73.6892 39.9647C73.7245 58.2716 58.8326 73.157 40.5176 73.1922C22.2027 73.2275 7.31081 58.3422 7.27552 40.0353C7.24023 21.7284 22.1321 6.84308 40.4471 6.80781C58.762 6.80781 73.6539 21.6579 73.6892 39.9647Z" />
          <path d="M20.1911 38.06L25.0962 42.9277L39.7058 28.2893L54.386 42.8924L59.2559 38.0247L39.7058 18.5186L20.1911 38.06Z" />
          <path d="M36.2473 23.3862L36.2826 61.6225L43.1992 61.6225L43.1639 23.3862L36.2473 23.3862Z" />
        </SvgIcon>
      );
    case T.Sentiment.Negative:
      return (
        <SvgIcon
          width="81"
          height="80"
          viewBox="0 0 81 80"
          fill="currentColor"
          style={{ color }}
          {...rest}
          className={clsx(className, 'statusIcon')}
        >
          <path d="M80.4999 39.9647C80.4647 17.9189 62.5026 0 40.4471 0C18.3915 0.0352783 0.464763 17.9894 0.500052 40.0353C0.535341 62.1164 18.4974 80.0352 40.5529 79.9999C62.6085 79.9647 80.5352 62.0105 80.4999 39.9647ZM7.31081 40.0353C7.27552 21.7284 22.1674 6.84303 40.4824 6.80776C58.7973 6.77248 73.6892 21.6578 73.7245 39.9647C73.7598 58.2716 58.8679 73.1569 40.5529 73.1922C22.238 73.1922 7.34609 58.3421 7.31081 40.0353Z" />
          <path d="M60.8089 41.94L55.9038 37.0723L41.2942 51.7107L26.614 37.1076L21.7441 41.9753L41.2942 61.4814L60.8089 41.94Z" />
          <path d="M44.7527 56.6138L44.7174 18.3775H37.8008L37.8361 56.6138H44.7527Z" />
        </SvgIcon>
      );
    default:
      return (
        <RemoveCircleOutline
          style={{ color }}
          {...rest}
          className={clsx(className, 'statusIcon')}
        />
      );
  }
};

export default StatusIcon;
