import { call, put, take, takeLatest } from 'redux-saga/effects';
import {
  appInitialized,
  appStarted,
  verifyUserDisclaimerFailure,
  verifyUserDisclaimerRequest,
  verifyUserDisclaimerSuccess,
} from 'store/app/slices';
import { confirmModal } from 'store/modals/sagas';
import { fetchModulesRequest, fetchModulesSuccess } from 'store/modules/slices';

const userDisclaimerStorageKey = 'aily.userDisclaimer';
const userDisclaimerStorage = process.env.REACT_APP_USER_DISCLAIMER_LOCAL_STORAGE
  ? localStorage
  : sessionStorage;

// Watchers
function* onAppStarted() {
  yield takeLatest(appStarted, handleAppStarted);
}

function* onVerifyUserDisclaimerRequest() {
  yield takeLatest(verifyUserDisclaimerRequest, handleUserDisclaimerRequest);
}

function* onVerifyUserDisclaimerSuccess() {
  yield takeLatest(verifyUserDisclaimerSuccess, handleUserDisclaimerSuccess);
}

function* onVerifyUserDisclaimerFailure() {
  yield takeLatest(verifyUserDisclaimerFailure, handleUserDisclaimerFailure);
}

// Workers
export function* handleAppStarted() {
  if (!userDisclaimerStorage.getItem(userDisclaimerStorageKey)) {
    yield put(verifyUserDisclaimerRequest());
    yield take(verifyUserDisclaimerSuccess);
  }

  yield put(fetchModulesRequest());
  yield take(fetchModulesSuccess);

  yield put(appInitialized());
}

function* handleUserDisclaimerRequest() {
  const confirmed = (yield call(confirmModal, {
    key: 'userDisclaimer',
    title: process.env.REACT_APP_USER_DISCLAIMER_TITLE,
    content: process.env.REACT_APP_USER_DISCLAIMER_MESSAGE,
    okText: 'Agree',
    cancelText: 'Disagree',
  })) as ReturnType<typeof confirmModal>;

  if (confirmed) {
    yield put(verifyUserDisclaimerSuccess());
  } else {
    yield put(verifyUserDisclaimerFailure(new Error('User did not confirm the statement')));
    // Sign out the user if he did not confirm the statement
    // yield call(authService.signOut);
  }
}

function* handleUserDisclaimerSuccess() {
  yield call(
    [userDisclaimerStorage, userDisclaimerStorage.setItem],
    userDisclaimerStorageKey,
    Date.now().toString(),
  );
}

function* handleUserDisclaimerFailure() {
  yield call([userDisclaimerStorage, userDisclaimerStorage.removeItem], userDisclaimerStorageKey);
}

export default [
  onAppStarted,
  onVerifyUserDisclaimerRequest,
  onVerifyUserDisclaimerSuccess,
  onVerifyUserDisclaimerFailure,
];
