import { Components, Theme } from '@mui/material';
import colors from 'theme/colors';
import typography from 'theme/typography';

export const MuiCssBaseline: Components<Theme>['MuiCssBaseline'] = {
  styleOverrides: {
    html: {
      scrollBehavior: 'smooth',
    },
    h1: { ...typography.h1 },
    h2: { ...typography.h2 },
    h3: { ...typography.h3 },
    h4: { ...typography.h4 },
    h5: { ...typography.h5 },
    h6: { ...typography.h6 },
    body: { ...typography.body },
    small: { ...typography.small },
    '*': {
      scrollbarColor: `${colors.grey3} ${colors.ailyBlue}`,
      scrollbarWidth: 'thin',
    },
    '*::-webkit-scrollbar': {
      width: 12,
      height: 12,
      backgroundColor: colors.ailyBlue,
    },
    '*::-webkit-scrollbar-corner': {
      backgroundColor: colors.grey3,
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: colors.grey3,
      borderRadius: 6,
      border: `2px solid ${colors.ailyBlue}`,
    },
    // The OIDC client uses iframes for silent checking of the user session, which creates a scroll bar on the `body` element, this fixes it
    'iframe[width="0"][height="0"]': {
      display: 'none',
    },
    '.grabbing *': {
      cursor: 'grabbing',
    },
    mark: {
      backgroundColor: colors.aqua,
      color: colors.white,
    },
  },
};
