import { useEffect } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';

/**
 * Custom hook for automatically signing in the user when possible and signing in silently when
 * the token is about to expire.
 */
export function useAutoSignIn() {
  const { activeNavigator, isAuthenticated, isLoading, events, signinRedirect, signinSilent } =
    useAuth();

  // Automatically sign in the user if they are not already signed in
  useEffect(() => {
    if (!hasAuthParams() && !activeNavigator && !isAuthenticated && !isLoading) {
      signinRedirect();
    }
  }, [activeNavigator, isAuthenticated, isLoading, signinRedirect]);

  // Silently sign in the user when the token is about to expire
  useEffect(() => {
    const unsubscribe = events.addAccessTokenExpiring(() => {
      signinSilent();
    });

    return () => {
      unsubscribe();
    };
  }, [events, signinSilent]);
}
