import { Components, Theme } from '@mui/material';

export const MuiChip: Components<Theme>['MuiChip'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      minWidth: 68,
      height: 20,
      borderRadius: 3,
      justifyContent: 'flex-end',
      color: theme.palette.text.primary,
      '&.MuiChip-colorSuccess': {
        backgroundColor: `${theme.palette.success.main} !important`,
      },
      '&.MuiChip-colorError': {
        backgroundColor: `${theme.palette.error.main} !important`,
      },
    }),
    label: ({ theme }) => ({
      ...theme.typography.body,
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    }),
  },
};
