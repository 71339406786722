import { createTheme, Theme } from '@mui/material';

import colors from './colors';
import {
  MuiAlert,
  MuiButtonBase,
  MuiChip,
  MuiContainer,
  MuiCssBaseline,
  MuiDialogContentText,
  MuiDialogTitle,
  MuiDivider,
  MuiMenu,
  MuiMenuItem,
  MuiPaper,
  MuiPopover,
  MuiSelect,
  MuiSkeleton,
  MuiTab,
  MuiTable,
  MuiTableCell,
  MuiTabs,
  MuiToolbar,
  MuiTooltip,
} from './components';
import typography from './typography';

const theme: Theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1440,
      xxl: 1920,
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: colors.aqua,
    },
    text: {
      primary: colors.white,
      secondary: colors.grey3,
    },
    background: {
      default: colors.darkBlue,
      paper: colors.ailyBlue,
    },
    error: {
      main: colors.pink,
    },
    success: {
      main: colors.green,
    },
    warning: {
      main: colors.orange,
    },
    info: {
      main: colors.aqua,
    },
    divider: colors.grey1,
  },
  typography,
  components: {
    MuiAlert,
    MuiButtonBase,
    MuiChip,
    MuiContainer,
    MuiCssBaseline,
    MuiDialogContentText,
    MuiDialogTitle,
    MuiDivider,
    MuiMenu,
    MuiMenuItem,
    MuiPaper,
    MuiPopover,
    MuiSelect,
    MuiSkeleton,
    MuiTab,
    MuiTable,
    MuiTableCell,
    MuiTabs,
    MuiToolbar,
    MuiTooltip,
  },
});

export default theme;
