import { ListItemButtonProps, styled, Typography } from '@mui/material';
import MuiListItemButton from '@mui/material/ListItemButton';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import MuiListItemText from '@mui/material/ListItemText';
import React from 'react';

export interface Props extends ListItemButtonProps {
  title: string;
  icon?: React.ReactNode;
}

const ListItemButton = styled(MuiListItemButton)(({ theme }) => ({
  marginBottom: theme.spacing(6),
  padding: 0,
  paddingLeft: 0,
  color: '#8a92ac',
  transition: theme.transitions.create('color', {
    duration: theme.transitions.duration.shortest,
  }),
  '&:hover, &:focus, &.Mui-selected, &:hover.Mui-selected': {
    color: theme.palette.text.primary,
    background: 'transparent',
  },
}));

const ListItemIcon = styled(MuiListItemIcon)(({ theme }) => ({
  width: 40,
  minWidth: 'initial',
  justifyContent: 'center',
  marginRight: theme.spacing(1.5),
  color: 'inherit',
}));

const ListItemText = styled(MuiListItemText)(({ theme }) => ({
  margin: theme.spacing(0.25, 0),
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.standard,
    easing: 'linear',
  }),
}));

export const PageMenuItem: React.FC<Props> = ({ title, icon, ...rest }) => (
  <ListItemButton data-testid="page-menu-item" {...rest}>
    {!!icon && <ListItemIcon>{icon}</ListItemIcon>}
    <ListItemText disableTypography primary={<Typography variant="small">{title}</Typography>} />
  </ListItemButton>
);

export default PageMenuItem;
