import { Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';

interface AiInspiredTextProps {
  /**
   * The template string with placeholders
   */
  template: string;
  /**
   * The value to replace the 'subject' placeholder with
   */
  subject: string;
}

/**
 * A component that replaces placeholders in a template string with provided values.
 * To be used as content for AI-inspired stories.
 */
const AiInspiredText: React.FC<AiInspiredTextProps> = ({ template, subject }) => {
  const content = useMemo(
    () =>
      template
        .split(/({\w+})/g)
        .filter(Boolean)
        .map((part, index) => {
          const [, placeholder] = part.match(/{(\w+)}/) ?? [];

          if (placeholder === 'subject') {
            return (
              <Typography key={index} variant="h2" color="primary">
                {subject}
              </Typography>
            );
          }

          return (
            <Typography key={index} variant="h6">
              {part}
            </Typography>
          );
        }),
    [template, subject],
  );

  return (
    <Stack direction="column" spacing={1.5}>
      {content}
    </Stack>
  );
};

export default AiInspiredText;
