import { Alert, Button, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import LoadingSkeleton from 'components/LoadingSkeleton';
import { PriorityCard } from 'components/PriorityCard';
import SortableGrid from 'components/SortableGrid/SortableGrid';
import { Variants } from 'framer-motion';
import {
  useFollowPriorityMutation,
  useReorderPriorityMutation,
  useUnfollowPriorityMutation,
} from 'graphql/__generated__/mutations';
import { GetUserPrioritiesQuery, useGetUserPrioritiesQuery } from 'graphql/__generated__/queries';
import { PriorityCategoryResult, PriorityInput, PriorityResult } from 'graphql/__generated__/types';
import { useAnalyticsTrackEvent } from 'hooks/useAnalyticsTrackEvent';
import { useCurrentModule } from 'hooks/useCurrentModule';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import flatMap from 'lodash-es/flatMap';
import sortBy from 'lodash-es/sortBy';
import { SnackbarKey, useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { ItemInterface, SortableEvent } from 'react-sortablejs';

export type UserPriority = PriorityResult & ItemInterface & { categoryName: string };

interface Props {}

const variants: Variants = {
  initial: { opacity: 0 },
  animate: (index: number) => ({
    opacity: 1,
    transition: { delay: index * 0.01, duration: 0.1 },
  }),
};

const SnackbarTransitionComponent = ({
  children,
  ...rest
}: TransitionProps & { children: React.ReactElement }) => (
  <Slide {...rest} direction="up">
    {children}
  </Slide>
);

const createUserPriorityType = (priority: PriorityResult, categoryName: string): UserPriority => ({
  id: String(priority.priorityId),
  categoryName,
  ...priority,
});

const extractPriorities = ({ categoryName, priorities }: PriorityCategoryResult): UserPriority[] =>
  priorities?.length
    ? [...priorities.map((priority) => createUserPriorityType(priority, categoryName))]
    : [];

const UserPriorities: React.FC<Props> = (props) => {
  const { trackEvent } = useAnalyticsTrackEvent();
  const moduleId = useCurrentModule()?.id ?? '';
  const [priorities, setPriorities] = useState<UserPriority[]>([]);

  const onCompleted = useCallback((data: GetUserPrioritiesQuery) => {
    setPriorities(sortBy(flatMap(data?.userPriorities, extractPriorities), 'sortOrder'));
  }, []);

  const { loading, error, refetch } = useGetUserPrioritiesQuery({
    variables: { input: { moduleId } },
    onCompleted,
  });

  const [reorderPriority] = useReorderPriorityMutation();
  const [followPriority] = useFollowPriorityMutation();
  const [unfollowPriority] = useUnfollowPriorityMutation();

  const trackPriorityRemoveEvent = useCallback(
    (priority: PriorityResult) => {
      trackEvent('item.clicked', {
        component: 'priority',
        component_id: priority.priorityId.toString(),
        intent: 'remove',
        item_type: 'button',
      });
    },
    [trackEvent],
  );

  const handleUpdate = useCallback(
    (event: SortableEvent) => {
      const { id } = event.item.dataset;
      const { priorityId } = find(priorities, { id }) as UserPriority;
      const input: PriorityInput = { moduleId, priorityId, sortOrder: event.newIndex ?? 0 };

      reorderPriority({ variables: { input } });
    },
    [moduleId, priorities],
  );

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleSnackbarUndoButtonClick = useCallback(
    (priority: UserPriority, snackbarKey: SnackbarKey) => () => {
      const { priorityId, sortOrder } = priority;
      const input: PriorityInput = { moduleId, priorityId, sortOrder };

      // Adding the item back to the end of the list
      setPriorities((prevState) =>
        prevState.concat({ ...priority, sortOrder: prevState.length, isFollowed: true }),
      );

      closeSnackbar(snackbarKey);

      followPriority({ variables: { input } });
    },
    [moduleId],
  );

  const handleRemove = useCallback(
    (priority: UserPriority) => () => {
      trackPriorityRemoveEvent(priority);

      const { priorityId, priorityName, categoryName, sortOrder } = priority;
      const input: PriorityInput = { moduleId, priorityId, sortOrder };

      // Filtering out an item with a matching id
      setPriorities((prevState) => filter(prevState, ({ id }) => id !== priority.id));

      enqueueSnackbar(`${priorityName} ${categoryName} removed`, {
        TransitionComponent: SnackbarTransitionComponent,
        action: (snackbarKey) => (
          <Button variant="text" onClick={handleSnackbarUndoButtonClick(priority, snackbarKey)}>
            Undo
          </Button>
        ),
      });

      unfollowPriority({ variables: { input } });
    },
    [moduleId, handleSnackbarUndoButtonClick, trackPriorityRemoveEvent],
  );

  const handleItemRender = useCallback(
    (item: UserPriority) => (
      <PriorityCard
        priorityName={item.priorityName}
        categoryName={item.categoryName}
        // Cannot be dragged or removed if the item is the only one in the list
        draggable={priorities.length > 1}
        removable={priorities.length > 1}
        onRemove={handleRemove(item)}
        sx={{ width: 'auto' }}
        data-testid="UserPriorities"
      />
    ),
    [priorities, handleRemove],
  );

  const handleStart = useCallback(() => document.body.classList.add('grabbing'), []);
  const handleEnd = useCallback(() => document.body.classList.remove('grabbing'), []);

  if (loading) {
    return <LoadingSkeleton />;
  }

  if (error) {
    return (
      <Alert
        variant="outlined"
        severity="error"
        action={
          <Button color="inherit" size="small" onClick={() => refetch()}>
            Reload
          </Button>
        }
      >
        {error.message}
      </Alert>
    );
  }

  if (!priorities?.length) {
    return (
      <Alert
        variant="outlined"
        severity="info"
        action={
          <Button color="inherit" size="small" onClick={() => refetch()}>
            Reload
          </Button>
        }
      >
        No data
      </Alert>
    );
  }

  return (
    <SortableGrid
      list={priorities}
      setList={setPriorities}
      onUpdate={handleUpdate}
      handle=".DragButton"
      animation={300}
      group="UserPriorities"
      forceFallback
      onStart={handleStart}
      onEnd={handleEnd}
      spacing={2}
      GridItemProps={{
        initial: 'initial',
        animate: 'animate',
        variants,
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
        sx: {
          zIndex: 1,
          '&.sortable-ghost': {
            zIndex: 0,
          },
          '&.sortable-drag': {
            opacity: '1 !important',
            transform: 'matrix(1, 0.15, -0.15, 1, 0, 0)',
          },
        },
      }}
      data-testid="UserPriorities"
    >
      {handleItemRender}
    </SortableGrid>
  );
};

export default UserPriorities;
