import {
  Alert,
  Button,
  IconButton,
  ListItem,
  ListItemText,
  styled,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import LoadingSkeleton from 'components/LoadingSkeleton';
import NestedList from 'components/NestedList';
import {
  useFollowPriorityMutation,
  useUnfollowPriorityMutation,
} from 'graphql/__generated__/mutations';
import { GetPrioritiesQuery, useGetPrioritiesQuery } from 'graphql/__generated__/queries';
import { PriorityInput, PriorityResult } from 'graphql/__generated__/types';
import { useAnalyticsTrackEvent } from 'hooks/useAnalyticsTrackEvent';
import { useCurrentModule } from 'hooks/useCurrentModule';
import { FilterDeepResult, useFilterDeep } from 'hooks/useFilterDeep';
import { Star, StarOutlined } from 'icons';
import { filter } from 'lodash-es';
import find from 'lodash-es/find';
import findIndex from 'lodash-es/findIndex';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { areEqual, FixedSizeList, ListChildComponentProps } from 'react-window';

interface Props {
  search?: string;
}

const StyledTab = styled(Tab)(({ theme }) => ({
  height: 32,
  ...theme.typography.small,
  borderRadius: 32,
  minHeight: 0,
  marginRight: theme.spacing(1.5),
  padding: theme.spacing(1),
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.text.secondary}`,
  transition: theme.transitions.create('all', {
    duration: theme.transitions.duration.shortest,
  }),
  '&.Mui-selected, &:hover': {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
  },
}));

interface Priority extends PriorityResult {
  children?: Priority[];
}

// eslint-disable-next-line react/display-name
const SearchListItem = React.memo(
  ({
    data,
    index,
    style,
  }: ListChildComponentProps<{
    items: FilterDeepResult<Priority>[];
    search: string;
    handleSecondaryAction: (item: Priority) => React.ReactNode;
  }>) => {
    const { items, search, handleSecondaryAction } = data;
    const item = items[index];
    return (
      <ListItem
        style={style}
        component="div"
        secondaryAction={handleSecondaryAction(item?.node as Priority)}
        sx={{
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
          '& .MuiIconButton-root': {
            '&:hover': {
              backgroundColor: 'transparent',
              color: (theme) => theme.palette.text.primary,
            },
          },
        }}
      >
        <ListItemText
          disableTypography
          primary={
            <Typography variant="body" component="div">
              <Highlighter
                searchWords={[search as string]}
                textToHighlight={item?.node.priorityName as string}
                autoEscape
              />
            </Typography>
          }
          secondary={
            <Typography variant="small" color="text.secondary" component="div">
              {item?.parents.map(({ priorityName }) => priorityName).join(' > ')}
            </Typography>
          }
        />
      </ListItem>
    );
  },
  areEqual,
);

const AddPriorities: React.FC<Props> = ({ search }) => {
  const { trackEvent } = useAnalyticsTrackEvent();
  const moduleId = useCurrentModule()?.id ?? '';
  const [priorities, setPriorities] = useState<Priority[]>([]);

  const trackPriorityAddOrRemoveEvent = useCallback(
    (priority: PriorityResult, intent: 'add' | 'remove') => {
      trackEvent('item.clicked', {
        component: 'priority',
        component_id: priority.priorityId.toString(),
        intent,
        item_type: 'button',
      });
    },
    [trackEvent],
  );

  const onCompleted = useCallback((data: GetPrioritiesQuery) => {
    setPriorities(
      (data?.priorities ?? []).map((priorityCategory, index) => ({
        priorityId: index,
        priorityName: priorityCategory.categoryName,
        isFollowed: false,
        sortOrder: index,
        children: priorityCategory.priorities,
      })),
    );
  }, []);

  const { loading, error, refetch } = useGetPrioritiesQuery({
    variables: { input: { moduleId } },
    onCompleted,
  });

  const [followPriority, followPriorityResult] = useFollowPriorityMutation();
  const [unfollowPriority, unfollowPriorityResult] = useUnfollowPriorityMutation();

  const [value, setValue] = useState(0);

  const getChildren = useCallback((priority: Priority) => priority.children ?? [], []);
  const predicate = useCallback(
    (s: string) => (priority: Priority) =>
      !priority.children?.length && priority.priorityName.toLowerCase().includes(s.toLowerCase()),
    [],
  );

  const filterDeep = useFilterDeep({ nodes: priorities, getChildren });

  const cachedResults = useRef(new Map());

  const searchResults = useMemo<FilterDeepResult<Priority>[]>(() => {
    if (!search || loading) {
      return [];
    }

    if (cachedResults.current.has(search)) {
      return cachedResults.current.get(search);
    }

    const results = filterDeep(predicate(search));
    cachedResults.current.set(search, results);
    return results;
  }, [search, loading, filterDeep, predicate, cachedResults]);

  const handleChange = useCallback((_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  }, []);

  const handleFollowPriority = useCallback(
    (priority: Priority) => () => {
      const { priorityId, sortOrder, isFollowed } = priority;
      const input: PriorityInput = { moduleId, priorityId, sortOrder };

      setPriorities((prevState) => {
        const categoryIndex = findIndex(
          prevState,
          ({ children }) => !!find(children, { priorityId }),
        );

        const categoryPriorities = prevState[categoryIndex].children;
        const priorityIndex = findIndex(categoryPriorities, { priorityId });

        categoryPriorities![priorityIndex].isFollowed = !isFollowed;

        return prevState;
      });

      if (isFollowed) {
        trackPriorityAddOrRemoveEvent(priority, 'remove');
        unfollowPriority({ variables: { input } });
      } else {
        trackPriorityAddOrRemoveEvent(priority, 'add');
        followPriority({ variables: { input } });
      }
    },
    [moduleId, trackPriorityAddOrRemoveEvent],
  );

  const handleSecondaryAction = useCallback(
    (item: Priority) => {
      const disabled =
        item.isFollowed &&
        priorities.reduce(
          (count: number, priorityCategory) =>
            count + filter(priorityCategory.children, { isFollowed: true }).length,
          0,
        ) === 1;

      return (
        <IconButton
          onClick={handleFollowPriority(item)}
          disabled={disabled}
          sx={{
            '&&': {
              borderLeft: 'none',
              color: (theme) =>
                item.isFollowed ? theme.palette.primary.main : theme.palette.text.secondary,
            },
          }}
        >
          {item.isFollowed ? <Star /> : <StarOutlined />}
        </IconButton>
      );
    },
    [priorities, handleFollowPriority],
  );

  if (loading) {
    return <LoadingSkeleton />;
  }

  if (error) {
    return (
      <Alert
        variant="outlined"
        severity="error"
        action={
          <Button color="inherit" size="small" onClick={() => refetch()}>
            Reload
          </Button>
        }
      >
        {error.message}
      </Alert>
    );
  }

  if (!priorities.length) {
    return (
      <Alert
        variant="outlined"
        severity="info"
        action={
          <Button color="inherit" size="small" onClick={() => refetch()}>
            Reload
          </Button>
        }
      >
        No data
      </Alert>
    );
  }

  if (search) {
    return searchResults.length ? (
      <FixedSizeList
        width="100%"
        height={10 * 64}
        itemData={{ items: searchResults, search, handleSecondaryAction }}
        itemCount={searchResults.length}
        itemSize={64}
      >
        {SearchListItem}
      </FixedSizeList>
    ) : (
      <Alert variant="outlined" severity="info">
        Nothing found
      </Alert>
    );
  }

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        sx={{ boxShadow: 'none', minHeight: 32, '& .MuiTabs-indicator': { display: 'none' } }}
      >
        {priorities.map((priorityCategory, index) => (
          <StyledTab
            key={index}
            value={index}
            label={priorityCategory.priorityName}
            data-testid="TabButton"
          />
        ))}
      </Tabs>
      {priorities.map((priorityCategory, index) => (
        <div key={index} role="tabpanel" hidden={value !== index}>
          {value === index && (
            <NestedList<PriorityResult>
              containerMaxHeight={12 * 46}
              itemHeight={46}
              items={priorityCategory.children ?? []}
              itemKey={({ priorityId }) => priorityId.toString()}
              itemLabel={({ priorityName }) => priorityName}
              itemChildren={() => []}
              secondaryAction={handleSecondaryAction}
              sx={{
                '& > div': { maxHeight: 'calc(100vh - 345px)' },
                '& .MuiListItemButton-root': { pointerEvents: 'none' },
                '& .MuiListItem-root': { color: (theme) => theme.palette.text.primary },
              }}
            />
          )}
        </div>
      ))}
    </>
  );
};

export default AddPriorities;
