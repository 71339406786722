import { ApolloClient, createHttpLink, from, InMemoryCache, ServerError } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import { SentryLink } from 'apollo-link-sentry';
import introspectionResult from 'graphql/__generated__/introspection-result';
import { getCurrentUser, userManager } from 'services/authService';

export const cache = new InMemoryCache({ possibleTypes: introspectionResult.possibleTypes });

const authLink = setContext((_, { headers }) => {
  const user = getCurrentUser();
  return {
    headers: {
      ...headers,
      'x-tenant-apikey': process.env.REACT_APP_API_KEY,
      'x-correlation-id': Date.now(),
      Authorization: user?.access_token ? `Bearer ${user.access_token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.map((graphQLError) => {
      console.error(`[GraphQL error]:`, graphQLError);

      Sentry.withScope((scope) => {
        scope.setTag('operationName', operation.operationName);
        scope.setExtra('query', operation.query.loc);
        scope.setExtra('variables', operation.variables);

        if (graphQLError.path) {
          scope.addBreadcrumb({
            category: 'queryPath',
            message: graphQLError.path.join('.'),
            level: 'debug',
          });
        }

        const error = new Error();
        error.name = '[GraphQL error]';
        error.message = (graphQLError.extensions?.message as string) ?? graphQLError.message;
        error.stack = graphQLError.extensions?.stackTrace as string;

        Sentry.captureException(error);
      });
    });
  }

  if (networkError) {
    console.error(`[Network error]:`, networkError);

    const error = new Error();
    error.name = '[Network error]:';
    error.message = networkError.message;

    Sentry.captureException(error);
  }
});

const httpLink = createHttpLink({ uri: process.env.REACT_APP_API_URL });
const sentryLink = new SentryLink({ uri: process.env.REACT_APP_API_URL });

const resetToken = onError(({ networkError }) => {
  if ((networkError as ServerError)?.statusCode === 401) {
    userManager.signinRedirect();
  }
});

const link = from([resetToken, authLink, errorLink, sentryLink, httpLink]);

export const client = new ApolloClient({
  connectToDevTools: true,
  link,
  cache,
  resolvers: {},
  // defaultOptions for ApolloClient is being ignored, see https://github.com/apollographql/react-apollo/issues/3750
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    mutate: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});
