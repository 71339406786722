export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Card: ['KpiCardResult', 'MicroChartCardResult', 'SimpleCardResult'],
    ChartAxis: [
      'ChartAxisCategoryViewResult',
      'ChartAxisPercentViewResult',
      'ChartAxisValueViewResult',
    ],
    ColorUnion: ['ColorString', 'ThemeColor'],
    Component: [
      'CardsComponent',
      'ChartComponent',
      'ColumnLayoutComponent',
      'DialsComponent',
      'FilterComponent',
      'FiltersComponent',
      'GridComponent',
      'HeaderRowComponent',
      'KpiComponent',
      'ListComponent',
      'MarkdownComponent',
      'MicroChartComponent',
      'NavigationTabsComponent',
      'PlailistComponent',
      'RecommenderComponent',
      'StoryListComponent',
      'SummaryInfoComponent',
      'TableComponent',
      'TimelineComponent',
      'UserAnalyticsComponent',
      'UserPrioritiesComponent',
    ],
    Filter: ['SelectFilter', 'SelectGroupFilter', 'TreeSelectFilter'],
    FilterValue: ['SelectFilterValue', 'SelectGroupFilterValue'],
    IDataView: [
      'CardsDataView',
      'ChartDataViewResult',
      'DialsDataView',
      'GridDataView',
      'HeaderRow',
      'KpiStatusOverview',
      'ListDataView',
      'MicroChartResult',
      'RecommenderDataView',
      'SummaryInfoDataView',
      'TableDataView',
    ],
    TableCellContent: [
      'DrillDownResult',
      'KpiStatus',
      'KpiStatusOverview',
      'LocationMarkerResult',
      'MicroChartResult',
      'ProgressBarResult',
      'TextLinkResult',
      'TextResult',
      'Timeline',
      'TimelineDataPoint',
      'TimelinePhases',
      'VarianceResult',
    ],
  },
};
export default result;
