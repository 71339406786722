import { SearchOutlined } from '@mui/icons-material';
import { MenuIcon } from 'components/MenuIcon';
import ModuleSelectPageMenuItem from 'components/ModuleSelect/ModuleSelectPageMenuItem';
import * as T from 'graphql/__generated__/types';
import { useCurrentModule } from 'hooks/useCurrentModule';
import { filter, kebabCase } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { getModules } from 'store/modules/slices';

import { PageMenu, Props as PageMenuProps } from './PageMenu';

export const PageMenuContainer: React.FC<Omit<PageMenuProps, 'children'>> = ({ expanded }) => {
  const modules = useSelector(getModules);
  const currentModule = useCurrentModule();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const pages = useMemo(
    () => filter(currentModule?.pages, { isMenuHidden: false }),
    [currentModule],
  );

  const handlePageMenuItemClick = useCallback(
    (path: string) => () => {
      navigate(path);
    },
    [],
  );

  const handleModuleSelect = useCallback((module: T.Module) => {
    navigate(`/${module.path}`);
  }, []);

  return (
    <PageMenu expanded={expanded}>
      <>
        {pages
          .filter(({ pageType }) => pageType !== T.PageTypeEnum.SettingsPage)
          .map((page) => {
            // Match this page and its subpages
            const selected = !!matchPath(`${page.absolutePath}/*`, pathname);
            return (
              <PageMenu.Item
                key={page.id}
                title={page.menuTitle ?? page.title}
                icon={page.menuIcon && <MenuIcon menuIcon={page.menuIcon} active={selected} />}
                selected={selected}
                onClick={handlePageMenuItemClick(page.absolutePath)}
              />
            );
          })}
      </>
      <ModuleSelectPageMenuItem
        title="Apps"
        icon={<SearchOutlined />}
        parentExpanded={expanded}
        modules={modules}
        currentModule={currentModule}
        onSelect={handleModuleSelect}
      />
      <>
        {pages
          .filter(({ pageType }) => pageType === T.PageTypeEnum.SettingsPage)
          .map((page) => {
            // Match this page and its subpages
            const selected = !!matchPath(`${page.absolutePath}/*`, pathname);
            return (
              <PageMenu.Item
                key={page.id}
                title={page.menuTitle ?? page.title}
                icon={page.menuIcon && <MenuIcon menuIcon={page.menuIcon} active={selected} />}
                selected={selected}
                onClick={handlePageMenuItemClick(page.absolutePath)}
                data-x-path={kebabCase(page.path)}
              />
            );
          })}
      </>
    </PageMenu>
  );
};

export default PageMenuContainer;
