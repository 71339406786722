import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { MarkdownRenderer } from 'components/MarkdownRenderer';
import React, { useCallback } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { getModals, ModalType, onModalCancel, onModalClose, onModalOk } from 'store/modals';

const ModalRoot = () => {
  const modals = useSelector(getModals);
  const dispatch = useDispatch();

  const handleCancel = useCallback(
    (key: string) => () => {
      batch(() => {
        dispatch(onModalCancel(key));
        dispatch(onModalClose(key));
      });
    },
    [],
  );

  const handleOk = useCallback(
    (key: string) => () => {
      batch(() => {
        dispatch(onModalOk(key));
        dispatch(onModalClose(key));
      });
    },
    [],
  );

  return (
    <>
      {modals.map(({ key, title, content, okText, cancelText, type }) => (
        <Dialog key={key} open>
          {!!title && <DialogTitle data-testid="DialogTitle">{title}</DialogTitle>}
          {!!content && (
            <DialogContent data-testid="DialogContent">
              <DialogContentText>
                <MarkdownRenderer>{content}</MarkdownRenderer>
              </DialogContentText>
            </DialogContent>
          )}
          <DialogActions>
            {type === ModalType.CONFIRM && (
              <Button onClick={handleCancel(key)} data-testid="CancelButton">
                {cancelText || 'Cancel'}
              </Button>
            )}
            <Button onClick={handleOk(key)} variant={'contained'} data-testid="OkButton">
              {okText || 'OK'}
            </Button>
          </DialogActions>
        </Dialog>
      ))}
    </>
  );
};

export default ModalRoot;
