import { GenericEventPayload, useAnalytics } from 'contexts';
import { useCurrentModule, useCurrentPage, useIsAilyUser } from 'hooks';
import { snakeCase, trim } from 'lodash-es';
import { useEffect } from 'react';

import { useUserEmailDomain } from './useUserEmailDomain';

export function useAnalyticsTrackPage() {
  const currentModule = useCurrentModule();
  const currentPage = useCurrentPage();
  const { page } = useAnalytics();
  const emailDomain = useUserEmailDomain();
  const isAilyUser = useIsAilyUser();

  useEffect(() => {
    if (currentModule && currentPage) {
      // Remove the module path from the beginning of the page path
      const trimmedPath = trim(currentPage.absolutePath, `/${currentModule.path}`);
      const pathParts = trimmedPath.split('/');
      const pageName = snakeCase(pathParts[0]);
      const focusArea = snakeCase(pathParts[1]);

      page(currentModule.name, pageName, {
        tenant: process.env.REACT_APP_TENANT,
        env: process.env.REACT_APP_ENV,
        email_domain: emailDomain,
        is_aily_user: isAilyUser,
        module: currentModule.name,
        name: pageName,
        focus_area: focusArea,
        path: currentPage.absolutePath,
      } as GenericEventPayload);
    }
  }, [currentModule, currentPage]);
}
