import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PriorityFieldsFragmentDoc, UserSettingFieldsFragmentDoc } from './fragments';
import * as Types from './types';
const defaultOptions = {
  fetchPolicy: 'no-cache',
  errorPolicy: 'all',
  notifyOnNetworkStatusChange: true,
} as const;
export type FollowPriorityMutationVariables = Types.Exact<{
  input: Types.PriorityInput;
}>;

export type FollowPriorityMutation = { __typename?: 'Mutation' } & {
  followPriority: { __typename?: 'PriorityResult' } & Pick<
    Types.PriorityResult,
    'priorityId' | 'priorityName' | 'isFollowed' | 'sortOrder'
  >;
};

export type MarkStoryAsReadMutationVariables = Types.Exact<{
  input: Types.MarkStoryAsReadInput;
}>;

export type MarkStoryAsReadMutation = { __typename?: 'Mutation' } & Pick<
  Types.Mutation,
  'markStoryAsRead'
>;

export type ReorderPriorityMutationVariables = Types.Exact<{
  input: Types.PriorityInput;
}>;

export type ReorderPriorityMutation = { __typename?: 'Mutation' } & {
  reorderPriority: { __typename?: 'PriorityResult' } & Pick<
    Types.PriorityResult,
    'priorityId' | 'priorityName' | 'isFollowed' | 'sortOrder'
  >;
};

export type SaveUserSettingsMutationVariables = Types.Exact<{
  input: Types.SaveUserSettingsInput;
}>;

export type SaveUserSettingsMutation = { __typename?: 'Mutation' } & {
  saveUserSettings?: Types.Maybe<
    Array<
      { __typename?: 'UserSetting' } & Pick<
        Types.UserSetting,
        'key' | 'label' | 'defaultValue' | 'userValue'
      > & {
          options: Array<
            { __typename?: 'SettingOption' } & Pick<Types.SettingOption, 'label' | 'value'>
          >;
        }
    >
  >;
};

export type UnfollowPriorityMutationVariables = Types.Exact<{
  input: Types.PriorityInput;
}>;

export type UnfollowPriorityMutation = { __typename?: 'Mutation' } & {
  unfollowPriority: { __typename?: 'PriorityResult' } & Pick<
    Types.PriorityResult,
    'priorityId' | 'priorityName' | 'isFollowed' | 'sortOrder'
  >;
};

export const FollowPriorityDocument = gql`
  mutation FollowPriority($input: PriorityInput!) {
    followPriority(input: $input) {
      ...PriorityFields
    }
  }
  ${PriorityFieldsFragmentDoc}
`;
export type FollowPriorityMutationFn = Apollo.MutationFunction<
  FollowPriorityMutation,
  FollowPriorityMutationVariables
>;

/**
 * __useFollowPriorityMutation__
 *
 * To run a mutation, you first call `useFollowPriorityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowPriorityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followPriorityMutation, { data, loading, error }] = useFollowPriorityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFollowPriorityMutation(
  baseOptions?: Apollo.MutationHookOptions<FollowPriorityMutation, FollowPriorityMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FollowPriorityMutation, FollowPriorityMutationVariables>(
    FollowPriorityDocument,
    options,
  );
}
export type FollowPriorityMutationHookResult = ReturnType<typeof useFollowPriorityMutation>;
export type FollowPriorityMutationResult = Apollo.MutationResult<FollowPriorityMutation>;
export type FollowPriorityMutationOptions = Apollo.BaseMutationOptions<
  FollowPriorityMutation,
  FollowPriorityMutationVariables
>;
export const MarkStoryAsReadDocument = gql`
  mutation MarkStoryAsRead($input: MarkStoryAsReadInput!) {
    markStoryAsRead(input: $input)
  }
`;
export type MarkStoryAsReadMutationFn = Apollo.MutationFunction<
  MarkStoryAsReadMutation,
  MarkStoryAsReadMutationVariables
>;

/**
 * __useMarkStoryAsReadMutation__
 *
 * To run a mutation, you first call `useMarkStoryAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkStoryAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markStoryAsReadMutation, { data, loading, error }] = useMarkStoryAsReadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkStoryAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkStoryAsReadMutation,
    MarkStoryAsReadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MarkStoryAsReadMutation, MarkStoryAsReadMutationVariables>(
    MarkStoryAsReadDocument,
    options,
  );
}
export type MarkStoryAsReadMutationHookResult = ReturnType<typeof useMarkStoryAsReadMutation>;
export type MarkStoryAsReadMutationResult = Apollo.MutationResult<MarkStoryAsReadMutation>;
export type MarkStoryAsReadMutationOptions = Apollo.BaseMutationOptions<
  MarkStoryAsReadMutation,
  MarkStoryAsReadMutationVariables
>;
export const ReorderPriorityDocument = gql`
  mutation ReorderPriority($input: PriorityInput!) {
    reorderPriority(input: $input) {
      ...PriorityFields
    }
  }
  ${PriorityFieldsFragmentDoc}
`;
export type ReorderPriorityMutationFn = Apollo.MutationFunction<
  ReorderPriorityMutation,
  ReorderPriorityMutationVariables
>;

/**
 * __useReorderPriorityMutation__
 *
 * To run a mutation, you first call `useReorderPriorityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderPriorityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderPriorityMutation, { data, loading, error }] = useReorderPriorityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReorderPriorityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReorderPriorityMutation,
    ReorderPriorityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReorderPriorityMutation, ReorderPriorityMutationVariables>(
    ReorderPriorityDocument,
    options,
  );
}
export type ReorderPriorityMutationHookResult = ReturnType<typeof useReorderPriorityMutation>;
export type ReorderPriorityMutationResult = Apollo.MutationResult<ReorderPriorityMutation>;
export type ReorderPriorityMutationOptions = Apollo.BaseMutationOptions<
  ReorderPriorityMutation,
  ReorderPriorityMutationVariables
>;
export const SaveUserSettingsDocument = gql`
  mutation SaveUserSettings($input: SaveUserSettingsInput!) {
    saveUserSettings(input: $input) {
      ...UserSettingFields
    }
  }
  ${UserSettingFieldsFragmentDoc}
`;
export type SaveUserSettingsMutationFn = Apollo.MutationFunction<
  SaveUserSettingsMutation,
  SaveUserSettingsMutationVariables
>;

/**
 * __useSaveUserSettingsMutation__
 *
 * To run a mutation, you first call `useSaveUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserSettingsMutation, { data, loading, error }] = useSaveUserSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveUserSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveUserSettingsMutation,
    SaveUserSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveUserSettingsMutation, SaveUserSettingsMutationVariables>(
    SaveUserSettingsDocument,
    options,
  );
}
export type SaveUserSettingsMutationHookResult = ReturnType<typeof useSaveUserSettingsMutation>;
export type SaveUserSettingsMutationResult = Apollo.MutationResult<SaveUserSettingsMutation>;
export type SaveUserSettingsMutationOptions = Apollo.BaseMutationOptions<
  SaveUserSettingsMutation,
  SaveUserSettingsMutationVariables
>;
export const UnfollowPriorityDocument = gql`
  mutation UnfollowPriority($input: PriorityInput!) {
    unfollowPriority(input: $input) {
      ...PriorityFields
    }
  }
  ${PriorityFieldsFragmentDoc}
`;
export type UnfollowPriorityMutationFn = Apollo.MutationFunction<
  UnfollowPriorityMutation,
  UnfollowPriorityMutationVariables
>;

/**
 * __useUnfollowPriorityMutation__
 *
 * To run a mutation, you first call `useUnfollowPriorityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfollowPriorityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfollowPriorityMutation, { data, loading, error }] = useUnfollowPriorityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnfollowPriorityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnfollowPriorityMutation,
    UnfollowPriorityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnfollowPriorityMutation, UnfollowPriorityMutationVariables>(
    UnfollowPriorityDocument,
    options,
  );
}
export type UnfollowPriorityMutationHookResult = ReturnType<typeof useUnfollowPriorityMutation>;
export type UnfollowPriorityMutationResult = Apollo.MutationResult<UnfollowPriorityMutation>;
export type UnfollowPriorityMutationOptions = Apollo.BaseMutationOptions<
  UnfollowPriorityMutation,
  UnfollowPriorityMutationVariables
>;
