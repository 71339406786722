import { List as MuiList, ListProps, styled } from '@mui/material';
import React from 'react';

import { PageMenuItem, Props as PageMenuItemProps } from './PageMenuItem';

export interface Props extends ListProps {
  expanded?: boolean;
  children?: React.ReactElement<PageMenuItemProps> | React.ReactElement<PageMenuItemProps>[];
}

const List = styled(MuiList, {
  shouldForwardProp: (prop) => prop !== 'expanded',
})<Pick<Props, 'expanded'>>(({ theme, expanded }) => ({
  padding: theme.spacing(6, 0),
  '& .MuiListItemText-root': {
    opacity: expanded ? 1 : 0,
    transitionDelay: `${expanded ? 0 : 500}ms`,
  },
}));

export const PageMenu: React.FC<Props> & { Item: typeof PageMenuItem } = ({
  expanded,
  children,
  ...rest
}) => (
  <List expanded={expanded} data-testid="page-menu" {...rest}>
    {children}
  </List>
);

PageMenu.Item = PageMenuItem;

export default PageMenu;
