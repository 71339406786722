import { Box, styled } from '@mui/material';
import { ReactComponent as AilyLogo } from 'aily-logo.svg';
import PageMenuContainer from 'components/PageMenu/PageMenuContainer';
import Sidebar from 'components/Sidebar';
import React, { PropsWithChildren, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

const drawerWidth = 88;

const ContentBox = styled(Box)({
  paddingRight: drawerWidth,
});

const PageLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const navigate = useNavigate();

  return (
    <Box>
      <ContentBox>{children ?? <Outlet />}</ContentBox>
      <Sidebar onExpandedChange={setSidebarExpanded}>
        <div>
          <AilyLogo width="100%" style={{ cursor: 'pointer' }} onClick={() => navigate('/')} />
        </div>
        <PageMenuContainer expanded={sidebarExpanded} />
      </Sidebar>
    </Box>
  );
};

export default PageLayout;
