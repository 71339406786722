import { Popover, PopoverProps, styled } from '@mui/material';
import { PageMenu } from 'components/PageMenu';
import { Props as PageMenuItemProps } from 'components/PageMenu/PageMenuItem';
import * as T from 'graphql/__generated__/types';
import React, { MouseEventHandler, useCallback, useEffect, useState } from 'react';
import Colors from 'theme/colors';

import ModuleSelect from './ModuleSelect';

interface Props extends Omit<PageMenuItemProps, 'onSelect'> {
  modules: T.Module[];
  currentModule?: T.Module;
  parentExpanded?: boolean;
  onSelect?: (module: T.Module) => void;
}

const StyledPopover = styled(({ children, ...rest }: PopoverProps) => (
  <Popover
    anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
    transformOrigin={{ vertical: 'center', horizontal: 'right' }}
    {...rest}
  >
    {children}
  </Popover>
))(({ theme }) => ({
  '& .MuiPaper-root': {
    maxWidth: 394,
    maxHeight: 480,
    border: '1px solid rgb(41, 52, 73)',
    borderRadius: 18,
    background: Colors.black,
    boxShadow: theme.shadows[20],
    padding: theme.spacing(3),
  },
}));

const ModuleSelectPageMenuItem: React.FC<Props> = ({
  modules,
  currentModule,
  parentExpanded,
  onSelect,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  const handleClick = useCallback<MouseEventHandler<HTMLElement>>((event) => {
    setAnchorEl(event.currentTarget);
    event.currentTarget.blur();
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(undefined);
  }, []);

  const handleModuleClick = useCallback(
    (module: T.Module) => () => {
      setAnchorEl(undefined);
      onSelect?.(module);
    },
    [onSelect],
  );

  const open = !!anchorEl;

  // Close the popover when the parent closes
  useEffect(() => {
    if (!parentExpanded) {
      setAnchorEl(undefined);
    }
  }, [parentExpanded]);

  // Do not display for less than 2 modules
  if (modules.length < 2) {
    return null;
  }

  return (
    <>
      <PageMenu.Item onClick={handleClick} {...rest} />
      <StyledPopover open={open} anchorEl={anchorEl} onClose={handleClose}>
        <ModuleSelect>
          {modules.map((module) => (
            <ModuleSelect.Item
              key={module.id}
              active={module.id === currentModule?.id}
              onClick={handleModuleClick(module)}
            >
              {module.name}
            </ModuleSelect.Item>
          ))}
        </ModuleSelect>
      </StyledPopover>
    </>
  );
};

export default ModuleSelectPageMenuItem;
