import { IconButton, Input, InputAdornment, InputProps, styled } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { CloseOutlined, SearchOutlined } from 'icons';
import React, { useCallback, useRef, useState } from 'react';

export interface SearchBarProps extends Omit<InputProps, 'onChange'> {
  value?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
}

const StyledInput = styled(Input)(({ theme }) => ({
  display: 'flex',
  height: 48,
  borderRadius: 6,
  padding: theme.spacing(0, 2),
  backgroundColor: theme.palette.divider,
  ...theme.typography.body,
  '& .MuiInput-input::placeholder': {
    opacity: 1,
    color: theme.palette.text.secondary,
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.text.secondary,
    fontSize: 28,
  },
  '& .MuiIconButton-root:hover > .MuiSvgIcon-root': {
    color: theme.palette.text.primary,
  },
  '& .MuiInputAdornment-positionStart': {
    marginRight: theme.spacing(1.25),
  },
  '& .MuiInputAdornment-positionEnd .MuiButtonBase-root': {
    marginRight: theme.spacing(-1),
  },
}));

const SearchBar: React.FC<SearchBarProps> = ({
  value,
  defaultValue,
  onChange,
  placeholder = 'Search',
  ...rest
}) => {
  const ref = useRef<HTMLInputElement>();
  const [localValue, setLocalValue] = useState(defaultValue ?? '');
  const currentValue = value ?? localValue;

  const focusInput = useCallback(() => {
    ref?.current?.focus();
  }, [ref]);

  const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    ({ target: { value: newValue } }) => {
      setLocalValue(newValue);
      onChange?.(newValue);
    },
    [onChange],
  );

  const handleClear = useCallback(() => {
    setLocalValue('');
    onChange?.('');
    focusInput();
  }, [onChange, focusInput]);

  return (
    <StyledInput
      inputRef={ref}
      value={currentValue}
      defaultValue={defaultValue}
      onChange={handleChange}
      placeholder={placeholder}
      disableUnderline
      startAdornment={
        <InputAdornment position="start">
          <SearchOutlined />
        </InputAdornment>
      }
      endAdornment={
        <AnimatePresence>
          {!!currentValue && (
            <InputAdornment
              position="end"
              component={motion.div}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}
            >
              <IconButton disableRipple onClick={handleClear} data-testid="ClearButton">
                <CloseOutlined />
              </IconButton>
            </InputAdornment>
          )}
        </AnimatePresence>
      }
      data-testid="SearchBar"
      {...rest}
    />
  );
};

export default SearchBar;
