import { useLazyQuery } from '@apollo/client';
import { OperationVariables } from '@apollo/client/core/types';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { DocumentNode } from 'graphql';
import * as T from 'graphql/__generated__/types';
import { createSelectGroupFilterValue, mapFilterOptionToFilterValue } from 'graphql/utils';
import { isEqual, isNil, reduce, sortBy } from 'lodash-es';
import find from 'lodash-es/find';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePrevious } from 'react-use';
import { resolveFilterOption } from 'utils';

interface Options<TData, TVariables> {
  query: DocumentNode | TypedDocumentNode<TData, TVariables>;
  variables?: TVariables;
  getFilters?: (data: TData) => T.Filter[];
  defaultFilterValues?: T.FilterValue[];
  defaultValue?: T.SelectFilterValue['value'];
  skip?: boolean;
}

function useFilters<TData = T.Query, TVariables extends OperationVariables = OperationVariables>({
  query,
  variables,
  getFilters,
  defaultFilterValues = [],
  defaultValue,
  skip,
}: Options<TData, TVariables>) {
  const [filters, setFilters] = useState<T.Filter[]>([]);
  const [loading, setLoading] = useState(true);
  const [filterValues, setFilterValues] = useState(defaultFilterValues);
  const prevVariables = usePrevious(variables);
  // This helps set the correct loading state
  const variablesChanged = useMemo(
    () => JSON.stringify(variables) !== JSON.stringify(prevVariables),
    [variables, prevVariables],
  );

  const updateFilterValues = useCallback(
    (newFilters: T.Filter[]) => {
      setFilterValues((prevState) => {
        const newState = reduce(
          newFilters,
          (acc: T.FilterValue[], filter) => {
            const { id } = filter;

            if (T.isSelectFilter(filter) || T.isTreeSelectFilter(filter)) {
              const prevFilterValue = find(prevState, { id }) as T.SelectFilterValue | undefined;

              const filterOption = resolveFilterOption(
                filter.options ?? [],
                newFilters.length === 1 && !prevState?.length && !isNil(defaultValue)
                  ? defaultValue
                  : prevFilterValue?.value,
              );

              if (filterOption && !isNil(filterOption.value)) {
                acc.push(mapFilterOptionToFilterValue(id, filterOption));
              }
            }

            if (T.isSelectGroupFilter(filter)) {
              const values: T.FilterCodeValue[] = [];

              filter.filters.forEach((filter) => {
                const prevFilterValue = find(prevState, { id }) as T.SelectFilterValue | undefined;

                const filterOption = resolveFilterOption(
                  filter.options ?? [],
                  prevFilterValue?.value,
                );

                if (filterOption && !isNil(filterOption.value)) {
                  values.push({
                    filterCode: filter.filterCode as string,
                    value: filterOption.value,
                    __typename: 'FilterCodeValue',
                  });
                }
              });

              acc.push(createSelectGroupFilterValue(id, values));
            }

            return acc;
          },
          [],
        );

        // Only set new state if it's changed (this avoids unwanted re-renders)
        if (!isEqual(sortBy(prevState, 'id'), sortBy(newState, 'id'))) {
          return newState;
        }

        return prevState;
      });
    },
    [defaultValue],
  );

  const onCompleted = useCallback(
    (data: TData) => {
      const newFilters = getFilters?.(data) ?? [];
      updateFilterValues(newFilters);
      setFilters(newFilters);
      setLoading(false);
    },
    [updateFilterValues],
  );

  const [fetchData] = useLazyQuery(query, { onCompleted });

  const doFetchData = useCallback(() => {
    setLoading(true);
    fetchData({ variables });
  }, [JSON.stringify(variables)]);

  useEffect(() => {
    if (!skip) {
      doFetchData();
    } else {
      setLoading(true);
    }
  }, [doFetchData, skip]);

  return {
    filters,
    loading: loading || variablesChanged,
    filterValues,
    setFilterValues,
    resolveFilterOption,
    refetch: doFetchData,
  } as const;
}

export { useFilters };
