import { Settings, SettingsOutlined } from '@mui/icons-material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { AiLogoOutlined, LineChartOutlined } from 'components/icons';
import * as T from 'graphql/__generated__/types';
import { Home, HomeOutlined, PlayCircle, PlayCircleOutlined, Star, StarOutlined } from 'icons';
import React from 'react';

interface Props extends SvgIconProps {
  menuIcon: T.MenuIcon;
  active?: boolean;
}

export const MenuIcon: React.FC<Props> = ({ menuIcon, active, ...rest }) => {
  switch (menuIcon) {
    case T.MenuIcon.AilyLogoOutlined:
      return <AiLogoOutlined {...rest} />;
    case T.MenuIcon.HomeOutlined:
      return active ? <Home {...rest} /> : <HomeOutlined {...rest} />;
    case T.MenuIcon.PlayCircleOutlined:
      return active ? <PlayCircle {...rest} /> : <PlayCircleOutlined {...rest} />;
    case T.MenuIcon.SettingsOutlined:
      return active ? <Settings {...rest} /> : <SettingsOutlined {...rest} />;
    case T.MenuIcon.StarOutlined:
      return active ? <Star {...rest} /> : <StarOutlined {...rest} />;
    case T.MenuIcon.LineChartOutlined:
      return <LineChartOutlined {...rest} />;
    default:
      return null;
  }
};
