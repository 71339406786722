import { Link } from 'graphql/__generated__/types';
import React, { createContext, PropsWithChildren, useContext } from 'react';

interface LinkContextState {
  link: Link;
}

const LinkContext = createContext<LinkContextState | null>(null);

export const LinkContextProvider: React.FC<PropsWithChildren<LinkContextState>> = ({
  children,
  link,
}) => <LinkContext.Provider value={{ link }}>{children}</LinkContext.Provider>;

export function useLinkContext() {
  return useContext(LinkContext);
}

export default LinkContext;
