import * as T from './__generated__/types';

/**
 * Create a new select filter value
 * @param {string} id - The ID of the filter
 * @param {number} value - The selected filter value
 * @returns {T.SelectFilterValue} - A select filter value
 */
export function createSelectFilterValue(id: string, value: number): T.SelectFilterValue {
  return { id, value, __typename: 'SelectFilterValue' };
}

/**
 * Create a new select group filter value
 * @param {string} id - The ID of the filter
 * @param {T.FilterCodeValue[]} values - The filter code values
 * @returns {T.SelectGroupFilterValue} - A select group filter value
 */
export function createSelectGroupFilterValue(
  id: string,
  values: T.FilterCodeValue[],
): T.SelectGroupFilterValue {
  return { id, values, __typename: 'SelectGroupFilterValue' };
}

/**
 * Gets the ID of the filter from a filter input
 * @param {T.FilterInput} filterInput - The filter input
 * @returns {string} - The ID of the filter
 */
export function getFilterIdFromFilterInput(filterInput: T.FilterInput) {
  if ('selectFilter' in filterInput) {
    return filterInput.selectFilter?.id;
  }

  if ('selectGroupFilter' in filterInput) {
    return filterInput.selectGroupFilter?.id;
  }

  return undefined;
}

/**
 * Map a filter component to a filter value
 * @param {T.FilterComponent} filterComponent - The filter component
 * @returns {T.FilterValue} - A filter value
 */
export function mapFilterComponentToFilterValue(filterComponent: T.FilterComponent): T.FilterValue {
  const { id, defaultValue } = filterComponent;
  return createSelectFilterValue(id, defaultValue ?? -1);
}

/**
 * Map filter code values to filter code inputs
 * @param {T.FilterCodeValue[]} values - The filter code values
 * @returns {T.SelectFilterCodeInput[]} - An array of filter code inputs
 */
export function mapFilterCodeValuesToFilterCodeInputs(
  values: T.FilterCodeValue[],
): T.SelectFilterCodeInput[] {
  return values.map(({ filterCode, value }) => ({ filterCode, value }));
}

/**
 * Map a filter option to a filter value
 * @param {T.Filter['id']} filterId - The filter ID
 * @param {T.FilterOptionResult | T.TreeFilterOptionResult} filterOption - The filter option
 * @returns {T.SelectFilterValue} - A select filter value
 */
export function mapFilterOptionToFilterValue(
  filterId: T.Filter['id'],
  filterOption: T.FilterOptionResult | T.TreeFilterOptionResult,
): T.SelectFilterValue {
  return createSelectFilterValue(filterId, filterOption.value ?? -1);
}

/**
 * Map a filter value to a filter input
 * @param {T.FilterValue} filterValue - The filter value
 * @returns {T.FilterInput | undefined} - A filter input or undefined if mapping is not possible
 */
export function mapFilterValueToFilterInput(filterValue: T.FilterValue): T.FilterInput | undefined {
  if (T.isSelectFilterValue(filterValue)) {
    return { selectFilter: { id: filterValue.id, value: filterValue.value } };
  }

  if (T.isSelectGroupFilterValue(filterValue)) {
    return {
      selectGroupFilter: {
        id: filterValue.id,
        values: mapFilterCodeValuesToFilterCodeInputs(filterValue.values as T.FilterCodeValue[]),
      },
    };
  }

  return undefined;
}

/**
 * Reduce a filter value to a filter input
 * @param {T.FilterInput[]} acc - The accumulator for filter inputs
 * @param {T.FilterValue} filterValue - The filter value
 * @returns {T.FilterInput[]} - An array of filter inputs
 */
export function reduceFilterValueToFilterInput(
  acc: T.FilterInput[],
  filterValue: T.FilterValue,
): T.FilterInput[] {
  const filterInput = mapFilterValueToFilterInput(filterValue);
  if (filterInput) {
    acc.push(filterInput);
  }

  return acc;
}
