import { Skeleton, SkeletonProps, styled } from '@mui/material';
import React from 'react';

interface Props extends SkeletonProps {}

const StyledDiv = styled('div')({
  // Collapse multiple adjacent elements into one
  '& + &': {
    display: 'none',
  },
});

const LoadingSkeleton: React.FC<Props> = (props) => (
  <StyledDiv>
    <Skeleton width="38%" {...props} />
    <Skeleton {...props} />
    <Skeleton {...props} />
    <Skeleton width="61%" {...props} />
  </StyledDiv>
);

export default LoadingSkeleton;
