import { AnalyticsBrowser } from '@segment/analytics-next';
import React, { createContext, useContext, useMemo } from 'react';

/**
 * A generic interface for tracking events in a consistent format across the application.
 */
export interface GenericEventPayload {
  /**
   * A string that identifies the component that triggered the event.
   */
  component?: string;

  /**
   * A string that describes the category.
   */
  category?: string;

  /**
   * A string that describes the action that was taken.
   */
  action?: string;

  /**
   * An optional object that can contain any additional data relevant to the event.
   */
  data?: Record<string, any>;
  tenant?: string;
  env?: string;
  /**
   * Whether it's an Aily user
   */
  is_aily_user?: boolean;
}

interface NoopAnalytics {
  identify: () => void;
  page: () => void;
  track: () => void;
}

interface AnalyticsProviderProps {
  writeKey?: string;
  children: React.ReactNode;
}

const AnalyticsContext = createContext<AnalyticsBrowser | NoopAnalytics | null>(null);

const noopAnalytics: NoopAnalytics = {
  identify: () => {},
  page: () => {},
  track: () => {},
};

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({ writeKey, children }) => {
  const analytics = useMemo(
    () => (writeKey ? AnalyticsBrowser.load({ writeKey }, { obfuscate: true }) : noopAnalytics),
    [writeKey],
  );

  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
};

export function useAnalytics() {
  const context = useContext(AnalyticsContext);

  if (!context) {
    throw new Error('useAnalytics must be called within AnalyticsProvider');
  }

  return context;
}

export default AnalyticsContext;
