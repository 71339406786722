import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React from 'react';

export const Home: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="28" height="28" viewBox="0 0 28 28" fill="currentColor" {...props}>
    <path d="M25.8809 13.8087L15.3152 3.24851L14.607 2.5403C14.4457 2.38 14.2275 2.29004 14 2.29004C13.7726 2.29004 13.5543 2.38 13.393 2.5403L2.11916 13.8087C1.95381 13.9734 1.82314 14.1695 1.73485 14.3855C1.64655 14.6016 1.60244 14.8331 1.60509 15.0665C1.61603 16.029 2.4172 16.7973 3.3797 16.7973H4.54181V25.7032H23.4582V16.7973H24.6449C25.1125 16.7973 25.5527 16.6141 25.8836 16.2833C26.0465 16.1209 26.1756 15.9278 26.2634 15.7152C26.3511 15.5025 26.3959 15.2746 26.3949 15.0446C26.3949 14.5798 26.2117 14.1395 25.8809 13.8087ZM21.4895 14.8286V23.7344H17.2771H16.1875H12.5C11.8957 23.7344 10.7188 23.7344 10.7188 23.7344H6.51056V14.8286H3.88556L14.0027 4.7196L14.6344 5.35124L24.1172 14.8286H21.4895Z" />
    <path d="M19.1816 13.9383V22H16.3333V17.6081C16.3333 17.2275 15.9737 17.2338 15.5556 17.2338H12.4444C12.0263 17.2338 11.6667 17.2275 11.6667 17.6081V22H8.81646V13.9383H7L14.0009 7L14.438 7.39784L21 13.9383H19.1816Z" />
  </SvgIcon>
);
