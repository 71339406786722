import { Components, Theme } from '@mui/material';

export const MuiMenuItem: Components<Theme>['MuiMenuItem'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.body,
      '&:hover, &.Mui-selected, &.Mui-selected.Mui-focusVisible': {
        color: `${theme.palette.text.primary} !important`,
        backgroundColor: 'transparent !important',
      },
      '&.Mui-focusVisible': {
        backgroundColor: 'transparent !important',
      },
    }),
  },
};
