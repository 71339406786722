import { ChipProps } from '@mui/material/Chip/Chip';
import { IDeepEntry } from 'deepdash-es/IDeepEntry';
import { findDeep as _findDeep } from 'deepdash-es/standalone';
import * as T from 'graphql/__generated__/types';
import {
  Color,
  ColorString,
  FilterOptionResult,
  isColorString,
  isThemeColor,
  Sentiment,
  Story,
  StoryDisplayType,
  ThemeColor,
  TreeFilterOptionResult,
} from 'graphql/__generated__/types';
import { isNil } from 'lodash-es';
import Colors, { colors } from 'theme/colors';

export function getSentimentColor(sentiment?: Sentiment | null, defaultColor?: string) {
  switch (sentiment) {
    case Sentiment.SlightlyPositive:
    case Sentiment.Positive:
    case Sentiment.VeryPositive:
      return Colors.green;

    case Sentiment.SlightlyNegative:
    case Sentiment.Negative:
    case Sentiment.VeryNegative:
      return Colors.pink;

    default:
      return defaultColor;
  }
}

export function getSentimentGradient(sentiment?: Sentiment | null) {
  switch (sentiment) {
    case Sentiment.SlightlyPositive:
    case Sentiment.Positive:
    case Sentiment.VeryPositive:
      return `linear-gradient(180deg, ${Colors.aqua} 0%, ${Colors.green} 100%)`;

    case Sentiment.SlightlyNegative:
    case Sentiment.Negative:
    case Sentiment.VeryNegative:
      return `linear-gradient(180deg, ${Colors.pink} 0%, ${Colors.pink} 100%)`;

    default:
      return `linear-gradient(180deg, ${Colors.aqua} 0%, #138682 100%)`;
  }
}

export function getSentimentColors(sentiment?: T.Sentiment | null): [string, string] {
  switch (sentiment) {
    case T.Sentiment.SlightlyPositive:
    case T.Sentiment.Positive:
    case T.Sentiment.VeryPositive:
      return colors.gradients.green;
    case T.Sentiment.SlightlyNegative:
    case T.Sentiment.Negative:
    case T.Sentiment.VeryNegative:
      return colors.gradients.pink;
    default:
      return colors.gradients.aqua;
  }
}

export function getStoryGradient(story: Story) {
  switch (story.storyType) {
    case StoryDisplayType.AiXplained:
      return `linear-gradient(180deg, #665191 0%, #5d77a4 100%)`;
    default:
      return getSentimentGradient(story.sentiment);
  }
}

export function findDeep<T = any>(collection: readonly T[], predicate: (item: T) => boolean) {
  return _findDeep(collection, predicate, { childrenPath: ['children'] }) as
    | (Omit<IDeepEntry, 'value' | 'parent'> & { value: T; parent?: T })
    | undefined;
}

export function findFilterOption(
  filterOptions: readonly FilterOptionResult[] | TreeFilterOptionResult[],
  predicate: (filterOption: FilterOptionResult | TreeFilterOptionResult) => boolean,
) {
  return findDeep<FilterOptionResult | TreeFilterOptionResult>(filterOptions, predicate)?.value;
}

export function translateColor(color: Color) {
  const map: { [K in Color]: string } = {
    [Color.AilyBlue]: Colors.ailyBlue,
    [Color.Aqua]: Colors.aqua,
    [Color.Black]: Colors.black,
    [Color.Blue]: Colors.blue,
    [Color.DarkBlue]: Colors.darkBlue,
    [Color.Default]: Colors.white,
    [Color.Failure]: Colors.pink,
    [Color.Green]: Colors.green,
    [Color.Grey_1]: Colors.grey1,
    [Color.Grey_2]: Colors.grey2,
    [Color.Grey_3]: Colors.grey3,
    [Color.Orange]: Colors.orange,
    [Color.Pink]: Colors.pink,
    [Color.Success]: Colors.green,
    [Color.Warning]: Colors.orange,
    [Color.White]: Colors.white,
  };

  return map[color];
}

export function resolveColor(
  color: ThemeColor | ColorString | string,
  defaultColor: string = Colors.aqua,
): string {
  if (isThemeColor(color)) {
    return color.color ? translateColor(color.color) : defaultColor;
  }

  if (isColorString(color)) {
    return color.color ?? defaultColor;
  }

  return color;
}

export function translateSentimentToChipColor(sentiment?: T.Sentiment | null): ChipProps['color'] {
  switch (sentiment) {
    case T.Sentiment.SlightlyPositive:
    case T.Sentiment.Positive:
    case T.Sentiment.VeryPositive:
      return 'success';
    case T.Sentiment.SlightlyNegative:
      return 'warning';
    case T.Sentiment.Negative:
    case T.Sentiment.VeryNegative:
      return 'error';
    default:
      return undefined;
  }
}

export function isSafari154() {
  return (
    typeof navigator !== 'undefined' &&
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
    /version\/15\.[4-9]/i.test(navigator.userAgent)
  );
}

export function resolveFilterOption(
  filterOptions: FilterOptionResult[] | TreeFilterOptionResult[],
  selectedOptionValue?: number,
): FilterOptionResult | TreeFilterOptionResult | undefined {
  return (
    // Find the option by the given value
    (!isNil(selectedOptionValue)
      ? findFilterOption(filterOptions, ({ value }) => value === selectedOptionValue)
      : undefined) ??
    // If the filter option is not found, try to get the default
    findFilterOption(filterOptions, ({ isDefault, value }) => !!isDefault && !isNil(value)) ??
    // If the default filter option is not found, fallback to the first selectable option
    findFilterOption(filterOptions, ({ value }) => !isNil(value))
  );
}
