import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React from 'react';

export const Star: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="28" height="28" viewBox="0 0 28 28" fill="currentColor" {...props}>
    <path d="M24.3308 9.6547L17.3882 8.64571L14.2847 2.35392C14.1999 2.18165 14.0604 2.0422 13.8882 1.95743C13.4561 1.74415 12.9311 1.92189 12.7151 2.35392L9.61162 8.64571L2.66904 9.6547C2.47763 9.68204 2.30263 9.77228 2.16865 9.90899C2.00667 10.0755 1.91741 10.2995 1.92049 10.5317C1.92356 10.764 2.01872 10.9855 2.18505 11.1477L7.2081 16.0449L6.02138 22.9602C5.99355 23.121 6.01135 23.2865 6.07277 23.4377C6.13418 23.589 6.23675 23.72 6.36884 23.816C6.50093 23.9119 6.65725 23.9689 6.82009 23.9805C6.98293 23.9921 7.14577 23.9579 7.29013 23.8817L13.4999 20.6168L19.7097 23.8817C19.8792 23.9719 20.0761 24.002 20.2647 23.9692C20.7405 23.8871 21.0604 23.4359 20.9784 22.9602L19.7917 16.0449L24.8147 11.1477C24.9515 11.0137 25.0417 10.8387 25.069 10.6473C25.1429 10.1688 24.8093 9.72579 24.3308 9.6547ZM17.678 15.3559L18.6651 21.1063L13.4999 18.3938L8.33466 21.109L9.32177 15.3586L5.14365 11.2844L10.9186 10.4449L13.4999 5.21407L16.0811 10.4449L21.8561 11.2844L17.678 15.3559Z" />
    <path d="M16.25 15.0186L16.8997 18.9981L13.5 17.1209L10.1003 19L10.75 15.0205L8 12.2009L11.801 11.62L13.5 8L15.199 11.62L19 12.2009L16.25 15.0186Z" />
  </SvgIcon>
);
