import { TableColumnOptionsResult, TableColumnResult } from 'graphql/__generated__/types';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import reject from 'lodash-es/reject';
import unionBy from 'lodash-es/unionBy';
import { useCallback, useEffect, useState } from 'react';

interface ToggleGroupState {
  toggleGroup: TableColumnOptionsResult['toggleGroup'];
  dataKey: TableColumnResult['dataKey'];
}

export function useColumnToggling(columns?: TableColumnResult[] | null) {
  const [toggledColumns, setToggledColumns] = useState<ToggleGroupState[]>([]);

  /**
   * Column is toggleable if there are multiple columns with the same toggle group
   */
  const isColumnToggleable = useCallback<(column: TableColumnResult) => boolean>(
    ({ options }) => {
      if (options?.toggleGroup) {
        const { toggleGroup } = options;
        return filter(columns, { options: { toggleGroup } })?.length > 1;
      }

      return false;
    },
    [columns],
  );

  /**
   * Column is toggled if it has a toggled state or if it is toggled by default
   */
  const isColumnToggled = useCallback<(column: TableColumnResult) => boolean>(
    ({ dataKey, options }) => {
      if (options?.toggleGroup) {
        const { toggleGroup } = options;
        const toggleState = find(toggledColumns, { toggleGroup });
        return toggleState ? toggleState.dataKey === dataKey : !!options.toggleDefault;
      }

      return false;
    },
    [toggledColumns],
  );

  /**
   * A helper function that determines whether the column is visible
   */
  const isColumnVisible = useCallback<(column: TableColumnResult) => boolean>(
    (column) => !isColumnToggleable(column) || isColumnToggled(column),
    [isColumnToggleable, isColumnToggled],
  );

  const toggleColumn = useCallback<(column: TableColumnResult) => TableColumnResult | undefined>(
    (column) => {
      const { dataKey, options } = column;
      if (options?.toggleGroup) {
        const { toggleGroup } = options;
        const nextColumnDataKey = (
          find(
            // Exclude the current column
            reject(columns, { dataKey }),
            // Find the next column with the same toggle group
            { options: { toggleGroup } },
          ) as TableColumnResult | undefined
        )?.dataKey;

        if (nextColumnDataKey) {
          setToggledColumns((prevState) =>
            unionBy([{ toggleGroup, dataKey: nextColumnDataKey }], prevState, 'toggleGroup'),
          );

          return find(columns, { dataKey: nextColumnDataKey });
        }
      }
    },
    [columns],
  );

  useEffect(() => {
    setToggledColumns([]);
  }, [columns]);

  return {
    toggledColumns,
    setToggledColumns,
    isColumnToggleable,
    isColumnToggled,
    isColumnVisible,
    toggleColumn,
  } as const;
}
