import { useNavigateSearch, useSearchParams } from 'hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

export interface UseStateParamProps<T> {
  paramName: string;
  serialize: (state: T) => string;
  deserialize: (state: string) => T;
  validate?: (state: T) => boolean;
  initialState?: T;
}

export function useStateParam<T extends unknown>({
  paramName,
  serialize,
  deserialize,
  validate = () => true,
  initialState,
}: UseStateParamProps<T>) {
  const { pathname } = useLocation();
  const searchParams = useSearchParams();
  const navigateSearch = useNavigateSearch();

  const paramState = searchParams.get(paramName);

  const defaultState = useMemo(() => {
    if (paramState) {
      const newState = deserialize(paramState);
      return validate(newState) ? newState : initialState;
    }

    return initialState;
  }, [paramState, deserialize, validate]);

  const [state, setState] = useState<T | undefined>(defaultState);

  const handleChange = useCallback(
    (newState: T) => {
      if (validate(newState)) {
        const serialized = serialize(newState);

        if (serialized) {
          searchParams.set(paramName, serialized);
        } else {
          searchParams.delete(paramName);
        }

        setState(newState);
        navigateSearch(pathname, searchParams.toString());
      }
    },
    [paramName, validate, serialize, pathname, searchParams],
  );

  useEffect(() => {
    if (paramState) {
      const newState = deserialize(paramState);
      if (validate(newState) && newState !== state) {
        setState(newState);
      }
    }
  }, [paramState, state, deserialize, validate]);

  return [state, handleChange] as const;
}
