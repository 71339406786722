import * as T from 'graphql/__generated__/types';
import find from 'lodash-es/find';
import { useCallback } from 'react';

export function useMetadata(metadata: T.MetaData[]) {
  /**
   * Finds and extracts a meta key from the string
   */
  const getKey = useCallback((value: string) => value.match(/^{{meta\.(.*?)}}$/)?.[1], []);

  /**
   * Gets an item with the given meta key
   */
  const getItem = useCallback((key: T.MetaData['key']) => find(metadata, { key }), [metadata]);

  /**
   * Translates the given string with metadata information if the string contains a meta placeholder and a meta item can be found
   */
  const translateValue = useCallback(
    (value: string) => {
      const metaKey = getKey(value);
      if (metaKey) {
        const metaItem = getItem(metaKey);
        if (metaItem) {
          return `${metaItem.label}: ${metaItem.value}`;
        }
      }

      return value;
    },
    [getKey, getItem],
  );

  return { getKey, getItem, translateValue } as const;
}
