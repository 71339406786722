import { Components, keyframes, Theme } from '@mui/material';

const skeletonAnimation = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
`;

export const MuiSkeleton: Components<Theme>['MuiSkeleton'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.background.default,
      '&:after': {
        animation: `${skeletonAnimation} 0.7s ease infinite !important`,
        background: 'linear-gradient(90deg, transparent, #223456, transparent)',
      },
    }),
    text: ({ theme }) => ({
      height: theme.spacing(2),
      marginTop: theme.spacing(2),
      borderRadius: theme.spacing(0.5),
      transform: 'none',
    }),
  },
  defaultProps: {
    animation: 'wave',
  },
};
