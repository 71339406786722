import { useMemo } from 'react';
import { useAuth } from 'react-oidc-context';

export const ailyEmailDomain = 'ailylabs.com';

/**
 * Custom hook to check whether it's an Aily user
 */
export function useIsAilyUser(): boolean {
  const { user } = useAuth();

  return useMemo(
    () => user?.profile?.email?.split('@')[1]?.toLowerCase() === ailyEmailDomain,
    [user],
  );
}
