import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Module, SelectFilter, TreeSelectFilter } from 'graphql/__generated__/types';
import { RootState } from 'store/rootReducer';

import { PriorityFilterState } from './types';

const initialState: PriorityFilterState = {
  priorityFilterByModuleId: {},
  loading: false,
  error: null,
};

const slice = createSlice({
  name: 'priorityFilter',
  initialState,
  reducers: {
    fetchPriorityFilterRequest: (state, _action: PayloadAction<{ moduleId: Module['id'] }>) => {
      state.loading = true;
      state.error = null;
    },
    fetchPriorityFilterFailure: (state, action: PayloadAction<Error>) => {
      state.loading = false;
      state.error = action.payload;
    },
    fetchPriorityFilterSuccess: (
      state,
      action: PayloadAction<{
        moduleId: Module['id'];
        priorityFilter: SelectFilter | TreeSelectFilter;
      }>,
    ) => {
      const { moduleId, priorityFilter } = action.payload;

      state.priorityFilterByModuleId[moduleId] = priorityFilter;
      state.loading = false;
    },
  },
});

export const getPriorityFilter = (moduleId: Module['id']) => (state: RootState) =>
  state.priorityFilter.priorityFilterByModuleId[moduleId];

export const getPriorityFilterLoading = (state: RootState) => state.priorityFilter.loading;
export const getPriorityFilterError = (state: RootState) => state.priorityFilter.error;

export const {
  fetchPriorityFilterRequest,
  fetchPriorityFilterFailure,
  fetchPriorityFilterSuccess,
} = slice.actions;

export default slice.reducer;
