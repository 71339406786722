import { User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';
import { AuthProviderProps } from 'react-oidc-context';

export const storageKey = `oidc.user:${process.env.REACT_APP_OIDC_AUTH_URL}:${process.env.REACT_APP_OIDC_CLIENT_ID}`;

export const userManagerSettings: UserManagerSettings = {
  authority: process.env.REACT_APP_OIDC_AUTH_URL as string,
  client_id: process.env.REACT_APP_OIDC_CLIENT_ID as string,
  redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URL as string,
  post_logout_redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URL as string,
  scope: process.env.REACT_APP_OIDC_SCOPE as string,
  response_type: 'code',
  automaticSilentRenew: true,
  loadUserInfo: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

export const userManager = new UserManager(userManagerSettings);

export const oidcConfig: AuthProviderProps = {
  userManager,
  onSigninCallback: () => {
    // Ensure the payload is removed from the URL after a successful login
    window.history.replaceState({}, document.title, window.location.pathname);
  },
};

export function getCurrentUser() {
  const oidcStorage = localStorage.getItem(storageKey);
  return oidcStorage ? User.fromStorageString(oidcStorage) : null;
}
