export enum ModalType {
  INFO,
  CONFIRM,
}

export interface Modal {
  key: string;
  type: ModalType;
  title?: string;
  content?: string;
  okText?: string;
  cancelText?: string;
}

export interface ModalsState {
  modals: Modal[];
}
