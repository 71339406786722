import { FilterSearchParams } from 'constants/filters';
import { useMemo } from 'react';

import { useLink } from './useLink';
import { useSearchParams } from './useSearchParams';

function deserializeValue(value: string | null): number[] | undefined {
  return value ? value.split('.').filter(Boolean).map(Number) : undefined;
}

export function useDrillId() {
  const searchParams = useSearchParams();
  const link = useLink();

  return useMemo(() => {
    if (searchParams.has(FilterSearchParams.DRILL_ID)) {
      return deserializeValue(searchParams.get(FilterSearchParams.DRILL_ID));
    }

    return link?.drillIds ?? undefined;
  }, [searchParams, link?.drillIds]);
}
