import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const ArrowCircleDown: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="28" height="28" viewBox="0 0 28 28" {...props}>
    <path d="M28 13.9876C27.9876 6.2716 21.7009 0 13.9815 0C6.26203 0.0123444 -0.0123329 6.29629 1.82037e-05 14.0123C0.0123693 21.7407 6.29908 28.0123 14.0185 28C21.738 27.9876 28.0123 21.7037 28 13.9876ZM2.38378 14.0123C2.37143 7.60493 7.5836 2.39506 13.9938 2.38271C20.4041 2.37037 25.6162 7.58024 25.6286 13.9876C25.6409 20.395 20.4288 25.6049 14.0185 25.6173C7.6083 25.6173 2.39613 20.4197 2.38378 14.0123Z" />
    <path d="M21.1082 14.6791L19.3914 12.9754L14.2781 18.0988L9.14 12.9877L7.43555 14.6914L14.2781 21.5186L21.1082 14.6791Z" />
    <path d="M15.4888 19.8149L15.4765 6.43223H13.0557L13.068 19.8149H15.4888Z" />
  </SvgIcon>
);
