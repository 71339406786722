import useResizeObserver, { UseResizeObserverCallback } from '@react-hook/resize-observer';
import { RefObject, useCallback, useState } from 'react';

interface Overflow {
  x: number;
  y: number;
}

export function useOverflow<T extends HTMLElement>(ref: RefObject<T> | null): Overflow {
  const [overflow, setOverflow] = useState<Overflow>({ x: 0, y: 0 });

  const onResize = useCallback<UseResizeObserverCallback>(
    ({ target: { scrollWidth, scrollHeight, clientWidth, clientHeight } }) => {
      setOverflow({ x: scrollWidth - clientWidth, y: scrollHeight - clientHeight });
    },
    [],
  );

  useResizeObserver(ref, onResize);

  return overflow;
}
