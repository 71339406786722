import { Components, Theme } from '@mui/material';

export const MuiContainer: Components<Theme>['MuiContainer'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      [theme.breakpoints.up('xs')]: {
        padding: theme.spacing(0, 2),
      },
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(0, 5),
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: theme.breakpoints.values['lg'],
        padding: theme.spacing(0, 7.5),
      },
      [theme.breakpoints.up('xl')]: {
        maxWidth: theme.breakpoints.values['xl'],
      },
      [theme.breakpoints.up('xxl')]: {
        maxWidth: theme.breakpoints.values['xxl'],
        padding: theme.spacing(0, 15),
      },
    }),
  },
};
