import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import LoadingSkeleton from 'components/LoadingSkeleton';
import * as T from 'graphql/__generated__/types';
import { PageTypeEnum } from 'graphql/__generated__/types';
import find from 'lodash-es/find';
import AddPrioritiesPage from 'pages/AddPrioritiesPage';
import NotFoundPage from 'pages/NotFoundPage';
import ServerErrorPage from 'pages/ServerErrorPage';
import UnauthorizedPage from 'pages/UnauthorizedPage';
import UserPrioritiesPage from 'pages/UserPrioritiesPage';
import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, RouteObject, useRoutes } from 'react-router-dom';
import { getDefaultModule, getModules } from 'store/modules/slices';

function createPageRoute(page: T.Page, parentPage?: T.Page): RouteObject {
  // If the path is empty, use the last segment of the absolute path as a fallback
  const path = page.path || page.absolutePath.split('/').pop();

  return {
    // Allow component-based routing for the home page
    path: page.pageType === PageTypeEnum.HomePage ? `${path}/*` : path,
    element: <Page key={page.id} page={page} parentPage={parentPage} />,
    children: page.children?.map((childPage) => createPageRoute(childPage, page)),
  };
}

function createModuleRoute(module: T.Module): RouteObject {
  // Get the first page that is not hidden in the menu
  const defaultPage = find(module.pages, { isMenuHidden: false });

  return {
    path: module.path,
    element: <Outlet />,
    children: [
      {
        path: 'my-priorities',
        element: <Outlet />,
        children: [
          { path: 'add', element: <AddPrioritiesPage /> },
          { index: true, element: <UserPrioritiesPage /> },
        ],
      },
      ...(module.pages?.map((page) => createPageRoute(page)) ?? []),
      {
        index: true,
        element: defaultPage ? <Navigate to={defaultPage.path} replace /> : <NotFoundPage />,
      },
    ] as RouteObject[],
  };
}

// To enable code-splitting on a page level
const Page = React.lazy(() => import('components/Page'));

const SuspenseFallback: React.FC = () => (
  <Container maxWidth="xl">
    <Box sx={{ py: 7.5 }}>
      <LoadingSkeleton />
    </Box>
  </Container>
);

const AppRouter: React.FC = () => {
  const modules = useSelector(getModules);
  const defaultModule = useSelector(getDefaultModule);

  const routes = useRoutes([
    {
      path: '/',
      element: <Outlet />,
      children: [
        ...modules.map(createModuleRoute),
        {
          index: true,
          element: defaultModule ? <Navigate to={defaultModule.path} replace /> : <NotFoundPage />,
        },
      ] as RouteObject[],
    },
    { path: '401', element: <UnauthorizedPage /> },
    { path: '404', element: <NotFoundPage /> },
    { path: '500', element: <ServerErrorPage /> },
    { path: '', element: <Navigate to="web" replace /> },
    {
      path: 'web',
      element: defaultModule ? (
        <Navigate to={`/${defaultModule.path}`} replace />
      ) : (
        <NotFoundPage />
      ),
    },
    { path: '*', element: <NotFoundPage /> },
  ]);

  return <Suspense fallback={<SuspenseFallback />}>{routes}</Suspense>;
};

export default AppRouter;
