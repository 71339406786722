import { useEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

class Item {
  public items: SVGTextElement[] = [];
  public from: number = 0;
  public to: number = 0;
  readonly caption: string = '';

  constructor(...items: SVGTextElement[]) {
    this.items = items;
    const { max, min, first } = items.reduce(
      (pV: { min: number; max: number; first: null | SVGTextElement }, cV: SVGTextElement) => {
        const { x, width } = cV.getBBox();
        if (pV.min === -1 || pV.min > x) {
          pV.min = x;
          pV.first = cV;
        }
        if (pV.max === -1 || pV.max < x + width) {
          pV.max = x + width;
        }
        return pV;
      },
      { min: -1, max: -1, first: null },
    );
    if (first) {
      this.caption = first.textContent || '';
      this.from = (min + max) / 2 - first.getBBox().width;
      this.to = (min + max) / 2 + first.getBBox().width;
    }
  }

  getSize() {
    return { from: this.from, to: this.to };
  }

  getText() {
    return this.caption;
  }

  show(canvas: SVGSVGElement) {
    if (this.items.length === 1) {
      this.items[0].style.visibility = 'visible';
    } else {
      this.items.forEach((item) => (item.style.visibility = 'hidden'));
      const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
      text.textContent = this.getText() + ', ...';
      text.setAttribute('class', this.items[0].getAttribute('class') || '');
      text.setAttribute('data-group', '');
      text.setAttribute(
        'list',
        this.items.reduce((pV, cV) => pV + cV.textContent + ', ', ''),
      );
      text.setAttribute('x', `${(this.to + this.from) / 2}`);
      text.setAttribute('y', this.items[0].getAttribute('y') || '');
      text.setAttribute('dy', this.items[0].getAttribute('dy') || '');
      text.setAttribute('dominant-baseline', 'middle');
      text.setAttribute('text-anchor', 'middle');
      canvas.appendChild(text);
    }
  }
}

const render = (labelsRefs: Item[]): Item[] => {
  const arr: { from: number; to: number }[] = labelsRefs.map((item: Item) => item.getSize());

  const matrix = arr.map(({ from, to }) =>
    arr.map(({ from: from2, to: to2 }) => !(from2 > to || from > to2)),
  );

  let max = labelsRefs.length;
  let maxId = -1;
  let list: number[] = [];
  matrix.forEach((item: boolean[], index: number) => {
    const count = item.reduce((pV: number, cV: boolean) => {
      if (cV) pV += 1;
      return pV;
    }, 0);

    list.push(index);

    if (max >= count && count >= 2) {
      max = count;
      maxId = index;
    }
  });

  if (maxId !== -1) {
    const groupIDs = matrix[maxId]
      .map((item, index) => (item ? index : -1))
      .filter((item) => item !== -1);

    const group = new Item(
      ...groupIDs
        .map((id: number) => labelsRefs[id])
        .reduce((l: SVGTextElement[], cV: Item) => [...l, ...cV.items], []),
    );

    list = list.filter((item) => groupIDs.findIndex((groupId) => groupId === item) === -1);
    const newList = labelsRefs.filter(
      (_, index) => list.findIndex((item) => item === index) !== -1,
    );

    newList.push(group);
    return render(newList);
  }

  return labelsRefs;
};

export function useTimelineLabelsGroup(timelineSVG: SVGSVGElement | null): void {
  let oldWidth: number = 0;

  const [ro] = useState(
    new ResizeObserver((entries) => {
      if (oldWidth !== entries[0].contentRect.width) {
        oldWidth = entries[0].contentRect.width;
        entries[0].target
          .querySelectorAll<SVGTextElement>('text[data-group]')
          .forEach((item) => entries[0].target.removeChild(item));

        const items: Item[] = [];
        entries[0].target
          .querySelectorAll<SVGTextElement>('text:not([data-group])')
          .forEach((item) => {
            items.push(new Item(item));
          });

        render(items).forEach((item) => item.show(entries[0].target as SVGSVGElement));
      }
    }),
  );

  useEffect(() => {
    if (timelineSVG) {
      ro.observe(timelineSVG);
    }

    return () => ro.disconnect();
  }, [timelineSVG]);
}
