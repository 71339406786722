import * as T from 'graphql/__generated__/types';
import React from 'react';
import Colors from 'theme/colors';
import { getSentimentColor } from 'utils';

interface Props {
  value?: string;
  sentiment?: string;
}

const Variance: React.FC<Props> = ({ value, sentiment }) => {
  return (
    <div
      className="variance"
      style={{ color: getSentimentColor(sentiment as T.Sentiment, Colors.aqua) }}
    >
      {value}
    </div>
  );
};

export default Variance;
