import { Module } from 'graphql/__generated__/types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getModules } from 'store/modules/slices';

/**
 * Custom hook to get the current module
 */
export function useCurrentModule(): Module | undefined {
  const modules = useSelector(getModules);
  const { pathname } = useLocation();

  return useMemo(
    // Find a module by its path, matching it with the exact pathname or its starting segment
    () => modules.find(({ path }) => pathname === `/${path}` || pathname.startsWith(`/${path}/`)),
    [modules, pathname],
  );
}
