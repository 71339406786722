import './fonts';

import { ApolloProvider } from '@apollo/client';
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { AnalyticsProvider, UserSettingsProvider } from 'contexts';
import ModalProvider from 'mui-modal-provider';
import { SnackbarProvider } from 'notistack';
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from 'react-oidc-context';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { oidcConfig } from 'services/authService';
import store from 'store';

import App from './App';
import { client } from './graphql/client';
import theme from './theme';

window.history.replaceState({}, document.title);

const root = createRoot(document.getElementById('root') as HTMLDivElement);

root.render(
  <StrictMode>
    <AuthProvider {...oidcConfig}>
      <AnalyticsProvider writeKey={process.env.REACT_APP_ANALYTICS_WRITE_KEY}>
        <StoreProvider store={store}>
          <ApolloProvider client={client}>
            <HelmetProvider>
              <ModalProvider>
                <SnackbarProvider maxSnack={3}>
                  <BrowserRouter>
                    <StyledEngineProvider injectFirst>
                      <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <UserSettingsProvider>
                          <App />
                        </UserSettingsProvider>
                      </ThemeProvider>
                    </StyledEngineProvider>
                  </BrowserRouter>
                </SnackbarProvider>
              </ModalProvider>
            </HelmetProvider>
          </ApolloProvider>
        </StoreProvider>
      </AnalyticsProvider>
    </AuthProvider>
  </StrictMode>,
);
