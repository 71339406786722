import 'services/monitoringService';

import AppRouter from 'components/AppRouter';
import LaunchScreen from 'components/LaunchScreen';
import ModalRoot from 'components/ModalRoot';
import PageLayout from 'components/PageLayout';
import { useAnalyticsIdentifyUser } from 'hooks/useAnalyticsIdentifyUser';
import { useAppStartedDispatcher } from 'hooks/useAppStartedDispatcher';
import { useAutoSignIn } from 'hooks/useAutoSignIn';
import { useCaptureAuthError } from 'hooks/useCaptureAuthError';
import { useModuleChangedDispatcher } from 'hooks/useModuleChangedDispatcher';
import React from 'react';
import { useAuth } from 'react-oidc-context';
import { useSelector } from 'react-redux';
import { getAppIsInitialized } from 'store/app/slices';

import { useAnalyticsTrackPage } from './hooks/useAnalyticsTrackPage';

function App() {
  const { error } = useAuth();
  const appIsInitialized = useSelector(getAppIsInitialized);

  useAutoSignIn();
  useAnalyticsIdentifyUser();
  useAnalyticsTrackPage();
  useCaptureAuthError();
  useAppStartedDispatcher();
  useModuleChangedDispatcher();

  return (
    <>
      <ModalRoot />
      {appIsInitialized ? (
        <PageLayout>
          <AppRouter />
        </PageLayout>
      ) : (
        <LaunchScreen error={error} />
      )}
    </>
  );
}

export default App;
