import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';

import { AppState } from './types';

const initialState: AppState = {
  isInitialized: false,
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    appStarted: (state) => state,
    appInitialized: (state) => {
      state.isInitialized = true;
    },
    verifyUserDisclaimerRequest: (state) => state,
    verifyUserDisclaimerFailure: (state, _action: PayloadAction<Error>) => state,
    verifyUserDisclaimerSuccess: (state) => state,
  },
});

export const getAppIsInitialized = (state: RootState) => state.app.isInitialized;

export const {
  appStarted,
  appInitialized,
  verifyUserDisclaimerRequest,
  verifyUserDisclaimerFailure,
  verifyUserDisclaimerSuccess,
} = slice.actions;

export default slice.reducer;
