import { Components, Theme } from '@mui/material';

export const MuiTooltip: Components<Theme>['MuiTooltip'] = {
  styleOverrides: {
    tooltip: ({ theme }) => ({
      backgroundColor: '#2a3558',
      boxShadow: theme.shadows['3'],
    }),
    arrow: {
      color: '#2a3558',
    },
  },
  defaultProps: {
    arrow: true,
    enterDelay: 0,
  },
};
