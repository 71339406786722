import { Box, Button, Container, Grid } from '@mui/material';
import PageHeader from 'components/PageHeader';
import SearchBar from 'components/SearchBar';
import AddPriorities from 'components/UserPriorities/AddPriorities';
import { useAnalyticsTrackEvent } from 'hooks/useAnalyticsTrackEvent';
import { useStateParam } from 'hooks/useStateParam';
import React, { useCallback, useDeferredValue } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

interface Props {
  title?: string;
}

const AddPrioritiesPage: React.FC<Props> = ({ title = 'Add priorities' }) => {
  const { trackEvent } = useAnalyticsTrackEvent();

  const [search, setSearch] = useStateParam<string | undefined>({
    paramName: 'q',
    serialize: useCallback((state) => state ?? '', []),
    deserialize: useCallback((state) => state, []),
  });

  const deferredSearch = useDeferredValue(search);
  const navigate = useNavigate();

  const trackPrioritiesDoneEvent = useCallback(() => {
    trackEvent('item.clicked', {
      component: 'priorities',
      intent: 'done',
      item_type: 'button',
    });
  }, [trackEvent]);

  const handleClose = useCallback(() => {
    trackPrioritiesDoneEvent();

    // Navigate to the parent page
    navigate('../');
  }, [trackPrioritiesDoneEvent]);

  return (
    <>
      <Helmet>
        <title>
          {title} | {process.env.REACT_APP_SITE_NAME}
        </title>
      </Helmet>
      <Box data-testid="AddPrioritiesPage">
        <PageHeader
          sticky
          title={title}
          closeButton={
            <Button
              variant="outlined"
              onClick={handleClose}
              sx={{
                height: 36,
                borderRadius: 36,
                padding: (theme) => theme.spacing(1, 3),
                borderColor: (theme) => theme.palette.text.primary,
                color: (theme) => theme.palette.text.primary,
                '&:hover': {
                  backgroundColor: 'transparent',
                  borderColor: (theme) => theme.palette.text.primary,
                  color: (theme) => theme.palette.text.primary,
                },
              }}
              data-testid="CloseButton"
            >
              Done
            </Button>
          }
        >
          <SearchBar value={search} onChange={setSearch} sx={{ mt: 4 }} />
        </PageHeader>
        <Container maxWidth="xl">
          <Box sx={{ pb: 5 }}>
            <Grid container>
              <Grid item xs={12} lg={6}>
                <AddPriorities search={deferredSearch} />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default AddPrioritiesPage;
