import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React from 'react';

export const LineChartOutlined: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="28" height="28" viewBox="0 0 28 28" {...props}>
    <path
      d="M25.3203 4.48438H2.57031C2.08633 4.48438 1.69531 4.87539 1.69531 5.35938V22.8594C1.69531 23.3434 2.08633 23.7344 2.57031 23.7344H25.3203C25.8043 23.7344 26.1953 23.3434 26.1953 22.8594V5.35938C26.1953 4.87539 25.8043 4.48438 25.3203 4.48438ZM24.2266 21.7656H3.66406V6.45312H24.2266V21.7656ZM6.20977 19.5152C6.29453 19.6 6.43398 19.6 6.51875 19.5152L11.2355 14.7984L14.3637 17.9293C14.4484 18.0141 14.5879 18.0141 14.6727 17.9293L22.7937 9.80273C22.8785 9.71797 22.8785 9.57852 22.7937 9.49375L21.7875 8.4875C21.7464 8.44679 21.6909 8.42395 21.633 8.42395C21.5751 8.42395 21.5196 8.44679 21.4785 8.4875L14.5195 15.4492L11.3914 12.3184C11.3503 12.2776 11.2948 12.2548 11.2369 12.2548C11.1791 12.2548 11.1235 12.2776 11.0824 12.3184L5.20625 18.1973C5.16554 18.2384 5.1427 18.2939 5.1427 18.3518C5.1427 18.4096 5.16554 18.4651 5.20625 18.5062L6.20977 19.5152Z"
      fill="currentColor"
    />
  </SvgIcon>
);
