import { styled, Typography } from '@mui/material';
import AilyLogo from 'components/AilyLogo';
import { motion } from 'framer-motion';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getModulesError } from 'store/modules/slices';

interface Props {
  error?: Error;
}

const MainWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
}));

const LogoWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  // Shift it up a little for better visibility
  paddingBottom: 60,
}));

const Logo = styled(AilyLogo)(({ theme }) => ({
  width: 130,
  height: 130,
  marginBottom: 30,
}));

const MotionDiv: React.FC<PropsWithChildren> = ({ children }) => (
  <motion.div
    animate={{ opacity: 1, scale: 1 }}
    initial={{ opacity: 0, scale: 0.8 }}
    transition={{ duration: 0.15, ease: 'easeOut' }}
  >
    {children}
  </motion.div>
);

const LaunchScreen: React.FC<Props> = ({ error }) => {
  const modulesError = useSelector(getModulesError);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('Loading');

  useEffect(() => {
    if (error || modulesError) {
      setLoading(false);
      setMessage('Error: Could not load data');
    }
  }, [error, modulesError]);

  return (
    <MainWrapper>
      <MotionDiv>
        <LogoWrapper>
          <Logo loading={loading} />
          <Typography variant="subtitle">{message}</Typography>
        </LogoWrapper>
      </MotionDiv>
    </MainWrapper>
  );
};

export default LaunchScreen;
