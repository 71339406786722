import * as T from 'graphql/__generated__/types';
import { reduceFilterValueToFilterInput } from 'graphql/utils';
import React, { createContext, PropsWithChildren, useCallback, useContext, useMemo } from 'react';

export interface FilterState {
  filter: T.Filter | null;
  filterValue: T.FilterValue | null;
  loading: boolean;
}

export interface FiltersContextState {
  filterStates: FilterState[];
  setFilterStates: React.Dispatch<React.SetStateAction<FilterState[]>>;
  hasFilter: (filterId: T.Filter['id']) => boolean;
  filtersLoading: boolean;
  filterValues: T.FilterValue[];
  filterInputs: T.FilterInput[];
}

const FiltersContext = createContext<FiltersContextState | null>(null);

function reduceFilterStateToFilterValue(
  acc: T.FilterValue[],
  filterState: FilterState,
): T.FilterValue[] {
  const { filterValue } = filterState;
  if (filterValue) {
    acc.push(filterValue);
  }

  return acc;
}

export const FiltersContextProvider: React.FC<
  PropsWithChildren<Pick<FiltersContextState, 'filterStates' | 'setFilterStates'>>
> = ({ children, filterStates, setFilterStates }) => {
  const hasFilter = useCallback(
    (id: T.Filter['id']) => !!filterStates.find(({ filter }) => filter?.id === id),
    [filterStates],
  );

  const filtersLoading = useMemo(() => filterStates.some(({ loading }) => loading), [filterStates]);

  const filterValues = useMemo(
    () => filterStates.reduce(reduceFilterStateToFilterValue, []),
    [filterStates],
  );

  const filterInputs = useMemo(
    () => filterValues.reduce(reduceFilterValueToFilterInput, []),
    [filterValues],
  );

  return (
    <FiltersContext.Provider
      value={{
        filterStates,
        setFilterStates,
        hasFilter,
        filtersLoading,
        filterValues,
        filterInputs,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export function useFiltersContext() {
  return useContext(FiltersContext);
}

export default FiltersContext;
