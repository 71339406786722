import { useLinkContext } from 'contexts/LinkContext';
import { Link } from 'graphql/__generated__/types';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useLink() {
  const linkContext = useLinkContext();
  const { state } = useLocation();
  const locationState = state as { link?: Link };

  return useMemo(() => linkContext?.link ?? locationState?.link, [linkContext, state]);
}
