// prettier-ignore
/**
 * Color scheme taken from the Figma design
 *
 * @see https://www.figma.com/file/xZBvpJ78GAaGwNlPhm3S5H/Aily-Design-system?node-id=1-29170&t=iR0n2errUflthol4-4
 */
const colors = {
  primary: {
    aqua:   '#00a19b',
    green:  '#38d430',
    orange: '#ef8833',
    pink:   '#ff3469',
  },
  darkPrimary: {
    pink: '#88294f',
  },
  lightPrimary: {
    aqua: '#c1e6e4',
  },
  secondary: {
    ailyBlue: '#202945',
    blue:     '#4bade9',
    darkAqua: '#055f5a',
    darkBlue: '#111e35',
    purple:   '#a05195',
  },
  neutrals: {
    black: '#000000',
    grey1: '#232f45',
    grey2: '#363d55',
    grey3: '#485069',
    grey4: '#8a92ac',
    white: '#ffffff',
  },
  overlays: {
    black: 'rgba(0, 0, 0, 0.75)',
  },
  gradients: {
    aqua:              ['#00a19b', '#138682'],
    aquaBright:        ['#06d0c9', '#00a19b'],
    aquaTransparent:   ['#00a19b', 'rgba(0, 161, 155, 0)'],
    blue:              ['#5d77a4', '#4bade9'],
    blueTransparent:   ['#4bade9', 'rgba(93, 119, 164, 0)'],
    brown:             ['#ad5245', '#aa8751'],
    darkBlue:          ['rgba(17, 30, 53, 0.75)', '#111e35'],
    green:             ['#00a19b', '#38d430'],
    greenTransparent:  ['#38d430', 'rgba(56, 212, 48, 0)'],
    orange:            ['#ff5005', '#ef8833'],
    orangeTransparent: ['#ef8833', 'rgba(239, 136, 51, 0)'],
    pink:              ['rgba(254, 44, 99, 0.8)', '#fe2c63'],
    pinkTransparent:   ['#eb4a6c', 'rgba(237, 30, 117, 0)'],
    purple:            ['#665191', '#5d77a4'],
    purpleTransparent: ['#a05195', 'rgba(160, 81, 149, 0)'],
    yellowTransparent: ['#f1e91d', 'rgba(241, 233, 29, 0)'],
  } as Record<string, [string, string]>,
  graphs: [
    '#00a19b', '#ef8833', '#ff3469', '#38d430', '#4bade9', '#f1e91d', '#5d77a4',
    '#f0b8b8', '#ff5005', '#665191', '#aecdc2', '#aa8751', '#ffffff', '#a05195',
    '#ed1e24', '#6aaa96', '#f95d6a', '#9bca3c', '#666666', '#e67f83', '#488f31',
    '#d45087', '#ad5245', '#999999', '#77782c', '#cccccc', '#06ddd3'
  ],
};

export default {
  ailyBlue: colors.secondary.ailyBlue,
  aqua: colors.primary.aqua,
  blue: colors.secondary.blue,
  black: colors.neutrals.black,
  darkAqua: colors.secondary.darkAqua,
  darkBlue: colors.secondary.darkBlue,
  green: colors.primary.green,
  grey1: colors.neutrals.grey1,
  grey2: colors.neutrals.grey2,
  grey3: colors.neutrals.grey4,
  orange: colors.primary.orange,
  pink: colors.primary.pink,
  white: colors.neutrals.white,
};

export { colors };
