import { GenericEventPayload, useAnalytics } from 'contexts';
import { useIsAilyUser } from 'hooks';
import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { getCurrentUser } from 'services/authService';

import { useUserEmailDomain } from './useUserEmailDomain';

export function useAnalyticsIdentifyUser() {
  const { isAuthenticated } = useAuth();
  const { identify } = useAnalytics();
  const emailDomain = useUserEmailDomain();
  const isAilyUser = useIsAilyUser();

  useEffect(() => {
    if (isAuthenticated) {
      const user = getCurrentUser();

      identify(user?.profile?.sub, {
        tenant: process.env.REACT_APP_TENANT,
        env: process.env.REACT_APP_ENV,
        email_domain: emailDomain,
        is_aily_user: isAilyUser,
      } as GenericEventPayload);
    }
  }, [isAuthenticated]);
}
