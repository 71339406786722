import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React from 'react';

export const PlayCircle: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="28" height="28" viewBox="0 0 28 28" fill="currentColor" {...props}>
    <path d="M14 1C6.82098 1 1 6.82098 1 14C1 21.179 6.82098 27 14 27C21.179 27 27 21.179 27 14C27 6.82098 21.179 1 14 1ZM14 24.7946C8.03973 24.7946 3.20536 19.9603 3.20536 14C3.20536 8.03973 8.03973 3.20536 14 3.20536C19.9603 3.20536 24.7946 8.03973 24.7946 14C24.7946 19.9603 19.9603 24.7946 14 24.7946Z" />
    <path d="M20.0178 13.625L11.4256 7.38614C11.3567 7.33563 11.2751 7.30524 11.1899 7.29837C11.1047 7.2915 11.0193 7.30841 10.9432 7.34722C10.8671 7.38603 10.8032 7.44522 10.7588 7.51819C10.7143 7.59115 10.691 7.67503 10.6914 7.76047V20.2382C10.6914 20.6183 11.1209 20.833 11.4256 20.6125L20.0178 14.3736C20.0771 14.3309 20.1254 14.2747 20.1587 14.2096C20.192 14.1445 20.2094 14.0724 20.2094 13.9993C20.2094 13.9262 20.192 13.8541 20.1587 13.789C20.1254 13.724 20.0771 13.6677 20.0178 13.625Z" />
  </SvgIcon>
);
