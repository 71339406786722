import { Page } from 'graphql/__generated__/types';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useCurrentModule } from './useCurrentModule';

// Recursive function to perform a depth-first search
function depthFirstSearch(pages: Page[], pathname: string): Page | undefined {
  for (let i = 0; i < pages.length; i++) {
    const page = pages[i];
    if (page.absolutePath === pathname) {
      return page;
    } else if (page.children) {
      const found = depthFirstSearch(page.children, pathname);
      if (found) {
        return found;
      }
    }
  }
}

/**
 * Custom hook to get the current page. It only searches the pages of the current module.
 */
export function useCurrentPage(): Page | undefined {
  const currentModule = useCurrentModule();
  const { pathname } = useLocation();

  return useMemo(
    () => depthFirstSearch(currentModule?.pages ?? [], pathname),
    [currentModule?.pages, pathname],
  );
}
