import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import {
  ChartAxisFieldsFragmentDoc,
  ChartSeriesFieldsFragmentDoc,
  ComponentFieldsFragmentDoc,
  DataViewFieldsFragmentDoc,
  DialItemFieldsFragmentDoc,
  FilterComponentFieldsFragmentDoc,
  FilterFieldsFragmentDoc,
  FilterValueFieldsFragmentDoc,
  KpiCardFieldsFragmentDoc,
  KpiStatusOverviewFieldsFragmentDoc,
  LinkFieldsFragmentDoc,
  MetricFieldsFragmentDoc,
  MetricListFieldsFragmentDoc,
  MicroChartCardFieldsFragmentDoc,
  ModuleFieldsFragmentDoc,
  NavigationTabsComponentFieldsFragmentDoc,
  PlailistCategoryFieldsFragmentDoc,
  PriorityCategoryFieldsFragmentDoc,
  SelectFilterFieldsFragmentDoc,
  SelectGroupFilterFieldsFragmentDoc,
  SimpleCardFieldsFragmentDoc,
  StoryFieldsFragmentDoc,
  SummaryInfoItemFieldsFragmentDoc,
  TableColumnFieldsFragmentDoc,
  TableComponentFieldsFragmentDoc,
  TableRowFieldsFragmentDoc,
  TreeSelectFilterFieldsFragmentDoc,
  UserSettingFieldsFragmentDoc,
} from './fragments';
import * as Types from './types';
const defaultOptions = {
  fetchPolicy: 'no-cache',
  errorPolicy: 'all',
  notifyOnNetworkStatusChange: true,
} as const;
export type GetDataViewQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.DataViewInput>;
}>;

export type GetDataViewQuery = { __typename?: 'Query' } & {
  dataView?: Types.Maybe<
    | ({ __typename?: 'CardsDataView' } & Pick<
        Types.CardsDataView,
        'title' | 'annotation' | 'id' | 'dataViewType'
      > & {
          cards?: Types.Maybe<
            Array<
              Types.Maybe<
                | ({ __typename?: 'KpiCardResult' } & Pick<
                    Types.KpiCardResult,
                    'id' | 'code' | 'cardType' | 'title'
                  > & {
                      items?: Types.Maybe<
                        Array<
                          { __typename?: 'KpiStatus' } & Pick<
                            Types.KpiStatus,
                            'actual' | 'target' | 'sentiment' | 'icon'
                          >
                        >
                      >;
                      linkResult?: Types.Maybe<
                        { __typename?: 'Link' } & Pick<
                          Types.Link,
                          'pageId' | 'absolutePath' | 'drillId' | 'drillIds' | 'target'
                        > & {
                            filters?: Types.Maybe<
                              Array<
                                | ({ __typename?: 'SelectFilterValue' } & Pick<
                                    Types.SelectFilterValue,
                                    'id' | 'value'
                                  >)
                                | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                                    Types.SelectGroupFilterValue,
                                    'id'
                                  > & {
                                      values: Array<
                                        Types.Maybe<
                                          { __typename?: 'FilterCodeValue' } & Pick<
                                            Types.FilterCodeValue,
                                            'filterCode' | 'value'
                                          >
                                        >
                                      >;
                                    })
                              >
                            >;
                          }
                      >;
                    })
                | ({ __typename?: 'MicroChartCardResult' } & Pick<
                    Types.MicroChartCardResult,
                    'id' | 'code' | 'cardType' | 'title'
                  > & {
                      actual?: Types.Maybe<
                        { __typename?: 'TextResult' } & Pick<
                          Types.TextResult,
                          'value' | 'subLabels'
                        >
                      >;
                      variance?: Types.Maybe<
                        { __typename?: 'VarianceResult' } & Pick<
                          Types.VarianceResult,
                          'actual' | 'target' | 'sentiment' | 'label'
                        >
                      >;
                      microChart?: Types.Maybe<
                        { __typename?: 'MicroChartResult' } & Pick<
                          Types.MicroChartResult,
                          'seriesData' | 'trendlineData' | 'sentiment'
                        >
                      >;
                      linkResult: { __typename?: 'Link' } & Pick<
                        Types.Link,
                        'pageId' | 'absolutePath' | 'drillId' | 'drillIds' | 'target'
                      > & {
                          filters?: Types.Maybe<
                            Array<
                              | ({ __typename?: 'SelectFilterValue' } & Pick<
                                  Types.SelectFilterValue,
                                  'id' | 'value'
                                >)
                              | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                                  Types.SelectGroupFilterValue,
                                  'id'
                                > & {
                                    values: Array<
                                      Types.Maybe<
                                        { __typename?: 'FilterCodeValue' } & Pick<
                                          Types.FilterCodeValue,
                                          'filterCode' | 'value'
                                        >
                                      >
                                    >;
                                  })
                            >
                          >;
                        };
                    })
                | ({ __typename?: 'SimpleCardResult' } & Pick<
                    Types.SimpleCardResult,
                    'id' | 'code' | 'cardType' | 'title'
                  > & {
                      textResult?: Types.Maybe<
                        { __typename?: 'TextResult' } & Pick<
                          Types.TextResult,
                          'value' | 'subLabels'
                        >
                      >;
                      linkResult?: Types.Maybe<
                        { __typename?: 'Link' } & Pick<
                          Types.Link,
                          'pageId' | 'absolutePath' | 'drillId' | 'drillIds' | 'target'
                        > & {
                            filters?: Types.Maybe<
                              Array<
                                | ({ __typename?: 'SelectFilterValue' } & Pick<
                                    Types.SelectFilterValue,
                                    'id' | 'value'
                                  >)
                                | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                                    Types.SelectGroupFilterValue,
                                    'id'
                                  > & {
                                      values: Array<
                                        Types.Maybe<
                                          { __typename?: 'FilterCodeValue' } & Pick<
                                            Types.FilterCodeValue,
                                            'filterCode' | 'value'
                                          >
                                        >
                                      >;
                                    })
                              >
                            >;
                          }
                      >;
                    })
              >
            >
          >;
          editLink?: Types.Maybe<
            { __typename?: 'Link' } & Pick<
              Types.Link,
              'pageId' | 'absolutePath' | 'drillId' | 'drillIds' | 'target'
            > & {
                filters?: Types.Maybe<
                  Array<
                    | ({ __typename?: 'SelectFilterValue' } & Pick<
                        Types.SelectFilterValue,
                        'id' | 'value'
                      >)
                    | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                        Types.SelectGroupFilterValue,
                        'id'
                      > & {
                          values: Array<
                            Types.Maybe<
                              { __typename?: 'FilterCodeValue' } & Pick<
                                Types.FilterCodeValue,
                                'filterCode' | 'value'
                              >
                            >
                          >;
                        })
                  >
                >;
              }
          >;
          metadata?: Types.Maybe<
            Array<{ __typename?: 'MetaData' } & Pick<Types.MetaData, 'key' | 'label' | 'value'>>
          >;
          availableUserSettings?: Types.Maybe<
            Array<
              { __typename?: 'UserSetting' } & Pick<
                Types.UserSetting,
                'key' | 'label' | 'defaultValue' | 'userValue'
              > & {
                  options: Array<
                    { __typename?: 'SettingOption' } & Pick<Types.SettingOption, 'label' | 'value'>
                  >;
                }
            >
          >;
        })
    | ({ __typename?: 'ChartDataViewResult' } & Pick<
        Types.ChartDataViewResult,
        'renderer' | 'id' | 'dataViewType'
      > & {
          xAxis:
            | ({ __typename?: 'ChartAxisCategoryViewResult' } & Pick<
                Types.ChartAxisCategoryViewResult,
                'categories' | 'renderer' | 'axisType'
              >)
            | ({ __typename?: 'ChartAxisPercentViewResult' } & Pick<
                Types.ChartAxisPercentViewResult,
                'renderer' | 'axisType'
              >)
            | ({ __typename?: 'ChartAxisValueViewResult' } & Pick<
                Types.ChartAxisValueViewResult,
                'renderer' | 'axisType'
              >);
          yAxis:
            | ({ __typename?: 'ChartAxisCategoryViewResult' } & Pick<
                Types.ChartAxisCategoryViewResult,
                'categories' | 'renderer' | 'axisType'
              >)
            | ({ __typename?: 'ChartAxisPercentViewResult' } & Pick<
                Types.ChartAxisPercentViewResult,
                'renderer' | 'axisType'
              >)
            | ({ __typename?: 'ChartAxisValueViewResult' } & Pick<
                Types.ChartAxisValueViewResult,
                'renderer' | 'axisType'
              >);
          series?: Types.Maybe<
            Array<
              { __typename?: 'ChartSeries' } & Pick<
                Types.ChartSeries,
                | 'id'
                | 'seriesType'
                | 'linkedTo'
                | 'name'
                | 'color'
                | 'lineStyle'
                | 'showInLegend'
                | 'showInTooltip'
                | 'showLastPointMarker'
                | 'tooltipRangeFormat'
                | 'tooltipTitleLow'
                | 'tooltipTitleHigh'
                | 'renderer'
              > & {
                  data?: Types.Maybe<
                    Array<
                      { __typename?: 'ChartSeriesDataPoint' } & Pick<
                        Types.ChartSeriesDataPoint,
                        'x'
                      > & {
                          y?: Types.Maybe<
                            { __typename?: 'ChartSeriesDataPointValue' } & {
                              value?: Types.Maybe<
                                { __typename?: 'FormattedValue' } & Pick<
                                  Types.FormattedValue,
                                  'raw' | 'formatted'
                                >
                              >;
                              high?: Types.Maybe<
                                { __typename?: 'FormattedValue' } & Pick<
                                  Types.FormattedValue,
                                  'raw' | 'formatted'
                                >
                              >;
                              low?: Types.Maybe<
                                { __typename?: 'FormattedValue' } & Pick<
                                  Types.FormattedValue,
                                  'raw' | 'formatted'
                                >
                              >;
                              variance?: Types.Maybe<
                                { __typename?: 'VarianceResult' } & Pick<
                                  Types.VarianceResult,
                                  'actual' | 'target' | 'sentiment' | 'label'
                                >
                              >;
                            }
                          >;
                        }
                    >
                  >;
                }
            >
          >;
          filters?: Types.Maybe<
            Array<
              | ({ __typename?: 'SelectFilterValue' } & Pick<
                  Types.SelectFilterValue,
                  'id' | 'value'
                >)
              | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                  Types.SelectGroupFilterValue,
                  'id'
                > & {
                    values: Array<
                      Types.Maybe<
                        { __typename?: 'FilterCodeValue' } & Pick<
                          Types.FilterCodeValue,
                          'filterCode' | 'value'
                        >
                      >
                    >;
                  })
            >
          >;
          metadata?: Types.Maybe<
            Array<{ __typename?: 'MetaData' } & Pick<Types.MetaData, 'key' | 'label' | 'value'>>
          >;
          availableUserSettings?: Types.Maybe<
            Array<
              { __typename?: 'UserSetting' } & Pick<
                Types.UserSetting,
                'key' | 'label' | 'defaultValue' | 'userValue'
              > & {
                  options: Array<
                    { __typename?: 'SettingOption' } & Pick<Types.SettingOption, 'label' | 'value'>
                  >;
                }
            >
          >;
        })
    | ({ __typename?: 'DialsDataView' } & Pick<
        Types.DialsDataView,
        'annotation' | 'annotationDate' | 'lastUpdateDate' | 'id' | 'dataViewType'
      > & {
          items?: Types.Maybe<
            Array<
              { __typename?: 'DialItem' } & Pick<
                Types.DialItem,
                'label' | 'sentiment' | 'progress' | 'target'
              > & {
                  progressText?: Types.Maybe<
                    { __typename?: 'TextResult' } & Pick<Types.TextResult, 'value'>
                  >;
                  targetText?: Types.Maybe<
                    { __typename?: 'TextResult' } & Pick<Types.TextResult, 'value'>
                  >;
                  actualText?: Types.Maybe<
                    { __typename?: 'TextResult' } & Pick<Types.TextResult, 'value'>
                  >;
                  varianceText?: Types.Maybe<
                    { __typename?: 'TextResult' } & Pick<Types.TextResult, 'value'>
                  >;
                  link?: Types.Maybe<
                    { __typename?: 'TextLinkResult' } & Pick<
                      Types.TextLinkResult,
                      'value' | 'label' | 'subLabels' | 'linkDirection'
                    > & {
                        linkResult?: Types.Maybe<
                          { __typename?: 'Link' } & Pick<
                            Types.Link,
                            'pageId' | 'absolutePath' | 'drillId' | 'drillIds' | 'target'
                          > & {
                              filters?: Types.Maybe<
                                Array<
                                  | ({ __typename?: 'SelectFilterValue' } & Pick<
                                      Types.SelectFilterValue,
                                      'id' | 'value'
                                    >)
                                  | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                                      Types.SelectGroupFilterValue,
                                      'id'
                                    > & {
                                        values: Array<
                                          Types.Maybe<
                                            { __typename?: 'FilterCodeValue' } & Pick<
                                              Types.FilterCodeValue,
                                              'filterCode' | 'value'
                                            >
                                          >
                                        >;
                                      })
                                >
                              >;
                            }
                        >;
                      }
                  >;
                }
            >
          >;
          metadata?: Types.Maybe<
            Array<{ __typename?: 'MetaData' } & Pick<Types.MetaData, 'key' | 'label' | 'value'>>
          >;
          availableUserSettings?: Types.Maybe<
            Array<
              { __typename?: 'UserSetting' } & Pick<
                Types.UserSetting,
                'key' | 'label' | 'defaultValue' | 'userValue'
              > & {
                  options: Array<
                    { __typename?: 'SettingOption' } & Pick<Types.SettingOption, 'label' | 'value'>
                  >;
                }
            >
          >;
        })
    | ({ __typename?: 'GridDataView' } & Pick<
        Types.GridDataView,
        'title' | 'annotation' | 'lastUpdateDate' | 'id' | 'dataViewType'
      > & {
          columns?: Types.Maybe<
            Array<
              { __typename?: 'TableColumnResult' } & Pick<
                Types.TableColumnResult,
                'columnId' | 'dataKey' | 'title'
              > & {
                  options?: Types.Maybe<
                    { __typename?: 'TableColumnOptionsResult' } & Pick<
                      Types.TableColumnOptionsResult,
                      | 'alignment'
                      | 'selectDefault'
                      | 'selectDisplayDefault'
                      | 'selectDisplayGroupId'
                      | 'selectGroupId'
                      | 'toggleDefault'
                      | 'toggleGroup'
                      | 'width'
                    >
                  >;
                }
            >
          >;
          rows?: Types.Maybe<
            Array<
              { __typename?: 'TableRowResult' } & Pick<
                Types.TableRowResult,
                'rowId' | 'rowType' | 'groupFirstChild'
              > & {
                  cells?: Types.Maybe<
                    Array<
                      { __typename?: 'TableCellResult' } & Pick<
                        Types.TableCellResult,
                        'columnDataKey'
                      > & {
                          cellContent?: Types.Maybe<
                            | ({ __typename?: 'DrillDownResult' } & Pick<
                                Types.DrillDownResult,
                                | 'value'
                                | 'subLabels'
                                | 'drillUpId'
                                | 'drillDownId'
                                | 'drillUpIds'
                                | 'drillDownIds'
                              >)
                            | { __typename?: 'KpiStatus' }
                            | ({ __typename?: 'KpiStatusOverview' } & {
                                items?: Types.Maybe<
                                  Array<
                                    { __typename?: 'KpiStatus' } & Pick<
                                      Types.KpiStatus,
                                      'actual' | 'target' | 'sentiment' | 'icon'
                                    >
                                  >
                                >;
                                tooltip: { __typename?: 'TableContent' } & Pick<
                                  Types.TableContent,
                                  'title'
                                > & {
                                    columns?: Types.Maybe<
                                      Array<
                                        { __typename?: 'TableColumnResult' } & Pick<
                                          Types.TableColumnResult,
                                          'columnId' | 'dataKey' | 'title'
                                        > & {
                                            options?: Types.Maybe<
                                              { __typename?: 'TableColumnOptionsResult' } & Pick<
                                                Types.TableColumnOptionsResult,
                                                | 'alignment'
                                                | 'selectDefault'
                                                | 'selectDisplayDefault'
                                                | 'selectDisplayGroupId'
                                                | 'selectGroupId'
                                                | 'toggleDefault'
                                                | 'toggleGroup'
                                                | 'width'
                                              >
                                            >;
                                          }
                                      >
                                    >;
                                    rows?: Types.Maybe<
                                      Array<
                                        { __typename?: 'TableRowResult' } & Pick<
                                          Types.TableRowResult,
                                          'rowId' | 'rowType'
                                        > & {
                                            cells?: Types.Maybe<
                                              Array<
                                                { __typename?: 'TableCellResult' } & Pick<
                                                  Types.TableCellResult,
                                                  'columnDataKey'
                                                > & {
                                                    cellContent?: Types.Maybe<
                                                      | { __typename?: 'DrillDownResult' }
                                                      | ({ __typename?: 'KpiStatus' } & Pick<
                                                          Types.KpiStatus,
                                                          'text' | 'icon' | 'sentiment'
                                                        >)
                                                      | { __typename?: 'KpiStatusOverview' }
                                                      | { __typename?: 'LocationMarkerResult' }
                                                      | { __typename?: 'MicroChartResult' }
                                                      | { __typename?: 'ProgressBarResult' }
                                                      | { __typename?: 'TextLinkResult' }
                                                      | ({ __typename?: 'TextResult' } & Pick<
                                                          Types.TextResult,
                                                          'value'
                                                        >)
                                                      | { __typename?: 'Timeline' }
                                                      | { __typename?: 'TimelineDataPoint' }
                                                      | { __typename?: 'TimelinePhases' }
                                                      | { __typename?: 'VarianceResult' }
                                                    >;
                                                  }
                                              >
                                            >;
                                          }
                                      >
                                    >;
                                  };
                              })
                            | ({ __typename?: 'LocationMarkerResult' } & Pick<
                                Types.LocationMarkerResult,
                                'text'
                              > & {
                                  position?: Types.Maybe<
                                    { __typename?: 'GeolocationPositionResult' } & {
                                      coordinates?: Types.Maybe<
                                        { __typename?: 'GeolocationCoordinatesResult' } & Pick<
                                          Types.GeolocationCoordinatesResult,
                                          'latitude' | 'longitude'
                                        >
                                      >;
                                    }
                                  >;
                                })
                            | ({ __typename?: 'MicroChartResult' } & Pick<
                                Types.MicroChartResult,
                                'seriesData' | 'trendlineData' | 'sentiment'
                              > & {
                                  linkResult?: Types.Maybe<
                                    { __typename?: 'Link' } & Pick<
                                      Types.Link,
                                      'pageId' | 'absolutePath' | 'drillId' | 'drillIds' | 'target'
                                    > & {
                                        filters?: Types.Maybe<
                                          Array<
                                            | ({ __typename?: 'SelectFilterValue' } & Pick<
                                                Types.SelectFilterValue,
                                                'id' | 'value'
                                              >)
                                            | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                                                Types.SelectGroupFilterValue,
                                                'id'
                                              > & {
                                                  values: Array<
                                                    Types.Maybe<
                                                      { __typename?: 'FilterCodeValue' } & Pick<
                                                        Types.FilterCodeValue,
                                                        'filterCode' | 'value'
                                                      >
                                                    >
                                                  >;
                                                })
                                          >
                                        >;
                                      }
                                  >;
                                })
                            | ({ __typename?: 'ProgressBarResult' } & Pick<
                                Types.ProgressBarResult,
                                | 'progressValue'
                                | 'baseValue'
                                | 'min'
                                | 'max'
                                | 'sentiment'
                                | 'inverted'
                                | 'variant'
                              >)
                            | ({ __typename?: 'TextLinkResult' } & Pick<
                                Types.TextLinkResult,
                                'value' | 'label' | 'subLabels' | 'linkDirection'
                              > & {
                                  linkResult?: Types.Maybe<
                                    { __typename?: 'Link' } & Pick<
                                      Types.Link,
                                      'pageId' | 'absolutePath' | 'drillId' | 'drillIds' | 'target'
                                    > & {
                                        filters?: Types.Maybe<
                                          Array<
                                            | ({ __typename?: 'SelectFilterValue' } & Pick<
                                                Types.SelectFilterValue,
                                                'id' | 'value'
                                              >)
                                            | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                                                Types.SelectGroupFilterValue,
                                                'id'
                                              > & {
                                                  values: Array<
                                                    Types.Maybe<
                                                      { __typename?: 'FilterCodeValue' } & Pick<
                                                        Types.FilterCodeValue,
                                                        'filterCode' | 'value'
                                                      >
                                                    >
                                                  >;
                                                })
                                          >
                                        >;
                                      }
                                  >;
                                })
                            | ({ __typename?: 'TextResult' } & Pick<
                                Types.TextResult,
                                'value' | 'subLabels'
                              >)
                            | ({ __typename?: 'Timeline' } & {
                                axis: { __typename?: 'TimelineAxis' } & Pick<
                                  Types.TimelineAxis,
                                  'minX' | 'maxX' | 'categories'
                                >;
                                intervals?: Types.Maybe<
                                  Array<
                                    { __typename?: 'TimelineInterval' } & Pick<
                                      Types.TimelineInterval,
                                      'name' | 'x1' | 'x2'
                                    > & {
                                        color: { __typename?: 'ThemeColor' } & Pick<
                                          Types.ThemeColor,
                                          'color'
                                        >;
                                      }
                                  >
                                >;
                                series?: Types.Maybe<
                                  Array<
                                    { __typename?: 'TimelineSeries' } & Pick<
                                      Types.TimelineSeries,
                                      'id' | 'name' | 'sortOrder'
                                    > & {
                                        marker: { __typename?: 'TimelineMarker' } & Pick<
                                          Types.TimelineMarker,
                                          'symbol' | 'isFilled'
                                        > & {
                                            color: { __typename?: 'ThemeColor' } & Pick<
                                              Types.ThemeColor,
                                              'color'
                                            >;
                                          };
                                        data?: Types.Maybe<
                                          Array<
                                            { __typename?: 'TimelineDataPoint' } & Pick<
                                              Types.TimelineDataPoint,
                                              'x' | 'formattedX' | 'label'
                                            > & {
                                                marker?: Types.Maybe<
                                                  { __typename?: 'TimelineMarker' } & Pick<
                                                    Types.TimelineMarker,
                                                    'symbol' | 'isFilled'
                                                  > & {
                                                      color: { __typename?: 'ThemeColor' } & Pick<
                                                        Types.ThemeColor,
                                                        'color'
                                                      >;
                                                    }
                                                >;
                                                tooltip?: Types.Maybe<
                                                  { __typename?: 'TableContent' } & Pick<
                                                    Types.TableContent,
                                                    'title'
                                                  > & {
                                                      columns?: Types.Maybe<
                                                        Array<
                                                          {
                                                            __typename?: 'TableColumnResult';
                                                          } & Pick<
                                                            Types.TableColumnResult,
                                                            'columnId' | 'dataKey' | 'title'
                                                          > & {
                                                              options?: Types.Maybe<
                                                                {
                                                                  __typename?: 'TableColumnOptionsResult';
                                                                } & Pick<
                                                                  Types.TableColumnOptionsResult,
                                                                  | 'alignment'
                                                                  | 'selectDefault'
                                                                  | 'selectDisplayDefault'
                                                                  | 'selectDisplayGroupId'
                                                                  | 'selectGroupId'
                                                                  | 'toggleDefault'
                                                                  | 'toggleGroup'
                                                                  | 'width'
                                                                >
                                                              >;
                                                            }
                                                        >
                                                      >;
                                                      rows?: Types.Maybe<
                                                        Array<
                                                          { __typename?: 'TableRowResult' } & Pick<
                                                            Types.TableRowResult,
                                                            'rowId' | 'rowType'
                                                          > & {
                                                              cells?: Types.Maybe<
                                                                Array<
                                                                  {
                                                                    __typename?: 'TableCellResult';
                                                                  } & Pick<
                                                                    Types.TableCellResult,
                                                                    'columnDataKey'
                                                                  > & {
                                                                      cellContent?: Types.Maybe<
                                                                        | {
                                                                            __typename?: 'DrillDownResult';
                                                                          }
                                                                        | ({
                                                                            __typename?: 'KpiStatus';
                                                                          } & Pick<
                                                                            Types.KpiStatus,
                                                                            | 'text'
                                                                            | 'icon'
                                                                            | 'sentiment'
                                                                          >)
                                                                        | {
                                                                            __typename?: 'KpiStatusOverview';
                                                                          }
                                                                        | {
                                                                            __typename?: 'LocationMarkerResult';
                                                                          }
                                                                        | {
                                                                            __typename?: 'MicroChartResult';
                                                                          }
                                                                        | {
                                                                            __typename?: 'ProgressBarResult';
                                                                          }
                                                                        | {
                                                                            __typename?: 'TextLinkResult';
                                                                          }
                                                                        | ({
                                                                            __typename?: 'TextResult';
                                                                          } & Pick<
                                                                            Types.TextResult,
                                                                            'value'
                                                                          >)
                                                                        | {
                                                                            __typename?: 'Timeline';
                                                                          }
                                                                        | {
                                                                            __typename?: 'TimelineDataPoint';
                                                                          }
                                                                        | {
                                                                            __typename?: 'TimelinePhases';
                                                                          }
                                                                        | {
                                                                            __typename?: 'VarianceResult';
                                                                          }
                                                                      >;
                                                                    }
                                                                >
                                                              >;
                                                            }
                                                        >
                                                      >;
                                                    }
                                                >;
                                              }
                                          >
                                        >;
                                      }
                                  >
                                >;
                              })
                            | { __typename?: 'TimelineDataPoint' }
                            | ({ __typename?: 'TimelinePhases' } & {
                                axis: { __typename?: 'TimelineAxis' } & Pick<
                                  Types.TimelineAxis,
                                  'minX' | 'maxX' | 'categories'
                                >;
                                intervals?: Types.Maybe<
                                  Array<
                                    { __typename?: 'TimelineInterval' } & Pick<
                                      Types.TimelineInterval,
                                      'name' | 'x1' | 'x2'
                                    > & {
                                        color: { __typename?: 'ThemeColor' } & Pick<
                                          Types.ThemeColor,
                                          'color'
                                        >;
                                      }
                                  >
                                >;
                              })
                            | ({ __typename?: 'VarianceResult' } & Pick<
                                Types.VarianceResult,
                                'actual' | 'target' | 'sentiment' | 'label'
                              >)
                          >;
                        }
                    >
                  >;
                }
            >
          >;
          filters?: Types.Maybe<
            Array<
              | ({ __typename?: 'SelectFilterValue' } & Pick<
                  Types.SelectFilterValue,
                  'id' | 'value'
                >)
              | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                  Types.SelectGroupFilterValue,
                  'id'
                > & {
                    values: Array<
                      Types.Maybe<
                        { __typename?: 'FilterCodeValue' } & Pick<
                          Types.FilterCodeValue,
                          'filterCode' | 'value'
                        >
                      >
                    >;
                  })
            >
          >;
          editLink?: Types.Maybe<
            { __typename?: 'Link' } & Pick<
              Types.Link,
              'pageId' | 'absolutePath' | 'drillId' | 'drillIds' | 'target'
            > & {
                filters?: Types.Maybe<
                  Array<
                    | ({ __typename?: 'SelectFilterValue' } & Pick<
                        Types.SelectFilterValue,
                        'id' | 'value'
                      >)
                    | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                        Types.SelectGroupFilterValue,
                        'id'
                      > & {
                          values: Array<
                            Types.Maybe<
                              { __typename?: 'FilterCodeValue' } & Pick<
                                Types.FilterCodeValue,
                                'filterCode' | 'value'
                              >
                            >
                          >;
                        })
                  >
                >;
              }
          >;
          metadata?: Types.Maybe<
            Array<{ __typename?: 'MetaData' } & Pick<Types.MetaData, 'key' | 'label' | 'value'>>
          >;
          availableUserSettings?: Types.Maybe<
            Array<
              { __typename?: 'UserSetting' } & Pick<
                Types.UserSetting,
                'key' | 'label' | 'defaultValue' | 'userValue'
              > & {
                  options: Array<
                    { __typename?: 'SettingOption' } & Pick<Types.SettingOption, 'label' | 'value'>
                  >;
                }
            >
          >;
        })
    | ({ __typename?: 'HeaderRow' } & Pick<Types.HeaderRow, 'id' | 'dataViewType'> & {
          columns?: Types.Maybe<
            Array<
              { __typename?: 'TableColumnResult' } & Pick<
                Types.TableColumnResult,
                'columnId' | 'dataKey' | 'title'
              > & {
                  options?: Types.Maybe<
                    { __typename?: 'TableColumnOptionsResult' } & Pick<
                      Types.TableColumnOptionsResult,
                      | 'alignment'
                      | 'selectDefault'
                      | 'selectDisplayDefault'
                      | 'selectDisplayGroupId'
                      | 'selectGroupId'
                      | 'toggleDefault'
                      | 'toggleGroup'
                      | 'width'
                    >
                  >;
                }
            >
          >;
          rows?: Types.Maybe<
            Array<
              Types.Maybe<
                { __typename?: 'TableRowResult' } & Pick<
                  Types.TableRowResult,
                  'rowId' | 'rowType' | 'groupFirstChild'
                > & {
                    cells?: Types.Maybe<
                      Array<
                        { __typename?: 'TableCellResult' } & Pick<
                          Types.TableCellResult,
                          'columnDataKey'
                        > & {
                            cellContent?: Types.Maybe<
                              | ({ __typename?: 'DrillDownResult' } & Pick<
                                  Types.DrillDownResult,
                                  | 'value'
                                  | 'subLabels'
                                  | 'drillUpId'
                                  | 'drillDownId'
                                  | 'drillUpIds'
                                  | 'drillDownIds'
                                >)
                              | { __typename?: 'KpiStatus' }
                              | ({ __typename?: 'KpiStatusOverview' } & {
                                  items?: Types.Maybe<
                                    Array<
                                      { __typename?: 'KpiStatus' } & Pick<
                                        Types.KpiStatus,
                                        'actual' | 'target' | 'sentiment' | 'icon'
                                      >
                                    >
                                  >;
                                  tooltip: { __typename?: 'TableContent' } & Pick<
                                    Types.TableContent,
                                    'title'
                                  > & {
                                      columns?: Types.Maybe<
                                        Array<
                                          { __typename?: 'TableColumnResult' } & Pick<
                                            Types.TableColumnResult,
                                            'columnId' | 'dataKey' | 'title'
                                          > & {
                                              options?: Types.Maybe<
                                                { __typename?: 'TableColumnOptionsResult' } & Pick<
                                                  Types.TableColumnOptionsResult,
                                                  | 'alignment'
                                                  | 'selectDefault'
                                                  | 'selectDisplayDefault'
                                                  | 'selectDisplayGroupId'
                                                  | 'selectGroupId'
                                                  | 'toggleDefault'
                                                  | 'toggleGroup'
                                                  | 'width'
                                                >
                                              >;
                                            }
                                        >
                                      >;
                                      rows?: Types.Maybe<
                                        Array<
                                          { __typename?: 'TableRowResult' } & Pick<
                                            Types.TableRowResult,
                                            'rowId' | 'rowType'
                                          > & {
                                              cells?: Types.Maybe<
                                                Array<
                                                  { __typename?: 'TableCellResult' } & Pick<
                                                    Types.TableCellResult,
                                                    'columnDataKey'
                                                  > & {
                                                      cellContent?: Types.Maybe<
                                                        | { __typename?: 'DrillDownResult' }
                                                        | ({ __typename?: 'KpiStatus' } & Pick<
                                                            Types.KpiStatus,
                                                            'text' | 'icon' | 'sentiment'
                                                          >)
                                                        | { __typename?: 'KpiStatusOverview' }
                                                        | { __typename?: 'LocationMarkerResult' }
                                                        | { __typename?: 'MicroChartResult' }
                                                        | { __typename?: 'ProgressBarResult' }
                                                        | { __typename?: 'TextLinkResult' }
                                                        | ({ __typename?: 'TextResult' } & Pick<
                                                            Types.TextResult,
                                                            'value'
                                                          >)
                                                        | { __typename?: 'Timeline' }
                                                        | { __typename?: 'TimelineDataPoint' }
                                                        | { __typename?: 'TimelinePhases' }
                                                        | { __typename?: 'VarianceResult' }
                                                      >;
                                                    }
                                                >
                                              >;
                                            }
                                        >
                                      >;
                                    };
                                })
                              | ({ __typename?: 'LocationMarkerResult' } & Pick<
                                  Types.LocationMarkerResult,
                                  'text'
                                > & {
                                    position?: Types.Maybe<
                                      { __typename?: 'GeolocationPositionResult' } & {
                                        coordinates?: Types.Maybe<
                                          { __typename?: 'GeolocationCoordinatesResult' } & Pick<
                                            Types.GeolocationCoordinatesResult,
                                            'latitude' | 'longitude'
                                          >
                                        >;
                                      }
                                    >;
                                  })
                              | ({ __typename?: 'MicroChartResult' } & Pick<
                                  Types.MicroChartResult,
                                  'seriesData' | 'trendlineData' | 'sentiment'
                                > & {
                                    linkResult?: Types.Maybe<
                                      { __typename?: 'Link' } & Pick<
                                        Types.Link,
                                        | 'pageId'
                                        | 'absolutePath'
                                        | 'drillId'
                                        | 'drillIds'
                                        | 'target'
                                      > & {
                                          filters?: Types.Maybe<
                                            Array<
                                              | ({ __typename?: 'SelectFilterValue' } & Pick<
                                                  Types.SelectFilterValue,
                                                  'id' | 'value'
                                                >)
                                              | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                                                  Types.SelectGroupFilterValue,
                                                  'id'
                                                > & {
                                                    values: Array<
                                                      Types.Maybe<
                                                        { __typename?: 'FilterCodeValue' } & Pick<
                                                          Types.FilterCodeValue,
                                                          'filterCode' | 'value'
                                                        >
                                                      >
                                                    >;
                                                  })
                                            >
                                          >;
                                        }
                                    >;
                                  })
                              | ({ __typename?: 'ProgressBarResult' } & Pick<
                                  Types.ProgressBarResult,
                                  | 'progressValue'
                                  | 'baseValue'
                                  | 'min'
                                  | 'max'
                                  | 'sentiment'
                                  | 'inverted'
                                  | 'variant'
                                >)
                              | ({ __typename?: 'TextLinkResult' } & Pick<
                                  Types.TextLinkResult,
                                  'value' | 'label' | 'subLabels' | 'linkDirection'
                                > & {
                                    linkResult?: Types.Maybe<
                                      { __typename?: 'Link' } & Pick<
                                        Types.Link,
                                        | 'pageId'
                                        | 'absolutePath'
                                        | 'drillId'
                                        | 'drillIds'
                                        | 'target'
                                      > & {
                                          filters?: Types.Maybe<
                                            Array<
                                              | ({ __typename?: 'SelectFilterValue' } & Pick<
                                                  Types.SelectFilterValue,
                                                  'id' | 'value'
                                                >)
                                              | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                                                  Types.SelectGroupFilterValue,
                                                  'id'
                                                > & {
                                                    values: Array<
                                                      Types.Maybe<
                                                        { __typename?: 'FilterCodeValue' } & Pick<
                                                          Types.FilterCodeValue,
                                                          'filterCode' | 'value'
                                                        >
                                                      >
                                                    >;
                                                  })
                                            >
                                          >;
                                        }
                                    >;
                                  })
                              | ({ __typename?: 'TextResult' } & Pick<
                                  Types.TextResult,
                                  'value' | 'subLabels'
                                >)
                              | ({ __typename?: 'Timeline' } & {
                                  axis: { __typename?: 'TimelineAxis' } & Pick<
                                    Types.TimelineAxis,
                                    'minX' | 'maxX' | 'categories'
                                  >;
                                  intervals?: Types.Maybe<
                                    Array<
                                      { __typename?: 'TimelineInterval' } & Pick<
                                        Types.TimelineInterval,
                                        'name' | 'x1' | 'x2'
                                      > & {
                                          color: { __typename?: 'ThemeColor' } & Pick<
                                            Types.ThemeColor,
                                            'color'
                                          >;
                                        }
                                    >
                                  >;
                                  series?: Types.Maybe<
                                    Array<
                                      { __typename?: 'TimelineSeries' } & Pick<
                                        Types.TimelineSeries,
                                        'id' | 'name' | 'sortOrder'
                                      > & {
                                          marker: { __typename?: 'TimelineMarker' } & Pick<
                                            Types.TimelineMarker,
                                            'symbol' | 'isFilled'
                                          > & {
                                              color: { __typename?: 'ThemeColor' } & Pick<
                                                Types.ThemeColor,
                                                'color'
                                              >;
                                            };
                                          data?: Types.Maybe<
                                            Array<
                                              { __typename?: 'TimelineDataPoint' } & Pick<
                                                Types.TimelineDataPoint,
                                                'x' | 'formattedX' | 'label'
                                              > & {
                                                  marker?: Types.Maybe<
                                                    { __typename?: 'TimelineMarker' } & Pick<
                                                      Types.TimelineMarker,
                                                      'symbol' | 'isFilled'
                                                    > & {
                                                        color: { __typename?: 'ThemeColor' } & Pick<
                                                          Types.ThemeColor,
                                                          'color'
                                                        >;
                                                      }
                                                  >;
                                                  tooltip?: Types.Maybe<
                                                    { __typename?: 'TableContent' } & Pick<
                                                      Types.TableContent,
                                                      'title'
                                                    > & {
                                                        columns?: Types.Maybe<
                                                          Array<
                                                            {
                                                              __typename?: 'TableColumnResult';
                                                            } & Pick<
                                                              Types.TableColumnResult,
                                                              'columnId' | 'dataKey' | 'title'
                                                            > & {
                                                                options?: Types.Maybe<
                                                                  {
                                                                    __typename?: 'TableColumnOptionsResult';
                                                                  } & Pick<
                                                                    Types.TableColumnOptionsResult,
                                                                    | 'alignment'
                                                                    | 'selectDefault'
                                                                    | 'selectDisplayDefault'
                                                                    | 'selectDisplayGroupId'
                                                                    | 'selectGroupId'
                                                                    | 'toggleDefault'
                                                                    | 'toggleGroup'
                                                                    | 'width'
                                                                  >
                                                                >;
                                                              }
                                                          >
                                                        >;
                                                        rows?: Types.Maybe<
                                                          Array<
                                                            {
                                                              __typename?: 'TableRowResult';
                                                            } & Pick<
                                                              Types.TableRowResult,
                                                              'rowId' | 'rowType'
                                                            > & {
                                                                cells?: Types.Maybe<
                                                                  Array<
                                                                    {
                                                                      __typename?: 'TableCellResult';
                                                                    } & Pick<
                                                                      Types.TableCellResult,
                                                                      'columnDataKey'
                                                                    > & {
                                                                        cellContent?: Types.Maybe<
                                                                          | {
                                                                              __typename?: 'DrillDownResult';
                                                                            }
                                                                          | ({
                                                                              __typename?: 'KpiStatus';
                                                                            } & Pick<
                                                                              Types.KpiStatus,
                                                                              | 'text'
                                                                              | 'icon'
                                                                              | 'sentiment'
                                                                            >)
                                                                          | {
                                                                              __typename?: 'KpiStatusOverview';
                                                                            }
                                                                          | {
                                                                              __typename?: 'LocationMarkerResult';
                                                                            }
                                                                          | {
                                                                              __typename?: 'MicroChartResult';
                                                                            }
                                                                          | {
                                                                              __typename?: 'ProgressBarResult';
                                                                            }
                                                                          | {
                                                                              __typename?: 'TextLinkResult';
                                                                            }
                                                                          | ({
                                                                              __typename?: 'TextResult';
                                                                            } & Pick<
                                                                              Types.TextResult,
                                                                              'value'
                                                                            >)
                                                                          | {
                                                                              __typename?: 'Timeline';
                                                                            }
                                                                          | {
                                                                              __typename?: 'TimelineDataPoint';
                                                                            }
                                                                          | {
                                                                              __typename?: 'TimelinePhases';
                                                                            }
                                                                          | {
                                                                              __typename?: 'VarianceResult';
                                                                            }
                                                                        >;
                                                                      }
                                                                  >
                                                                >;
                                                              }
                                                          >
                                                        >;
                                                      }
                                                  >;
                                                }
                                            >
                                          >;
                                        }
                                    >
                                  >;
                                })
                              | { __typename?: 'TimelineDataPoint' }
                              | ({ __typename?: 'TimelinePhases' } & {
                                  axis: { __typename?: 'TimelineAxis' } & Pick<
                                    Types.TimelineAxis,
                                    'minX' | 'maxX' | 'categories'
                                  >;
                                  intervals?: Types.Maybe<
                                    Array<
                                      { __typename?: 'TimelineInterval' } & Pick<
                                        Types.TimelineInterval,
                                        'name' | 'x1' | 'x2'
                                      > & {
                                          color: { __typename?: 'ThemeColor' } & Pick<
                                            Types.ThemeColor,
                                            'color'
                                          >;
                                        }
                                    >
                                  >;
                                })
                              | ({ __typename?: 'VarianceResult' } & Pick<
                                  Types.VarianceResult,
                                  'actual' | 'target' | 'sentiment' | 'label'
                                >)
                            >;
                          }
                      >
                    >;
                  }
              >
            >
          >;
          metadata?: Types.Maybe<
            Array<{ __typename?: 'MetaData' } & Pick<Types.MetaData, 'key' | 'label' | 'value'>>
          >;
          availableUserSettings?: Types.Maybe<
            Array<
              { __typename?: 'UserSetting' } & Pick<
                Types.UserSetting,
                'key' | 'label' | 'defaultValue' | 'userValue'
              > & {
                  options: Array<
                    { __typename?: 'SettingOption' } & Pick<Types.SettingOption, 'label' | 'value'>
                  >;
                }
            >
          >;
        })
    | ({ __typename?: 'KpiStatusOverview' } & Pick<
        Types.KpiStatusOverview,
        'id' | 'dataViewType'
      > & {
          items?: Types.Maybe<
            Array<
              { __typename?: 'KpiStatus' } & Pick<
                Types.KpiStatus,
                'actual' | 'target' | 'sentiment' | 'icon'
              >
            >
          >;
          tooltip: { __typename?: 'TableContent' } & Pick<Types.TableContent, 'title'> & {
              columns?: Types.Maybe<
                Array<
                  { __typename?: 'TableColumnResult' } & Pick<
                    Types.TableColumnResult,
                    'columnId' | 'dataKey' | 'title'
                  > & {
                      options?: Types.Maybe<
                        { __typename?: 'TableColumnOptionsResult' } & Pick<
                          Types.TableColumnOptionsResult,
                          | 'alignment'
                          | 'selectDefault'
                          | 'selectDisplayDefault'
                          | 'selectDisplayGroupId'
                          | 'selectGroupId'
                          | 'toggleDefault'
                          | 'toggleGroup'
                          | 'width'
                        >
                      >;
                    }
                >
              >;
              rows?: Types.Maybe<
                Array<
                  { __typename?: 'TableRowResult' } & Pick<
                    Types.TableRowResult,
                    'rowId' | 'rowType'
                  > & {
                      cells?: Types.Maybe<
                        Array<
                          { __typename?: 'TableCellResult' } & Pick<
                            Types.TableCellResult,
                            'columnDataKey'
                          > & {
                              cellContent?: Types.Maybe<
                                | { __typename?: 'DrillDownResult' }
                                | ({ __typename?: 'KpiStatus' } & Pick<
                                    Types.KpiStatus,
                                    'text' | 'icon' | 'sentiment'
                                  >)
                                | { __typename?: 'KpiStatusOverview' }
                                | { __typename?: 'LocationMarkerResult' }
                                | { __typename?: 'MicroChartResult' }
                                | { __typename?: 'ProgressBarResult' }
                                | { __typename?: 'TextLinkResult' }
                                | ({ __typename?: 'TextResult' } & Pick<Types.TextResult, 'value'>)
                                | { __typename?: 'Timeline' }
                                | { __typename?: 'TimelineDataPoint' }
                                | { __typename?: 'TimelinePhases' }
                                | { __typename?: 'VarianceResult' }
                              >;
                            }
                        >
                      >;
                    }
                >
              >;
            };
          metadata?: Types.Maybe<
            Array<{ __typename?: 'MetaData' } & Pick<Types.MetaData, 'key' | 'label' | 'value'>>
          >;
          availableUserSettings?: Types.Maybe<
            Array<
              { __typename?: 'UserSetting' } & Pick<
                Types.UserSetting,
                'key' | 'label' | 'defaultValue' | 'userValue'
              > & {
                  options: Array<
                    { __typename?: 'SettingOption' } & Pick<Types.SettingOption, 'label' | 'value'>
                  >;
                }
            >
          >;
        })
    | ({ __typename?: 'ListDataView' } & Pick<
        Types.ListDataView,
        'title' | 'annotation' | 'id' | 'dataViewType'
      > & {
          columns?: Types.Maybe<
            Array<
              { __typename?: 'TableColumnResult' } & Pick<
                Types.TableColumnResult,
                'columnId' | 'dataKey' | 'title'
              > & {
                  options?: Types.Maybe<
                    { __typename?: 'TableColumnOptionsResult' } & Pick<
                      Types.TableColumnOptionsResult,
                      | 'alignment'
                      | 'selectDefault'
                      | 'selectDisplayDefault'
                      | 'selectDisplayGroupId'
                      | 'selectGroupId'
                      | 'toggleDefault'
                      | 'toggleGroup'
                      | 'width'
                    >
                  >;
                }
            >
          >;
          rows?: Types.Maybe<
            Array<
              { __typename?: 'TableRowResult' } & Pick<
                Types.TableRowResult,
                'rowId' | 'rowType' | 'groupFirstChild'
              > & {
                  cells?: Types.Maybe<
                    Array<
                      { __typename?: 'TableCellResult' } & Pick<
                        Types.TableCellResult,
                        'columnDataKey'
                      > & {
                          cellContent?: Types.Maybe<
                            | ({ __typename?: 'DrillDownResult' } & Pick<
                                Types.DrillDownResult,
                                | 'value'
                                | 'subLabels'
                                | 'drillUpId'
                                | 'drillDownId'
                                | 'drillUpIds'
                                | 'drillDownIds'
                              >)
                            | { __typename?: 'KpiStatus' }
                            | ({ __typename?: 'KpiStatusOverview' } & {
                                items?: Types.Maybe<
                                  Array<
                                    { __typename?: 'KpiStatus' } & Pick<
                                      Types.KpiStatus,
                                      'actual' | 'target' | 'sentiment' | 'icon'
                                    >
                                  >
                                >;
                                tooltip: { __typename?: 'TableContent' } & Pick<
                                  Types.TableContent,
                                  'title'
                                > & {
                                    columns?: Types.Maybe<
                                      Array<
                                        { __typename?: 'TableColumnResult' } & Pick<
                                          Types.TableColumnResult,
                                          'columnId' | 'dataKey' | 'title'
                                        > & {
                                            options?: Types.Maybe<
                                              { __typename?: 'TableColumnOptionsResult' } & Pick<
                                                Types.TableColumnOptionsResult,
                                                | 'alignment'
                                                | 'selectDefault'
                                                | 'selectDisplayDefault'
                                                | 'selectDisplayGroupId'
                                                | 'selectGroupId'
                                                | 'toggleDefault'
                                                | 'toggleGroup'
                                                | 'width'
                                              >
                                            >;
                                          }
                                      >
                                    >;
                                    rows?: Types.Maybe<
                                      Array<
                                        { __typename?: 'TableRowResult' } & Pick<
                                          Types.TableRowResult,
                                          'rowId' | 'rowType'
                                        > & {
                                            cells?: Types.Maybe<
                                              Array<
                                                { __typename?: 'TableCellResult' } & Pick<
                                                  Types.TableCellResult,
                                                  'columnDataKey'
                                                > & {
                                                    cellContent?: Types.Maybe<
                                                      | { __typename?: 'DrillDownResult' }
                                                      | ({ __typename?: 'KpiStatus' } & Pick<
                                                          Types.KpiStatus,
                                                          'text' | 'icon' | 'sentiment'
                                                        >)
                                                      | { __typename?: 'KpiStatusOverview' }
                                                      | { __typename?: 'LocationMarkerResult' }
                                                      | { __typename?: 'MicroChartResult' }
                                                      | { __typename?: 'ProgressBarResult' }
                                                      | { __typename?: 'TextLinkResult' }
                                                      | ({ __typename?: 'TextResult' } & Pick<
                                                          Types.TextResult,
                                                          'value'
                                                        >)
                                                      | { __typename?: 'Timeline' }
                                                      | { __typename?: 'TimelineDataPoint' }
                                                      | { __typename?: 'TimelinePhases' }
                                                      | { __typename?: 'VarianceResult' }
                                                    >;
                                                  }
                                              >
                                            >;
                                          }
                                      >
                                    >;
                                  };
                              })
                            | ({ __typename?: 'LocationMarkerResult' } & Pick<
                                Types.LocationMarkerResult,
                                'text'
                              > & {
                                  position?: Types.Maybe<
                                    { __typename?: 'GeolocationPositionResult' } & {
                                      coordinates?: Types.Maybe<
                                        { __typename?: 'GeolocationCoordinatesResult' } & Pick<
                                          Types.GeolocationCoordinatesResult,
                                          'latitude' | 'longitude'
                                        >
                                      >;
                                    }
                                  >;
                                })
                            | ({ __typename?: 'MicroChartResult' } & Pick<
                                Types.MicroChartResult,
                                'seriesData' | 'trendlineData' | 'sentiment'
                              > & {
                                  linkResult?: Types.Maybe<
                                    { __typename?: 'Link' } & Pick<
                                      Types.Link,
                                      'pageId' | 'absolutePath' | 'drillId' | 'drillIds' | 'target'
                                    > & {
                                        filters?: Types.Maybe<
                                          Array<
                                            | ({ __typename?: 'SelectFilterValue' } & Pick<
                                                Types.SelectFilterValue,
                                                'id' | 'value'
                                              >)
                                            | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                                                Types.SelectGroupFilterValue,
                                                'id'
                                              > & {
                                                  values: Array<
                                                    Types.Maybe<
                                                      { __typename?: 'FilterCodeValue' } & Pick<
                                                        Types.FilterCodeValue,
                                                        'filterCode' | 'value'
                                                      >
                                                    >
                                                  >;
                                                })
                                          >
                                        >;
                                      }
                                  >;
                                })
                            | ({ __typename?: 'ProgressBarResult' } & Pick<
                                Types.ProgressBarResult,
                                | 'progressValue'
                                | 'baseValue'
                                | 'min'
                                | 'max'
                                | 'sentiment'
                                | 'inverted'
                                | 'variant'
                              >)
                            | ({ __typename?: 'TextLinkResult' } & Pick<
                                Types.TextLinkResult,
                                'value' | 'label' | 'subLabels' | 'linkDirection'
                              > & {
                                  linkResult?: Types.Maybe<
                                    { __typename?: 'Link' } & Pick<
                                      Types.Link,
                                      'pageId' | 'absolutePath' | 'drillId' | 'drillIds' | 'target'
                                    > & {
                                        filters?: Types.Maybe<
                                          Array<
                                            | ({ __typename?: 'SelectFilterValue' } & Pick<
                                                Types.SelectFilterValue,
                                                'id' | 'value'
                                              >)
                                            | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                                                Types.SelectGroupFilterValue,
                                                'id'
                                              > & {
                                                  values: Array<
                                                    Types.Maybe<
                                                      { __typename?: 'FilterCodeValue' } & Pick<
                                                        Types.FilterCodeValue,
                                                        'filterCode' | 'value'
                                                      >
                                                    >
                                                  >;
                                                })
                                          >
                                        >;
                                      }
                                  >;
                                })
                            | ({ __typename?: 'TextResult' } & Pick<
                                Types.TextResult,
                                'value' | 'subLabels'
                              >)
                            | ({ __typename?: 'Timeline' } & {
                                axis: { __typename?: 'TimelineAxis' } & Pick<
                                  Types.TimelineAxis,
                                  'minX' | 'maxX' | 'categories'
                                >;
                                intervals?: Types.Maybe<
                                  Array<
                                    { __typename?: 'TimelineInterval' } & Pick<
                                      Types.TimelineInterval,
                                      'name' | 'x1' | 'x2'
                                    > & {
                                        color: { __typename?: 'ThemeColor' } & Pick<
                                          Types.ThemeColor,
                                          'color'
                                        >;
                                      }
                                  >
                                >;
                                series?: Types.Maybe<
                                  Array<
                                    { __typename?: 'TimelineSeries' } & Pick<
                                      Types.TimelineSeries,
                                      'id' | 'name' | 'sortOrder'
                                    > & {
                                        marker: { __typename?: 'TimelineMarker' } & Pick<
                                          Types.TimelineMarker,
                                          'symbol' | 'isFilled'
                                        > & {
                                            color: { __typename?: 'ThemeColor' } & Pick<
                                              Types.ThemeColor,
                                              'color'
                                            >;
                                          };
                                        data?: Types.Maybe<
                                          Array<
                                            { __typename?: 'TimelineDataPoint' } & Pick<
                                              Types.TimelineDataPoint,
                                              'x' | 'formattedX' | 'label'
                                            > & {
                                                marker?: Types.Maybe<
                                                  { __typename?: 'TimelineMarker' } & Pick<
                                                    Types.TimelineMarker,
                                                    'symbol' | 'isFilled'
                                                  > & {
                                                      color: { __typename?: 'ThemeColor' } & Pick<
                                                        Types.ThemeColor,
                                                        'color'
                                                      >;
                                                    }
                                                >;
                                                tooltip?: Types.Maybe<
                                                  { __typename?: 'TableContent' } & Pick<
                                                    Types.TableContent,
                                                    'title'
                                                  > & {
                                                      columns?: Types.Maybe<
                                                        Array<
                                                          {
                                                            __typename?: 'TableColumnResult';
                                                          } & Pick<
                                                            Types.TableColumnResult,
                                                            'columnId' | 'dataKey' | 'title'
                                                          > & {
                                                              options?: Types.Maybe<
                                                                {
                                                                  __typename?: 'TableColumnOptionsResult';
                                                                } & Pick<
                                                                  Types.TableColumnOptionsResult,
                                                                  | 'alignment'
                                                                  | 'selectDefault'
                                                                  | 'selectDisplayDefault'
                                                                  | 'selectDisplayGroupId'
                                                                  | 'selectGroupId'
                                                                  | 'toggleDefault'
                                                                  | 'toggleGroup'
                                                                  | 'width'
                                                                >
                                                              >;
                                                            }
                                                        >
                                                      >;
                                                      rows?: Types.Maybe<
                                                        Array<
                                                          { __typename?: 'TableRowResult' } & Pick<
                                                            Types.TableRowResult,
                                                            'rowId' | 'rowType'
                                                          > & {
                                                              cells?: Types.Maybe<
                                                                Array<
                                                                  {
                                                                    __typename?: 'TableCellResult';
                                                                  } & Pick<
                                                                    Types.TableCellResult,
                                                                    'columnDataKey'
                                                                  > & {
                                                                      cellContent?: Types.Maybe<
                                                                        | {
                                                                            __typename?: 'DrillDownResult';
                                                                          }
                                                                        | ({
                                                                            __typename?: 'KpiStatus';
                                                                          } & Pick<
                                                                            Types.KpiStatus,
                                                                            | 'text'
                                                                            | 'icon'
                                                                            | 'sentiment'
                                                                          >)
                                                                        | {
                                                                            __typename?: 'KpiStatusOverview';
                                                                          }
                                                                        | {
                                                                            __typename?: 'LocationMarkerResult';
                                                                          }
                                                                        | {
                                                                            __typename?: 'MicroChartResult';
                                                                          }
                                                                        | {
                                                                            __typename?: 'ProgressBarResult';
                                                                          }
                                                                        | {
                                                                            __typename?: 'TextLinkResult';
                                                                          }
                                                                        | ({
                                                                            __typename?: 'TextResult';
                                                                          } & Pick<
                                                                            Types.TextResult,
                                                                            'value'
                                                                          >)
                                                                        | {
                                                                            __typename?: 'Timeline';
                                                                          }
                                                                        | {
                                                                            __typename?: 'TimelineDataPoint';
                                                                          }
                                                                        | {
                                                                            __typename?: 'TimelinePhases';
                                                                          }
                                                                        | {
                                                                            __typename?: 'VarianceResult';
                                                                          }
                                                                      >;
                                                                    }
                                                                >
                                                              >;
                                                            }
                                                        >
                                                      >;
                                                    }
                                                >;
                                              }
                                          >
                                        >;
                                      }
                                  >
                                >;
                              })
                            | { __typename?: 'TimelineDataPoint' }
                            | ({ __typename?: 'TimelinePhases' } & {
                                axis: { __typename?: 'TimelineAxis' } & Pick<
                                  Types.TimelineAxis,
                                  'minX' | 'maxX' | 'categories'
                                >;
                                intervals?: Types.Maybe<
                                  Array<
                                    { __typename?: 'TimelineInterval' } & Pick<
                                      Types.TimelineInterval,
                                      'name' | 'x1' | 'x2'
                                    > & {
                                        color: { __typename?: 'ThemeColor' } & Pick<
                                          Types.ThemeColor,
                                          'color'
                                        >;
                                      }
                                  >
                                >;
                              })
                            | ({ __typename?: 'VarianceResult' } & Pick<
                                Types.VarianceResult,
                                'actual' | 'target' | 'sentiment' | 'label'
                              >)
                          >;
                        }
                    >
                  >;
                }
            >
          >;
          filters?: Types.Maybe<
            Array<
              | ({ __typename?: 'SelectFilterValue' } & Pick<
                  Types.SelectFilterValue,
                  'id' | 'value'
                >)
              | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                  Types.SelectGroupFilterValue,
                  'id'
                > & {
                    values: Array<
                      Types.Maybe<
                        { __typename?: 'FilterCodeValue' } & Pick<
                          Types.FilterCodeValue,
                          'filterCode' | 'value'
                        >
                      >
                    >;
                  })
            >
          >;
          metadata?: Types.Maybe<
            Array<{ __typename?: 'MetaData' } & Pick<Types.MetaData, 'key' | 'label' | 'value'>>
          >;
          availableUserSettings?: Types.Maybe<
            Array<
              { __typename?: 'UserSetting' } & Pick<
                Types.UserSetting,
                'key' | 'label' | 'defaultValue' | 'userValue'
              > & {
                  options: Array<
                    { __typename?: 'SettingOption' } & Pick<Types.SettingOption, 'label' | 'value'>
                  >;
                }
            >
          >;
        })
    | ({ __typename?: 'MicroChartResult' } & Pick<Types.MicroChartResult, 'id' | 'dataViewType'> & {
          metadata?: Types.Maybe<
            Array<{ __typename?: 'MetaData' } & Pick<Types.MetaData, 'key' | 'label' | 'value'>>
          >;
          availableUserSettings?: Types.Maybe<
            Array<
              { __typename?: 'UserSetting' } & Pick<
                Types.UserSetting,
                'key' | 'label' | 'defaultValue' | 'userValue'
              > & {
                  options: Array<
                    { __typename?: 'SettingOption' } & Pick<Types.SettingOption, 'label' | 'value'>
                  >;
                }
            >
          >;
        })
    | ({ __typename?: 'RecommenderDataView' } & Pick<
        Types.RecommenderDataView,
        'id' | 'dataViewType'
      > & {
          stories?: Types.Maybe<
            Array<
              { __typename?: 'Story' } & Pick<
                Types.Story,
                | 'id'
                | 'storyType'
                | 'headline'
                | 'recommenderHeadline'
                | 'caption'
                | 'sentiment'
                | 'publishDate'
                | 'isRead'
                | 'tag'
              > & {
                  content?: Types.Maybe<
                    Array<
                      Types.Maybe<
                        | { __typename?: 'CardsComponent' }
                        | ({ __typename?: 'ChartComponent' } & Pick<
                            Types.ChartComponent,
                            'id' | 'code' | 'componentType' | 'icon'
                          > & {
                              filters?: Types.Maybe<
                                Array<
                                  { __typename?: 'FilterComponent' } & Pick<
                                    Types.FilterComponent,
                                    | 'defaultValue'
                                    | 'alignment'
                                    | 'isHidden'
                                    | 'displayMode'
                                    | 'id'
                                    | 'code'
                                    | 'componentType'
                                    | 'icon'
                                  >
                                >
                              >;
                            })
                        | { __typename?: 'ColumnLayoutComponent' }
                        | { __typename?: 'DialsComponent' }
                        | { __typename?: 'FilterComponent' }
                        | { __typename?: 'FiltersComponent' }
                        | { __typename?: 'GridComponent' }
                        | ({ __typename?: 'HeaderRowComponent' } & Pick<
                            Types.HeaderRowComponent,
                            'variant' | 'id' | 'code' | 'componentType' | 'icon'
                          > & {
                              filters?: Types.Maybe<
                                Array<
                                  { __typename?: 'FilterComponent' } & Pick<
                                    Types.FilterComponent,
                                    | 'defaultValue'
                                    | 'alignment'
                                    | 'isHidden'
                                    | 'displayMode'
                                    | 'id'
                                    | 'code'
                                    | 'componentType'
                                    | 'icon'
                                  >
                                >
                              >;
                            })
                        | { __typename?: 'KpiComponent' }
                        | { __typename?: 'ListComponent' }
                        | ({ __typename?: 'MarkdownComponent' } & Pick<
                            Types.MarkdownComponent,
                            'content' | 'id' | 'code' | 'componentType' | 'icon'
                          >)
                        | { __typename?: 'MicroChartComponent' }
                        | { __typename?: 'NavigationTabsComponent' }
                        | { __typename?: 'PlailistComponent' }
                        | { __typename?: 'RecommenderComponent' }
                        | { __typename?: 'StoryListComponent' }
                        | { __typename?: 'SummaryInfoComponent' }
                        | ({ __typename?: 'TableComponent' } & Pick<
                            Types.TableComponent,
                            'id' | 'code' | 'componentType' | 'icon'
                          > & {
                              filters?: Types.Maybe<
                                Array<
                                  { __typename?: 'FilterComponent' } & Pick<
                                    Types.FilterComponent,
                                    | 'defaultValue'
                                    | 'alignment'
                                    | 'isHidden'
                                    | 'displayMode'
                                    | 'id'
                                    | 'code'
                                    | 'componentType'
                                    | 'icon'
                                  >
                                >
                              >;
                            })
                        | { __typename?: 'TimelineComponent' }
                        | { __typename?: 'UserAnalyticsComponent' }
                        | { __typename?: 'UserPrioritiesComponent' }
                      >
                    >
                  >;
                  link?: Types.Maybe<
                    { __typename?: 'Link' } & Pick<
                      Types.Link,
                      'pageId' | 'absolutePath' | 'drillId' | 'drillIds' | 'target'
                    > & {
                        filters?: Types.Maybe<
                          Array<
                            | ({ __typename?: 'SelectFilterValue' } & Pick<
                                Types.SelectFilterValue,
                                'id' | 'value'
                              >)
                            | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                                Types.SelectGroupFilterValue,
                                'id'
                              > & {
                                  values: Array<
                                    Types.Maybe<
                                      { __typename?: 'FilterCodeValue' } & Pick<
                                        Types.FilterCodeValue,
                                        'filterCode' | 'value'
                                      >
                                    >
                                  >;
                                })
                          >
                        >;
                      }
                  >;
                }
            >
          >;
          metadata?: Types.Maybe<
            Array<{ __typename?: 'MetaData' } & Pick<Types.MetaData, 'key' | 'label' | 'value'>>
          >;
          availableUserSettings?: Types.Maybe<
            Array<
              { __typename?: 'UserSetting' } & Pick<
                Types.UserSetting,
                'key' | 'label' | 'defaultValue' | 'userValue'
              > & {
                  options: Array<
                    { __typename?: 'SettingOption' } & Pick<Types.SettingOption, 'label' | 'value'>
                  >;
                }
            >
          >;
        })
    | ({ __typename?: 'SummaryInfoDataView' } & Pick<
        Types.SummaryInfoDataView,
        'id' | 'dataViewType'
      > & {
          summaryInfo: { __typename?: 'SummaryInfo' } & {
            items?: Types.Maybe<
              Array<
                { __typename?: 'SummaryInfoItem' } & {
                  header:
                    | { __typename?: 'DrillDownResult' }
                    | { __typename?: 'KpiStatus' }
                    | { __typename?: 'KpiStatusOverview' }
                    | { __typename?: 'LocationMarkerResult' }
                    | { __typename?: 'MicroChartResult' }
                    | { __typename?: 'ProgressBarResult' }
                    | ({ __typename?: 'TextLinkResult' } & Pick<
                        Types.TextLinkResult,
                        'value' | 'label' | 'subLabels' | 'linkDirection'
                      > & {
                          linkResult?: Types.Maybe<
                            { __typename?: 'Link' } & Pick<
                              Types.Link,
                              'pageId' | 'absolutePath' | 'drillId' | 'drillIds' | 'target'
                            > & {
                                filters?: Types.Maybe<
                                  Array<
                                    | ({ __typename?: 'SelectFilterValue' } & Pick<
                                        Types.SelectFilterValue,
                                        'id' | 'value'
                                      >)
                                    | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                                        Types.SelectGroupFilterValue,
                                        'id'
                                      > & {
                                          values: Array<
                                            Types.Maybe<
                                              { __typename?: 'FilterCodeValue' } & Pick<
                                                Types.FilterCodeValue,
                                                'filterCode' | 'value'
                                              >
                                            >
                                          >;
                                        })
                                  >
                                >;
                              }
                          >;
                        })
                    | ({ __typename?: 'TextResult' } & Pick<Types.TextResult, 'value'>)
                    | { __typename?: 'Timeline' }
                    | { __typename?: 'TimelineDataPoint' }
                    | { __typename?: 'TimelinePhases' }
                    | { __typename?: 'VarianceResult' };
                  content:
                    | { __typename?: 'DrillDownResult' }
                    | { __typename?: 'KpiStatus' }
                    | { __typename?: 'KpiStatusOverview' }
                    | { __typename?: 'LocationMarkerResult' }
                    | { __typename?: 'MicroChartResult' }
                    | { __typename?: 'ProgressBarResult' }
                    | { __typename?: 'TextLinkResult' }
                    | ({ __typename?: 'TextResult' } & Pick<Types.TextResult, 'value'>)
                    | { __typename?: 'Timeline' }
                    | { __typename?: 'TimelineDataPoint' }
                    | { __typename?: 'TimelinePhases' }
                    | { __typename?: 'VarianceResult' };
                }
              >
            >;
          };
          metadata?: Types.Maybe<
            Array<{ __typename?: 'MetaData' } & Pick<Types.MetaData, 'key' | 'label' | 'value'>>
          >;
          availableUserSettings?: Types.Maybe<
            Array<
              { __typename?: 'UserSetting' } & Pick<
                Types.UserSetting,
                'key' | 'label' | 'defaultValue' | 'userValue'
              > & {
                  options: Array<
                    { __typename?: 'SettingOption' } & Pick<Types.SettingOption, 'label' | 'value'>
                  >;
                }
            >
          >;
        })
    | ({ __typename?: 'TableDataView' } & Pick<
        Types.TableDataView,
        'title' | 'annotation' | 'lastUpdateDate' | 'id' | 'dataViewType'
      > & {
          columns?: Types.Maybe<
            Array<
              { __typename?: 'TableColumnResult' } & Pick<
                Types.TableColumnResult,
                'columnId' | 'dataKey' | 'title'
              > & {
                  options?: Types.Maybe<
                    { __typename?: 'TableColumnOptionsResult' } & Pick<
                      Types.TableColumnOptionsResult,
                      | 'alignment'
                      | 'selectDefault'
                      | 'selectDisplayDefault'
                      | 'selectDisplayGroupId'
                      | 'selectGroupId'
                      | 'toggleDefault'
                      | 'toggleGroup'
                      | 'width'
                    >
                  >;
                }
            >
          >;
          rows?: Types.Maybe<
            Array<
              Types.Maybe<
                { __typename?: 'TableRowResult' } & Pick<
                  Types.TableRowResult,
                  'rowId' | 'rowType' | 'groupFirstChild'
                > & {
                    children?: Types.Maybe<
                      Array<
                        { __typename?: 'TableRowResult' } & Pick<
                          Types.TableRowResult,
                          'rowId' | 'rowType' | 'groupFirstChild'
                        > & {
                            children?: Types.Maybe<
                              Array<
                                { __typename?: 'TableRowResult' } & Pick<
                                  Types.TableRowResult,
                                  'rowId' | 'rowType' | 'groupFirstChild'
                                > & {
                                    children?: Types.Maybe<
                                      Array<
                                        { __typename?: 'TableRowResult' } & Pick<
                                          Types.TableRowResult,
                                          'rowId' | 'rowType' | 'groupFirstChild'
                                        > & {
                                            children?: Types.Maybe<
                                              Array<
                                                { __typename?: 'TableRowResult' } & Pick<
                                                  Types.TableRowResult,
                                                  'rowId' | 'rowType' | 'groupFirstChild'
                                                > & {
                                                    children?: Types.Maybe<
                                                      Array<
                                                        { __typename?: 'TableRowResult' } & Pick<
                                                          Types.TableRowResult,
                                                          'rowId' | 'rowType' | 'groupFirstChild'
                                                        > & {
                                                            cells?: Types.Maybe<
                                                              Array<
                                                                {
                                                                  __typename?: 'TableCellResult';
                                                                } & Pick<
                                                                  Types.TableCellResult,
                                                                  'columnDataKey'
                                                                > & {
                                                                    cellContent?: Types.Maybe<
                                                                      | ({
                                                                          __typename?: 'DrillDownResult';
                                                                        } & Pick<
                                                                          Types.DrillDownResult,
                                                                          | 'value'
                                                                          | 'subLabels'
                                                                          | 'drillUpId'
                                                                          | 'drillDownId'
                                                                          | 'drillUpIds'
                                                                          | 'drillDownIds'
                                                                        >)
                                                                      | { __typename?: 'KpiStatus' }
                                                                      | ({
                                                                          __typename?: 'KpiStatusOverview';
                                                                        } & {
                                                                          items?: Types.Maybe<
                                                                            Array<
                                                                              {
                                                                                __typename?: 'KpiStatus';
                                                                              } & Pick<
                                                                                Types.KpiStatus,
                                                                                | 'actual'
                                                                                | 'target'
                                                                                | 'sentiment'
                                                                                | 'icon'
                                                                              >
                                                                            >
                                                                          >;
                                                                          tooltip: {
                                                                            __typename?: 'TableContent';
                                                                          } & Pick<
                                                                            Types.TableContent,
                                                                            'title'
                                                                          > & {
                                                                              columns?: Types.Maybe<
                                                                                Array<
                                                                                  {
                                                                                    __typename?: 'TableColumnResult';
                                                                                  } & Pick<
                                                                                    Types.TableColumnResult,
                                                                                    | 'columnId'
                                                                                    | 'dataKey'
                                                                                    | 'title'
                                                                                  > & {
                                                                                      options?: Types.Maybe<
                                                                                        {
                                                                                          __typename?: 'TableColumnOptionsResult';
                                                                                        } & Pick<
                                                                                          Types.TableColumnOptionsResult,
                                                                                          | 'alignment'
                                                                                          | 'selectDefault'
                                                                                          | 'selectDisplayDefault'
                                                                                          | 'selectDisplayGroupId'
                                                                                          | 'selectGroupId'
                                                                                          | 'toggleDefault'
                                                                                          | 'toggleGroup'
                                                                                          | 'width'
                                                                                        >
                                                                                      >;
                                                                                    }
                                                                                >
                                                                              >;
                                                                              rows?: Types.Maybe<
                                                                                Array<
                                                                                  {
                                                                                    __typename?: 'TableRowResult';
                                                                                  } & Pick<
                                                                                    Types.TableRowResult,
                                                                                    | 'rowId'
                                                                                    | 'rowType'
                                                                                  > & {
                                                                                      cells?: Types.Maybe<
                                                                                        Array<
                                                                                          {
                                                                                            __typename?: 'TableCellResult';
                                                                                          } & Pick<
                                                                                            Types.TableCellResult,
                                                                                            'columnDataKey'
                                                                                          > & {
                                                                                              cellContent?: Types.Maybe<
                                                                                                | {
                                                                                                    __typename?: 'DrillDownResult';
                                                                                                  }
                                                                                                | ({
                                                                                                    __typename?: 'KpiStatus';
                                                                                                  } & Pick<
                                                                                                    Types.KpiStatus,
                                                                                                    | 'text'
                                                                                                    | 'icon'
                                                                                                    | 'sentiment'
                                                                                                  >)
                                                                                                | {
                                                                                                    __typename?: 'KpiStatusOverview';
                                                                                                  }
                                                                                                | {
                                                                                                    __typename?: 'LocationMarkerResult';
                                                                                                  }
                                                                                                | {
                                                                                                    __typename?: 'MicroChartResult';
                                                                                                  }
                                                                                                | {
                                                                                                    __typename?: 'ProgressBarResult';
                                                                                                  }
                                                                                                | {
                                                                                                    __typename?: 'TextLinkResult';
                                                                                                  }
                                                                                                | ({
                                                                                                    __typename?: 'TextResult';
                                                                                                  } & Pick<
                                                                                                    Types.TextResult,
                                                                                                    'value'
                                                                                                  >)
                                                                                                | {
                                                                                                    __typename?: 'Timeline';
                                                                                                  }
                                                                                                | {
                                                                                                    __typename?: 'TimelineDataPoint';
                                                                                                  }
                                                                                                | {
                                                                                                    __typename?: 'TimelinePhases';
                                                                                                  }
                                                                                                | {
                                                                                                    __typename?: 'VarianceResult';
                                                                                                  }
                                                                                              >;
                                                                                            }
                                                                                        >
                                                                                      >;
                                                                                    }
                                                                                >
                                                                              >;
                                                                            };
                                                                        })
                                                                      | ({
                                                                          __typename?: 'LocationMarkerResult';
                                                                        } & Pick<
                                                                          Types.LocationMarkerResult,
                                                                          'text'
                                                                        > & {
                                                                            position?: Types.Maybe<
                                                                              {
                                                                                __typename?: 'GeolocationPositionResult';
                                                                              } & {
                                                                                coordinates?: Types.Maybe<
                                                                                  {
                                                                                    __typename?: 'GeolocationCoordinatesResult';
                                                                                  } & Pick<
                                                                                    Types.GeolocationCoordinatesResult,
                                                                                    | 'latitude'
                                                                                    | 'longitude'
                                                                                  >
                                                                                >;
                                                                              }
                                                                            >;
                                                                          })
                                                                      | ({
                                                                          __typename?: 'MicroChartResult';
                                                                        } & Pick<
                                                                          Types.MicroChartResult,
                                                                          | 'seriesData'
                                                                          | 'trendlineData'
                                                                          | 'sentiment'
                                                                        > & {
                                                                            linkResult?: Types.Maybe<
                                                                              {
                                                                                __typename?: 'Link';
                                                                              } & Pick<
                                                                                Types.Link,
                                                                                | 'pageId'
                                                                                | 'absolutePath'
                                                                                | 'drillId'
                                                                                | 'drillIds'
                                                                                | 'target'
                                                                              > & {
                                                                                  filters?: Types.Maybe<
                                                                                    Array<
                                                                                      | ({
                                                                                          __typename?: 'SelectFilterValue';
                                                                                        } & Pick<
                                                                                          Types.SelectFilterValue,
                                                                                          | 'id'
                                                                                          | 'value'
                                                                                        >)
                                                                                      | ({
                                                                                          __typename?: 'SelectGroupFilterValue';
                                                                                        } & Pick<
                                                                                          Types.SelectGroupFilterValue,
                                                                                          'id'
                                                                                        > & {
                                                                                            values: Array<
                                                                                              Types.Maybe<
                                                                                                {
                                                                                                  __typename?: 'FilterCodeValue';
                                                                                                } & Pick<
                                                                                                  Types.FilterCodeValue,
                                                                                                  | 'filterCode'
                                                                                                  | 'value'
                                                                                                >
                                                                                              >
                                                                                            >;
                                                                                          })
                                                                                    >
                                                                                  >;
                                                                                }
                                                                            >;
                                                                          })
                                                                      | ({
                                                                          __typename?: 'ProgressBarResult';
                                                                        } & Pick<
                                                                          Types.ProgressBarResult,
                                                                          | 'progressValue'
                                                                          | 'baseValue'
                                                                          | 'min'
                                                                          | 'max'
                                                                          | 'sentiment'
                                                                          | 'inverted'
                                                                          | 'variant'
                                                                        >)
                                                                      | ({
                                                                          __typename?: 'TextLinkResult';
                                                                        } & Pick<
                                                                          Types.TextLinkResult,
                                                                          | 'value'
                                                                          | 'label'
                                                                          | 'subLabels'
                                                                          | 'linkDirection'
                                                                        > & {
                                                                            linkResult?: Types.Maybe<
                                                                              {
                                                                                __typename?: 'Link';
                                                                              } & Pick<
                                                                                Types.Link,
                                                                                | 'pageId'
                                                                                | 'absolutePath'
                                                                                | 'drillId'
                                                                                | 'drillIds'
                                                                                | 'target'
                                                                              > & {
                                                                                  filters?: Types.Maybe<
                                                                                    Array<
                                                                                      | ({
                                                                                          __typename?: 'SelectFilterValue';
                                                                                        } & Pick<
                                                                                          Types.SelectFilterValue,
                                                                                          | 'id'
                                                                                          | 'value'
                                                                                        >)
                                                                                      | ({
                                                                                          __typename?: 'SelectGroupFilterValue';
                                                                                        } & Pick<
                                                                                          Types.SelectGroupFilterValue,
                                                                                          'id'
                                                                                        > & {
                                                                                            values: Array<
                                                                                              Types.Maybe<
                                                                                                {
                                                                                                  __typename?: 'FilterCodeValue';
                                                                                                } & Pick<
                                                                                                  Types.FilterCodeValue,
                                                                                                  | 'filterCode'
                                                                                                  | 'value'
                                                                                                >
                                                                                              >
                                                                                            >;
                                                                                          })
                                                                                    >
                                                                                  >;
                                                                                }
                                                                            >;
                                                                          })
                                                                      | ({
                                                                          __typename?: 'TextResult';
                                                                        } & Pick<
                                                                          Types.TextResult,
                                                                          'value' | 'subLabels'
                                                                        >)
                                                                      | ({
                                                                          __typename?: 'Timeline';
                                                                        } & {
                                                                          axis: {
                                                                            __typename?: 'TimelineAxis';
                                                                          } & Pick<
                                                                            Types.TimelineAxis,
                                                                            | 'minX'
                                                                            | 'maxX'
                                                                            | 'categories'
                                                                          >;
                                                                          intervals?: Types.Maybe<
                                                                            Array<
                                                                              {
                                                                                __typename?: 'TimelineInterval';
                                                                              } & Pick<
                                                                                Types.TimelineInterval,
                                                                                'name' | 'x1' | 'x2'
                                                                              > & {
                                                                                  color: {
                                                                                    __typename?: 'ThemeColor';
                                                                                  } & Pick<
                                                                                    Types.ThemeColor,
                                                                                    'color'
                                                                                  >;
                                                                                }
                                                                            >
                                                                          >;
                                                                          series?: Types.Maybe<
                                                                            Array<
                                                                              {
                                                                                __typename?: 'TimelineSeries';
                                                                              } & Pick<
                                                                                Types.TimelineSeries,
                                                                                | 'id'
                                                                                | 'name'
                                                                                | 'sortOrder'
                                                                              > & {
                                                                                  marker: {
                                                                                    __typename?: 'TimelineMarker';
                                                                                  } & Pick<
                                                                                    Types.TimelineMarker,
                                                                                    | 'symbol'
                                                                                    | 'isFilled'
                                                                                  > & {
                                                                                      color: {
                                                                                        __typename?: 'ThemeColor';
                                                                                      } & Pick<
                                                                                        Types.ThemeColor,
                                                                                        'color'
                                                                                      >;
                                                                                    };
                                                                                  data?: Types.Maybe<
                                                                                    Array<
                                                                                      {
                                                                                        __typename?: 'TimelineDataPoint';
                                                                                      } & Pick<
                                                                                        Types.TimelineDataPoint,
                                                                                        | 'x'
                                                                                        | 'formattedX'
                                                                                        | 'label'
                                                                                      > & {
                                                                                          marker?: Types.Maybe<
                                                                                            {
                                                                                              __typename?: 'TimelineMarker';
                                                                                            } & Pick<
                                                                                              Types.TimelineMarker,
                                                                                              | 'symbol'
                                                                                              | 'isFilled'
                                                                                            > & {
                                                                                                color: {
                                                                                                  __typename?: 'ThemeColor';
                                                                                                } & Pick<
                                                                                                  Types.ThemeColor,
                                                                                                  'color'
                                                                                                >;
                                                                                              }
                                                                                          >;
                                                                                          tooltip?: Types.Maybe<
                                                                                            {
                                                                                              __typename?: 'TableContent';
                                                                                            } & Pick<
                                                                                              Types.TableContent,
                                                                                              'title'
                                                                                            > & {
                                                                                                columns?: Types.Maybe<
                                                                                                  Array<
                                                                                                    {
                                                                                                      __typename?: 'TableColumnResult';
                                                                                                    } & Pick<
                                                                                                      Types.TableColumnResult,
                                                                                                      | 'columnId'
                                                                                                      | 'dataKey'
                                                                                                      | 'title'
                                                                                                    > & {
                                                                                                        options?: Types.Maybe<
                                                                                                          {
                                                                                                            __typename?: 'TableColumnOptionsResult';
                                                                                                          } & Pick<
                                                                                                            Types.TableColumnOptionsResult,
                                                                                                            | 'alignment'
                                                                                                            | 'selectDefault'
                                                                                                            | 'selectDisplayDefault'
                                                                                                            | 'selectDisplayGroupId'
                                                                                                            | 'selectGroupId'
                                                                                                            | 'toggleDefault'
                                                                                                            | 'toggleGroup'
                                                                                                            | 'width'
                                                                                                          >
                                                                                                        >;
                                                                                                      }
                                                                                                  >
                                                                                                >;
                                                                                                rows?: Types.Maybe<
                                                                                                  Array<
                                                                                                    {
                                                                                                      __typename?: 'TableRowResult';
                                                                                                    } & Pick<
                                                                                                      Types.TableRowResult,
                                                                                                      | 'rowId'
                                                                                                      | 'rowType'
                                                                                                    > & {
                                                                                                        cells?: Types.Maybe<
                                                                                                          Array<
                                                                                                            {
                                                                                                              __typename?: 'TableCellResult';
                                                                                                            } & Pick<
                                                                                                              Types.TableCellResult,
                                                                                                              'columnDataKey'
                                                                                                            > & {
                                                                                                                cellContent?: Types.Maybe<
                                                                                                                  | {
                                                                                                                      __typename?: 'DrillDownResult';
                                                                                                                    }
                                                                                                                  | ({
                                                                                                                      __typename?: 'KpiStatus';
                                                                                                                    } & Pick<
                                                                                                                      Types.KpiStatus,
                                                                                                                      | 'text'
                                                                                                                      | 'icon'
                                                                                                                      | 'sentiment'
                                                                                                                    >)
                                                                                                                  | {
                                                                                                                      __typename?: 'KpiStatusOverview';
                                                                                                                    }
                                                                                                                  | {
                                                                                                                      __typename?: 'LocationMarkerResult';
                                                                                                                    }
                                                                                                                  | {
                                                                                                                      __typename?: 'MicroChartResult';
                                                                                                                    }
                                                                                                                  | {
                                                                                                                      __typename?: 'ProgressBarResult';
                                                                                                                    }
                                                                                                                  | {
                                                                                                                      __typename?: 'TextLinkResult';
                                                                                                                    }
                                                                                                                  | ({
                                                                                                                      __typename?: 'TextResult';
                                                                                                                    } & Pick<
                                                                                                                      Types.TextResult,
                                                                                                                      'value'
                                                                                                                    >)
                                                                                                                  | {
                                                                                                                      __typename?: 'Timeline';
                                                                                                                    }
                                                                                                                  | {
                                                                                                                      __typename?: 'TimelineDataPoint';
                                                                                                                    }
                                                                                                                  | {
                                                                                                                      __typename?: 'TimelinePhases';
                                                                                                                    }
                                                                                                                  | {
                                                                                                                      __typename?: 'VarianceResult';
                                                                                                                    }
                                                                                                                >;
                                                                                                              }
                                                                                                          >
                                                                                                        >;
                                                                                                      }
                                                                                                  >
                                                                                                >;
                                                                                              }
                                                                                          >;
                                                                                        }
                                                                                    >
                                                                                  >;
                                                                                }
                                                                            >
                                                                          >;
                                                                        })
                                                                      | {
                                                                          __typename?: 'TimelineDataPoint';
                                                                        }
                                                                      | ({
                                                                          __typename?: 'TimelinePhases';
                                                                        } & {
                                                                          axis: {
                                                                            __typename?: 'TimelineAxis';
                                                                          } & Pick<
                                                                            Types.TimelineAxis,
                                                                            | 'minX'
                                                                            | 'maxX'
                                                                            | 'categories'
                                                                          >;
                                                                          intervals?: Types.Maybe<
                                                                            Array<
                                                                              {
                                                                                __typename?: 'TimelineInterval';
                                                                              } & Pick<
                                                                                Types.TimelineInterval,
                                                                                'name' | 'x1' | 'x2'
                                                                              > & {
                                                                                  color: {
                                                                                    __typename?: 'ThemeColor';
                                                                                  } & Pick<
                                                                                    Types.ThemeColor,
                                                                                    'color'
                                                                                  >;
                                                                                }
                                                                            >
                                                                          >;
                                                                        })
                                                                      | ({
                                                                          __typename?: 'VarianceResult';
                                                                        } & Pick<
                                                                          Types.VarianceResult,
                                                                          | 'actual'
                                                                          | 'target'
                                                                          | 'sentiment'
                                                                          | 'label'
                                                                        >)
                                                                    >;
                                                                  }
                                                              >
                                                            >;
                                                          }
                                                      >
                                                    >;
                                                    cells?: Types.Maybe<
                                                      Array<
                                                        { __typename?: 'TableCellResult' } & Pick<
                                                          Types.TableCellResult,
                                                          'columnDataKey'
                                                        > & {
                                                            cellContent?: Types.Maybe<
                                                              | ({
                                                                  __typename?: 'DrillDownResult';
                                                                } & Pick<
                                                                  Types.DrillDownResult,
                                                                  | 'value'
                                                                  | 'subLabels'
                                                                  | 'drillUpId'
                                                                  | 'drillDownId'
                                                                  | 'drillUpIds'
                                                                  | 'drillDownIds'
                                                                >)
                                                              | { __typename?: 'KpiStatus' }
                                                              | ({
                                                                  __typename?: 'KpiStatusOverview';
                                                                } & {
                                                                  items?: Types.Maybe<
                                                                    Array<
                                                                      {
                                                                        __typename?: 'KpiStatus';
                                                                      } & Pick<
                                                                        Types.KpiStatus,
                                                                        | 'actual'
                                                                        | 'target'
                                                                        | 'sentiment'
                                                                        | 'icon'
                                                                      >
                                                                    >
                                                                  >;
                                                                  tooltip: {
                                                                    __typename?: 'TableContent';
                                                                  } & Pick<
                                                                    Types.TableContent,
                                                                    'title'
                                                                  > & {
                                                                      columns?: Types.Maybe<
                                                                        Array<
                                                                          {
                                                                            __typename?: 'TableColumnResult';
                                                                          } & Pick<
                                                                            Types.TableColumnResult,
                                                                            | 'columnId'
                                                                            | 'dataKey'
                                                                            | 'title'
                                                                          > & {
                                                                              options?: Types.Maybe<
                                                                                {
                                                                                  __typename?: 'TableColumnOptionsResult';
                                                                                } & Pick<
                                                                                  Types.TableColumnOptionsResult,
                                                                                  | 'alignment'
                                                                                  | 'selectDefault'
                                                                                  | 'selectDisplayDefault'
                                                                                  | 'selectDisplayGroupId'
                                                                                  | 'selectGroupId'
                                                                                  | 'toggleDefault'
                                                                                  | 'toggleGroup'
                                                                                  | 'width'
                                                                                >
                                                                              >;
                                                                            }
                                                                        >
                                                                      >;
                                                                      rows?: Types.Maybe<
                                                                        Array<
                                                                          {
                                                                            __typename?: 'TableRowResult';
                                                                          } & Pick<
                                                                            Types.TableRowResult,
                                                                            'rowId' | 'rowType'
                                                                          > & {
                                                                              cells?: Types.Maybe<
                                                                                Array<
                                                                                  {
                                                                                    __typename?: 'TableCellResult';
                                                                                  } & Pick<
                                                                                    Types.TableCellResult,
                                                                                    'columnDataKey'
                                                                                  > & {
                                                                                      cellContent?: Types.Maybe<
                                                                                        | {
                                                                                            __typename?: 'DrillDownResult';
                                                                                          }
                                                                                        | ({
                                                                                            __typename?: 'KpiStatus';
                                                                                          } & Pick<
                                                                                            Types.KpiStatus,
                                                                                            | 'text'
                                                                                            | 'icon'
                                                                                            | 'sentiment'
                                                                                          >)
                                                                                        | {
                                                                                            __typename?: 'KpiStatusOverview';
                                                                                          }
                                                                                        | {
                                                                                            __typename?: 'LocationMarkerResult';
                                                                                          }
                                                                                        | {
                                                                                            __typename?: 'MicroChartResult';
                                                                                          }
                                                                                        | {
                                                                                            __typename?: 'ProgressBarResult';
                                                                                          }
                                                                                        | {
                                                                                            __typename?: 'TextLinkResult';
                                                                                          }
                                                                                        | ({
                                                                                            __typename?: 'TextResult';
                                                                                          } & Pick<
                                                                                            Types.TextResult,
                                                                                            'value'
                                                                                          >)
                                                                                        | {
                                                                                            __typename?: 'Timeline';
                                                                                          }
                                                                                        | {
                                                                                            __typename?: 'TimelineDataPoint';
                                                                                          }
                                                                                        | {
                                                                                            __typename?: 'TimelinePhases';
                                                                                          }
                                                                                        | {
                                                                                            __typename?: 'VarianceResult';
                                                                                          }
                                                                                      >;
                                                                                    }
                                                                                >
                                                                              >;
                                                                            }
                                                                        >
                                                                      >;
                                                                    };
                                                                })
                                                              | ({
                                                                  __typename?: 'LocationMarkerResult';
                                                                } & Pick<
                                                                  Types.LocationMarkerResult,
                                                                  'text'
                                                                > & {
                                                                    position?: Types.Maybe<
                                                                      {
                                                                        __typename?: 'GeolocationPositionResult';
                                                                      } & {
                                                                        coordinates?: Types.Maybe<
                                                                          {
                                                                            __typename?: 'GeolocationCoordinatesResult';
                                                                          } & Pick<
                                                                            Types.GeolocationCoordinatesResult,
                                                                            'latitude' | 'longitude'
                                                                          >
                                                                        >;
                                                                      }
                                                                    >;
                                                                  })
                                                              | ({
                                                                  __typename?: 'MicroChartResult';
                                                                } & Pick<
                                                                  Types.MicroChartResult,
                                                                  | 'seriesData'
                                                                  | 'trendlineData'
                                                                  | 'sentiment'
                                                                > & {
                                                                    linkResult?: Types.Maybe<
                                                                      {
                                                                        __typename?: 'Link';
                                                                      } & Pick<
                                                                        Types.Link,
                                                                        | 'pageId'
                                                                        | 'absolutePath'
                                                                        | 'drillId'
                                                                        | 'drillIds'
                                                                        | 'target'
                                                                      > & {
                                                                          filters?: Types.Maybe<
                                                                            Array<
                                                                              | ({
                                                                                  __typename?: 'SelectFilterValue';
                                                                                } & Pick<
                                                                                  Types.SelectFilterValue,
                                                                                  'id' | 'value'
                                                                                >)
                                                                              | ({
                                                                                  __typename?: 'SelectGroupFilterValue';
                                                                                } & Pick<
                                                                                  Types.SelectGroupFilterValue,
                                                                                  'id'
                                                                                > & {
                                                                                    values: Array<
                                                                                      Types.Maybe<
                                                                                        {
                                                                                          __typename?: 'FilterCodeValue';
                                                                                        } & Pick<
                                                                                          Types.FilterCodeValue,
                                                                                          | 'filterCode'
                                                                                          | 'value'
                                                                                        >
                                                                                      >
                                                                                    >;
                                                                                  })
                                                                            >
                                                                          >;
                                                                        }
                                                                    >;
                                                                  })
                                                              | ({
                                                                  __typename?: 'ProgressBarResult';
                                                                } & Pick<
                                                                  Types.ProgressBarResult,
                                                                  | 'progressValue'
                                                                  | 'baseValue'
                                                                  | 'min'
                                                                  | 'max'
                                                                  | 'sentiment'
                                                                  | 'inverted'
                                                                  | 'variant'
                                                                >)
                                                              | ({
                                                                  __typename?: 'TextLinkResult';
                                                                } & Pick<
                                                                  Types.TextLinkResult,
                                                                  | 'value'
                                                                  | 'label'
                                                                  | 'subLabels'
                                                                  | 'linkDirection'
                                                                > & {
                                                                    linkResult?: Types.Maybe<
                                                                      {
                                                                        __typename?: 'Link';
                                                                      } & Pick<
                                                                        Types.Link,
                                                                        | 'pageId'
                                                                        | 'absolutePath'
                                                                        | 'drillId'
                                                                        | 'drillIds'
                                                                        | 'target'
                                                                      > & {
                                                                          filters?: Types.Maybe<
                                                                            Array<
                                                                              | ({
                                                                                  __typename?: 'SelectFilterValue';
                                                                                } & Pick<
                                                                                  Types.SelectFilterValue,
                                                                                  'id' | 'value'
                                                                                >)
                                                                              | ({
                                                                                  __typename?: 'SelectGroupFilterValue';
                                                                                } & Pick<
                                                                                  Types.SelectGroupFilterValue,
                                                                                  'id'
                                                                                > & {
                                                                                    values: Array<
                                                                                      Types.Maybe<
                                                                                        {
                                                                                          __typename?: 'FilterCodeValue';
                                                                                        } & Pick<
                                                                                          Types.FilterCodeValue,
                                                                                          | 'filterCode'
                                                                                          | 'value'
                                                                                        >
                                                                                      >
                                                                                    >;
                                                                                  })
                                                                            >
                                                                          >;
                                                                        }
                                                                    >;
                                                                  })
                                                              | ({
                                                                  __typename?: 'TextResult';
                                                                } & Pick<
                                                                  Types.TextResult,
                                                                  'value' | 'subLabels'
                                                                >)
                                                              | ({ __typename?: 'Timeline' } & {
                                                                  axis: {
                                                                    __typename?: 'TimelineAxis';
                                                                  } & Pick<
                                                                    Types.TimelineAxis,
                                                                    'minX' | 'maxX' | 'categories'
                                                                  >;
                                                                  intervals?: Types.Maybe<
                                                                    Array<
                                                                      {
                                                                        __typename?: 'TimelineInterval';
                                                                      } & Pick<
                                                                        Types.TimelineInterval,
                                                                        'name' | 'x1' | 'x2'
                                                                      > & {
                                                                          color: {
                                                                            __typename?: 'ThemeColor';
                                                                          } & Pick<
                                                                            Types.ThemeColor,
                                                                            'color'
                                                                          >;
                                                                        }
                                                                    >
                                                                  >;
                                                                  series?: Types.Maybe<
                                                                    Array<
                                                                      {
                                                                        __typename?: 'TimelineSeries';
                                                                      } & Pick<
                                                                        Types.TimelineSeries,
                                                                        'id' | 'name' | 'sortOrder'
                                                                      > & {
                                                                          marker: {
                                                                            __typename?: 'TimelineMarker';
                                                                          } & Pick<
                                                                            Types.TimelineMarker,
                                                                            'symbol' | 'isFilled'
                                                                          > & {
                                                                              color: {
                                                                                __typename?: 'ThemeColor';
                                                                              } & Pick<
                                                                                Types.ThemeColor,
                                                                                'color'
                                                                              >;
                                                                            };
                                                                          data?: Types.Maybe<
                                                                            Array<
                                                                              {
                                                                                __typename?: 'TimelineDataPoint';
                                                                              } & Pick<
                                                                                Types.TimelineDataPoint,
                                                                                | 'x'
                                                                                | 'formattedX'
                                                                                | 'label'
                                                                              > & {
                                                                                  marker?: Types.Maybe<
                                                                                    {
                                                                                      __typename?: 'TimelineMarker';
                                                                                    } & Pick<
                                                                                      Types.TimelineMarker,
                                                                                      | 'symbol'
                                                                                      | 'isFilled'
                                                                                    > & {
                                                                                        color: {
                                                                                          __typename?: 'ThemeColor';
                                                                                        } & Pick<
                                                                                          Types.ThemeColor,
                                                                                          'color'
                                                                                        >;
                                                                                      }
                                                                                  >;
                                                                                  tooltip?: Types.Maybe<
                                                                                    {
                                                                                      __typename?: 'TableContent';
                                                                                    } & Pick<
                                                                                      Types.TableContent,
                                                                                      'title'
                                                                                    > & {
                                                                                        columns?: Types.Maybe<
                                                                                          Array<
                                                                                            {
                                                                                              __typename?: 'TableColumnResult';
                                                                                            } & Pick<
                                                                                              Types.TableColumnResult,
                                                                                              | 'columnId'
                                                                                              | 'dataKey'
                                                                                              | 'title'
                                                                                            > & {
                                                                                                options?: Types.Maybe<
                                                                                                  {
                                                                                                    __typename?: 'TableColumnOptionsResult';
                                                                                                  } & Pick<
                                                                                                    Types.TableColumnOptionsResult,
                                                                                                    | 'alignment'
                                                                                                    | 'selectDefault'
                                                                                                    | 'selectDisplayDefault'
                                                                                                    | 'selectDisplayGroupId'
                                                                                                    | 'selectGroupId'
                                                                                                    | 'toggleDefault'
                                                                                                    | 'toggleGroup'
                                                                                                    | 'width'
                                                                                                  >
                                                                                                >;
                                                                                              }
                                                                                          >
                                                                                        >;
                                                                                        rows?: Types.Maybe<
                                                                                          Array<
                                                                                            {
                                                                                              __typename?: 'TableRowResult';
                                                                                            } & Pick<
                                                                                              Types.TableRowResult,
                                                                                              | 'rowId'
                                                                                              | 'rowType'
                                                                                            > & {
                                                                                                cells?: Types.Maybe<
                                                                                                  Array<
                                                                                                    {
                                                                                                      __typename?: 'TableCellResult';
                                                                                                    } & Pick<
                                                                                                      Types.TableCellResult,
                                                                                                      'columnDataKey'
                                                                                                    > & {
                                                                                                        cellContent?: Types.Maybe<
                                                                                                          | {
                                                                                                              __typename?: 'DrillDownResult';
                                                                                                            }
                                                                                                          | ({
                                                                                                              __typename?: 'KpiStatus';
                                                                                                            } & Pick<
                                                                                                              Types.KpiStatus,
                                                                                                              | 'text'
                                                                                                              | 'icon'
                                                                                                              | 'sentiment'
                                                                                                            >)
                                                                                                          | {
                                                                                                              __typename?: 'KpiStatusOverview';
                                                                                                            }
                                                                                                          | {
                                                                                                              __typename?: 'LocationMarkerResult';
                                                                                                            }
                                                                                                          | {
                                                                                                              __typename?: 'MicroChartResult';
                                                                                                            }
                                                                                                          | {
                                                                                                              __typename?: 'ProgressBarResult';
                                                                                                            }
                                                                                                          | {
                                                                                                              __typename?: 'TextLinkResult';
                                                                                                            }
                                                                                                          | ({
                                                                                                              __typename?: 'TextResult';
                                                                                                            } & Pick<
                                                                                                              Types.TextResult,
                                                                                                              'value'
                                                                                                            >)
                                                                                                          | {
                                                                                                              __typename?: 'Timeline';
                                                                                                            }
                                                                                                          | {
                                                                                                              __typename?: 'TimelineDataPoint';
                                                                                                            }
                                                                                                          | {
                                                                                                              __typename?: 'TimelinePhases';
                                                                                                            }
                                                                                                          | {
                                                                                                              __typename?: 'VarianceResult';
                                                                                                            }
                                                                                                        >;
                                                                                                      }
                                                                                                  >
                                                                                                >;
                                                                                              }
                                                                                          >
                                                                                        >;
                                                                                      }
                                                                                  >;
                                                                                }
                                                                            >
                                                                          >;
                                                                        }
                                                                    >
                                                                  >;
                                                                })
                                                              | { __typename?: 'TimelineDataPoint' }
                                                              | ({
                                                                  __typename?: 'TimelinePhases';
                                                                } & {
                                                                  axis: {
                                                                    __typename?: 'TimelineAxis';
                                                                  } & Pick<
                                                                    Types.TimelineAxis,
                                                                    'minX' | 'maxX' | 'categories'
                                                                  >;
                                                                  intervals?: Types.Maybe<
                                                                    Array<
                                                                      {
                                                                        __typename?: 'TimelineInterval';
                                                                      } & Pick<
                                                                        Types.TimelineInterval,
                                                                        'name' | 'x1' | 'x2'
                                                                      > & {
                                                                          color: {
                                                                            __typename?: 'ThemeColor';
                                                                          } & Pick<
                                                                            Types.ThemeColor,
                                                                            'color'
                                                                          >;
                                                                        }
                                                                    >
                                                                  >;
                                                                })
                                                              | ({
                                                                  __typename?: 'VarianceResult';
                                                                } & Pick<
                                                                  Types.VarianceResult,
                                                                  | 'actual'
                                                                  | 'target'
                                                                  | 'sentiment'
                                                                  | 'label'
                                                                >)
                                                            >;
                                                          }
                                                      >
                                                    >;
                                                  }
                                              >
                                            >;
                                            cells?: Types.Maybe<
                                              Array<
                                                { __typename?: 'TableCellResult' } & Pick<
                                                  Types.TableCellResult,
                                                  'columnDataKey'
                                                > & {
                                                    cellContent?: Types.Maybe<
                                                      | ({ __typename?: 'DrillDownResult' } & Pick<
                                                          Types.DrillDownResult,
                                                          | 'value'
                                                          | 'subLabels'
                                                          | 'drillUpId'
                                                          | 'drillDownId'
                                                          | 'drillUpIds'
                                                          | 'drillDownIds'
                                                        >)
                                                      | { __typename?: 'KpiStatus' }
                                                      | ({ __typename?: 'KpiStatusOverview' } & {
                                                          items?: Types.Maybe<
                                                            Array<
                                                              { __typename?: 'KpiStatus' } & Pick<
                                                                Types.KpiStatus,
                                                                | 'actual'
                                                                | 'target'
                                                                | 'sentiment'
                                                                | 'icon'
                                                              >
                                                            >
                                                          >;
                                                          tooltip: {
                                                            __typename?: 'TableContent';
                                                          } & Pick<Types.TableContent, 'title'> & {
                                                              columns?: Types.Maybe<
                                                                Array<
                                                                  {
                                                                    __typename?: 'TableColumnResult';
                                                                  } & Pick<
                                                                    Types.TableColumnResult,
                                                                    'columnId' | 'dataKey' | 'title'
                                                                  > & {
                                                                      options?: Types.Maybe<
                                                                        {
                                                                          __typename?: 'TableColumnOptionsResult';
                                                                        } & Pick<
                                                                          Types.TableColumnOptionsResult,
                                                                          | 'alignment'
                                                                          | 'selectDefault'
                                                                          | 'selectDisplayDefault'
                                                                          | 'selectDisplayGroupId'
                                                                          | 'selectGroupId'
                                                                          | 'toggleDefault'
                                                                          | 'toggleGroup'
                                                                          | 'width'
                                                                        >
                                                                      >;
                                                                    }
                                                                >
                                                              >;
                                                              rows?: Types.Maybe<
                                                                Array<
                                                                  {
                                                                    __typename?: 'TableRowResult';
                                                                  } & Pick<
                                                                    Types.TableRowResult,
                                                                    'rowId' | 'rowType'
                                                                  > & {
                                                                      cells?: Types.Maybe<
                                                                        Array<
                                                                          {
                                                                            __typename?: 'TableCellResult';
                                                                          } & Pick<
                                                                            Types.TableCellResult,
                                                                            'columnDataKey'
                                                                          > & {
                                                                              cellContent?: Types.Maybe<
                                                                                | {
                                                                                    __typename?: 'DrillDownResult';
                                                                                  }
                                                                                | ({
                                                                                    __typename?: 'KpiStatus';
                                                                                  } & Pick<
                                                                                    Types.KpiStatus,
                                                                                    | 'text'
                                                                                    | 'icon'
                                                                                    | 'sentiment'
                                                                                  >)
                                                                                | {
                                                                                    __typename?: 'KpiStatusOverview';
                                                                                  }
                                                                                | {
                                                                                    __typename?: 'LocationMarkerResult';
                                                                                  }
                                                                                | {
                                                                                    __typename?: 'MicroChartResult';
                                                                                  }
                                                                                | {
                                                                                    __typename?: 'ProgressBarResult';
                                                                                  }
                                                                                | {
                                                                                    __typename?: 'TextLinkResult';
                                                                                  }
                                                                                | ({
                                                                                    __typename?: 'TextResult';
                                                                                  } & Pick<
                                                                                    Types.TextResult,
                                                                                    'value'
                                                                                  >)
                                                                                | {
                                                                                    __typename?: 'Timeline';
                                                                                  }
                                                                                | {
                                                                                    __typename?: 'TimelineDataPoint';
                                                                                  }
                                                                                | {
                                                                                    __typename?: 'TimelinePhases';
                                                                                  }
                                                                                | {
                                                                                    __typename?: 'VarianceResult';
                                                                                  }
                                                                              >;
                                                                            }
                                                                        >
                                                                      >;
                                                                    }
                                                                >
                                                              >;
                                                            };
                                                        })
                                                      | ({
                                                          __typename?: 'LocationMarkerResult';
                                                        } & Pick<
                                                          Types.LocationMarkerResult,
                                                          'text'
                                                        > & {
                                                            position?: Types.Maybe<
                                                              {
                                                                __typename?: 'GeolocationPositionResult';
                                                              } & {
                                                                coordinates?: Types.Maybe<
                                                                  {
                                                                    __typename?: 'GeolocationCoordinatesResult';
                                                                  } & Pick<
                                                                    Types.GeolocationCoordinatesResult,
                                                                    'latitude' | 'longitude'
                                                                  >
                                                                >;
                                                              }
                                                            >;
                                                          })
                                                      | ({ __typename?: 'MicroChartResult' } & Pick<
                                                          Types.MicroChartResult,
                                                          | 'seriesData'
                                                          | 'trendlineData'
                                                          | 'sentiment'
                                                        > & {
                                                            linkResult?: Types.Maybe<
                                                              { __typename?: 'Link' } & Pick<
                                                                Types.Link,
                                                                | 'pageId'
                                                                | 'absolutePath'
                                                                | 'drillId'
                                                                | 'drillIds'
                                                                | 'target'
                                                              > & {
                                                                  filters?: Types.Maybe<
                                                                    Array<
                                                                      | ({
                                                                          __typename?: 'SelectFilterValue';
                                                                        } & Pick<
                                                                          Types.SelectFilterValue,
                                                                          'id' | 'value'
                                                                        >)
                                                                      | ({
                                                                          __typename?: 'SelectGroupFilterValue';
                                                                        } & Pick<
                                                                          Types.SelectGroupFilterValue,
                                                                          'id'
                                                                        > & {
                                                                            values: Array<
                                                                              Types.Maybe<
                                                                                {
                                                                                  __typename?: 'FilterCodeValue';
                                                                                } & Pick<
                                                                                  Types.FilterCodeValue,
                                                                                  | 'filterCode'
                                                                                  | 'value'
                                                                                >
                                                                              >
                                                                            >;
                                                                          })
                                                                    >
                                                                  >;
                                                                }
                                                            >;
                                                          })
                                                      | ({
                                                          __typename?: 'ProgressBarResult';
                                                        } & Pick<
                                                          Types.ProgressBarResult,
                                                          | 'progressValue'
                                                          | 'baseValue'
                                                          | 'min'
                                                          | 'max'
                                                          | 'sentiment'
                                                          | 'inverted'
                                                          | 'variant'
                                                        >)
                                                      | ({ __typename?: 'TextLinkResult' } & Pick<
                                                          Types.TextLinkResult,
                                                          | 'value'
                                                          | 'label'
                                                          | 'subLabels'
                                                          | 'linkDirection'
                                                        > & {
                                                            linkResult?: Types.Maybe<
                                                              { __typename?: 'Link' } & Pick<
                                                                Types.Link,
                                                                | 'pageId'
                                                                | 'absolutePath'
                                                                | 'drillId'
                                                                | 'drillIds'
                                                                | 'target'
                                                              > & {
                                                                  filters?: Types.Maybe<
                                                                    Array<
                                                                      | ({
                                                                          __typename?: 'SelectFilterValue';
                                                                        } & Pick<
                                                                          Types.SelectFilterValue,
                                                                          'id' | 'value'
                                                                        >)
                                                                      | ({
                                                                          __typename?: 'SelectGroupFilterValue';
                                                                        } & Pick<
                                                                          Types.SelectGroupFilterValue,
                                                                          'id'
                                                                        > & {
                                                                            values: Array<
                                                                              Types.Maybe<
                                                                                {
                                                                                  __typename?: 'FilterCodeValue';
                                                                                } & Pick<
                                                                                  Types.FilterCodeValue,
                                                                                  | 'filterCode'
                                                                                  | 'value'
                                                                                >
                                                                              >
                                                                            >;
                                                                          })
                                                                    >
                                                                  >;
                                                                }
                                                            >;
                                                          })
                                                      | ({ __typename?: 'TextResult' } & Pick<
                                                          Types.TextResult,
                                                          'value' | 'subLabels'
                                                        >)
                                                      | ({ __typename?: 'Timeline' } & {
                                                          axis: {
                                                            __typename?: 'TimelineAxis';
                                                          } & Pick<
                                                            Types.TimelineAxis,
                                                            'minX' | 'maxX' | 'categories'
                                                          >;
                                                          intervals?: Types.Maybe<
                                                            Array<
                                                              {
                                                                __typename?: 'TimelineInterval';
                                                              } & Pick<
                                                                Types.TimelineInterval,
                                                                'name' | 'x1' | 'x2'
                                                              > & {
                                                                  color: {
                                                                    __typename?: 'ThemeColor';
                                                                  } & Pick<
                                                                    Types.ThemeColor,
                                                                    'color'
                                                                  >;
                                                                }
                                                            >
                                                          >;
                                                          series?: Types.Maybe<
                                                            Array<
                                                              {
                                                                __typename?: 'TimelineSeries';
                                                              } & Pick<
                                                                Types.TimelineSeries,
                                                                'id' | 'name' | 'sortOrder'
                                                              > & {
                                                                  marker: {
                                                                    __typename?: 'TimelineMarker';
                                                                  } & Pick<
                                                                    Types.TimelineMarker,
                                                                    'symbol' | 'isFilled'
                                                                  > & {
                                                                      color: {
                                                                        __typename?: 'ThemeColor';
                                                                      } & Pick<
                                                                        Types.ThemeColor,
                                                                        'color'
                                                                      >;
                                                                    };
                                                                  data?: Types.Maybe<
                                                                    Array<
                                                                      {
                                                                        __typename?: 'TimelineDataPoint';
                                                                      } & Pick<
                                                                        Types.TimelineDataPoint,
                                                                        'x' | 'formattedX' | 'label'
                                                                      > & {
                                                                          marker?: Types.Maybe<
                                                                            {
                                                                              __typename?: 'TimelineMarker';
                                                                            } & Pick<
                                                                              Types.TimelineMarker,
                                                                              'symbol' | 'isFilled'
                                                                            > & {
                                                                                color: {
                                                                                  __typename?: 'ThemeColor';
                                                                                } & Pick<
                                                                                  Types.ThemeColor,
                                                                                  'color'
                                                                                >;
                                                                              }
                                                                          >;
                                                                          tooltip?: Types.Maybe<
                                                                            {
                                                                              __typename?: 'TableContent';
                                                                            } & Pick<
                                                                              Types.TableContent,
                                                                              'title'
                                                                            > & {
                                                                                columns?: Types.Maybe<
                                                                                  Array<
                                                                                    {
                                                                                      __typename?: 'TableColumnResult';
                                                                                    } & Pick<
                                                                                      Types.TableColumnResult,
                                                                                      | 'columnId'
                                                                                      | 'dataKey'
                                                                                      | 'title'
                                                                                    > & {
                                                                                        options?: Types.Maybe<
                                                                                          {
                                                                                            __typename?: 'TableColumnOptionsResult';
                                                                                          } & Pick<
                                                                                            Types.TableColumnOptionsResult,
                                                                                            | 'alignment'
                                                                                            | 'selectDefault'
                                                                                            | 'selectDisplayDefault'
                                                                                            | 'selectDisplayGroupId'
                                                                                            | 'selectGroupId'
                                                                                            | 'toggleDefault'
                                                                                            | 'toggleGroup'
                                                                                            | 'width'
                                                                                          >
                                                                                        >;
                                                                                      }
                                                                                  >
                                                                                >;
                                                                                rows?: Types.Maybe<
                                                                                  Array<
                                                                                    {
                                                                                      __typename?: 'TableRowResult';
                                                                                    } & Pick<
                                                                                      Types.TableRowResult,
                                                                                      | 'rowId'
                                                                                      | 'rowType'
                                                                                    > & {
                                                                                        cells?: Types.Maybe<
                                                                                          Array<
                                                                                            {
                                                                                              __typename?: 'TableCellResult';
                                                                                            } & Pick<
                                                                                              Types.TableCellResult,
                                                                                              'columnDataKey'
                                                                                            > & {
                                                                                                cellContent?: Types.Maybe<
                                                                                                  | {
                                                                                                      __typename?: 'DrillDownResult';
                                                                                                    }
                                                                                                  | ({
                                                                                                      __typename?: 'KpiStatus';
                                                                                                    } & Pick<
                                                                                                      Types.KpiStatus,
                                                                                                      | 'text'
                                                                                                      | 'icon'
                                                                                                      | 'sentiment'
                                                                                                    >)
                                                                                                  | {
                                                                                                      __typename?: 'KpiStatusOverview';
                                                                                                    }
                                                                                                  | {
                                                                                                      __typename?: 'LocationMarkerResult';
                                                                                                    }
                                                                                                  | {
                                                                                                      __typename?: 'MicroChartResult';
                                                                                                    }
                                                                                                  | {
                                                                                                      __typename?: 'ProgressBarResult';
                                                                                                    }
                                                                                                  | {
                                                                                                      __typename?: 'TextLinkResult';
                                                                                                    }
                                                                                                  | ({
                                                                                                      __typename?: 'TextResult';
                                                                                                    } & Pick<
                                                                                                      Types.TextResult,
                                                                                                      'value'
                                                                                                    >)
                                                                                                  | {
                                                                                                      __typename?: 'Timeline';
                                                                                                    }
                                                                                                  | {
                                                                                                      __typename?: 'TimelineDataPoint';
                                                                                                    }
                                                                                                  | {
                                                                                                      __typename?: 'TimelinePhases';
                                                                                                    }
                                                                                                  | {
                                                                                                      __typename?: 'VarianceResult';
                                                                                                    }
                                                                                                >;
                                                                                              }
                                                                                          >
                                                                                        >;
                                                                                      }
                                                                                  >
                                                                                >;
                                                                              }
                                                                          >;
                                                                        }
                                                                    >
                                                                  >;
                                                                }
                                                            >
                                                          >;
                                                        })
                                                      | { __typename?: 'TimelineDataPoint' }
                                                      | ({ __typename?: 'TimelinePhases' } & {
                                                          axis: {
                                                            __typename?: 'TimelineAxis';
                                                          } & Pick<
                                                            Types.TimelineAxis,
                                                            'minX' | 'maxX' | 'categories'
                                                          >;
                                                          intervals?: Types.Maybe<
                                                            Array<
                                                              {
                                                                __typename?: 'TimelineInterval';
                                                              } & Pick<
                                                                Types.TimelineInterval,
                                                                'name' | 'x1' | 'x2'
                                                              > & {
                                                                  color: {
                                                                    __typename?: 'ThemeColor';
                                                                  } & Pick<
                                                                    Types.ThemeColor,
                                                                    'color'
                                                                  >;
                                                                }
                                                            >
                                                          >;
                                                        })
                                                      | ({ __typename?: 'VarianceResult' } & Pick<
                                                          Types.VarianceResult,
                                                          | 'actual'
                                                          | 'target'
                                                          | 'sentiment'
                                                          | 'label'
                                                        >)
                                                    >;
                                                  }
                                              >
                                            >;
                                          }
                                      >
                                    >;
                                    cells?: Types.Maybe<
                                      Array<
                                        { __typename?: 'TableCellResult' } & Pick<
                                          Types.TableCellResult,
                                          'columnDataKey'
                                        > & {
                                            cellContent?: Types.Maybe<
                                              | ({ __typename?: 'DrillDownResult' } & Pick<
                                                  Types.DrillDownResult,
                                                  | 'value'
                                                  | 'subLabels'
                                                  | 'drillUpId'
                                                  | 'drillDownId'
                                                  | 'drillUpIds'
                                                  | 'drillDownIds'
                                                >)
                                              | { __typename?: 'KpiStatus' }
                                              | ({ __typename?: 'KpiStatusOverview' } & {
                                                  items?: Types.Maybe<
                                                    Array<
                                                      { __typename?: 'KpiStatus' } & Pick<
                                                        Types.KpiStatus,
                                                        'actual' | 'target' | 'sentiment' | 'icon'
                                                      >
                                                    >
                                                  >;
                                                  tooltip: { __typename?: 'TableContent' } & Pick<
                                                    Types.TableContent,
                                                    'title'
                                                  > & {
                                                      columns?: Types.Maybe<
                                                        Array<
                                                          {
                                                            __typename?: 'TableColumnResult';
                                                          } & Pick<
                                                            Types.TableColumnResult,
                                                            'columnId' | 'dataKey' | 'title'
                                                          > & {
                                                              options?: Types.Maybe<
                                                                {
                                                                  __typename?: 'TableColumnOptionsResult';
                                                                } & Pick<
                                                                  Types.TableColumnOptionsResult,
                                                                  | 'alignment'
                                                                  | 'selectDefault'
                                                                  | 'selectDisplayDefault'
                                                                  | 'selectDisplayGroupId'
                                                                  | 'selectGroupId'
                                                                  | 'toggleDefault'
                                                                  | 'toggleGroup'
                                                                  | 'width'
                                                                >
                                                              >;
                                                            }
                                                        >
                                                      >;
                                                      rows?: Types.Maybe<
                                                        Array<
                                                          { __typename?: 'TableRowResult' } & Pick<
                                                            Types.TableRowResult,
                                                            'rowId' | 'rowType'
                                                          > & {
                                                              cells?: Types.Maybe<
                                                                Array<
                                                                  {
                                                                    __typename?: 'TableCellResult';
                                                                  } & Pick<
                                                                    Types.TableCellResult,
                                                                    'columnDataKey'
                                                                  > & {
                                                                      cellContent?: Types.Maybe<
                                                                        | {
                                                                            __typename?: 'DrillDownResult';
                                                                          }
                                                                        | ({
                                                                            __typename?: 'KpiStatus';
                                                                          } & Pick<
                                                                            Types.KpiStatus,
                                                                            | 'text'
                                                                            | 'icon'
                                                                            | 'sentiment'
                                                                          >)
                                                                        | {
                                                                            __typename?: 'KpiStatusOverview';
                                                                          }
                                                                        | {
                                                                            __typename?: 'LocationMarkerResult';
                                                                          }
                                                                        | {
                                                                            __typename?: 'MicroChartResult';
                                                                          }
                                                                        | {
                                                                            __typename?: 'ProgressBarResult';
                                                                          }
                                                                        | {
                                                                            __typename?: 'TextLinkResult';
                                                                          }
                                                                        | ({
                                                                            __typename?: 'TextResult';
                                                                          } & Pick<
                                                                            Types.TextResult,
                                                                            'value'
                                                                          >)
                                                                        | {
                                                                            __typename?: 'Timeline';
                                                                          }
                                                                        | {
                                                                            __typename?: 'TimelineDataPoint';
                                                                          }
                                                                        | {
                                                                            __typename?: 'TimelinePhases';
                                                                          }
                                                                        | {
                                                                            __typename?: 'VarianceResult';
                                                                          }
                                                                      >;
                                                                    }
                                                                >
                                                              >;
                                                            }
                                                        >
                                                      >;
                                                    };
                                                })
                                              | ({ __typename?: 'LocationMarkerResult' } & Pick<
                                                  Types.LocationMarkerResult,
                                                  'text'
                                                > & {
                                                    position?: Types.Maybe<
                                                      {
                                                        __typename?: 'GeolocationPositionResult';
                                                      } & {
                                                        coordinates?: Types.Maybe<
                                                          {
                                                            __typename?: 'GeolocationCoordinatesResult';
                                                          } & Pick<
                                                            Types.GeolocationCoordinatesResult,
                                                            'latitude' | 'longitude'
                                                          >
                                                        >;
                                                      }
                                                    >;
                                                  })
                                              | ({ __typename?: 'MicroChartResult' } & Pick<
                                                  Types.MicroChartResult,
                                                  'seriesData' | 'trendlineData' | 'sentiment'
                                                > & {
                                                    linkResult?: Types.Maybe<
                                                      { __typename?: 'Link' } & Pick<
                                                        Types.Link,
                                                        | 'pageId'
                                                        | 'absolutePath'
                                                        | 'drillId'
                                                        | 'drillIds'
                                                        | 'target'
                                                      > & {
                                                          filters?: Types.Maybe<
                                                            Array<
                                                              | ({
                                                                  __typename?: 'SelectFilterValue';
                                                                } & Pick<
                                                                  Types.SelectFilterValue,
                                                                  'id' | 'value'
                                                                >)
                                                              | ({
                                                                  __typename?: 'SelectGroupFilterValue';
                                                                } & Pick<
                                                                  Types.SelectGroupFilterValue,
                                                                  'id'
                                                                > & {
                                                                    values: Array<
                                                                      Types.Maybe<
                                                                        {
                                                                          __typename?: 'FilterCodeValue';
                                                                        } & Pick<
                                                                          Types.FilterCodeValue,
                                                                          'filterCode' | 'value'
                                                                        >
                                                                      >
                                                                    >;
                                                                  })
                                                            >
                                                          >;
                                                        }
                                                    >;
                                                  })
                                              | ({ __typename?: 'ProgressBarResult' } & Pick<
                                                  Types.ProgressBarResult,
                                                  | 'progressValue'
                                                  | 'baseValue'
                                                  | 'min'
                                                  | 'max'
                                                  | 'sentiment'
                                                  | 'inverted'
                                                  | 'variant'
                                                >)
                                              | ({ __typename?: 'TextLinkResult' } & Pick<
                                                  Types.TextLinkResult,
                                                  'value' | 'label' | 'subLabels' | 'linkDirection'
                                                > & {
                                                    linkResult?: Types.Maybe<
                                                      { __typename?: 'Link' } & Pick<
                                                        Types.Link,
                                                        | 'pageId'
                                                        | 'absolutePath'
                                                        | 'drillId'
                                                        | 'drillIds'
                                                        | 'target'
                                                      > & {
                                                          filters?: Types.Maybe<
                                                            Array<
                                                              | ({
                                                                  __typename?: 'SelectFilterValue';
                                                                } & Pick<
                                                                  Types.SelectFilterValue,
                                                                  'id' | 'value'
                                                                >)
                                                              | ({
                                                                  __typename?: 'SelectGroupFilterValue';
                                                                } & Pick<
                                                                  Types.SelectGroupFilterValue,
                                                                  'id'
                                                                > & {
                                                                    values: Array<
                                                                      Types.Maybe<
                                                                        {
                                                                          __typename?: 'FilterCodeValue';
                                                                        } & Pick<
                                                                          Types.FilterCodeValue,
                                                                          'filterCode' | 'value'
                                                                        >
                                                                      >
                                                                    >;
                                                                  })
                                                            >
                                                          >;
                                                        }
                                                    >;
                                                  })
                                              | ({ __typename?: 'TextResult' } & Pick<
                                                  Types.TextResult,
                                                  'value' | 'subLabels'
                                                >)
                                              | ({ __typename?: 'Timeline' } & {
                                                  axis: { __typename?: 'TimelineAxis' } & Pick<
                                                    Types.TimelineAxis,
                                                    'minX' | 'maxX' | 'categories'
                                                  >;
                                                  intervals?: Types.Maybe<
                                                    Array<
                                                      { __typename?: 'TimelineInterval' } & Pick<
                                                        Types.TimelineInterval,
                                                        'name' | 'x1' | 'x2'
                                                      > & {
                                                          color: {
                                                            __typename?: 'ThemeColor';
                                                          } & Pick<Types.ThemeColor, 'color'>;
                                                        }
                                                    >
                                                  >;
                                                  series?: Types.Maybe<
                                                    Array<
                                                      { __typename?: 'TimelineSeries' } & Pick<
                                                        Types.TimelineSeries,
                                                        'id' | 'name' | 'sortOrder'
                                                      > & {
                                                          marker: {
                                                            __typename?: 'TimelineMarker';
                                                          } & Pick<
                                                            Types.TimelineMarker,
                                                            'symbol' | 'isFilled'
                                                          > & {
                                                              color: {
                                                                __typename?: 'ThemeColor';
                                                              } & Pick<Types.ThemeColor, 'color'>;
                                                            };
                                                          data?: Types.Maybe<
                                                            Array<
                                                              {
                                                                __typename?: 'TimelineDataPoint';
                                                              } & Pick<
                                                                Types.TimelineDataPoint,
                                                                'x' | 'formattedX' | 'label'
                                                              > & {
                                                                  marker?: Types.Maybe<
                                                                    {
                                                                      __typename?: 'TimelineMarker';
                                                                    } & Pick<
                                                                      Types.TimelineMarker,
                                                                      'symbol' | 'isFilled'
                                                                    > & {
                                                                        color: {
                                                                          __typename?: 'ThemeColor';
                                                                        } & Pick<
                                                                          Types.ThemeColor,
                                                                          'color'
                                                                        >;
                                                                      }
                                                                  >;
                                                                  tooltip?: Types.Maybe<
                                                                    {
                                                                      __typename?: 'TableContent';
                                                                    } & Pick<
                                                                      Types.TableContent,
                                                                      'title'
                                                                    > & {
                                                                        columns?: Types.Maybe<
                                                                          Array<
                                                                            {
                                                                              __typename?: 'TableColumnResult';
                                                                            } & Pick<
                                                                              Types.TableColumnResult,
                                                                              | 'columnId'
                                                                              | 'dataKey'
                                                                              | 'title'
                                                                            > & {
                                                                                options?: Types.Maybe<
                                                                                  {
                                                                                    __typename?: 'TableColumnOptionsResult';
                                                                                  } & Pick<
                                                                                    Types.TableColumnOptionsResult,
                                                                                    | 'alignment'
                                                                                    | 'selectDefault'
                                                                                    | 'selectDisplayDefault'
                                                                                    | 'selectDisplayGroupId'
                                                                                    | 'selectGroupId'
                                                                                    | 'toggleDefault'
                                                                                    | 'toggleGroup'
                                                                                    | 'width'
                                                                                  >
                                                                                >;
                                                                              }
                                                                          >
                                                                        >;
                                                                        rows?: Types.Maybe<
                                                                          Array<
                                                                            {
                                                                              __typename?: 'TableRowResult';
                                                                            } & Pick<
                                                                              Types.TableRowResult,
                                                                              'rowId' | 'rowType'
                                                                            > & {
                                                                                cells?: Types.Maybe<
                                                                                  Array<
                                                                                    {
                                                                                      __typename?: 'TableCellResult';
                                                                                    } & Pick<
                                                                                      Types.TableCellResult,
                                                                                      'columnDataKey'
                                                                                    > & {
                                                                                        cellContent?: Types.Maybe<
                                                                                          | {
                                                                                              __typename?: 'DrillDownResult';
                                                                                            }
                                                                                          | ({
                                                                                              __typename?: 'KpiStatus';
                                                                                            } & Pick<
                                                                                              Types.KpiStatus,
                                                                                              | 'text'
                                                                                              | 'icon'
                                                                                              | 'sentiment'
                                                                                            >)
                                                                                          | {
                                                                                              __typename?: 'KpiStatusOverview';
                                                                                            }
                                                                                          | {
                                                                                              __typename?: 'LocationMarkerResult';
                                                                                            }
                                                                                          | {
                                                                                              __typename?: 'MicroChartResult';
                                                                                            }
                                                                                          | {
                                                                                              __typename?: 'ProgressBarResult';
                                                                                            }
                                                                                          | {
                                                                                              __typename?: 'TextLinkResult';
                                                                                            }
                                                                                          | ({
                                                                                              __typename?: 'TextResult';
                                                                                            } & Pick<
                                                                                              Types.TextResult,
                                                                                              'value'
                                                                                            >)
                                                                                          | {
                                                                                              __typename?: 'Timeline';
                                                                                            }
                                                                                          | {
                                                                                              __typename?: 'TimelineDataPoint';
                                                                                            }
                                                                                          | {
                                                                                              __typename?: 'TimelinePhases';
                                                                                            }
                                                                                          | {
                                                                                              __typename?: 'VarianceResult';
                                                                                            }
                                                                                        >;
                                                                                      }
                                                                                  >
                                                                                >;
                                                                              }
                                                                          >
                                                                        >;
                                                                      }
                                                                  >;
                                                                }
                                                            >
                                                          >;
                                                        }
                                                    >
                                                  >;
                                                })
                                              | { __typename?: 'TimelineDataPoint' }
                                              | ({ __typename?: 'TimelinePhases' } & {
                                                  axis: { __typename?: 'TimelineAxis' } & Pick<
                                                    Types.TimelineAxis,
                                                    'minX' | 'maxX' | 'categories'
                                                  >;
                                                  intervals?: Types.Maybe<
                                                    Array<
                                                      { __typename?: 'TimelineInterval' } & Pick<
                                                        Types.TimelineInterval,
                                                        'name' | 'x1' | 'x2'
                                                      > & {
                                                          color: {
                                                            __typename?: 'ThemeColor';
                                                          } & Pick<Types.ThemeColor, 'color'>;
                                                        }
                                                    >
                                                  >;
                                                })
                                              | ({ __typename?: 'VarianceResult' } & Pick<
                                                  Types.VarianceResult,
                                                  'actual' | 'target' | 'sentiment' | 'label'
                                                >)
                                            >;
                                          }
                                      >
                                    >;
                                  }
                              >
                            >;
                            cells?: Types.Maybe<
                              Array<
                                { __typename?: 'TableCellResult' } & Pick<
                                  Types.TableCellResult,
                                  'columnDataKey'
                                > & {
                                    cellContent?: Types.Maybe<
                                      | ({ __typename?: 'DrillDownResult' } & Pick<
                                          Types.DrillDownResult,
                                          | 'value'
                                          | 'subLabels'
                                          | 'drillUpId'
                                          | 'drillDownId'
                                          | 'drillUpIds'
                                          | 'drillDownIds'
                                        >)
                                      | { __typename?: 'KpiStatus' }
                                      | ({ __typename?: 'KpiStatusOverview' } & {
                                          items?: Types.Maybe<
                                            Array<
                                              { __typename?: 'KpiStatus' } & Pick<
                                                Types.KpiStatus,
                                                'actual' | 'target' | 'sentiment' | 'icon'
                                              >
                                            >
                                          >;
                                          tooltip: { __typename?: 'TableContent' } & Pick<
                                            Types.TableContent,
                                            'title'
                                          > & {
                                              columns?: Types.Maybe<
                                                Array<
                                                  { __typename?: 'TableColumnResult' } & Pick<
                                                    Types.TableColumnResult,
                                                    'columnId' | 'dataKey' | 'title'
                                                  > & {
                                                      options?: Types.Maybe<
                                                        {
                                                          __typename?: 'TableColumnOptionsResult';
                                                        } & Pick<
                                                          Types.TableColumnOptionsResult,
                                                          | 'alignment'
                                                          | 'selectDefault'
                                                          | 'selectDisplayDefault'
                                                          | 'selectDisplayGroupId'
                                                          | 'selectGroupId'
                                                          | 'toggleDefault'
                                                          | 'toggleGroup'
                                                          | 'width'
                                                        >
                                                      >;
                                                    }
                                                >
                                              >;
                                              rows?: Types.Maybe<
                                                Array<
                                                  { __typename?: 'TableRowResult' } & Pick<
                                                    Types.TableRowResult,
                                                    'rowId' | 'rowType'
                                                  > & {
                                                      cells?: Types.Maybe<
                                                        Array<
                                                          { __typename?: 'TableCellResult' } & Pick<
                                                            Types.TableCellResult,
                                                            'columnDataKey'
                                                          > & {
                                                              cellContent?: Types.Maybe<
                                                                | { __typename?: 'DrillDownResult' }
                                                                | ({
                                                                    __typename?: 'KpiStatus';
                                                                  } & Pick<
                                                                    Types.KpiStatus,
                                                                    'text' | 'icon' | 'sentiment'
                                                                  >)
                                                                | {
                                                                    __typename?: 'KpiStatusOverview';
                                                                  }
                                                                | {
                                                                    __typename?: 'LocationMarkerResult';
                                                                  }
                                                                | {
                                                                    __typename?: 'MicroChartResult';
                                                                  }
                                                                | {
                                                                    __typename?: 'ProgressBarResult';
                                                                  }
                                                                | { __typename?: 'TextLinkResult' }
                                                                | ({
                                                                    __typename?: 'TextResult';
                                                                  } & Pick<
                                                                    Types.TextResult,
                                                                    'value'
                                                                  >)
                                                                | { __typename?: 'Timeline' }
                                                                | {
                                                                    __typename?: 'TimelineDataPoint';
                                                                  }
                                                                | { __typename?: 'TimelinePhases' }
                                                                | { __typename?: 'VarianceResult' }
                                                              >;
                                                            }
                                                        >
                                                      >;
                                                    }
                                                >
                                              >;
                                            };
                                        })
                                      | ({ __typename?: 'LocationMarkerResult' } & Pick<
                                          Types.LocationMarkerResult,
                                          'text'
                                        > & {
                                            position?: Types.Maybe<
                                              { __typename?: 'GeolocationPositionResult' } & {
                                                coordinates?: Types.Maybe<
                                                  {
                                                    __typename?: 'GeolocationCoordinatesResult';
                                                  } & Pick<
                                                    Types.GeolocationCoordinatesResult,
                                                    'latitude' | 'longitude'
                                                  >
                                                >;
                                              }
                                            >;
                                          })
                                      | ({ __typename?: 'MicroChartResult' } & Pick<
                                          Types.MicroChartResult,
                                          'seriesData' | 'trendlineData' | 'sentiment'
                                        > & {
                                            linkResult?: Types.Maybe<
                                              { __typename?: 'Link' } & Pick<
                                                Types.Link,
                                                | 'pageId'
                                                | 'absolutePath'
                                                | 'drillId'
                                                | 'drillIds'
                                                | 'target'
                                              > & {
                                                  filters?: Types.Maybe<
                                                    Array<
                                                      | ({
                                                          __typename?: 'SelectFilterValue';
                                                        } & Pick<
                                                          Types.SelectFilterValue,
                                                          'id' | 'value'
                                                        >)
                                                      | ({
                                                          __typename?: 'SelectGroupFilterValue';
                                                        } & Pick<
                                                          Types.SelectGroupFilterValue,
                                                          'id'
                                                        > & {
                                                            values: Array<
                                                              Types.Maybe<
                                                                {
                                                                  __typename?: 'FilterCodeValue';
                                                                } & Pick<
                                                                  Types.FilterCodeValue,
                                                                  'filterCode' | 'value'
                                                                >
                                                              >
                                                            >;
                                                          })
                                                    >
                                                  >;
                                                }
                                            >;
                                          })
                                      | ({ __typename?: 'ProgressBarResult' } & Pick<
                                          Types.ProgressBarResult,
                                          | 'progressValue'
                                          | 'baseValue'
                                          | 'min'
                                          | 'max'
                                          | 'sentiment'
                                          | 'inverted'
                                          | 'variant'
                                        >)
                                      | ({ __typename?: 'TextLinkResult' } & Pick<
                                          Types.TextLinkResult,
                                          'value' | 'label' | 'subLabels' | 'linkDirection'
                                        > & {
                                            linkResult?: Types.Maybe<
                                              { __typename?: 'Link' } & Pick<
                                                Types.Link,
                                                | 'pageId'
                                                | 'absolutePath'
                                                | 'drillId'
                                                | 'drillIds'
                                                | 'target'
                                              > & {
                                                  filters?: Types.Maybe<
                                                    Array<
                                                      | ({
                                                          __typename?: 'SelectFilterValue';
                                                        } & Pick<
                                                          Types.SelectFilterValue,
                                                          'id' | 'value'
                                                        >)
                                                      | ({
                                                          __typename?: 'SelectGroupFilterValue';
                                                        } & Pick<
                                                          Types.SelectGroupFilterValue,
                                                          'id'
                                                        > & {
                                                            values: Array<
                                                              Types.Maybe<
                                                                {
                                                                  __typename?: 'FilterCodeValue';
                                                                } & Pick<
                                                                  Types.FilterCodeValue,
                                                                  'filterCode' | 'value'
                                                                >
                                                              >
                                                            >;
                                                          })
                                                    >
                                                  >;
                                                }
                                            >;
                                          })
                                      | ({ __typename?: 'TextResult' } & Pick<
                                          Types.TextResult,
                                          'value' | 'subLabels'
                                        >)
                                      | ({ __typename?: 'Timeline' } & {
                                          axis: { __typename?: 'TimelineAxis' } & Pick<
                                            Types.TimelineAxis,
                                            'minX' | 'maxX' | 'categories'
                                          >;
                                          intervals?: Types.Maybe<
                                            Array<
                                              { __typename?: 'TimelineInterval' } & Pick<
                                                Types.TimelineInterval,
                                                'name' | 'x1' | 'x2'
                                              > & {
                                                  color: { __typename?: 'ThemeColor' } & Pick<
                                                    Types.ThemeColor,
                                                    'color'
                                                  >;
                                                }
                                            >
                                          >;
                                          series?: Types.Maybe<
                                            Array<
                                              { __typename?: 'TimelineSeries' } & Pick<
                                                Types.TimelineSeries,
                                                'id' | 'name' | 'sortOrder'
                                              > & {
                                                  marker: { __typename?: 'TimelineMarker' } & Pick<
                                                    Types.TimelineMarker,
                                                    'symbol' | 'isFilled'
                                                  > & {
                                                      color: { __typename?: 'ThemeColor' } & Pick<
                                                        Types.ThemeColor,
                                                        'color'
                                                      >;
                                                    };
                                                  data?: Types.Maybe<
                                                    Array<
                                                      { __typename?: 'TimelineDataPoint' } & Pick<
                                                        Types.TimelineDataPoint,
                                                        'x' | 'formattedX' | 'label'
                                                      > & {
                                                          marker?: Types.Maybe<
                                                            {
                                                              __typename?: 'TimelineMarker';
                                                            } & Pick<
                                                              Types.TimelineMarker,
                                                              'symbol' | 'isFilled'
                                                            > & {
                                                                color: {
                                                                  __typename?: 'ThemeColor';
                                                                } & Pick<Types.ThemeColor, 'color'>;
                                                              }
                                                          >;
                                                          tooltip?: Types.Maybe<
                                                            { __typename?: 'TableContent' } & Pick<
                                                              Types.TableContent,
                                                              'title'
                                                            > & {
                                                                columns?: Types.Maybe<
                                                                  Array<
                                                                    {
                                                                      __typename?: 'TableColumnResult';
                                                                    } & Pick<
                                                                      Types.TableColumnResult,
                                                                      | 'columnId'
                                                                      | 'dataKey'
                                                                      | 'title'
                                                                    > & {
                                                                        options?: Types.Maybe<
                                                                          {
                                                                            __typename?: 'TableColumnOptionsResult';
                                                                          } & Pick<
                                                                            Types.TableColumnOptionsResult,
                                                                            | 'alignment'
                                                                            | 'selectDefault'
                                                                            | 'selectDisplayDefault'
                                                                            | 'selectDisplayGroupId'
                                                                            | 'selectGroupId'
                                                                            | 'toggleDefault'
                                                                            | 'toggleGroup'
                                                                            | 'width'
                                                                          >
                                                                        >;
                                                                      }
                                                                  >
                                                                >;
                                                                rows?: Types.Maybe<
                                                                  Array<
                                                                    {
                                                                      __typename?: 'TableRowResult';
                                                                    } & Pick<
                                                                      Types.TableRowResult,
                                                                      'rowId' | 'rowType'
                                                                    > & {
                                                                        cells?: Types.Maybe<
                                                                          Array<
                                                                            {
                                                                              __typename?: 'TableCellResult';
                                                                            } & Pick<
                                                                              Types.TableCellResult,
                                                                              'columnDataKey'
                                                                            > & {
                                                                                cellContent?: Types.Maybe<
                                                                                  | {
                                                                                      __typename?: 'DrillDownResult';
                                                                                    }
                                                                                  | ({
                                                                                      __typename?: 'KpiStatus';
                                                                                    } & Pick<
                                                                                      Types.KpiStatus,
                                                                                      | 'text'
                                                                                      | 'icon'
                                                                                      | 'sentiment'
                                                                                    >)
                                                                                  | {
                                                                                      __typename?: 'KpiStatusOverview';
                                                                                    }
                                                                                  | {
                                                                                      __typename?: 'LocationMarkerResult';
                                                                                    }
                                                                                  | {
                                                                                      __typename?: 'MicroChartResult';
                                                                                    }
                                                                                  | {
                                                                                      __typename?: 'ProgressBarResult';
                                                                                    }
                                                                                  | {
                                                                                      __typename?: 'TextLinkResult';
                                                                                    }
                                                                                  | ({
                                                                                      __typename?: 'TextResult';
                                                                                    } & Pick<
                                                                                      Types.TextResult,
                                                                                      'value'
                                                                                    >)
                                                                                  | {
                                                                                      __typename?: 'Timeline';
                                                                                    }
                                                                                  | {
                                                                                      __typename?: 'TimelineDataPoint';
                                                                                    }
                                                                                  | {
                                                                                      __typename?: 'TimelinePhases';
                                                                                    }
                                                                                  | {
                                                                                      __typename?: 'VarianceResult';
                                                                                    }
                                                                                >;
                                                                              }
                                                                          >
                                                                        >;
                                                                      }
                                                                  >
                                                                >;
                                                              }
                                                          >;
                                                        }
                                                    >
                                                  >;
                                                }
                                            >
                                          >;
                                        })
                                      | { __typename?: 'TimelineDataPoint' }
                                      | ({ __typename?: 'TimelinePhases' } & {
                                          axis: { __typename?: 'TimelineAxis' } & Pick<
                                            Types.TimelineAxis,
                                            'minX' | 'maxX' | 'categories'
                                          >;
                                          intervals?: Types.Maybe<
                                            Array<
                                              { __typename?: 'TimelineInterval' } & Pick<
                                                Types.TimelineInterval,
                                                'name' | 'x1' | 'x2'
                                              > & {
                                                  color: { __typename?: 'ThemeColor' } & Pick<
                                                    Types.ThemeColor,
                                                    'color'
                                                  >;
                                                }
                                            >
                                          >;
                                        })
                                      | ({ __typename?: 'VarianceResult' } & Pick<
                                          Types.VarianceResult,
                                          'actual' | 'target' | 'sentiment' | 'label'
                                        >)
                                    >;
                                  }
                              >
                            >;
                          }
                      >
                    >;
                    cells?: Types.Maybe<
                      Array<
                        { __typename?: 'TableCellResult' } & Pick<
                          Types.TableCellResult,
                          'columnDataKey'
                        > & {
                            cellContent?: Types.Maybe<
                              | ({ __typename?: 'DrillDownResult' } & Pick<
                                  Types.DrillDownResult,
                                  | 'value'
                                  | 'subLabels'
                                  | 'drillUpId'
                                  | 'drillDownId'
                                  | 'drillUpIds'
                                  | 'drillDownIds'
                                >)
                              | { __typename?: 'KpiStatus' }
                              | ({ __typename?: 'KpiStatusOverview' } & {
                                  items?: Types.Maybe<
                                    Array<
                                      { __typename?: 'KpiStatus' } & Pick<
                                        Types.KpiStatus,
                                        'actual' | 'target' | 'sentiment' | 'icon'
                                      >
                                    >
                                  >;
                                  tooltip: { __typename?: 'TableContent' } & Pick<
                                    Types.TableContent,
                                    'title'
                                  > & {
                                      columns?: Types.Maybe<
                                        Array<
                                          { __typename?: 'TableColumnResult' } & Pick<
                                            Types.TableColumnResult,
                                            'columnId' | 'dataKey' | 'title'
                                          > & {
                                              options?: Types.Maybe<
                                                { __typename?: 'TableColumnOptionsResult' } & Pick<
                                                  Types.TableColumnOptionsResult,
                                                  | 'alignment'
                                                  | 'selectDefault'
                                                  | 'selectDisplayDefault'
                                                  | 'selectDisplayGroupId'
                                                  | 'selectGroupId'
                                                  | 'toggleDefault'
                                                  | 'toggleGroup'
                                                  | 'width'
                                                >
                                              >;
                                            }
                                        >
                                      >;
                                      rows?: Types.Maybe<
                                        Array<
                                          { __typename?: 'TableRowResult' } & Pick<
                                            Types.TableRowResult,
                                            'rowId' | 'rowType'
                                          > & {
                                              cells?: Types.Maybe<
                                                Array<
                                                  { __typename?: 'TableCellResult' } & Pick<
                                                    Types.TableCellResult,
                                                    'columnDataKey'
                                                  > & {
                                                      cellContent?: Types.Maybe<
                                                        | { __typename?: 'DrillDownResult' }
                                                        | ({ __typename?: 'KpiStatus' } & Pick<
                                                            Types.KpiStatus,
                                                            'text' | 'icon' | 'sentiment'
                                                          >)
                                                        | { __typename?: 'KpiStatusOverview' }
                                                        | { __typename?: 'LocationMarkerResult' }
                                                        | { __typename?: 'MicroChartResult' }
                                                        | { __typename?: 'ProgressBarResult' }
                                                        | { __typename?: 'TextLinkResult' }
                                                        | ({ __typename?: 'TextResult' } & Pick<
                                                            Types.TextResult,
                                                            'value'
                                                          >)
                                                        | { __typename?: 'Timeline' }
                                                        | { __typename?: 'TimelineDataPoint' }
                                                        | { __typename?: 'TimelinePhases' }
                                                        | { __typename?: 'VarianceResult' }
                                                      >;
                                                    }
                                                >
                                              >;
                                            }
                                        >
                                      >;
                                    };
                                })
                              | ({ __typename?: 'LocationMarkerResult' } & Pick<
                                  Types.LocationMarkerResult,
                                  'text'
                                > & {
                                    position?: Types.Maybe<
                                      { __typename?: 'GeolocationPositionResult' } & {
                                        coordinates?: Types.Maybe<
                                          { __typename?: 'GeolocationCoordinatesResult' } & Pick<
                                            Types.GeolocationCoordinatesResult,
                                            'latitude' | 'longitude'
                                          >
                                        >;
                                      }
                                    >;
                                  })
                              | ({ __typename?: 'MicroChartResult' } & Pick<
                                  Types.MicroChartResult,
                                  'seriesData' | 'trendlineData' | 'sentiment'
                                > & {
                                    linkResult?: Types.Maybe<
                                      { __typename?: 'Link' } & Pick<
                                        Types.Link,
                                        | 'pageId'
                                        | 'absolutePath'
                                        | 'drillId'
                                        | 'drillIds'
                                        | 'target'
                                      > & {
                                          filters?: Types.Maybe<
                                            Array<
                                              | ({ __typename?: 'SelectFilterValue' } & Pick<
                                                  Types.SelectFilterValue,
                                                  'id' | 'value'
                                                >)
                                              | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                                                  Types.SelectGroupFilterValue,
                                                  'id'
                                                > & {
                                                    values: Array<
                                                      Types.Maybe<
                                                        { __typename?: 'FilterCodeValue' } & Pick<
                                                          Types.FilterCodeValue,
                                                          'filterCode' | 'value'
                                                        >
                                                      >
                                                    >;
                                                  })
                                            >
                                          >;
                                        }
                                    >;
                                  })
                              | ({ __typename?: 'ProgressBarResult' } & Pick<
                                  Types.ProgressBarResult,
                                  | 'progressValue'
                                  | 'baseValue'
                                  | 'min'
                                  | 'max'
                                  | 'sentiment'
                                  | 'inverted'
                                  | 'variant'
                                >)
                              | ({ __typename?: 'TextLinkResult' } & Pick<
                                  Types.TextLinkResult,
                                  'value' | 'label' | 'subLabels' | 'linkDirection'
                                > & {
                                    linkResult?: Types.Maybe<
                                      { __typename?: 'Link' } & Pick<
                                        Types.Link,
                                        | 'pageId'
                                        | 'absolutePath'
                                        | 'drillId'
                                        | 'drillIds'
                                        | 'target'
                                      > & {
                                          filters?: Types.Maybe<
                                            Array<
                                              | ({ __typename?: 'SelectFilterValue' } & Pick<
                                                  Types.SelectFilterValue,
                                                  'id' | 'value'
                                                >)
                                              | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                                                  Types.SelectGroupFilterValue,
                                                  'id'
                                                > & {
                                                    values: Array<
                                                      Types.Maybe<
                                                        { __typename?: 'FilterCodeValue' } & Pick<
                                                          Types.FilterCodeValue,
                                                          'filterCode' | 'value'
                                                        >
                                                      >
                                                    >;
                                                  })
                                            >
                                          >;
                                        }
                                    >;
                                  })
                              | ({ __typename?: 'TextResult' } & Pick<
                                  Types.TextResult,
                                  'value' | 'subLabels'
                                >)
                              | ({ __typename?: 'Timeline' } & {
                                  axis: { __typename?: 'TimelineAxis' } & Pick<
                                    Types.TimelineAxis,
                                    'minX' | 'maxX' | 'categories'
                                  >;
                                  intervals?: Types.Maybe<
                                    Array<
                                      { __typename?: 'TimelineInterval' } & Pick<
                                        Types.TimelineInterval,
                                        'name' | 'x1' | 'x2'
                                      > & {
                                          color: { __typename?: 'ThemeColor' } & Pick<
                                            Types.ThemeColor,
                                            'color'
                                          >;
                                        }
                                    >
                                  >;
                                  series?: Types.Maybe<
                                    Array<
                                      { __typename?: 'TimelineSeries' } & Pick<
                                        Types.TimelineSeries,
                                        'id' | 'name' | 'sortOrder'
                                      > & {
                                          marker: { __typename?: 'TimelineMarker' } & Pick<
                                            Types.TimelineMarker,
                                            'symbol' | 'isFilled'
                                          > & {
                                              color: { __typename?: 'ThemeColor' } & Pick<
                                                Types.ThemeColor,
                                                'color'
                                              >;
                                            };
                                          data?: Types.Maybe<
                                            Array<
                                              { __typename?: 'TimelineDataPoint' } & Pick<
                                                Types.TimelineDataPoint,
                                                'x' | 'formattedX' | 'label'
                                              > & {
                                                  marker?: Types.Maybe<
                                                    { __typename?: 'TimelineMarker' } & Pick<
                                                      Types.TimelineMarker,
                                                      'symbol' | 'isFilled'
                                                    > & {
                                                        color: { __typename?: 'ThemeColor' } & Pick<
                                                          Types.ThemeColor,
                                                          'color'
                                                        >;
                                                      }
                                                  >;
                                                  tooltip?: Types.Maybe<
                                                    { __typename?: 'TableContent' } & Pick<
                                                      Types.TableContent,
                                                      'title'
                                                    > & {
                                                        columns?: Types.Maybe<
                                                          Array<
                                                            {
                                                              __typename?: 'TableColumnResult';
                                                            } & Pick<
                                                              Types.TableColumnResult,
                                                              'columnId' | 'dataKey' | 'title'
                                                            > & {
                                                                options?: Types.Maybe<
                                                                  {
                                                                    __typename?: 'TableColumnOptionsResult';
                                                                  } & Pick<
                                                                    Types.TableColumnOptionsResult,
                                                                    | 'alignment'
                                                                    | 'selectDefault'
                                                                    | 'selectDisplayDefault'
                                                                    | 'selectDisplayGroupId'
                                                                    | 'selectGroupId'
                                                                    | 'toggleDefault'
                                                                    | 'toggleGroup'
                                                                    | 'width'
                                                                  >
                                                                >;
                                                              }
                                                          >
                                                        >;
                                                        rows?: Types.Maybe<
                                                          Array<
                                                            {
                                                              __typename?: 'TableRowResult';
                                                            } & Pick<
                                                              Types.TableRowResult,
                                                              'rowId' | 'rowType'
                                                            > & {
                                                                cells?: Types.Maybe<
                                                                  Array<
                                                                    {
                                                                      __typename?: 'TableCellResult';
                                                                    } & Pick<
                                                                      Types.TableCellResult,
                                                                      'columnDataKey'
                                                                    > & {
                                                                        cellContent?: Types.Maybe<
                                                                          | {
                                                                              __typename?: 'DrillDownResult';
                                                                            }
                                                                          | ({
                                                                              __typename?: 'KpiStatus';
                                                                            } & Pick<
                                                                              Types.KpiStatus,
                                                                              | 'text'
                                                                              | 'icon'
                                                                              | 'sentiment'
                                                                            >)
                                                                          | {
                                                                              __typename?: 'KpiStatusOverview';
                                                                            }
                                                                          | {
                                                                              __typename?: 'LocationMarkerResult';
                                                                            }
                                                                          | {
                                                                              __typename?: 'MicroChartResult';
                                                                            }
                                                                          | {
                                                                              __typename?: 'ProgressBarResult';
                                                                            }
                                                                          | {
                                                                              __typename?: 'TextLinkResult';
                                                                            }
                                                                          | ({
                                                                              __typename?: 'TextResult';
                                                                            } & Pick<
                                                                              Types.TextResult,
                                                                              'value'
                                                                            >)
                                                                          | {
                                                                              __typename?: 'Timeline';
                                                                            }
                                                                          | {
                                                                              __typename?: 'TimelineDataPoint';
                                                                            }
                                                                          | {
                                                                              __typename?: 'TimelinePhases';
                                                                            }
                                                                          | {
                                                                              __typename?: 'VarianceResult';
                                                                            }
                                                                        >;
                                                                      }
                                                                  >
                                                                >;
                                                              }
                                                          >
                                                        >;
                                                      }
                                                  >;
                                                }
                                            >
                                          >;
                                        }
                                    >
                                  >;
                                })
                              | { __typename?: 'TimelineDataPoint' }
                              | ({ __typename?: 'TimelinePhases' } & {
                                  axis: { __typename?: 'TimelineAxis' } & Pick<
                                    Types.TimelineAxis,
                                    'minX' | 'maxX' | 'categories'
                                  >;
                                  intervals?: Types.Maybe<
                                    Array<
                                      { __typename?: 'TimelineInterval' } & Pick<
                                        Types.TimelineInterval,
                                        'name' | 'x1' | 'x2'
                                      > & {
                                          color: { __typename?: 'ThemeColor' } & Pick<
                                            Types.ThemeColor,
                                            'color'
                                          >;
                                        }
                                    >
                                  >;
                                })
                              | ({ __typename?: 'VarianceResult' } & Pick<
                                  Types.VarianceResult,
                                  'actual' | 'target' | 'sentiment' | 'label'
                                >)
                            >;
                          }
                      >
                    >;
                  }
              >
            >
          >;
          filters?: Types.Maybe<
            Array<
              | ({ __typename?: 'SelectFilterValue' } & Pick<
                  Types.SelectFilterValue,
                  'id' | 'value'
                >)
              | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                  Types.SelectGroupFilterValue,
                  'id'
                > & {
                    values: Array<
                      Types.Maybe<
                        { __typename?: 'FilterCodeValue' } & Pick<
                          Types.FilterCodeValue,
                          'filterCode' | 'value'
                        >
                      >
                    >;
                  })
            >
          >;
          editLink?: Types.Maybe<
            { __typename?: 'Link' } & Pick<
              Types.Link,
              'pageId' | 'absolutePath' | 'drillId' | 'drillIds' | 'target'
            > & {
                filters?: Types.Maybe<
                  Array<
                    | ({ __typename?: 'SelectFilterValue' } & Pick<
                        Types.SelectFilterValue,
                        'id' | 'value'
                      >)
                    | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                        Types.SelectGroupFilterValue,
                        'id'
                      > & {
                          values: Array<
                            Types.Maybe<
                              { __typename?: 'FilterCodeValue' } & Pick<
                                Types.FilterCodeValue,
                                'filterCode' | 'value'
                              >
                            >
                          >;
                        })
                  >
                >;
              }
          >;
          metadata?: Types.Maybe<
            Array<{ __typename?: 'MetaData' } & Pick<Types.MetaData, 'key' | 'label' | 'value'>>
          >;
          availableUserSettings?: Types.Maybe<
            Array<
              { __typename?: 'UserSetting' } & Pick<
                Types.UserSetting,
                'key' | 'label' | 'defaultValue' | 'userValue'
              > & {
                  options: Array<
                    { __typename?: 'SettingOption' } & Pick<Types.SettingOption, 'label' | 'value'>
                  >;
                }
            >
          >;
        })
  >;
};

export type GetFilterByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['Uuid']['input'];
}>;

export type GetFilterByIdQuery = { __typename?: 'Query' } & {
  filterById?: Types.Maybe<
    | ({ __typename?: 'SelectFilter' } & Pick<
        Types.SelectFilter,
        'filterCode' | 'additionalLabels' | 'id' | 'label' | 'filterType'
      > & {
          options?: Types.Maybe<
            Array<
              { __typename?: 'FilterOptionResult' } & Pick<
                Types.FilterOptionResult,
                'id' | 'label' | 'value' | 'isDefault' | 'additionalLabels' | 'sentiment'
              >
            >
          >;
        })
    | ({ __typename?: 'SelectGroupFilter' } & Pick<
        Types.SelectGroupFilter,
        'filterCode' | 'additionalLabels' | 'id' | 'label' | 'filterType'
      > & {
          filters: Array<
            { __typename?: 'SelectFilter' } & Pick<
              Types.SelectFilter,
              'id' | 'filterCode' | 'label'
            > & {
                options?: Types.Maybe<
                  Array<
                    { __typename?: 'FilterOptionResult' } & Pick<
                      Types.FilterOptionResult,
                      'id' | 'label' | 'value' | 'isDefault' | 'additionalLabels' | 'sentiment'
                    >
                  >
                >;
              }
          >;
        })
    | ({ __typename?: 'TreeSelectFilter' } & Pick<
        Types.TreeSelectFilter,
        'filterCode' | 'additionalLabels' | 'id' | 'label' | 'filterType'
      > & {
          options?: Types.Maybe<
            Array<
              { __typename?: 'TreeFilterOptionResult' } & Pick<
                Types.TreeFilterOptionResult,
                'id' | 'label' | 'value' | 'isDefault' | 'additionalLabels' | 'sentiment'
              > & {
                  children?: Types.Maybe<
                    Array<
                      { __typename?: 'TreeFilterOptionResult' } & Pick<
                        Types.TreeFilterOptionResult,
                        'id' | 'label' | 'value' | 'isDefault' | 'additionalLabels' | 'sentiment'
                      > & {
                          children?: Types.Maybe<
                            Array<
                              { __typename?: 'TreeFilterOptionResult' } & Pick<
                                Types.TreeFilterOptionResult,
                                | 'id'
                                | 'label'
                                | 'value'
                                | 'isDefault'
                                | 'additionalLabels'
                                | 'sentiment'
                              >
                            >
                          >;
                        }
                    >
                  >;
                }
            >
          >;
        })
  >;
};

export type GetFiltersByIdQueryVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars['Uuid']['input']> | Types.Scalars['Uuid']['input']>;
  moduleId?: Types.InputMaybe<Types.Scalars['Uuid']['input']>;
  filters?: Types.InputMaybe<
    Array<Types.InputMaybe<Types.FilterInput>> | Types.InputMaybe<Types.FilterInput>
  >;
  drillId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  drillIds?: Types.InputMaybe<Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input']>;
  viewCode?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type GetFiltersByIdQuery = { __typename?: 'Query' } & {
  filtersById?: Types.Maybe<
    Array<
      Types.Maybe<
        | ({ __typename?: 'SelectFilter' } & Pick<
            Types.SelectFilter,
            'filterCode' | 'additionalLabels' | 'id' | 'label' | 'filterType'
          > & {
              options?: Types.Maybe<
                Array<
                  { __typename?: 'FilterOptionResult' } & Pick<
                    Types.FilterOptionResult,
                    'id' | 'label' | 'value' | 'isDefault' | 'additionalLabels' | 'sentiment'
                  >
                >
              >;
            })
        | ({ __typename?: 'SelectGroupFilter' } & Pick<
            Types.SelectGroupFilter,
            'filterCode' | 'additionalLabels' | 'id' | 'label' | 'filterType'
          > & {
              filters: Array<
                { __typename?: 'SelectFilter' } & Pick<
                  Types.SelectFilter,
                  'id' | 'filterCode' | 'label'
                > & {
                    options?: Types.Maybe<
                      Array<
                        { __typename?: 'FilterOptionResult' } & Pick<
                          Types.FilterOptionResult,
                          'id' | 'label' | 'value' | 'isDefault' | 'additionalLabels' | 'sentiment'
                        >
                      >
                    >;
                  }
              >;
            })
        | ({ __typename?: 'TreeSelectFilter' } & Pick<
            Types.TreeSelectFilter,
            'filterCode' | 'additionalLabels' | 'id' | 'label' | 'filterType'
          > & {
              options?: Types.Maybe<
                Array<
                  { __typename?: 'TreeFilterOptionResult' } & Pick<
                    Types.TreeFilterOptionResult,
                    'id' | 'label' | 'value' | 'isDefault' | 'additionalLabels' | 'sentiment'
                  > & {
                      children?: Types.Maybe<
                        Array<
                          { __typename?: 'TreeFilterOptionResult' } & Pick<
                            Types.TreeFilterOptionResult,
                            | 'id'
                            | 'label'
                            | 'value'
                            | 'isDefault'
                            | 'additionalLabels'
                            | 'sentiment'
                          > & {
                              children?: Types.Maybe<
                                Array<
                                  { __typename?: 'TreeFilterOptionResult' } & Pick<
                                    Types.TreeFilterOptionResult,
                                    | 'id'
                                    | 'label'
                                    | 'value'
                                    | 'isDefault'
                                    | 'additionalLabels'
                                    | 'sentiment'
                                  >
                                >
                              >;
                            }
                        >
                      >;
                    }
                >
              >;
            })
      >
    >
  >;
};

export type GetModulesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetModulesQuery = { __typename?: 'Query' } & {
  modules?: Types.Maybe<
    Array<
      Types.Maybe<
        { __typename?: 'Module' } & Pick<Types.Module, 'id' | 'name' | 'moduleCode' | 'path'> & {
            pages?: Types.Maybe<
              Array<
                { __typename?: 'Page' } & Pick<
                  Types.Page,
                  | 'id'
                  | 'title'
                  | 'pageType'
                  | 'menuTitle'
                  | 'menuIcon'
                  | 'path'
                  | 'absolutePath'
                  | 'isMenuHidden'
                > & {
                    children?: Types.Maybe<
                      Array<
                        { __typename?: 'Page' } & Pick<
                          Types.Page,
                          | 'id'
                          | 'title'
                          | 'pageType'
                          | 'menuTitle'
                          | 'menuIcon'
                          | 'path'
                          | 'absolutePath'
                          | 'isMenuHidden'
                        > & {
                            children?: Types.Maybe<
                              Array<
                                { __typename?: 'Page' } & Pick<
                                  Types.Page,
                                  | 'id'
                                  | 'title'
                                  | 'pageType'
                                  | 'menuTitle'
                                  | 'menuIcon'
                                  | 'path'
                                  | 'absolutePath'
                                  | 'isMenuHidden'
                                >
                              >
                            >;
                          }
                      >
                    >;
                  }
              >
            >;
          }
      >
    >
  >;
};

export type GetPageContentQueryVariables = Types.Exact<{
  pageId: Types.Scalars['Uuid']['input'];
  priorityId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type GetPageContentQuery = { __typename?: 'Query' } & {
  pageContent: { __typename?: 'PageContentResult' } & {
    body: Array<
      | ({ __typename?: 'CardsComponent' } & Pick<
          Types.CardsComponent,
          'id' | 'code' | 'componentType' | 'icon'
        >)
      | ({ __typename?: 'ChartComponent' } & Pick<
          Types.ChartComponent,
          'id' | 'code' | 'componentType' | 'icon'
        > & {
            filters?: Types.Maybe<
              Array<
                { __typename?: 'FilterComponent' } & Pick<
                  Types.FilterComponent,
                  | 'defaultValue'
                  | 'alignment'
                  | 'isHidden'
                  | 'displayMode'
                  | 'id'
                  | 'code'
                  | 'componentType'
                  | 'icon'
                >
              >
            >;
          })
      | ({ __typename?: 'ColumnLayoutComponent' } & Pick<
          Types.ColumnLayoutComponent,
          'id' | 'code' | 'componentType' | 'icon'
        > & {
            headerRow?: Types.Maybe<
              Array<
                | ({ __typename?: 'CardsComponent' } & Pick<
                    Types.CardsComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'ChartComponent' } & Pick<
                    Types.ChartComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'ColumnLayoutComponent' } & Pick<
                    Types.ColumnLayoutComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'DialsComponent' } & Pick<
                    Types.DialsComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'FilterComponent' } & Pick<
                    Types.FilterComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'FiltersComponent' } & Pick<
                    Types.FiltersComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  > & {
                      filters?: Types.Maybe<
                        Array<
                          { __typename?: 'FilterComponent' } & Pick<
                            Types.FilterComponent,
                            | 'defaultValue'
                            | 'alignment'
                            | 'isHidden'
                            | 'displayMode'
                            | 'id'
                            | 'code'
                            | 'componentType'
                            | 'icon'
                          >
                        >
                      >;
                    })
                | ({ __typename?: 'GridComponent' } & Pick<
                    Types.GridComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'HeaderRowComponent' } & Pick<
                    Types.HeaderRowComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'KpiComponent' } & Pick<
                    Types.KpiComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'ListComponent' } & Pick<
                    Types.ListComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'MarkdownComponent' } & Pick<
                    Types.MarkdownComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'MicroChartComponent' } & Pick<
                    Types.MicroChartComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'NavigationTabsComponent' } & Pick<
                    Types.NavigationTabsComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'PlailistComponent' } & Pick<
                    Types.PlailistComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'RecommenderComponent' } & Pick<
                    Types.RecommenderComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'StoryListComponent' } & Pick<
                    Types.StoryListComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'SummaryInfoComponent' } & Pick<
                    Types.SummaryInfoComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'TableComponent' } & Pick<
                    Types.TableComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'TimelineComponent' } & Pick<
                    Types.TimelineComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'UserAnalyticsComponent' } & Pick<
                    Types.UserAnalyticsComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'UserPrioritiesComponent' } & Pick<
                    Types.UserPrioritiesComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
              >
            >;
            firstColumn?: Types.Maybe<
              Array<
                | ({ __typename?: 'CardsComponent' } & Pick<
                    Types.CardsComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'ChartComponent' } & Pick<
                    Types.ChartComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  > & {
                      filters?: Types.Maybe<
                        Array<
                          { __typename?: 'FilterComponent' } & Pick<
                            Types.FilterComponent,
                            | 'defaultValue'
                            | 'alignment'
                            | 'isHidden'
                            | 'displayMode'
                            | 'id'
                            | 'code'
                            | 'componentType'
                            | 'icon'
                          >
                        >
                      >;
                    })
                | ({ __typename?: 'ColumnLayoutComponent' } & Pick<
                    Types.ColumnLayoutComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'DialsComponent' } & Pick<
                    Types.DialsComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'FilterComponent' } & Pick<
                    Types.FilterComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'FiltersComponent' } & Pick<
                    Types.FiltersComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'GridComponent' } & Pick<
                    Types.GridComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'HeaderRowComponent' } & Pick<
                    Types.HeaderRowComponent,
                    'variant' | 'id' | 'code' | 'componentType' | 'icon'
                  > & {
                      filters?: Types.Maybe<
                        Array<
                          { __typename?: 'FilterComponent' } & Pick<
                            Types.FilterComponent,
                            | 'defaultValue'
                            | 'alignment'
                            | 'isHidden'
                            | 'displayMode'
                            | 'id'
                            | 'code'
                            | 'componentType'
                            | 'icon'
                          >
                        >
                      >;
                    })
                | ({ __typename?: 'KpiComponent' } & Pick<
                    Types.KpiComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'ListComponent' } & Pick<
                    Types.ListComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'MarkdownComponent' } & Pick<
                    Types.MarkdownComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'MicroChartComponent' } & Pick<
                    Types.MicroChartComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'NavigationTabsComponent' } & Pick<
                    Types.NavigationTabsComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'PlailistComponent' } & Pick<
                    Types.PlailistComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'RecommenderComponent' } & Pick<
                    Types.RecommenderComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'StoryListComponent' } & Pick<
                    Types.StoryListComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'SummaryInfoComponent' } & Pick<
                    Types.SummaryInfoComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'TableComponent' } & Pick<
                    Types.TableComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'TimelineComponent' } & Pick<
                    Types.TimelineComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'UserAnalyticsComponent' } & Pick<
                    Types.UserAnalyticsComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'UserPrioritiesComponent' } & Pick<
                    Types.UserPrioritiesComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
              >
            >;
            secondColumn?: Types.Maybe<
              Array<
                | ({ __typename?: 'CardsComponent' } & Pick<
                    Types.CardsComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'ChartComponent' } & Pick<
                    Types.ChartComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'ColumnLayoutComponent' } & Pick<
                    Types.ColumnLayoutComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'DialsComponent' } & Pick<
                    Types.DialsComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'FilterComponent' } & Pick<
                    Types.FilterComponent,
                    | 'defaultValue'
                    | 'alignment'
                    | 'isHidden'
                    | 'displayMode'
                    | 'id'
                    | 'code'
                    | 'componentType'
                    | 'icon'
                  >)
                | ({ __typename?: 'FiltersComponent' } & Pick<
                    Types.FiltersComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'GridComponent' } & Pick<
                    Types.GridComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'HeaderRowComponent' } & Pick<
                    Types.HeaderRowComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'KpiComponent' } & Pick<
                    Types.KpiComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'ListComponent' } & Pick<
                    Types.ListComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'MarkdownComponent' } & Pick<
                    Types.MarkdownComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'MicroChartComponent' } & Pick<
                    Types.MicroChartComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'NavigationTabsComponent' } & Pick<
                    Types.NavigationTabsComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'PlailistComponent' } & Pick<
                    Types.PlailistComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'RecommenderComponent' } & Pick<
                    Types.RecommenderComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'StoryListComponent' } & Pick<
                    Types.StoryListComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'SummaryInfoComponent' } & Pick<
                    Types.SummaryInfoComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'TableComponent' } & Pick<
                    Types.TableComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'TimelineComponent' } & Pick<
                    Types.TimelineComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'UserAnalyticsComponent' } & Pick<
                    Types.UserAnalyticsComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
                | ({ __typename?: 'UserPrioritiesComponent' } & Pick<
                    Types.UserPrioritiesComponent,
                    'id' | 'code' | 'componentType' | 'icon'
                  >)
              >
            >;
          })
      | ({ __typename?: 'DialsComponent' } & Pick<
          Types.DialsComponent,
          'id' | 'code' | 'componentType' | 'icon'
        >)
      | ({ __typename?: 'FilterComponent' } & Pick<
          Types.FilterComponent,
          | 'defaultValue'
          | 'alignment'
          | 'isHidden'
          | 'displayMode'
          | 'id'
          | 'code'
          | 'componentType'
          | 'icon'
        >)
      | ({ __typename?: 'FiltersComponent' } & Pick<
          Types.FiltersComponent,
          'id' | 'code' | 'componentType' | 'icon'
        > & {
            filters?: Types.Maybe<
              Array<
                { __typename?: 'FilterComponent' } & Pick<
                  Types.FilterComponent,
                  | 'defaultValue'
                  | 'alignment'
                  | 'isHidden'
                  | 'displayMode'
                  | 'id'
                  | 'code'
                  | 'componentType'
                  | 'icon'
                >
              >
            >;
          })
      | ({ __typename?: 'GridComponent' } & Pick<
          Types.GridComponent,
          'id' | 'code' | 'componentType' | 'icon'
        > & {
            filters?: Types.Maybe<
              Array<
                { __typename?: 'FilterComponent' } & Pick<
                  Types.FilterComponent,
                  | 'defaultValue'
                  | 'alignment'
                  | 'isHidden'
                  | 'displayMode'
                  | 'id'
                  | 'code'
                  | 'componentType'
                  | 'icon'
                >
              >
            >;
          })
      | ({ __typename?: 'HeaderRowComponent' } & Pick<
          Types.HeaderRowComponent,
          'variant' | 'id' | 'code' | 'componentType' | 'icon'
        > & {
            filters?: Types.Maybe<
              Array<
                { __typename?: 'FilterComponent' } & Pick<
                  Types.FilterComponent,
                  | 'defaultValue'
                  | 'alignment'
                  | 'isHidden'
                  | 'displayMode'
                  | 'id'
                  | 'code'
                  | 'componentType'
                  | 'icon'
                >
              >
            >;
          })
      | ({ __typename?: 'KpiComponent' } & Pick<
          Types.KpiComponent,
          'id' | 'code' | 'componentType' | 'icon'
        > & {
            filters?: Types.Maybe<
              Array<
                { __typename?: 'FilterComponent' } & Pick<
                  Types.FilterComponent,
                  | 'defaultValue'
                  | 'alignment'
                  | 'isHidden'
                  | 'displayMode'
                  | 'id'
                  | 'code'
                  | 'componentType'
                  | 'icon'
                >
              >
            >;
          })
      | ({ __typename?: 'ListComponent' } & Pick<
          Types.ListComponent,
          'id' | 'code' | 'componentType' | 'icon'
        > & {
            filters?: Types.Maybe<
              Array<
                { __typename?: 'FilterComponent' } & Pick<
                  Types.FilterComponent,
                  | 'defaultValue'
                  | 'alignment'
                  | 'isHidden'
                  | 'displayMode'
                  | 'id'
                  | 'code'
                  | 'componentType'
                  | 'icon'
                >
              >
            >;
          })
      | { __typename?: 'MarkdownComponent' }
      | { __typename?: 'MicroChartComponent' }
      | ({ __typename?: 'NavigationTabsComponent' } & Pick<
          Types.NavigationTabsComponent,
          'id' | 'code' | 'componentType' | 'icon'
        > & {
            tabs?: Types.Maybe<
              Array<{ __typename?: 'Tab' } & Pick<Types.Tab, 'title' | 'path' | 'pageId'>>
            >;
          })
      | ({ __typename?: 'PlailistComponent' } & Pick<
          Types.PlailistComponent,
          'id' | 'code' | 'componentType' | 'icon'
        >)
      | ({ __typename?: 'RecommenderComponent' } & Pick<
          Types.RecommenderComponent,
          'id' | 'code' | 'componentType' | 'icon'
        >)
      | ({ __typename?: 'StoryListComponent' } & Pick<
          Types.StoryListComponent,
          'id' | 'code' | 'componentType' | 'icon'
        >)
      | ({ __typename?: 'SummaryInfoComponent' } & Pick<
          Types.SummaryInfoComponent,
          'id' | 'code' | 'componentType' | 'icon'
        > & {
            filters?: Types.Maybe<
              Array<
                { __typename?: 'FilterComponent' } & Pick<
                  Types.FilterComponent,
                  | 'defaultValue'
                  | 'alignment'
                  | 'isHidden'
                  | 'displayMode'
                  | 'id'
                  | 'code'
                  | 'componentType'
                  | 'icon'
                >
              >
            >;
          })
      | ({ __typename?: 'TableComponent' } & Pick<
          Types.TableComponent,
          'id' | 'code' | 'componentType' | 'icon'
        > & {
            filters?: Types.Maybe<
              Array<
                { __typename?: 'FilterComponent' } & Pick<
                  Types.FilterComponent,
                  | 'defaultValue'
                  | 'alignment'
                  | 'isHidden'
                  | 'displayMode'
                  | 'id'
                  | 'code'
                  | 'componentType'
                  | 'icon'
                >
              >
            >;
          })
      | ({ __typename?: 'TimelineComponent' } & Pick<
          Types.TimelineComponent,
          'id' | 'code' | 'componentType' | 'icon'
        >)
      | ({ __typename?: 'UserAnalyticsComponent' } & Pick<
          Types.UserAnalyticsComponent,
          'id' | 'code' | 'componentType' | 'icon'
        > & {
            filters?: Types.Maybe<
              Array<
                { __typename?: 'FilterComponent' } & Pick<
                  Types.FilterComponent,
                  | 'defaultValue'
                  | 'alignment'
                  | 'isHidden'
                  | 'displayMode'
                  | 'id'
                  | 'code'
                  | 'componentType'
                  | 'icon'
                >
              >
            >;
          })
      | ({ __typename?: 'UserPrioritiesComponent' } & Pick<
          Types.UserPrioritiesComponent,
          'id' | 'code' | 'componentType' | 'icon'
        >)
    >;
  };
};

export type GetPlailistsQueryVariables = Types.Exact<{
  input: Types.PlailistsQueryInput;
}>;

export type GetPlailistsQuery = { __typename?: 'Query' } & {
  plailists?: Types.Maybe<
    Array<
      Types.Maybe<
        { __typename?: 'PlailistCategory' } & Pick<
          Types.PlailistCategory,
          'categoryId' | 'name'
        > & {
            plailists?: Types.Maybe<
              Array<
                Types.Maybe<
                  { __typename?: 'Plailist' } & Pick<
                    Types.Plailist,
                    'plailistId' | 'title' | 'summary'
                  > & {
                      colorStart?: Types.Maybe<
                        | ({ __typename?: 'ColorString' } & Pick<Types.ColorString, 'color'>)
                        | { __typename?: 'ThemeColor' }
                      >;
                      colorEnd?: Types.Maybe<
                        | ({ __typename?: 'ColorString' } & Pick<Types.ColorString, 'color'>)
                        | { __typename?: 'ThemeColor' }
                      >;
                      stories?: Types.Maybe<
                        Array<
                          Types.Maybe<
                            { __typename?: 'Story' } & Pick<
                              Types.Story,
                              | 'id'
                              | 'storyType'
                              | 'headline'
                              | 'recommenderHeadline'
                              | 'caption'
                              | 'sentiment'
                              | 'publishDate'
                              | 'isRead'
                              | 'tag'
                            > & {
                                content?: Types.Maybe<
                                  Array<
                                    Types.Maybe<
                                      | { __typename?: 'CardsComponent' }
                                      | ({ __typename?: 'ChartComponent' } & Pick<
                                          Types.ChartComponent,
                                          'id' | 'code' | 'componentType' | 'icon'
                                        > & {
                                            filters?: Types.Maybe<
                                              Array<
                                                { __typename?: 'FilterComponent' } & Pick<
                                                  Types.FilterComponent,
                                                  | 'defaultValue'
                                                  | 'alignment'
                                                  | 'isHidden'
                                                  | 'displayMode'
                                                  | 'id'
                                                  | 'code'
                                                  | 'componentType'
                                                  | 'icon'
                                                >
                                              >
                                            >;
                                          })
                                      | { __typename?: 'ColumnLayoutComponent' }
                                      | { __typename?: 'DialsComponent' }
                                      | { __typename?: 'FilterComponent' }
                                      | { __typename?: 'FiltersComponent' }
                                      | { __typename?: 'GridComponent' }
                                      | ({ __typename?: 'HeaderRowComponent' } & Pick<
                                          Types.HeaderRowComponent,
                                          'variant' | 'id' | 'code' | 'componentType' | 'icon'
                                        > & {
                                            filters?: Types.Maybe<
                                              Array<
                                                { __typename?: 'FilterComponent' } & Pick<
                                                  Types.FilterComponent,
                                                  | 'defaultValue'
                                                  | 'alignment'
                                                  | 'isHidden'
                                                  | 'displayMode'
                                                  | 'id'
                                                  | 'code'
                                                  | 'componentType'
                                                  | 'icon'
                                                >
                                              >
                                            >;
                                          })
                                      | { __typename?: 'KpiComponent' }
                                      | { __typename?: 'ListComponent' }
                                      | ({ __typename?: 'MarkdownComponent' } & Pick<
                                          Types.MarkdownComponent,
                                          'content' | 'id' | 'code' | 'componentType' | 'icon'
                                        >)
                                      | { __typename?: 'MicroChartComponent' }
                                      | { __typename?: 'NavigationTabsComponent' }
                                      | { __typename?: 'PlailistComponent' }
                                      | { __typename?: 'RecommenderComponent' }
                                      | { __typename?: 'StoryListComponent' }
                                      | { __typename?: 'SummaryInfoComponent' }
                                      | ({ __typename?: 'TableComponent' } & Pick<
                                          Types.TableComponent,
                                          'id' | 'code' | 'componentType' | 'icon'
                                        > & {
                                            filters?: Types.Maybe<
                                              Array<
                                                { __typename?: 'FilterComponent' } & Pick<
                                                  Types.FilterComponent,
                                                  | 'defaultValue'
                                                  | 'alignment'
                                                  | 'isHidden'
                                                  | 'displayMode'
                                                  | 'id'
                                                  | 'code'
                                                  | 'componentType'
                                                  | 'icon'
                                                >
                                              >
                                            >;
                                          })
                                      | { __typename?: 'TimelineComponent' }
                                      | { __typename?: 'UserAnalyticsComponent' }
                                      | { __typename?: 'UserPrioritiesComponent' }
                                    >
                                  >
                                >;
                                link?: Types.Maybe<
                                  { __typename?: 'Link' } & Pick<
                                    Types.Link,
                                    'pageId' | 'absolutePath' | 'drillId' | 'drillIds' | 'target'
                                  > & {
                                      filters?: Types.Maybe<
                                        Array<
                                          | ({ __typename?: 'SelectFilterValue' } & Pick<
                                              Types.SelectFilterValue,
                                              'id' | 'value'
                                            >)
                                          | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                                              Types.SelectGroupFilterValue,
                                              'id'
                                            > & {
                                                values: Array<
                                                  Types.Maybe<
                                                    { __typename?: 'FilterCodeValue' } & Pick<
                                                      Types.FilterCodeValue,
                                                      'filterCode' | 'value'
                                                    >
                                                  >
                                                >;
                                              })
                                        >
                                      >;
                                    }
                                >;
                              }
                          >
                        >
                      >;
                    }
                >
              >
            >;
          }
      >
    >
  >;
};

export type GetPrioritiesQueryVariables = Types.Exact<{
  input: Types.PrioritiesInput;
}>;

export type GetPrioritiesQuery = { __typename?: 'Query' } & {
  priorities?: Types.Maybe<
    Array<
      { __typename?: 'PriorityCategoryResult' } & Pick<
        Types.PriorityCategoryResult,
        'categoryName'
      > & {
          priorities: Array<
            { __typename?: 'PriorityResult' } & Pick<
              Types.PriorityResult,
              'priorityId' | 'priorityName' | 'isFollowed' | 'sortOrder'
            >
          >;
        }
    >
  >;
};

export type GetPriorityFilterQueryVariables = Types.Exact<{
  moduleId: Types.Scalars['Uuid']['input'];
}>;

export type GetPriorityFilterQuery = { __typename?: 'Query' } & {
  priorityFilter?: Types.Maybe<
    | ({ __typename?: 'SelectFilter' } & Pick<
        Types.SelectFilter,
        'filterCode' | 'additionalLabels' | 'id' | 'label' | 'filterType'
      > & {
          options?: Types.Maybe<
            Array<
              { __typename?: 'FilterOptionResult' } & Pick<
                Types.FilterOptionResult,
                'id' | 'label' | 'value' | 'isDefault' | 'additionalLabels' | 'sentiment'
              >
            >
          >;
        })
    | ({ __typename?: 'SelectGroupFilter' } & Pick<
        Types.SelectGroupFilter,
        'id' | 'label' | 'filterType'
      >)
    | ({ __typename?: 'TreeSelectFilter' } & Pick<
        Types.TreeSelectFilter,
        'filterCode' | 'additionalLabels' | 'id' | 'label' | 'filterType'
      > & {
          options?: Types.Maybe<
            Array<
              { __typename?: 'TreeFilterOptionResult' } & Pick<
                Types.TreeFilterOptionResult,
                'id' | 'label' | 'value' | 'isDefault' | 'additionalLabels' | 'sentiment'
              > & {
                  children?: Types.Maybe<
                    Array<
                      { __typename?: 'TreeFilterOptionResult' } & Pick<
                        Types.TreeFilterOptionResult,
                        'id' | 'label' | 'value' | 'isDefault' | 'additionalLabels' | 'sentiment'
                      > & {
                          children?: Types.Maybe<
                            Array<
                              { __typename?: 'TreeFilterOptionResult' } & Pick<
                                Types.TreeFilterOptionResult,
                                | 'id'
                                | 'label'
                                | 'value'
                                | 'isDefault'
                                | 'additionalLabels'
                                | 'sentiment'
                              >
                            >
                          >;
                        }
                    >
                  >;
                }
            >
          >;
        })
  >;
};

export type GetStoriesQueryVariables = Types.Exact<{
  input: Types.StoriesQueryInput;
}>;

export type GetStoriesQuery = { __typename?: 'Query' } & {
  stories?: Types.Maybe<
    Array<
      Types.Maybe<
        { __typename?: 'Story' } & Pick<
          Types.Story,
          | 'id'
          | 'storyType'
          | 'headline'
          | 'recommenderHeadline'
          | 'caption'
          | 'sentiment'
          | 'publishDate'
          | 'isRead'
          | 'tag'
        > & {
            content?: Types.Maybe<
              Array<
                Types.Maybe<
                  | { __typename?: 'CardsComponent' }
                  | ({ __typename?: 'ChartComponent' } & Pick<
                      Types.ChartComponent,
                      'id' | 'code' | 'componentType' | 'icon'
                    > & {
                        filters?: Types.Maybe<
                          Array<
                            { __typename?: 'FilterComponent' } & Pick<
                              Types.FilterComponent,
                              | 'defaultValue'
                              | 'alignment'
                              | 'isHidden'
                              | 'displayMode'
                              | 'id'
                              | 'code'
                              | 'componentType'
                              | 'icon'
                            >
                          >
                        >;
                      })
                  | { __typename?: 'ColumnLayoutComponent' }
                  | { __typename?: 'DialsComponent' }
                  | { __typename?: 'FilterComponent' }
                  | { __typename?: 'FiltersComponent' }
                  | { __typename?: 'GridComponent' }
                  | ({ __typename?: 'HeaderRowComponent' } & Pick<
                      Types.HeaderRowComponent,
                      'variant' | 'id' | 'code' | 'componentType' | 'icon'
                    > & {
                        filters?: Types.Maybe<
                          Array<
                            { __typename?: 'FilterComponent' } & Pick<
                              Types.FilterComponent,
                              | 'defaultValue'
                              | 'alignment'
                              | 'isHidden'
                              | 'displayMode'
                              | 'id'
                              | 'code'
                              | 'componentType'
                              | 'icon'
                            >
                          >
                        >;
                      })
                  | { __typename?: 'KpiComponent' }
                  | { __typename?: 'ListComponent' }
                  | ({ __typename?: 'MarkdownComponent' } & Pick<
                      Types.MarkdownComponent,
                      'content' | 'id' | 'code' | 'componentType' | 'icon'
                    >)
                  | { __typename?: 'MicroChartComponent' }
                  | { __typename?: 'NavigationTabsComponent' }
                  | { __typename?: 'PlailistComponent' }
                  | { __typename?: 'RecommenderComponent' }
                  | { __typename?: 'StoryListComponent' }
                  | { __typename?: 'SummaryInfoComponent' }
                  | ({ __typename?: 'TableComponent' } & Pick<
                      Types.TableComponent,
                      'id' | 'code' | 'componentType' | 'icon'
                    > & {
                        filters?: Types.Maybe<
                          Array<
                            { __typename?: 'FilterComponent' } & Pick<
                              Types.FilterComponent,
                              | 'defaultValue'
                              | 'alignment'
                              | 'isHidden'
                              | 'displayMode'
                              | 'id'
                              | 'code'
                              | 'componentType'
                              | 'icon'
                            >
                          >
                        >;
                      })
                  | { __typename?: 'TimelineComponent' }
                  | { __typename?: 'UserAnalyticsComponent' }
                  | { __typename?: 'UserPrioritiesComponent' }
                >
              >
            >;
            link?: Types.Maybe<
              { __typename?: 'Link' } & Pick<
                Types.Link,
                'pageId' | 'absolutePath' | 'drillId' | 'drillIds' | 'target'
              > & {
                  filters?: Types.Maybe<
                    Array<
                      | ({ __typename?: 'SelectFilterValue' } & Pick<
                          Types.SelectFilterValue,
                          'id' | 'value'
                        >)
                      | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                          Types.SelectGroupFilterValue,
                          'id'
                        > & {
                            values: Array<
                              Types.Maybe<
                                { __typename?: 'FilterCodeValue' } & Pick<
                                  Types.FilterCodeValue,
                                  'filterCode' | 'value'
                                >
                              >
                            >;
                          })
                    >
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type GetStoryByIdQueryVariables = Types.Exact<{
  storyId: Types.Scalars['Uuid']['input'];
}>;

export type GetStoryByIdQuery = { __typename?: 'Query' } & {
  storyById?: Types.Maybe<
    { __typename?: 'Story' } & Pick<
      Types.Story,
      | 'id'
      | 'storyType'
      | 'headline'
      | 'recommenderHeadline'
      | 'caption'
      | 'sentiment'
      | 'publishDate'
      | 'isRead'
      | 'tag'
    > & {
        content?: Types.Maybe<
          Array<
            Types.Maybe<
              | { __typename?: 'CardsComponent' }
              | ({ __typename?: 'ChartComponent' } & Pick<
                  Types.ChartComponent,
                  'id' | 'code' | 'componentType' | 'icon'
                > & {
                    filters?: Types.Maybe<
                      Array<
                        { __typename?: 'FilterComponent' } & Pick<
                          Types.FilterComponent,
                          | 'defaultValue'
                          | 'alignment'
                          | 'isHidden'
                          | 'displayMode'
                          | 'id'
                          | 'code'
                          | 'componentType'
                          | 'icon'
                        >
                      >
                    >;
                  })
              | { __typename?: 'ColumnLayoutComponent' }
              | { __typename?: 'DialsComponent' }
              | { __typename?: 'FilterComponent' }
              | { __typename?: 'FiltersComponent' }
              | { __typename?: 'GridComponent' }
              | ({ __typename?: 'HeaderRowComponent' } & Pick<
                  Types.HeaderRowComponent,
                  'variant' | 'id' | 'code' | 'componentType' | 'icon'
                > & {
                    filters?: Types.Maybe<
                      Array<
                        { __typename?: 'FilterComponent' } & Pick<
                          Types.FilterComponent,
                          | 'defaultValue'
                          | 'alignment'
                          | 'isHidden'
                          | 'displayMode'
                          | 'id'
                          | 'code'
                          | 'componentType'
                          | 'icon'
                        >
                      >
                    >;
                  })
              | { __typename?: 'KpiComponent' }
              | { __typename?: 'ListComponent' }
              | ({ __typename?: 'MarkdownComponent' } & Pick<
                  Types.MarkdownComponent,
                  'content' | 'id' | 'code' | 'componentType' | 'icon'
                >)
              | { __typename?: 'MicroChartComponent' }
              | { __typename?: 'NavigationTabsComponent' }
              | { __typename?: 'PlailistComponent' }
              | { __typename?: 'RecommenderComponent' }
              | { __typename?: 'StoryListComponent' }
              | { __typename?: 'SummaryInfoComponent' }
              | ({ __typename?: 'TableComponent' } & Pick<
                  Types.TableComponent,
                  'id' | 'code' | 'componentType' | 'icon'
                > & {
                    filters?: Types.Maybe<
                      Array<
                        { __typename?: 'FilterComponent' } & Pick<
                          Types.FilterComponent,
                          | 'defaultValue'
                          | 'alignment'
                          | 'isHidden'
                          | 'displayMode'
                          | 'id'
                          | 'code'
                          | 'componentType'
                          | 'icon'
                        >
                      >
                    >;
                  })
              | { __typename?: 'TimelineComponent' }
              | { __typename?: 'UserAnalyticsComponent' }
              | { __typename?: 'UserPrioritiesComponent' }
            >
          >
        >;
        link?: Types.Maybe<
          { __typename?: 'Link' } & Pick<
            Types.Link,
            'pageId' | 'absolutePath' | 'drillId' | 'drillIds' | 'target'
          > & {
              filters?: Types.Maybe<
                Array<
                  | ({ __typename?: 'SelectFilterValue' } & Pick<
                      Types.SelectFilterValue,
                      'id' | 'value'
                    >)
                  | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                      Types.SelectGroupFilterValue,
                      'id'
                    > & {
                        values: Array<
                          Types.Maybe<
                            { __typename?: 'FilterCodeValue' } & Pick<
                              Types.FilterCodeValue,
                              'filterCode' | 'value'
                            >
                          >
                        >;
                      })
                >
              >;
            }
        >;
      }
  >;
};

export type GetUserAnalyticsQueryVariables = Types.Exact<{
  input: Types.UserAnalyticsInput;
}>;

export type GetUserAnalyticsQuery = { __typename?: 'Query' } & {
  userAnalytics?: Types.Maybe<
    { __typename?: 'UserAnalytics' } & {
      headerMetrics?: Types.Maybe<
        Array<
          Types.Maybe<
            { __typename?: 'Metric' } & Pick<
              Types.Metric,
              'id' | 'name' | 'value' | 'sentiment'
            > & {
                variances?: Types.Maybe<
                  Array<
                    Types.Maybe<
                      { __typename?: 'VarianceResult' } & Pick<
                        Types.VarianceResult,
                        'actual' | 'target' | 'sentiment' | 'label'
                      >
                    >
                  >
                >;
              }
          >
        >
      >;
      metricLists?: Types.Maybe<
        Array<
          Types.Maybe<
            { __typename?: 'MetricList' } & Pick<Types.MetricList, 'name'> & {
                metrics: Array<
                  Types.Maybe<
                    { __typename?: 'Metric' } & Pick<
                      Types.Metric,
                      'id' | 'name' | 'value' | 'sentiment'
                    > & {
                        variances?: Types.Maybe<
                          Array<
                            Types.Maybe<
                              { __typename?: 'VarianceResult' } & Pick<
                                Types.VarianceResult,
                                'actual' | 'target' | 'sentiment' | 'label'
                              >
                            >
                          >
                        >;
                      }
                  >
                >;
              }
          >
        >
      >;
    }
  >;
};

export type GetUserPrioritiesQueryVariables = Types.Exact<{
  input: Types.PrioritiesInput;
}>;

export type GetUserPrioritiesQuery = { __typename?: 'Query' } & {
  userPriorities?: Types.Maybe<
    Array<
      { __typename?: 'PriorityCategoryResult' } & Pick<
        Types.PriorityCategoryResult,
        'categoryName'
      > & {
          priorities: Array<
            { __typename?: 'PriorityResult' } & Pick<
              Types.PriorityResult,
              'priorityId' | 'priorityName' | 'isFollowed' | 'sortOrder'
            >
          >;
        }
    >
  >;
};

export type GetUserSettingsQueryVariables = Types.Exact<{
  input: Types.GetUserSettingsInput;
}>;

export type GetUserSettingsQuery = { __typename?: 'Query' } & {
  userSettings?: Types.Maybe<
    Array<
      { __typename?: 'UserSetting' } & Pick<
        Types.UserSetting,
        'key' | 'label' | 'defaultValue' | 'userValue'
      > & {
          options: Array<
            { __typename?: 'SettingOption' } & Pick<Types.SettingOption, 'label' | 'value'>
          >;
        }
    >
  >;
};

export const GetDataViewDocument = gql`
  query GetDataView($input: DataViewInput) {
    dataView(input: $input) {
      ...DataViewFields
      ... on TableDataView {
        title
        annotation
        columns {
          ...TableColumnFields
        }
        rows {
          ...TableRowFields
          children {
            ...TableRowFields
            children {
              ...TableRowFields
              children {
                ...TableRowFields
                children {
                  ...TableRowFields
                  children {
                    ...TableRowFields
                  }
                }
              }
            }
          }
        }
        filters {
          ...FilterValueFields
        }
        editLink {
          ...LinkFields
        }
        lastUpdateDate
      }
      ... on GridDataView {
        title
        annotation
        columns {
          ...TableColumnFields
        }
        rows {
          ...TableRowFields
        }
        filters {
          ...FilterValueFields
        }
        editLink {
          ...LinkFields
        }
        lastUpdateDate
      }
      ... on ListDataView {
        title
        annotation
        columns {
          ...TableColumnFields
        }
        rows {
          ...TableRowFields
        }
        filters {
          ...FilterValueFields
        }
      }
      ... on ChartDataViewResult {
        xAxis {
          ...ChartAxisFields
        }
        yAxis {
          ...ChartAxisFields
        }
        series {
          ...ChartSeriesFields
        }
        filters {
          ...FilterValueFields
        }
        renderer
      }
      ... on HeaderRow {
        columns {
          ...TableColumnFields
        }
        rows {
          ...TableRowFields
        }
      }
      ... on SummaryInfoDataView {
        summaryInfo {
          items {
            ...SummaryInfoItemFields
          }
        }
      }
      ... on KpiStatusOverview {
        ...KpiStatusOverviewFields
      }
      ... on DialsDataView {
        annotation
        annotationDate
        items {
          ...DialItemFields
        }
        lastUpdateDate
      }
      ... on RecommenderDataView {
        stories {
          ...StoryFields
        }
      }
      ... on CardsDataView {
        title
        annotation
        cards {
          ... on MicroChartCardResult {
            ...MicroChartCardFields
          }
          ... on KpiCardResult {
            ...KpiCardFields
          }
          ... on SimpleCardResult {
            ...SimpleCardFields
          }
        }
        editLink {
          ...LinkFields
        }
      }
    }
  }
  ${DataViewFieldsFragmentDoc}
  ${TableColumnFieldsFragmentDoc}
  ${TableRowFieldsFragmentDoc}
  ${FilterValueFieldsFragmentDoc}
  ${LinkFieldsFragmentDoc}
  ${ChartAxisFieldsFragmentDoc}
  ${ChartSeriesFieldsFragmentDoc}
  ${SummaryInfoItemFieldsFragmentDoc}
  ${KpiStatusOverviewFieldsFragmentDoc}
  ${DialItemFieldsFragmentDoc}
  ${StoryFieldsFragmentDoc}
  ${MicroChartCardFieldsFragmentDoc}
  ${KpiCardFieldsFragmentDoc}
  ${SimpleCardFieldsFragmentDoc}
`;

/**
 * __useGetDataViewQuery__
 *
 * To run a query within a React component, call `useGetDataViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataViewQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDataViewQuery(
  baseOptions?: Apollo.QueryHookOptions<GetDataViewQuery, GetDataViewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDataViewQuery, GetDataViewQueryVariables>(GetDataViewDocument, options);
}
export function useGetDataViewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDataViewQuery, GetDataViewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDataViewQuery, GetDataViewQueryVariables>(
    GetDataViewDocument,
    options,
  );
}
export type GetDataViewQueryHookResult = ReturnType<typeof useGetDataViewQuery>;
export type GetDataViewLazyQueryHookResult = ReturnType<typeof useGetDataViewLazyQuery>;
export type GetDataViewQueryResult = Apollo.QueryResult<
  GetDataViewQuery,
  GetDataViewQueryVariables
>;
export const GetFilterByIdDocument = gql`
  query GetFilterById($id: Uuid!) {
    filterById(id: $id) {
      ...FilterFields
      ... on SelectFilter {
        ...SelectFilterFields
      }
      ... on TreeSelectFilter {
        ...TreeSelectFilterFields
      }
      ... on SelectGroupFilter {
        ...SelectGroupFilterFields
      }
    }
  }
  ${FilterFieldsFragmentDoc}
  ${SelectFilterFieldsFragmentDoc}
  ${TreeSelectFilterFieldsFragmentDoc}
  ${SelectGroupFilterFieldsFragmentDoc}
`;

/**
 * __useGetFilterByIdQuery__
 *
 * To run a query within a React component, call `useGetFilterByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilterByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilterByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFilterByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetFilterByIdQuery, GetFilterByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFilterByIdQuery, GetFilterByIdQueryVariables>(
    GetFilterByIdDocument,
    options,
  );
}
export function useGetFilterByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFilterByIdQuery, GetFilterByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFilterByIdQuery, GetFilterByIdQueryVariables>(
    GetFilterByIdDocument,
    options,
  );
}
export type GetFilterByIdQueryHookResult = ReturnType<typeof useGetFilterByIdQuery>;
export type GetFilterByIdLazyQueryHookResult = ReturnType<typeof useGetFilterByIdLazyQuery>;
export type GetFilterByIdQueryResult = Apollo.QueryResult<
  GetFilterByIdQuery,
  GetFilterByIdQueryVariables
>;
export const GetFiltersByIdDocument = gql`
  query GetFiltersById(
    $ids: [Uuid!]
    $moduleId: Uuid
    $filters: [FilterInput]
    $drillId: Int
    $drillIds: [Int!]
    $viewCode: String
  ) {
    filtersById(
      ids: $ids
      moduleId: $moduleId
      filters: $filters
      drillId: $drillId
      drillIds: $drillIds
      viewCode: $viewCode
    ) {
      ...FilterFields
      ... on SelectFilter {
        ...SelectFilterFields
      }
      ... on TreeSelectFilter {
        ...TreeSelectFilterFields
      }
      ... on SelectGroupFilter {
        ...SelectGroupFilterFields
      }
    }
  }
  ${FilterFieldsFragmentDoc}
  ${SelectFilterFieldsFragmentDoc}
  ${TreeSelectFilterFieldsFragmentDoc}
  ${SelectGroupFilterFieldsFragmentDoc}
`;

/**
 * __useGetFiltersByIdQuery__
 *
 * To run a query within a React component, call `useGetFiltersByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFiltersByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFiltersByIdQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      moduleId: // value for 'moduleId'
 *      filters: // value for 'filters'
 *      drillId: // value for 'drillId'
 *      drillIds: // value for 'drillIds'
 *      viewCode: // value for 'viewCode'
 *   },
 * });
 */
export function useGetFiltersByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<GetFiltersByIdQuery, GetFiltersByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFiltersByIdQuery, GetFiltersByIdQueryVariables>(
    GetFiltersByIdDocument,
    options,
  );
}
export function useGetFiltersByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFiltersByIdQuery, GetFiltersByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFiltersByIdQuery, GetFiltersByIdQueryVariables>(
    GetFiltersByIdDocument,
    options,
  );
}
export type GetFiltersByIdQueryHookResult = ReturnType<typeof useGetFiltersByIdQuery>;
export type GetFiltersByIdLazyQueryHookResult = ReturnType<typeof useGetFiltersByIdLazyQuery>;
export type GetFiltersByIdQueryResult = Apollo.QueryResult<
  GetFiltersByIdQuery,
  GetFiltersByIdQueryVariables
>;
export const GetModulesDocument = gql`
  query GetModules {
    modules {
      ...ModuleFields
    }
  }
  ${ModuleFieldsFragmentDoc}
`;

/**
 * __useGetModulesQuery__
 *
 * To run a query within a React component, call `useGetModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetModulesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetModulesQuery, GetModulesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetModulesQuery, GetModulesQueryVariables>(GetModulesDocument, options);
}
export function useGetModulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetModulesQuery, GetModulesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetModulesQuery, GetModulesQueryVariables>(
    GetModulesDocument,
    options,
  );
}
export type GetModulesQueryHookResult = ReturnType<typeof useGetModulesQuery>;
export type GetModulesLazyQueryHookResult = ReturnType<typeof useGetModulesLazyQuery>;
export type GetModulesQueryResult = Apollo.QueryResult<GetModulesQuery, GetModulesQueryVariables>;
export const GetPageContentDocument = gql`
  query GetPageContent($pageId: Uuid!, $priorityId: Int) {
    pageContent(pageId: $pageId, priorityId: $priorityId) {
      body {
        ... on FilterComponent {
          ...FilterComponentFields
        }
        ... on NavigationTabsComponent {
          ...NavigationTabsComponentFields
        }
        ... on FiltersComponent {
          ...ComponentFields
          filters {
            ...FilterComponentFields
          }
        }
        ... on TableComponent {
          ...TableComponentFields
        }
        ... on GridComponent {
          ...ComponentFields
          filters {
            ...FilterComponentFields
          }
        }
        ... on ListComponent {
          ...ComponentFields
          filters {
            ...FilterComponentFields
          }
        }
        ... on ChartComponent {
          ...ComponentFields
          filters {
            ...FilterComponentFields
          }
        }
        ... on HeaderRowComponent {
          ...ComponentFields
          filters {
            ...FilterComponentFields
          }
          variant
        }
        ... on SummaryInfoComponent {
          ...ComponentFields
          filters {
            ...FilterComponentFields
          }
        }
        ... on KpiComponent {
          ...ComponentFields
          filters {
            ...FilterComponentFields
          }
        }
        ... on TimelineComponent {
          ...ComponentFields
        }
        ... on DialsComponent {
          ...ComponentFields
        }
        ... on StoryListComponent {
          ...ComponentFields
        }
        ... on RecommenderComponent {
          ...ComponentFields
        }
        ... on PlailistComponent {
          ...ComponentFields
        }
        ... on ColumnLayoutComponent {
          ...ComponentFields
          headerRow {
            ...ComponentFields
            ... on FiltersComponent {
              filters {
                ...FilterComponentFields
              }
            }
          }
          firstColumn {
            ...ComponentFields
            ... on HeaderRowComponent {
              filters {
                ...FilterComponentFields
              }
              variant
            }
            ... on ChartComponent {
              filters {
                ...FilterComponentFields
              }
            }
          }
          secondColumn {
            ...ComponentFields
            ... on FilterComponent {
              ...FilterComponentFields
            }
          }
        }
        ... on UserAnalyticsComponent {
          ...ComponentFields
          filters {
            ...FilterComponentFields
          }
        }
        ... on UserPrioritiesComponent {
          ...ComponentFields
        }
        ... on CardsComponent {
          ...ComponentFields
        }
      }
    }
  }
  ${FilterComponentFieldsFragmentDoc}
  ${NavigationTabsComponentFieldsFragmentDoc}
  ${ComponentFieldsFragmentDoc}
  ${TableComponentFieldsFragmentDoc}
`;

/**
 * __useGetPageContentQuery__
 *
 * To run a query within a React component, call `useGetPageContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPageContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPageContentQuery({
 *   variables: {
 *      pageId: // value for 'pageId'
 *      priorityId: // value for 'priorityId'
 *   },
 * });
 */
export function useGetPageContentQuery(
  baseOptions: Apollo.QueryHookOptions<GetPageContentQuery, GetPageContentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPageContentQuery, GetPageContentQueryVariables>(
    GetPageContentDocument,
    options,
  );
}
export function useGetPageContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPageContentQuery, GetPageContentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPageContentQuery, GetPageContentQueryVariables>(
    GetPageContentDocument,
    options,
  );
}
export type GetPageContentQueryHookResult = ReturnType<typeof useGetPageContentQuery>;
export type GetPageContentLazyQueryHookResult = ReturnType<typeof useGetPageContentLazyQuery>;
export type GetPageContentQueryResult = Apollo.QueryResult<
  GetPageContentQuery,
  GetPageContentQueryVariables
>;
export const GetPlailistsDocument = gql`
  query GetPlailists($input: PlailistsQueryInput!) {
    plailists(input: $input) {
      ...PlailistCategoryFields
    }
  }
  ${PlailistCategoryFieldsFragmentDoc}
`;

/**
 * __useGetPlailistsQuery__
 *
 * To run a query within a React component, call `useGetPlailistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlailistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlailistsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPlailistsQuery(
  baseOptions: Apollo.QueryHookOptions<GetPlailistsQuery, GetPlailistsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlailistsQuery, GetPlailistsQueryVariables>(
    GetPlailistsDocument,
    options,
  );
}
export function useGetPlailistsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPlailistsQuery, GetPlailistsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlailistsQuery, GetPlailistsQueryVariables>(
    GetPlailistsDocument,
    options,
  );
}
export type GetPlailistsQueryHookResult = ReturnType<typeof useGetPlailistsQuery>;
export type GetPlailistsLazyQueryHookResult = ReturnType<typeof useGetPlailistsLazyQuery>;
export type GetPlailistsQueryResult = Apollo.QueryResult<
  GetPlailistsQuery,
  GetPlailistsQueryVariables
>;
export const GetPrioritiesDocument = gql`
  query GetPriorities($input: PrioritiesInput!) {
    priorities(input: $input) {
      ...PriorityCategoryFields
    }
  }
  ${PriorityCategoryFieldsFragmentDoc}
`;

/**
 * __useGetPrioritiesQuery__
 *
 * To run a query within a React component, call `useGetPrioritiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrioritiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrioritiesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPrioritiesQuery(
  baseOptions: Apollo.QueryHookOptions<GetPrioritiesQuery, GetPrioritiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPrioritiesQuery, GetPrioritiesQueryVariables>(
    GetPrioritiesDocument,
    options,
  );
}
export function useGetPrioritiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPrioritiesQuery, GetPrioritiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPrioritiesQuery, GetPrioritiesQueryVariables>(
    GetPrioritiesDocument,
    options,
  );
}
export type GetPrioritiesQueryHookResult = ReturnType<typeof useGetPrioritiesQuery>;
export type GetPrioritiesLazyQueryHookResult = ReturnType<typeof useGetPrioritiesLazyQuery>;
export type GetPrioritiesQueryResult = Apollo.QueryResult<
  GetPrioritiesQuery,
  GetPrioritiesQueryVariables
>;
export const GetPriorityFilterDocument = gql`
  query GetPriorityFilter($moduleId: Uuid!) {
    priorityFilter(moduleId: $moduleId) {
      ...FilterFields
      ... on SelectFilter {
        ...SelectFilterFields
      }
      ... on TreeSelectFilter {
        ...TreeSelectFilterFields
      }
    }
  }
  ${FilterFieldsFragmentDoc}
  ${SelectFilterFieldsFragmentDoc}
  ${TreeSelectFilterFieldsFragmentDoc}
`;

/**
 * __useGetPriorityFilterQuery__
 *
 * To run a query within a React component, call `useGetPriorityFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPriorityFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPriorityFilterQuery({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useGetPriorityFilterQuery(
  baseOptions: Apollo.QueryHookOptions<GetPriorityFilterQuery, GetPriorityFilterQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPriorityFilterQuery, GetPriorityFilterQueryVariables>(
    GetPriorityFilterDocument,
    options,
  );
}
export function useGetPriorityFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPriorityFilterQuery,
    GetPriorityFilterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPriorityFilterQuery, GetPriorityFilterQueryVariables>(
    GetPriorityFilterDocument,
    options,
  );
}
export type GetPriorityFilterQueryHookResult = ReturnType<typeof useGetPriorityFilterQuery>;
export type GetPriorityFilterLazyQueryHookResult = ReturnType<typeof useGetPriorityFilterLazyQuery>;
export type GetPriorityFilterQueryResult = Apollo.QueryResult<
  GetPriorityFilterQuery,
  GetPriorityFilterQueryVariables
>;
export const GetStoriesDocument = gql`
  query GetStories($input: StoriesQueryInput!) {
    stories(input: $input) {
      ...StoryFields
    }
  }
  ${StoryFieldsFragmentDoc}
`;

/**
 * __useGetStoriesQuery__
 *
 * To run a query within a React component, call `useGetStoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetStoriesQuery(
  baseOptions: Apollo.QueryHookOptions<GetStoriesQuery, GetStoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStoriesQuery, GetStoriesQueryVariables>(GetStoriesDocument, options);
}
export function useGetStoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStoriesQuery, GetStoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStoriesQuery, GetStoriesQueryVariables>(
    GetStoriesDocument,
    options,
  );
}
export type GetStoriesQueryHookResult = ReturnType<typeof useGetStoriesQuery>;
export type GetStoriesLazyQueryHookResult = ReturnType<typeof useGetStoriesLazyQuery>;
export type GetStoriesQueryResult = Apollo.QueryResult<GetStoriesQuery, GetStoriesQueryVariables>;
export const GetStoryByIdDocument = gql`
  query GetStoryById($storyId: Uuid!) {
    storyById(storyId: $storyId) {
      ...StoryFields
    }
  }
  ${StoryFieldsFragmentDoc}
`;

/**
 * __useGetStoryByIdQuery__
 *
 * To run a query within a React component, call `useGetStoryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoryByIdQuery({
 *   variables: {
 *      storyId: // value for 'storyId'
 *   },
 * });
 */
export function useGetStoryByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetStoryByIdQuery, GetStoryByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStoryByIdQuery, GetStoryByIdQueryVariables>(
    GetStoryByIdDocument,
    options,
  );
}
export function useGetStoryByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStoryByIdQuery, GetStoryByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStoryByIdQuery, GetStoryByIdQueryVariables>(
    GetStoryByIdDocument,
    options,
  );
}
export type GetStoryByIdQueryHookResult = ReturnType<typeof useGetStoryByIdQuery>;
export type GetStoryByIdLazyQueryHookResult = ReturnType<typeof useGetStoryByIdLazyQuery>;
export type GetStoryByIdQueryResult = Apollo.QueryResult<
  GetStoryByIdQuery,
  GetStoryByIdQueryVariables
>;
export const GetUserAnalyticsDocument = gql`
  query GetUserAnalytics($input: UserAnalyticsInput!) {
    userAnalytics(input: $input) {
      headerMetrics {
        ...MetricFields
      }
      metricLists {
        ...MetricListFields
      }
    }
  }
  ${MetricFieldsFragmentDoc}
  ${MetricListFieldsFragmentDoc}
`;

/**
 * __useGetUserAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetUserAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAnalyticsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserAnalyticsQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserAnalyticsQuery, GetUserAnalyticsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserAnalyticsQuery, GetUserAnalyticsQueryVariables>(
    GetUserAnalyticsDocument,
    options,
  );
}
export function useGetUserAnalyticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserAnalyticsQuery, GetUserAnalyticsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserAnalyticsQuery, GetUserAnalyticsQueryVariables>(
    GetUserAnalyticsDocument,
    options,
  );
}
export type GetUserAnalyticsQueryHookResult = ReturnType<typeof useGetUserAnalyticsQuery>;
export type GetUserAnalyticsLazyQueryHookResult = ReturnType<typeof useGetUserAnalyticsLazyQuery>;
export type GetUserAnalyticsQueryResult = Apollo.QueryResult<
  GetUserAnalyticsQuery,
  GetUserAnalyticsQueryVariables
>;
export const GetUserPrioritiesDocument = gql`
  query GetUserPriorities($input: PrioritiesInput!) {
    userPriorities(input: $input) {
      ...PriorityCategoryFields
    }
  }
  ${PriorityCategoryFieldsFragmentDoc}
`;

/**
 * __useGetUserPrioritiesQuery__
 *
 * To run a query within a React component, call `useGetUserPrioritiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPrioritiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPrioritiesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserPrioritiesQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserPrioritiesQuery, GetUserPrioritiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserPrioritiesQuery, GetUserPrioritiesQueryVariables>(
    GetUserPrioritiesDocument,
    options,
  );
}
export function useGetUserPrioritiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserPrioritiesQuery,
    GetUserPrioritiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserPrioritiesQuery, GetUserPrioritiesQueryVariables>(
    GetUserPrioritiesDocument,
    options,
  );
}
export type GetUserPrioritiesQueryHookResult = ReturnType<typeof useGetUserPrioritiesQuery>;
export type GetUserPrioritiesLazyQueryHookResult = ReturnType<typeof useGetUserPrioritiesLazyQuery>;
export type GetUserPrioritiesQueryResult = Apollo.QueryResult<
  GetUserPrioritiesQuery,
  GetUserPrioritiesQueryVariables
>;
export const GetUserSettingsDocument = gql`
  query GetUserSettings($input: GetUserSettingsInput!) {
    userSettings(input: $input) {
      ...UserSettingFields
    }
  }
  ${UserSettingFieldsFragmentDoc}
`;

/**
 * __useGetUserSettingsQuery__
 *
 * To run a query within a React component, call `useGetUserSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSettingsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserSettingsQuery, GetUserSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserSettingsQuery, GetUserSettingsQueryVariables>(
    GetUserSettingsDocument,
    options,
  );
}
export function useGetUserSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserSettingsQuery, GetUserSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserSettingsQuery, GetUserSettingsQueryVariables>(
    GetUserSettingsDocument,
    options,
  );
}
export type GetUserSettingsQueryHookResult = ReturnType<typeof useGetUserSettingsQuery>;
export type GetUserSettingsLazyQueryHookResult = ReturnType<typeof useGetUserSettingsLazyQuery>;
export type GetUserSettingsQueryResult = Apollo.QueryResult<
  GetUserSettingsQuery,
  GetUserSettingsQueryVariables
>;
