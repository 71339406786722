import { Grid, GridProps as MuiGridProps } from '@mui/material';
import { motion, MotionProps } from 'framer-motion';
import React from 'react';
import { ItemInterface, ReactSortable, ReactSortableProps } from 'react-sortablejs';

// The following props either cause a type collision, or we don't want them to be set from outside
type OmitPropKeys = 'children' | 'component' | 'custom' | 'direction' | 'ref' | 'style';

// The `Grid` container component is overridden by the `ReactSortable` component
type GridPropsOverride<T> = Omit<MuiGridProps, keyof ReactSortableProps<T>> & ReactSortableProps<T>;
type GridProps<T> = Omit<GridPropsOverride<T>, OmitPropKeys>;

// The `Grid` item component is overridden by the `Motion` component
type GridItemPropsOverride = Omit<MuiGridProps, keyof MotionProps> & MotionProps;
type GridItemProps = Omit<GridItemPropsOverride, OmitPropKeys>;

interface Props<T> extends GridProps<T> {
  children?: (item: T, index: number) => React.ReactNode;
  GridItemProps?: GridItemProps;
}

const SortableGrid = <T extends ItemInterface>({
  list,
  children,
  GridItemProps,
  ...rest
}: Props<T>) => (
  <Grid container component={ReactSortable<T>} list={list} {...rest}>
    {list.map((item, index) => (
      <Grid key={item.id} item component={motion.div} custom={index} {...GridItemProps}>
        {children?.(item, index)}
      </Grid>
    ))}
  </Grid>
);

export default SortableGrid;
