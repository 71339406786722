import { useCallback } from 'react';

export type FilterDeepParameters<T> = Parameters<typeof filterDeep<T>>;
export type FilterDeepResult<T> = { node: T; parents: T[]; parent?: T } | undefined;

export interface UseFilterDeepProps<T> {
  nodes: FilterDeepParameters<T>[0];
  getChildren: FilterDeepParameters<T>[1];
}

export function useFilterDeep<T>({
  nodes,
  getChildren,
}: UseFilterDeepProps<T>): (predicate: FilterDeepParameters<T>[2]) => FilterDeepResult<T>[] {
  return useCallback(
    (predicate) => filterDeep(nodes, getChildren, predicate),
    [nodes, getChildren],
  );
}

export function filterDeep<T>(
  nodes: T[],
  getChildren: (node: T) => T[] | undefined,
  predicate: (node: T) => boolean,
): FilterDeepResult<T>[] {
  const results: FilterDeepResult<T>[] = [];

  function traverse(node: T, parents: T[]) {
    if (predicate(node)) {
      results.push({ node, parents, parent: parents[parents.length - 1] });
    }

    const children = getChildren(node);
    if (children) {
      const newParents = [...parents, node];
      children.forEach((child) => traverse(child, newParents));
    }
  }

  nodes.forEach((node) => traverse(node, []));

  return results;
}
