import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

export function useCaptureAuthError() {
  const { error } = useAuth();

  useEffect(() => {
    if (error) {
      console.error(`[Auth error]:`, error);

      const e = new Error();
      e.name = '[Auth error]:';
      e.message = error.message;

      Sentry.captureException(e);
    }
  }, [error]);
}
