import * as Sentry from '@sentry/react';

import { getCurrentUser } from './authService';

if (process.env.NODE_ENV !== 'test' && process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: `${process.env.REACT_APP_TENANT}-${process.env.REACT_APP_ENV}`,
    integrations: [new Sentry.BrowserTracing({ traceFetch: false })],
    tracesSampleRate: 0.25,
  });

  const user = getCurrentUser();
  if (user) {
    Sentry.setUser({ id: user.profile?.sub });
  }
}
