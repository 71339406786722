import { TableColumnOptionsResult, TableColumnResult } from 'graphql/__generated__/types';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import unionBy from 'lodash-es/unionBy';
import { useCallback, useEffect, useState } from 'react';

interface SelectGroupState {
  selectGroupId: TableColumnOptionsResult['selectGroupId'];
  selectDisplayGroupId: TableColumnOptionsResult['selectDisplayGroupId'];
  dataKey: TableColumnResult['dataKey'];
}

export function useColumnSelect(columns?: TableColumnResult[] | null) {
  const [selectedColumns, setSelectedColumns] = useState<SelectGroupState[]>([]);

  /**
   * Column is selectable if there are multiple columns with the same `selectGroupId`
   */
  const isColumnSelectable = useCallback<(column: TableColumnResult) => boolean>(
    ({ options }) => {
      if (options?.selectGroupId) {
        const { selectGroupId } = options;
        return filter(columns, { options: { selectGroupId } })?.length > 1;
      }

      if (options?.selectDisplayGroupId) {
        const { selectDisplayGroupId } = options;
        return filter(columns, { options: { selectDisplayGroupId } })?.length > 1;
      }

      return false;
    },
    [columns],
  );

  /**
   * Column is selected if it has a selected state or if it is selected by default
   */
  const isColumnSelected = useCallback<(column: TableColumnResult) => boolean>(
    ({ dataKey, options }) => {
      if (options?.selectGroupId) {
        const { selectGroupId } = options;
        const selectState = find(selectedColumns, { selectGroupId });
        return selectState ? selectState.dataKey === dataKey : !!options.selectDefault;
      }

      if (options?.selectDisplayGroupId) {
        const { selectDisplayGroupId } = options;
        // Find the column that controls this display group
        const selectGroupColumn = find(
          columns,
          ({ options }) =>
            !!options?.selectGroupId && options?.selectDisplayGroupId === selectDisplayGroupId,
        );

        if (selectGroupColumn?.options?.selectGroupId) {
          const { selectGroupId } = selectGroupColumn.options;
          const selectColumnState = find(selectedColumns, { selectGroupId });
          return selectColumnState
            ? !!find(selectedColumns, { selectDisplayGroupId })
            : !!find(columns, {
                options: { selectDefault: true, selectGroupId, selectDisplayGroupId },
              });
        }

        return false;
      }

      return false;
    },
    [columns, selectedColumns],
  );

  /**
   * A helper function that determines whether the column is visible
   */
  const isColumnVisible = useCallback<(column: TableColumnResult) => boolean>(
    (column) => !isColumnSelectable(column) || isColumnSelected(column),
    [isColumnSelectable, isColumnSelected],
  );

  const selectColumn = useCallback<(column: TableColumnResult) => void>(
    ({ dataKey, options }) => {
      if (options?.selectGroupId) {
        const { selectGroupId, selectDisplayGroupId } = options;
        setSelectedColumns((prevState) =>
          unionBy([{ selectGroupId, selectDisplayGroupId, dataKey }], prevState, 'selectGroupId'),
        );
      }
    },
    [columns],
  );

  useEffect(() => {
    setSelectedColumns([]);
  }, [columns]);

  return {
    selectedColumns,
    setSelectedColumns,
    isColumnSelectable,
    isColumnSelected,
    isColumnVisible,
    selectColumn,
  } as const;
}
