import { Action } from 'redux';
import { put, race, take } from 'redux-saga/effects';

import { onModalCancel, onModalOk, showModal } from './slices';
import { Modal, ModalType } from './types';

// Workers
export function* confirmModal({ key, ...rest }: Partial<Modal> & Pick<Modal, 'key'>) {
  yield put(showModal({ ...rest, key, type: ModalType.CONFIRM }));

  const effects = {
    ok: take((action: Action) => onModalOk.match(action) && action.payload === key),
    cancel: take((action: Action) => onModalCancel.match(action) && action.payload === key),
  };
  const { ok } = (yield race(effects)) as typeof effects;

  // Return TRUE whether the modal was confirmed
  return !!ok;
}

export default [];
