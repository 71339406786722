import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const AiCircle: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="28" height="28" viewBox="0 0 28 28" {...props}>
    <path d="M14 27.9983C6.28037 27.9983 0 21.7187 0 14.0001C0 12.9847 0.82243 12.1623 1.83801 12.1623C2.85358 12.1623 3.67601 12.9847 3.67601 14.0001C3.67601 19.6878 8.3053 24.3227 14 24.3227C19.6885 24.3227 24.324 19.6941 24.324 14.0001C24.324 8.31238 19.6947 3.67748 14 3.67748C12.9844 3.67748 12.162 2.85516 12.162 1.83972C12.162 0.824275 12.9844 0.00195312 14 0.00195312C21.7196 0.00195312 28 6.2815 28 14.0001C28 21.7187 21.7196 27.9983 14 27.9983Z" />
    <path d="M11.3953 16.822C11.8626 16.822 12.4047 16.7348 12.7598 16.6538V15.0403C12.3735 14.9843 11.819 14.9282 11.3767 14.9282C10.3112 14.9282 9.88133 15.2833 9.88133 15.8689C9.88756 16.5355 10.5293 16.822 11.3953 16.822ZM11.4514 10.5425C13.2147 10.5425 14.4109 11.4271 14.4109 13.4331V17.5883C14.4109 17.7004 14.3548 17.7876 14.2552 17.8312C13.5137 18.0617 12.4483 18.2611 11.3953 18.2611C9.74425 18.2611 8.21777 17.663 8.21777 15.8813C8.21777 14.3301 9.51372 13.6013 11.3393 13.6013C11.8066 13.6013 12.3362 13.6573 12.7661 13.7134V13.4455C12.7661 12.4363 12.3985 12.0065 11.3393 12.0065C10.629 12.0065 9.97478 12.1934 9.51373 12.4612C9.41404 12.5298 9.30189 12.5173 9.22712 12.4052L8.66014 11.5392C8.59161 11.4271 8.59161 11.3523 8.66014 11.2963C9.3455 10.8228 10.2988 10.5425 11.4514 10.5425Z" />
    <path d="M16.7162 9.71413C16.1866 9.71413 15.7754 9.30297 15.7754 8.77344C15.7754 8.23146 16.1866 7.84521 16.7162 7.84521C17.2583 7.84521 17.6446 8.23146 17.6446 8.77344C17.6446 9.30297 17.2583 9.71413 16.7162 9.71413Z" />
    <path d="M16.0927 18.0869C15.9619 18.0869 15.8809 18.0184 15.8809 17.8751V10.9103C15.8809 10.7795 15.9681 10.7109 16.0927 10.7109H17.3326C17.4634 10.7109 17.532 10.7795 17.532 10.9103V17.8751C17.532 18.0184 17.4634 18.0869 17.3326 18.0869H16.0927Z" />
  </SvgIcon>
);
