import { Components, Theme } from '@mui/material';

export const MuiToolbar: Components<Theme>['MuiToolbar'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      minHeight: 48,
      [theme.breakpoints.up('sm')]: {
        minHeight: 48,
      },
    }),
  },
  defaultProps: {
    disableGutters: true,
  },
};
