import { GenericEventPayload, useAnalytics } from 'contexts';
import { useCurrentModule, useIsAilyUser } from 'hooks';
import { useCallback, useMemo } from 'react';
import { getCurrentUser } from 'services/authService';

import { useUserEmailDomain } from './useUserEmailDomain';

export function useAnalyticsTrackEvent() {
  const currentModule = useCurrentModule();
  const { track } = useAnalytics();
  const emailDomain = useUserEmailDomain();
  const isAilyUser = useIsAilyUser();

  const trackEvent = useCallback(
    (eventName: string, eventProps: { [key: string]: string }) => {
      if (currentModule) {
        const user = getCurrentUser();

        track(eventName, {
          tenant: process.env.REACT_APP_TENANT,
          env: process.env.REACT_APP_ENV,
          user_id: user?.profile?.sub,
          email_domain: emailDomain,
          is_aily_user: isAilyUser,
          category: currentModule.name,
          module: currentModule.name,
          ...eventProps,
        } as GenericEventPayload);
      }
    },
    [currentModule],
  );

  const analyticsTrackEvent = useMemo(() => ({ trackEvent }), [trackEvent]);

  return analyticsTrackEvent;
}
