import { Remove } from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardProps,
  IconButton,
  IconButtonProps,
  styled,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { colors } from 'theme/colors';

const StyledCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  overflow: 'visible',
  width: 297,
  height: 97,
  background: `linear-gradient(180deg, ${colors.gradients.aqua[0]} 0%, ${colors.gradients.aqua[1]} 180%)`,
  borderRadius: 5,
  userSelect: 'none',
  '.sortable-ghost &': {
    background: colors.neutrals.grey2,
    boxShadow: theme.shadows[0],
  },
  '.sortable-drag &': {
    boxShadow: theme.shadows[5],
  },
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  padding: theme.spacing(2, 1.5),
  '&:last-child': {
    paddingBottom: theme.spacing(2),
  },
  '.sortable-ghost &': {
    display: 'none',
  },
}));

const RemoveButton = styled((props: IconButtonProps) => (
  <IconButton {...props}>
    <Remove />
  </IconButton>
))(({ theme }) => ({
  position: 'absolute',
  top: -7,
  left: -8,
  width: 20,
  height: 20,
  backgroundColor: colors.lightPrimary.aqua,
  boxShadow: theme.shadows[1],
  color: colors.neutrals.black,
  '&:hover': {
    backgroundColor: colors.lightPrimary.aqua,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 16,
  },
  '.sortable-ghost &': {
    display: 'none',
  },
}));

const DragButton = styled(({ className, ...rest }: IconButtonProps) => (
  <IconButton {...rest} className={clsx(className, 'DragButton')}>
    <svg width="28" height="28" viewBox="0 0 28 28" fill="currentColor">
      <path d="M12.4688 6.31641C12.4688 6.72252 12.6301 7.112 12.9172 7.39916C13.2044 7.68633 13.5939 7.84766 14 7.84766C14.4061 7.84766 14.7956 7.68633 15.0828 7.39916C15.3699 7.112 15.5312 6.72252 15.5312 6.31641C15.5312 5.91029 15.3699 5.52081 15.0828 5.23365C14.7956 4.94648 14.4061 4.78516 14 4.78516C13.5939 4.78516 13.2044 4.94648 12.9172 5.23365C12.6301 5.52081 12.4688 5.91029 12.4688 6.31641ZM12.4688 13.9727C12.4688 14.3788 12.6301 14.7682 12.9172 15.0554C13.2044 15.3426 13.5939 15.5039 14 15.5039C14.4061 15.5039 14.7956 15.3426 15.0828 15.0554C15.3699 14.7682 15.5312 14.3788 15.5312 13.9727C15.5312 13.5665 15.3699 13.1771 15.0828 12.8899C14.7956 12.6027 14.4061 12.4414 14 12.4414C13.5939 12.4414 13.2044 12.6027 12.9172 12.8899C12.6301 13.1771 12.4688 13.5665 12.4688 13.9727V13.9727ZM12.4688 21.6289C12.4688 22.035 12.6301 22.4245 12.9172 22.7117C13.2044 22.9988 13.5939 23.1602 14 23.1602C14.4061 23.1602 14.7956 22.9988 15.0828 22.7117C15.3699 22.4245 15.5312 22.035 15.5312 21.6289C15.5312 21.2228 15.3699 20.8333 15.0828 20.5461C14.7956 20.259 14.4061 20.0977 14 20.0977C13.5939 20.0977 13.2044 20.259 12.9172 20.5461C12.6301 20.8333 12.4688 21.2228 12.4688 21.6289V21.6289Z" />
    </svg>
  </IconButton>
))({
  position: 'absolute',
  top: 'calc(50% - 14px)',
  right: 0,
  width: 28,
  height: 28,
  padding: 0,
  cursor: 'grab',
  backgroundColor: 'transparent',
  color: colors.neutrals.white,
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '.sortable-ghost &': {
    display: 'none',
  },
});

interface Props extends CardProps {
  priorityName: string;
  categoryName?: string;
  draggable?: boolean;
  removable?: boolean;
  onRemove?: () => void;
  onDrag?: () => void;
}

export const PriorityCard: React.FC<Props> = ({
  priorityName,
  categoryName,
  draggable,
  removable,
  onRemove,
  onDrag,
  ...rest
}) => (
  <StyledCard data-testid="PriorityCard" {...rest}>
    <StyledCardContent>
      <Typography
        variant="body"
        sx={{ marginBottom: 0.5, fontWeight: 900 }}
        data-testid="PriorityName"
      >
        {priorityName}
      </Typography>
      {!!categoryName && (
        <Typography variant="small" sx={{ opacity: 0.75 }} data-testid="CategoryName">
          {categoryName}
        </Typography>
      )}
    </StyledCardContent>
    {!!removable && <RemoveButton data-testid="RemoveButton" onClick={onRemove} />}
    {!!draggable && <DragButton data-testid="DragButton" onClick={onDrag} />}
  </StyledCard>
);
