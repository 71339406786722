import { Components, Theme } from '@mui/material';

export const MuiTab: Components<Theme>['MuiTab'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      minWidth: 'auto',
      marginRight: theme.spacing(4.375),
      padding: 0,
      ...theme.typography.body,
      textTransform: 'none',
      '&:hover, &:focus, &.Mui-selected': {
        color: theme.palette.text.primary,
      },
      '&:hover': {
        opacity: 1,
      },
      [theme.breakpoints.up('sm')]: {
        minWidth: 'auto',
      },
    }),
    textColorInherit: {
      opacity: 1,
    },
  },
};
