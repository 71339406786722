import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const ArrowCircleUp: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="28" height="28" viewBox="0 0 28 28" {...props}>
    <path d="M1.64838e-05 14.0124C0.0123691 21.7284 6.29908 28 14.0185 28C21.738 27.9877 28.0123 21.7037 28 13.9877C27.9876 6.25927 21.7009 -0.0123269 13.9815 1.94212e-05C6.26203 0.0123658 -0.0123343 6.29631 1.64838e-05 14.0124ZM25.6162 13.9877C25.6286 20.3951 20.4164 25.6049 14.0062 25.6173C7.59595 25.6296 2.38378 20.4198 2.37143 14.0124C2.35908 7.60495 7.57125 2.39508 13.9815 2.38273C20.3917 2.38273 25.6039 7.58026 25.6162 13.9877Z" />
    <path d="M6.89178 13.3209L8.60858 15.0246L13.7219 9.9012L18.86 15.0123L20.5645 13.3086L13.7219 6.48145L6.89178 13.3209Z" />
    <path d="M12.5112 8.18506L12.5235 21.5678L14.9443 21.5678L14.932 8.18506L12.5112 8.18506Z" />
  </SvgIcon>
);
