import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React from 'react';

export const AiLogoOutlined: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="29" height="28" viewBox="0 0 29 28" fill="currentColor" {...props}>
    <path d="M12.082 16.62c.434 0 .937-.08 1.267-.155v-1.499a9.863 9.863 0 00-1.285-.104c-.989 0-1.388.33-1.388.874.006.619.601.885 1.406.885zm.052-5.83c1.637 0 2.748.82 2.748 2.684v3.858a.234.234 0 01-.145.226c-.688.214-1.678.4-2.655.4-1.534 0-2.95-.556-2.95-2.21 0-1.441 1.203-2.118 2.898-2.118.434 0 .925.052 1.325.104v-.249c0-.937-.342-1.336-1.325-1.336-.66 0-1.267.173-1.695.422-.093.064-.197.052-.267-.052l-.526-.804c-.064-.104-.064-.174 0-.226.636-.44 1.521-.7 2.592-.7zM17.022 10.02a.862.862 0 01-.873-.874c0-.503.381-.862.873-.862.504 0 .862.359.862.862 0 .492-.358.874-.862.874zM19.655 17.801a.862.862 0 01-.874-.873c0-.504.382-.863.874-.863.503 0 .862.36.862.862 0 .492-.359.874-.862.874zM16.444 17.795c-.121 0-.197-.063-.197-.196V11.13c0-.121.081-.185.197-.185h1.151c.122 0 .186.064.186.185V17.6c0 .133-.064.196-.186.196h-1.151z" />
    <path
      d="M13.5 3a1 1 0 011-1c6.627 0 12 5.373 12 12s-5.373 12-12 12-12-5.373-12-12a1 1 0 112 0c0 5.523 4.477 10 10 10s10-4.477 10-10-4.477-10-10-10a1 1 0 01-1-1z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </SvgIcon>
);
