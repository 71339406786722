import { Add } from '@mui/icons-material';
import { Box, Button, Container, Toolbar, Typography } from '@mui/material';
import PageHeader from 'components/PageHeader';
import UserPriorities from 'components/UserPriorities';
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

interface Props {
  title?: string;
}

const UserPrioritiesPage: React.FC<Props> = ({ title = 'My priorities' }) => {
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    // Navigate to the parent page
    navigate('../');
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {title} | {process.env.REACT_APP_SITE_NAME}
        </title>
      </Helmet>
      <Box>
        <PageHeader sticky title={title} onClose={handleClose} />
        <Container maxWidth="xl">
          <Box sx={{ pb: 5 }}>
            <Toolbar sx={{ mb: 1 }}>
              <Button
                variant="text"
                startIcon={<Add />}
                sx={{
                  color: (theme) => theme.palette.text.primary,
                  '&:hover': { backgroundColor: 'transparent' },
                  '&& .MuiSvgIcon-root': { fontSize: 30 },
                }}
                onClick={() => navigate('add')}
                data-testid="AddButton"
              >
                <Typography variant="subtitle">Add</Typography>
              </Button>
            </Toolbar>
            <UserPriorities />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default UserPrioritiesPage;
