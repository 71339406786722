export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The `Date` scalar represents an ISO-8601 compliant date type. */
  Date: { input: any; output: any };
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: string; output: string };
  JObject: { input: any; output: any };
  /** The name scalar represents a valid GraphQL name as specified in the spec and can be used to refer to fields or types. */
  Name: { input: any; output: any };
  Uuid: { input: string; output: string };
};

export enum Alignment {
  Center = 'CENTER',
  Left = 'LEFT',
  Right = 'RIGHT',
}

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
}

export enum AxisType {
  Category = 'CATEGORY',
  Percent = 'PERCENT',
  Value = 'VALUE',
}

export type Card = {
  cardType: CardType;
  code: Scalars['String']['output'];
  id: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export enum CardType {
  Kpi = 'KPI',
  MicroChart = 'MICRO_CHART',
  Simple = 'SIMPLE',
}

export type CardsComponent = Component & {
  __typename?: 'CardsComponent';
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  viewType: ViewType;
};

export type CardsDataView = IDataView & {
  __typename?: 'CardsDataView';
  /** Annotation */
  annotation?: Maybe<Scalars['String']['output']>;
  availableUserSettings?: Maybe<Array<UserSetting>>;
  /** Cards */
  cards?: Maybe<Array<Maybe<Card>>>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Edit link */
  editLink?: Maybe<Link>;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
  /** Title */
  title?: Maybe<Scalars['String']['output']>;
};

export type ChartAxis =
  | ChartAxisCategoryViewResult
  | ChartAxisPercentViewResult
  | ChartAxisValueViewResult;

export type ChartAxisCategoryViewResult = {
  __typename?: 'ChartAxisCategoryViewResult';
  /** Axis type */
  axisType: AxisType;
  /** Axis categories */
  categories?: Maybe<Array<Scalars['String']['output']>>;
  /** Renderer for chart */
  renderer?: Maybe<Scalars['JObject']['output']>;
};

export type ChartAxisPercentViewResult = {
  __typename?: 'ChartAxisPercentViewResult';
  /** Axis type */
  axisType: AxisType;
  /** Renderer for chart */
  renderer?: Maybe<Scalars['JObject']['output']>;
};

export type ChartAxisValueViewResult = {
  __typename?: 'ChartAxisValueViewResult';
  /** Axis type */
  axisType: AxisType;
  /** Renderer for chart */
  renderer?: Maybe<Scalars['JObject']['output']>;
};

export type ChartComponent = Component & {
  __typename?: 'ChartComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  viewType: ViewType;
};

export type ChartDataViewResult = IDataView & {
  __typename?: 'ChartDataViewResult';
  availableUserSettings?: Maybe<Array<UserSetting>>;
  componentIcon: ComponentIcon;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Id of the row for which is chart displayed */
  drillId?: Maybe<Scalars['Int']['output']>;
  /** Selected filters for chart */
  filters?: Maybe<Array<FilterValue>>;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
  /** Renderer for chart */
  renderer?: Maybe<Scalars['JObject']['output']>;
  /** Chart series */
  series?: Maybe<Array<ChartSeries>>;
  /** Axis x configuration */
  xAxis: ChartAxis;
  /** Axis y configuration */
  yAxis: ChartAxis;
};

export type ChartSeries = {
  __typename?: 'ChartSeries';
  /** Series color */
  color?: Maybe<Color>;
  /** Chart data */
  data?: Maybe<Array<ChartSeriesDataPoint>>;
  /** Series identifier */
  id: Scalars['Int']['output'];
  /** Line style: solid, dotted, dashed */
  lineStyle?: Maybe<LineStyle>;
  /** Identifier of linked series */
  linkedTo?: Maybe<Scalars['Int']['output']>;
  /** Name of the series */
  name: Scalars['String']['output'];
  /** Renderer for chart */
  renderer?: Maybe<Scalars['JObject']['output']>;
  /** Series type: Bar, Line, Area */
  seriesType: SeriesType;
  /** Indicates if series should be shown in legend */
  showInLegend?: Maybe<Scalars['Boolean']['output']>;
  /** Determines if series should be shown in tooltip */
  showInTooltip: Scalars['Boolean']['output'];
  showLastPointMarker: Scalars['Boolean']['output'];
  tooltipRangeFormat: TooltipRangeFormat;
  tooltipTitleHigh?: Maybe<Scalars['String']['output']>;
  tooltipTitleLow?: Maybe<Scalars['String']['output']>;
};

export type ChartSeriesDataPoint = {
  __typename?: 'ChartSeriesDataPoint';
  /** X value */
  x?: Maybe<Scalars['Float']['output']>;
  /** Y value */
  y?: Maybe<ChartSeriesDataPointValue>;
};

export type ChartSeriesDataPointValue = {
  __typename?: 'ChartSeriesDataPointValue';
  /** high */
  high?: Maybe<FormattedValue>;
  /** low */
  low?: Maybe<FormattedValue>;
  /** value */
  value?: Maybe<FormattedValue>;
  /** variance */
  variance?: Maybe<VarianceResult>;
};

export enum Color {
  AilyBlue = 'AILY_BLUE',
  Aqua = 'AQUA',
  Black = 'BLACK',
  Blue = 'BLUE',
  DarkBlue = 'DARK_BLUE',
  Default = 'DEFAULT',
  Failure = 'FAILURE',
  Green = 'GREEN',
  Grey_1 = 'GREY_1',
  Grey_2 = 'GREY_2',
  Grey_3 = 'GREY_3',
  Orange = 'ORANGE',
  Pink = 'PINK',
  Success = 'SUCCESS',
  Warning = 'WARNING',
  White = 'WHITE',
}

export type ColorString = {
  __typename?: 'ColorString';
  color?: Maybe<Scalars['String']['output']>;
};

export type ColorUnion = ColorString | ThemeColor;

export type ColumnLayoutComponent = Component & {
  __typename?: 'ColumnLayoutComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  /** First column components */
  firstColumn?: Maybe<Array<Component>>;
  /** Header row components */
  headerRow?: Maybe<Array<Component>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  /** Second column components */
  secondColumn?: Maybe<Array<Component>>;
  viewType: ViewType;
};

export type Component = {
  /** Component code */
  code: Scalars['String']['output'];
  /** Component type */
  componentType: ComponentType;
  /** Chart icon for chart */
  icon: ComponentIcon;
  /** Component identifier */
  id: Scalars['Uuid']['output'];
  /** View type for which component should be displayed (Landscape, Portrait) */
  viewType: ViewType;
};

export enum ComponentIcon {
  BarChart = 'BAR_CHART',
  LineChart = 'LINE_CHART',
  None = 'NONE',
  WaterfallChart = 'WATERFALL_CHART',
}

export enum ComponentType {
  Cards = 'CARDS',
  ChartDataView = 'CHART_DATA_VIEW',
  Content = 'CONTENT',
  Dials = 'DIALS',
  Filter = 'FILTER',
  Filters = 'FILTERS',
  Grid = 'GRID',
  HeaderRowView = 'HEADER_ROW_VIEW',
  Kpi = 'KPI',
  List = 'LIST',
  Markdown = 'MARKDOWN',
  Microchart = 'MICROCHART',
  Module = 'MODULE',
  Plailist = 'PLAILIST',
  Recommender = 'RECOMMENDER',
  Story = 'STORY',
  StoryList = 'STORY_LIST',
  SummaryInfoDataView = 'SUMMARY_INFO_DATA_VIEW',
  TableDataView = 'TABLE_DATA_VIEW',
  TabNavigation = 'TAB_NAVIGATION',
  Timeline = 'TIMELINE',
  TwoColumnLayout = 'TWO_COLUMN_LAYOUT',
  Unknown = 'UNKNOWN',
  UserAnalytics = 'USER_ANALYTICS',
  UserPriorities = 'USER_PRIORITIES',
}

export type DataViewInput = {
  componentCode?: InputMaybe<Scalars['String']['input']>;
  dimensionId?: InputMaybe<Scalars['Int']['input']>;
  drillId?: InputMaybe<Scalars['Int']['input']>;
  drillIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  filters?: InputMaybe<Array<FilterInput>>;
  id: Scalars['Uuid']['input'];
  moduleId: Scalars['Uuid']['input'];
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  rowsPerPage?: InputMaybe<Scalars['Int']['input']>;
  sortColumn?: InputMaybe<Scalars['String']['input']>;
  sortType?: InputMaybe<Scalars['String']['input']>;
  userSettings?: InputMaybe<Array<UserSettingInput>>;
};

export enum DataViewType {
  Cards = 'CARDS',
  Chart = 'CHART',
  Dials = 'DIALS',
  Grid = 'GRID',
  HeaderRow = 'HEADER_ROW',
  Kpi = 'KPI',
  List = 'LIST',
  Microchart = 'MICROCHART',
  Recommender = 'RECOMMENDER',
  SummaryInfo = 'SUMMARY_INFO',
  Table = 'TABLE',
}

export type DialItem = {
  __typename?: 'DialItem';
  actualText?: Maybe<TextResult>;
  label: Scalars['String']['output'];
  link?: Maybe<TextLinkResult>;
  progress: Scalars['Float']['output'];
  progressText?: Maybe<TextResult>;
  sentiment: Color;
  target?: Maybe<Scalars['Float']['output']>;
  targetText?: Maybe<TextResult>;
  varianceText?: Maybe<TextResult>;
};

export type DialsComponent = Component & {
  __typename?: 'DialsComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  viewType: ViewType;
};

export type DialsDataView = IDataView & {
  __typename?: 'DialsDataView';
  /** A information to be displayed along with the annotation */
  annotation?: Maybe<Scalars['String']['output']>;
  /** A date information to be displayed along with the annotation */
  annotationDate?: Maybe<Scalars['String']['output']>;
  availableUserSettings?: Maybe<Array<UserSetting>>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** Dial items */
  items?: Maybe<Array<DialItem>>;
  /** Formatted date of last data update */
  lastUpdateDate?: Maybe<Scalars['String']['output']>;
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
};

export enum DisplayMode {
  Default = 'DEFAULT',
  Inline = 'INLINE',
  Slider = 'SLIDER',
}

export enum DisplayVariant {
  Alternative = 'ALTERNATIVE',
  Default = 'DEFAULT',
}

export type DrillDownResult = {
  __typename?: 'DrillDownResult';
  /** Id of row id which is used to drill down action */
  drillDownId?: Maybe<Scalars['Int']['output']>;
  /** Ids of rows that are creating Drill down path */
  drillDownIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Id of parent row id which is used to drill up action */
  drillUpId?: Maybe<Scalars['Int']['output']>;
  drillUpIds?: Maybe<Array<Scalars['Int']['output']>>;
  /** Indicator if cell is hierarchical */
  isHierarchical?: Maybe<Scalars['Boolean']['output']>;
  /** SubLabels content */
  subLabels?: Maybe<Array<Scalars['String']['output']>>;
  /** Text content */
  value?: Maybe<Scalars['String']['output']>;
};

export type Filter = {
  /** Type of the filter: Select, MultipleSelect */
  filterType: FilterType;
  /** Filter Identifier. */
  id: Scalars['Uuid']['output'];
  /** Filter title. */
  label?: Maybe<Scalars['String']['output']>;
  /** ParentId to support hierarchy */
  parentId?: Maybe<Scalars['Uuid']['output']>;
};

export type FilterCodeValue = {
  __typename?: 'FilterCodeValue';
  /** Child filter code */
  filterCode: Scalars['String']['output'];
  /** Child filter value */
  value: Scalars['Int']['output'];
};

export type FilterComponent = Component & {
  __typename?: 'FilterComponent';
  /** Component alignment */
  alignment?: Maybe<Alignment>;
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  defaultValue?: Maybe<Scalars['Int']['output']>;
  /** Display mode for the selected field (inline or default) */
  displayMode?: Maybe<DisplayMode>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  /** Is hidden */
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  /** Component position */
  position?: Maybe<Position>;
  viewType: ViewType;
};

export type FilterInput = {
  selectFilter?: InputMaybe<SelectFilterInput>;
  selectGroupFilter?: InputMaybe<SelectGroupFilterInput>;
};

export type FilterOptionResult = {
  __typename?: 'FilterOptionResult';
  /** Additional filter option labels */
  additionalLabels?: Maybe<Array<Scalars['String']['output']>>;
  /** Option unique ID. */
  id: Scalars['ID']['output'];
  /** Flag to indicate whether option is default for filter */
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  /** Option label. */
  label: Scalars['String']['output'];
  /** Filter Option Sentiment */
  sentiment?: Maybe<Sentiment>;
  /** Option value */
  value?: Maybe<Scalars['Int']['output']>;
};

export enum FilterType {
  Multiselect = 'MULTISELECT',
  Range = 'RANGE',
  Select = 'SELECT',
  SelectGroup = 'SELECT_GROUP',
  Switch = 'SWITCH',
  Treeselect = 'TREESELECT',
}

export type FilterValue = SelectFilterValue | SelectGroupFilterValue;

export type FiltersComponent = Component & {
  __typename?: 'FiltersComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  viewType: ViewType;
};

export type FormattedValue = {
  __typename?: 'FormattedValue';
  /** Formatted value */
  formatted?: Maybe<Scalars['String']['output']>;
  /** Raw value */
  raw?: Maybe<Scalars['Float']['output']>;
  title: Scalars['String']['output'];
};

export type GeolocationCoordinatesResult = {
  __typename?: 'GeolocationCoordinatesResult';
  /** Latitude coordinate */
  latitude?: Maybe<Scalars['Float']['output']>;
  /** Longitude coordinate */
  longitude?: Maybe<Scalars['Float']['output']>;
};

export type GeolocationPositionResult = {
  __typename?: 'GeolocationPositionResult';
  /** Geolocation position coordinates */
  coordinates?: Maybe<GeolocationCoordinatesResult>;
};

export type GetUserSettingsInput = {
  moduleId: Scalars['Uuid']['input'];
};

export type GridComponent = Component & {
  __typename?: 'GridComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  viewType: ViewType;
};

export type GridDataView = IDataView & {
  __typename?: 'GridDataView';
  /** Annotations */
  annotation?: Maybe<Scalars['String']['output']>;
  availableUserSettings?: Maybe<Array<UserSetting>>;
  /** Grid columns */
  columns?: Maybe<Array<TableColumnResult>>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Edit link */
  editLink?: Maybe<Link>;
  /** Dependent filters */
  filters?: Maybe<Array<FilterValue>>;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** Formatted date of last data update */
  lastUpdateDate?: Maybe<Scalars['String']['output']>;
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
  /** Grid options */
  options?: Maybe<TableViewOptionsResult>;
  /** Grid rows */
  rows?: Maybe<Array<TableRowResult>>;
  /** Title */
  title?: Maybe<Scalars['String']['output']>;
};

export type HeaderRow = IDataView & {
  __typename?: 'HeaderRow';
  availableUserSettings?: Maybe<Array<UserSetting>>;
  /** Header columns */
  columns?: Maybe<Array<TableColumnResult>>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Selected filters for chart header */
  filters?: Maybe<Array<FilterValue>>;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
  options: TableViewOptionsResult;
  /** Chart header row */
  rows?: Maybe<Array<Maybe<TableRowResult>>>;
};

export type HeaderRowComponent = Component & {
  __typename?: 'HeaderRowComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  /** Variant of header row */
  variant: DisplayVariant;
  viewType: ViewType;
};

export type IDataView = {
  availableUserSettings?: Maybe<Array<UserSetting>>;
  dataViewType: DataViewType;
  id: Scalars['Uuid']['output'];
  metadata?: Maybe<Array<MetaData>>;
};

export enum Icon {
  Calendar = 'CALENDAR',
  Euro = 'EURO',
  People = 'PEOPLE',
  Rhombus = 'RHOMBUS',
}

export type KpiCardResult = Card & {
  __typename?: 'KpiCardResult';
  /** Card Type */
  cardType: CardType;
  code: Scalars['String']['output'];
  componentIcon: ComponentIcon;
  /** Data view identifier */
  id: Scalars['String']['output'];
  /** Kpi statuses */
  items?: Maybe<Array<KpiStatus>>;
  /** Link result */
  linkResult?: Maybe<Link>;
  title: Scalars['String']['output'];
};

export type KpiComponent = Component & {
  __typename?: 'KpiComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  viewType: ViewType;
};

export type KpiStatus = {
  __typename?: 'KpiStatus';
  actual: Scalars['String']['output'];
  icon: Icon;
  sentiment: Sentiment;
  target?: Maybe<Scalars['String']['output']>;
  /** @deprecated Field is replaced by Actual field. Please use it instead */
  text?: Maybe<Scalars['String']['output']>;
};

export type KpiStatusOverview = IDataView & {
  __typename?: 'KpiStatusOverview';
  availableUserSettings?: Maybe<Array<UserSetting>>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** Kpi statuses */
  items?: Maybe<Array<KpiStatus>>;
  /** Link */
  link?: Maybe<Link>;
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
  /** Kpi's tooltip */
  tooltip: TableContent;
};

export enum LineStyle {
  Dashed = 'DASHED',
  Dasheddotted = 'DASHEDDOTTED',
  Dotted = 'DOTTED',
  None = 'NONE',
  Solid = 'SOLID',
}

export type Link = {
  __typename?: 'Link';
  absolutePath?: Maybe<Scalars['String']['output']>;
  drillId?: Maybe<Scalars['Int']['output']>;
  drillIds?: Maybe<Array<Scalars['Int']['output']>>;
  filters?: Maybe<Array<FilterValue>>;
  pageId?: Maybe<Scalars['Uuid']['output']>;
  target?: Maybe<LinkTargetType>;
};

export enum LinkDirection {
  Back = 'BACK',
  Forward = 'FORWARD',
  None = 'NONE',
}

export enum LinkTargetType {
  Modal = 'MODAL',
  Page = 'PAGE',
}

export type ListComponent = Component & {
  __typename?: 'ListComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  viewType: ViewType;
};

export type ListDataView = IDataView & {
  __typename?: 'ListDataView';
  /** Annotations */
  annotation?: Maybe<Scalars['String']['output']>;
  availableUserSettings?: Maybe<Array<UserSetting>>;
  /** List item columns */
  columns?: Maybe<Array<TableColumnResult>>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Dependent filters */
  filters?: Maybe<Array<FilterValue>>;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** Formatted date of last data update */
  lastUpdateDate?: Maybe<Scalars['String']['output']>;
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
  /** List options/extensions */
  options?: Maybe<TableViewOptionsResult>;
  /** List rows */
  rows?: Maybe<Array<TableRowResult>>;
  /** Title */
  title?: Maybe<Scalars['String']['output']>;
};

export type LocationMarkerResult = {
  __typename?: 'LocationMarkerResult';
  /** Geolocation position */
  position?: Maybe<GeolocationPositionResult>;
  /** SubLabels content */
  subLabels?: Maybe<Array<Scalars['String']['output']>>;
  /** Coordinate text description */
  text?: Maybe<Scalars['String']['output']>;
};

export type MarkStoryAsReadInput = {
  storyId: Scalars['Uuid']['input'];
};

export type MarkdownComponent = Component & {
  __typename?: 'MarkdownComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  /** Content of the markdown */
  content: Scalars['String']['output'];
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  viewType: ViewType;
};

export enum MenuIcon {
  AilyLogoOutlined = 'AILY_LOGO_OUTLINED',
  HomeOutlined = 'HOME_OUTLINED',
  LineChartOutlined = 'LINE_CHART_OUTLINED',
  None = 'NONE',
  PlayCircleOutlined = 'PLAY_CIRCLE_OUTLINED',
  SettingsOutlined = 'SETTINGS_OUTLINED',
  StarOutlined = 'STAR_OUTLINED',
}

export type MetaData = {
  __typename?: 'MetaData';
  /** Meta info key */
  key?: Maybe<Scalars['String']['output']>;
  /** Meta info label */
  label?: Maybe<Scalars['String']['output']>;
  /** Meta info value */
  value?: Maybe<Scalars['String']['output']>;
};

export type Metric = {
  __typename?: 'Metric';
  /** Metric name */
  id: Scalars['Int']['output'];
  /** Metric name */
  name: Scalars['String']['output'];
  /** Sentiment for the metric */
  sentiment?: Maybe<Sentiment>;
  timestamp?: Maybe<Scalars['DateTime']['output']>;
  /** Metric unit */
  unit: Scalars['String']['output'];
  /** Metric value */
  value: Scalars['String']['output'];
  /** Available variances */
  variances?: Maybe<Array<Maybe<VarianceResult>>>;
};

export type MetricList = {
  __typename?: 'MetricList';
  /** MetricList metrics */
  metrics: Array<Maybe<Metric>>;
  /** MetricList name */
  name: Scalars['String']['output'];
};

export type MicroChartCardResult = Card & {
  __typename?: 'MicroChartCardResult';
  /** Actual text */
  actual?: Maybe<TextResult>;
  /** Card Type */
  cardType: CardType;
  code: Scalars['String']['output'];
  /** Data view identifier */
  id: Scalars['String']['output'];
  linkResult: Link;
  /** Microchart */
  microChart?: Maybe<MicroChartResult>;
  title: Scalars['String']['output'];
  /** Variance result */
  variance?: Maybe<VarianceResult>;
};

export type MicroChartComponent = Component & {
  __typename?: 'MicroChartComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  viewType: ViewType;
};

export type MicroChartResult = IDataView & {
  __typename?: 'MicroChartResult';
  availableUserSettings?: Maybe<Array<UserSetting>>;
  dataDimensionId?: Maybe<Scalars['Int']['output']>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** Link configuration */
  linkResult?: Maybe<Link>;
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
  /** Sentiment for chart */
  sentiment?: Maybe<Sentiment>;
  /** Series data */
  seriesData?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  subLabels: Array<Scalars['String']['output']>;
  /** Trend line data */
  trendlineData?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
};

export type Module = {
  __typename?: 'Module';
  /** Module unique ID. */
  id: Scalars['Uuid']['output'];
  /** Module code */
  moduleCode: ModuleCode;
  /** Module name. */
  name: Scalars['String']['output'];
  /** Hierarchical structure of children pages */
  pages?: Maybe<Array<Page>>;
  /** Module path. */
  path: Scalars['String']['output'];
};

export enum ModuleCode {
  Clinops = 'CLINOPS',
  Financial = 'FINANCIAL',
  Rnd = 'RND',
}

export type ModuleDefaultsResult = {
  __typename?: 'ModuleDefaultsResult';
  availableNumberScalingSettings: Array<NumberScaling>;
  decimalPrecision: Scalars['Int']['output'];
  exchangeRate: Scalars['Int']['output'];
  moduleCode: ModuleCode;
  numberScaling: NumberScaling;
  priorities: Array<TenantDefaultsPriorityResult>;
};

export type Mutation = {
  __typename?: 'Mutation';
  followPriority: PriorityResult;
  markStoryAsRead: Scalars['Boolean']['output'];
  reorderPriorities?: Maybe<Array<PriorityResult>>;
  reorderPriority: PriorityResult;
  saveUserSettings?: Maybe<Array<UserSetting>>;
  unfollowPriority: PriorityResult;
};

export type MutationFollowPriorityArgs = {
  input: PriorityInput;
};

export type MutationMarkStoryAsReadArgs = {
  input: MarkStoryAsReadInput;
};

export type MutationReorderPrioritiesArgs = {
  input?: InputMaybe<Array<PriorityInput>>;
};

export type MutationReorderPriorityArgs = {
  input: PriorityInput;
};

export type MutationSaveUserSettingsArgs = {
  input: SaveUserSettingsInput;
};

export type MutationUnfollowPriorityArgs = {
  input: PriorityInput;
};

export type NavigationTabsComponent = Component & {
  __typename?: 'NavigationTabsComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  defaultSelectionIndex?: Maybe<Scalars['Int']['output']>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  tabs?: Maybe<Array<Tab>>;
  viewType: ViewType;
};

export enum NumberScaling {
  Billions = 'BILLIONS',
  Millions = 'MILLIONS',
  Ones = 'ONES',
  Thousands = 'THOUSANDS',
}

export type Page = {
  __typename?: 'Page';
  /** Page absolute path */
  absolutePath: Scalars['String']['output'];
  /** Hierarchical structure of children pages */
  children?: Maybe<Array<Page>>;
  focusAreaCode: Scalars['String']['output'];
  /** Page unique ID. */
  id: Scalars['Uuid']['output'];
  /** Should this page be displayed in menu */
  isMenuHidden?: Maybe<Scalars['Boolean']['output']>;
  /** Icon used in navigation menus */
  menuIcon?: Maybe<MenuIcon>;
  /** Title used in navigation menus */
  menuTitle?: Maybe<Scalars['String']['output']>;
  /** Type of the page: HOME_PAGE, PRIORITY_PAGE, SETTINGS_PAGE, CONTENT_PAGE, DEFAULT_PAGE */
  pageType: PageTypeEnum;
  /** Page relative to parent path */
  path: Scalars['String']['output'];
  /** Page permissions */
  permissions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Page title. */
  title: Scalars['String']['output'];
};

export type PageContentResult = {
  __typename?: 'PageContentResult';
  body: Array<Component>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export enum PageTypeEnum {
  ContentPage = 'CONTENT_PAGE',
  DefaultPage = 'DEFAULT_PAGE',
  HomePage = 'HOME_PAGE',
  LandingPage = 'LANDING_PAGE',
  PriorityPage = 'PRIORITY_PAGE',
  SettingsPage = 'SETTINGS_PAGE',
}

/** A connection to a list of items. */
export type PagesConnection = {
  __typename?: 'PagesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<PagesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Page>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PagesEdge = {
  __typename?: 'PagesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Page;
};

export type Plailist = {
  __typename?: 'Plailist';
  /** Plailist tile color end */
  colorEnd?: Maybe<ColorUnion>;
  /** Plailist tile color start */
  colorStart?: Maybe<ColorUnion>;
  permissions: Array<Scalars['String']['output']>;
  /** Plailist Id */
  plailistId: Scalars['Uuid']['output'];
  /** Stories which belong to this plailist */
  stories?: Maybe<Array<Maybe<Story>>>;
  /** Plailist summary (text) */
  summary?: Maybe<Scalars['String']['output']>;
  /** Plailist title */
  title?: Maybe<Scalars['String']['output']>;
};

export type PlailistCategory = {
  __typename?: 'PlailistCategory';
  /** Plailist category Id */
  categoryId: Scalars['Uuid']['output'];
  /** Plailist category name */
  name: Scalars['String']['output'];
  /** Plailists which belong to this category */
  plailists?: Maybe<Array<Maybe<Plailist>>>;
};

export type PlailistComponent = Component & {
  __typename?: 'PlailistComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  viewType: ViewType;
};

export type PlailistsQueryInput = {
  moduleId: Scalars['Uuid']['input'];
};

export enum Position {
  Bottom = 'BOTTOM',
  Top = 'TOP',
}

export type PrioritiesInput = {
  moduleId: Scalars['Uuid']['input'];
};

export type PriorityCategoryResult = {
  __typename?: 'PriorityCategoryResult';
  categoryName: Scalars['String']['output'];
  priorities: Array<PriorityResult>;
};

export type PriorityInput = {
  moduleId: Scalars['Uuid']['input'];
  priorityId: Scalars['Int']['input'];
  sortOrder: Scalars['Int']['input'];
};

export type PriorityResult = {
  __typename?: 'PriorityResult';
  isFollowed: Scalars['Boolean']['output'];
  priorityId: Scalars['Int']['output'];
  priorityName: Scalars['String']['output'];
  sortOrder: Scalars['Int']['output'];
};

export type ProgressBarResult = {
  __typename?: 'ProgressBarResult';
  /** Base progress bar value */
  baseValue: Scalars['Float']['output'];
  /** Is progress bar inverted */
  inverted: Scalars['Boolean']['output'];
  /** Maximum progress bar value */
  max: Scalars['Float']['output'];
  /** Minimum progress bar value */
  min: Scalars['Float']['output'];
  /** Bar progress value */
  progressValue: Scalars['Float']['output'];
  /** Sentiment for value */
  sentiment?: Maybe<Sentiment>;
  /** ProgressBarVariant for value */
  variant?: Maybe<ProgressBarVariant>;
};

export enum ProgressBarVariant {
  Default = 'DEFAULT',
  Tornado = 'TORNADO',
}

export type Query = {
  __typename?: 'Query';
  component?: Maybe<Component>;
  dataView?: Maybe<IDataView>;
  filterById?: Maybe<Filter>;
  filtersById?: Maybe<Array<Maybe<Filter>>>;
  modules?: Maybe<Array<Maybe<Module>>>;
  pageContent: PageContentResult;
  /** @deprecated Query is obsolete because its used as a fallback for GetModules within mobile application. */
  pages?: Maybe<PagesConnection>;
  plailists?: Maybe<Array<Maybe<PlailistCategory>>>;
  priorities?: Maybe<Array<PriorityCategoryResult>>;
  priorityFilter?: Maybe<Filter>;
  stories?: Maybe<Array<Maybe<Story>>>;
  storyById?: Maybe<Story>;
  userAnalytics?: Maybe<UserAnalytics>;
  userPriorities?: Maybe<Array<PriorityCategoryResult>>;
  userSettings?: Maybe<Array<UserSetting>>;
};

export type QueryComponentArgs = {
  componentId: Scalars['Uuid']['input'];
};

export type QueryDataViewArgs = {
  input?: InputMaybe<DataViewInput>;
};

export type QueryFilterByIdArgs = {
  id: Scalars['Uuid']['input'];
};

export type QueryFiltersByIdArgs = {
  drillId?: InputMaybe<Scalars['Int']['input']>;
  drillIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  ids?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  moduleId?: InputMaybe<Scalars['Uuid']['input']>;
  viewCode?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPageContentArgs = {
  pageId: Scalars['Uuid']['input'];
  priorityId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryPagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryPlailistsArgs = {
  input: PlailistsQueryInput;
};

export type QueryPrioritiesArgs = {
  input: PrioritiesInput;
};

export type QueryPriorityFilterArgs = {
  moduleId: Scalars['Uuid']['input'];
};

export type QueryStoriesArgs = {
  input: StoriesQueryInput;
};

export type QueryStoryByIdArgs = {
  storyId: Scalars['Uuid']['input'];
};

export type QueryUserAnalyticsArgs = {
  input?: InputMaybe<UserAnalyticsInput>;
};

export type QueryUserPrioritiesArgs = {
  input: PrioritiesInput;
};

export type QueryUserSettingsArgs = {
  input: GetUserSettingsInput;
};

export type RecommenderComponent = Component & {
  __typename?: 'RecommenderComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  viewType: ViewType;
};

export type RecommenderDataView = IDataView & {
  __typename?: 'RecommenderDataView';
  availableUserSettings?: Maybe<Array<UserSetting>>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
  /** Stories for recommender */
  stories?: Maybe<Array<Story>>;
};

export enum RowType {
  Aggregation = 'AGGREGATION',
  Category = 'CATEGORY',
  Default = 'DEFAULT',
  Header = 'HEADER',
  Phases = 'PHASES',
  Summary = 'SUMMARY',
}

export type SaveUserSettingsInput = {
  moduleId: Scalars['Uuid']['input'];
  settings: Array<UserSettingInput>;
};

export type SelectFilter = Filter & {
  __typename?: 'SelectFilter';
  /** Additional Labels */
  additionalLabels: Array<Maybe<Scalars['String']['output']>>;
  /** Filter ApiKey */
  filterCode?: Maybe<Scalars['String']['output']>;
  filterType: FilterType;
  id: Scalars['Uuid']['output'];
  label?: Maybe<Scalars['String']['output']>;
  /** Hierarchical structure of filters */
  options?: Maybe<Array<FilterOptionResult>>;
  parentId?: Maybe<Scalars['Uuid']['output']>;
};

export type SelectFilterCodeInput = {
  /** Filter code */
  filterCode: Scalars['String']['input'];
  /** Filter value */
  value: Scalars['Int']['input'];
};

export type SelectFilterInput = {
  /** Filter identifier */
  id: Scalars['Uuid']['input'];
  /** Selected filter option identifier */
  value: Scalars['Int']['input'];
};

export type SelectFilterValue = {
  __typename?: 'SelectFilterValue';
  /** Filter identifier */
  id: Scalars['Uuid']['output'];
  /** Selected filter option identifier */
  value: Scalars['Int']['output'];
};

export type SelectGroupFilter = Filter & {
  __typename?: 'SelectGroupFilter';
  additionalLabels: Array<Maybe<Scalars['String']['output']>>;
  filterCode?: Maybe<Scalars['String']['output']>;
  filterType: FilterType;
  /** Select filters that belong to group */
  filters: Array<SelectFilter>;
  id: Scalars['Uuid']['output'];
  label?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['Uuid']['output']>;
};

export type SelectGroupFilterInput = {
  /** Identifier of the Select Group filter */
  id: Scalars['Uuid']['input'];
  /** Selected values for SelectGroupFilter's child filters */
  values: Array<SelectFilterCodeInput>;
};

export type SelectGroupFilterValue = {
  __typename?: 'SelectGroupFilterValue';
  /** Filter id */
  id: Scalars['Uuid']['output'];
  /** Child filters values */
  values: Array<Maybe<FilterCodeValue>>;
};

export enum Sentiment {
  Critical = 'CRITICAL',
  Negative = 'NEGATIVE',
  Neutral = 'NEUTRAL',
  Positive = 'POSITIVE',
  SlightlyNegative = 'SLIGHTLY_NEGATIVE',
  SlightlyPositive = 'SLIGHTLY_POSITIVE',
  VeryNegative = 'VERY_NEGATIVE',
  VeryPositive = 'VERY_POSITIVE',
}

export enum SeriesType {
  Area = 'AREA',
  AreaRange = 'AREA_RANGE',
  Bar = 'BAR',
  Column = 'COLUMN',
  ColumnRange = 'COLUMN_RANGE',
  Line = 'LINE',
  Waterfall = 'WATERFALL',
}

export type SettingOption = {
  __typename?: 'SettingOption';
  label: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type SimpleCardResult = Card & {
  __typename?: 'SimpleCardResult';
  /** Card Type */
  cardType: CardType;
  code: Scalars['String']['output'];
  /** Data view identifier */
  id: Scalars['String']['output'];
  /** Link result */
  linkResult?: Maybe<Link>;
  /** Text result */
  textResult?: Maybe<TextResult>;
  title: Scalars['String']['output'];
};

export type StoriesQueryInput = {
  moduleId: Scalars['Uuid']['input'];
};

export type Story = {
  __typename?: 'Story';
  /** Caption */
  caption?: Maybe<Scalars['String']['output']>;
  /** Components */
  content?: Maybe<Array<Maybe<Component>>>;
  /** Story's headline */
  headline: Scalars['String']['output'];
  /** Story identifier */
  id: Scalars['Uuid']['output'];
  /** Story is read by user */
  isRead: Scalars['Boolean']['output'];
  /** Link */
  link?: Maybe<Link>;
  /** Publish date */
  publishDate: Scalars['Date']['output'];
  /** Story's recommender headline */
  recommenderHeadline: Scalars['String']['output'];
  /** Story's sentiment */
  sentiment?: Maybe<Sentiment>;
  storyType: StoryDisplayType;
  /** Tag */
  tag?: Maybe<Scalars['String']['output']>;
};

export enum StoryDisplayType {
  AiDriveFocus = 'AI_DRIVE_FOCUS',
  AiInspired = 'AI_INSPIRED',
  AiXplained = 'AI_XPLAINED',
  Default = 'DEFAULT',
  PlailistStory = 'PLAILIST_STORY',
}

export type StoryListComponent = Component & {
  __typename?: 'StoryListComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  viewType: ViewType;
};

export type SummaryInfo = {
  __typename?: 'SummaryInfo';
  items?: Maybe<Array<SummaryInfoItem>>;
  lastUpdateDate?: Maybe<Scalars['String']['output']>;
};

export type SummaryInfoComponent = Component & {
  __typename?: 'SummaryInfoComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  viewType: ViewType;
};

export type SummaryInfoDataView = IDataView & {
  __typename?: 'SummaryInfoDataView';
  availableUserSettings?: Maybe<Array<UserSetting>>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
  /** The timeline axis */
  summaryInfo: SummaryInfo;
};

export type SummaryInfoItem = {
  __typename?: 'SummaryInfoItem';
  content: TableCellContent;
  header: TableCellContent;
};

export type Tab = {
  __typename?: 'Tab';
  name?: Maybe<Scalars['String']['output']>;
  pageId?: Maybe<Scalars['Uuid']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type TableCellContent =
  | DrillDownResult
  | KpiStatus
  | KpiStatusOverview
  | LocationMarkerResult
  | MicroChartResult
  | ProgressBarResult
  | TextLinkResult
  | TextResult
  | Timeline
  | TimelineDataPoint
  | TimelinePhases
  | VarianceResult;

export type TableCellResult = {
  __typename?: 'TableCellResult';
  /** Cell component */
  cellContent?: Maybe<TableCellContent>;
  /** Column data key where cell belongs */
  columnDataKey: Scalars['String']['output'];
};

export type TableColumnOptionsResult = {
  __typename?: 'TableColumnOptionsResult';
  /** Alignment setting for column */
  alignment?: Maybe<Alignment>;
  /** Is default select */
  selectDefault?: Maybe<Scalars['Boolean']['output']>;
  selectDisplayDefault: Scalars['Boolean']['output'];
  /** Select display group id */
  selectDisplayGroupId: Scalars['Int']['output'];
  /** Select group id */
  selectGroupId: Scalars['Int']['output'];
  /** Is default toggle */
  toggleDefault?: Maybe<Scalars['Boolean']['output']>;
  /** Toggle group id */
  toggleGroup: Scalars['Int']['output'];
  /** Width setting for column */
  width?: Maybe<Scalars['Int']['output']>;
};

export type TableColumnResult = {
  __typename?: 'TableColumnResult';
  /** Column identifier */
  columnId: Scalars['Int']['output'];
  /** DataKey which connects column with data */
  dataKey: Scalars['String']['output'];
  /** Column options */
  options?: Maybe<TableColumnOptionsResult>;
  /** Column title */
  title?: Maybe<Scalars['String']['output']>;
  /** Column title display type */
  titleDisplayType: TitleDisplayType;
};

export type TableComponent = Component & {
  __typename?: 'TableComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  viewType: ViewType;
};

export type TableContent = {
  __typename?: 'TableContent';
  columns?: Maybe<Array<TableColumnResult>>;
  rows?: Maybe<Array<TableRowResult>>;
  title: Scalars['String']['output'];
};

export type TableDataView = IDataView & {
  __typename?: 'TableDataView';
  /** Annotations */
  annotation?: Maybe<Scalars['String']['output']>;
  availableUserSettings?: Maybe<Array<UserSetting>>;
  /** Table columns */
  columns?: Maybe<Array<TableColumnResult>>;
  /** Type of the data view */
  dataViewType: DataViewType;
  /** Edit link */
  editLink?: Maybe<Link>;
  /** Dependent filters */
  filters?: Maybe<Array<FilterValue>>;
  /** Data view identifier */
  id: Scalars['Uuid']['output'];
  /** Formatted date of last data update */
  lastUpdateDate?: Maybe<Scalars['String']['output']>;
  /** MetaData */
  metadata?: Maybe<Array<MetaData>>;
  /** Table options */
  options?: Maybe<TableViewOptionsResult>;
  /** Table rows */
  rows?: Maybe<Array<Maybe<TableRowResult>>>;
  /** Title */
  title?: Maybe<Scalars['String']['output']>;
};

export type TableRowResult = {
  __typename?: 'TableRowResult';
  /** Cells */
  cells?: Maybe<Array<TableCellResult>>;
  /** Children rows */
  children?: Maybe<Array<TableRowResult>>;
  /**
   * Deprecated field which should not be used
   * @deprecated To be removed after P&L implementation
   */
  groupChildren?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Deprecated field which should not be used
   * @deprecated To be removed after P&L implementation
   */
  groupFirstChild?: Maybe<Scalars['Boolean']['output']>;
  /** Row identifier */
  rowId: Scalars['Int']['output'];
  /** Type of the row */
  rowType: RowType;
};

export type TableViewOptionsResult = {
  __typename?: 'TableViewOptionsResult';
  showMap?: Maybe<Scalars['Boolean']['output']>;
};

export type TenantDefaultsInput = {
  availableNumberScalingSettings: Array<NumberScaling>;
  decimalPrecision: Scalars['Int']['input'];
  exchangeRate: Scalars['Int']['input'];
  numberScaling: NumberScaling;
};

export type TenantDefaultsPriorityInput = {
  id: Scalars['Int']['input'];
};

export type TenantDefaultsPriorityResult = {
  __typename?: 'TenantDefaultsPriorityResult';
  id: Scalars['Int']['output'];
  moduleCode: ModuleCode;
  tenantId: Scalars['String']['output'];
};

export type TenantDefaultsResult = {
  __typename?: 'TenantDefaultsResult';
  moduleDefaults: Array<ModuleDefaultsResult>;
  tenantId: Scalars['String']['output'];
};

export type TenantModuleDefaultsInput = {
  defaultPriorities: Array<TenantDefaultsPriorityInput>;
  valueSettings: TenantDefaultsInput;
};

export type TextLinkResult = {
  __typename?: 'TextLinkResult';
  /** Label of content */
  label?: Maybe<Scalars['String']['output']>;
  /** Link direction */
  linkDirection?: Maybe<LinkDirection>;
  /** Link configuration */
  linkResult?: Maybe<Link>;
  /** SubLabels content */
  subLabels?: Maybe<Array<Scalars['String']['output']>>;
  /** Text content */
  value?: Maybe<Scalars['String']['output']>;
};

export type TextResult = {
  __typename?: 'TextResult';
  /** A color for a text value */
  color?: Maybe<ColorUnion>;
  /** SubLabels content */
  subLabels?: Maybe<Array<Scalars['String']['output']>>;
  /** Text content */
  value?: Maybe<Scalars['String']['output']>;
};

export type ThemeColor = {
  __typename?: 'ThemeColor';
  color?: Maybe<Color>;
};

export type Timeline = {
  __typename?: 'Timeline';
  /** The timeline axis */
  axis: TimelineAxis;
  /** The timeline intervals */
  intervals?: Maybe<Array<TimelineInterval>>;
  /** The timeline series */
  series?: Maybe<Array<TimelineSeries>>;
};

export type TimelineAxis = {
  __typename?: 'TimelineAxis';
  /** Axis categories */
  categories?: Maybe<Array<Scalars['String']['output']>>;
  /** The maximum x value of the axis */
  maxX: Scalars['Int']['output'];
  /** The minimum x value of the axis */
  minX: Scalars['Int']['output'];
};

export type TimelineComponent = Component & {
  __typename?: 'TimelineComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  viewType: ViewType;
};

export type TimelineDataPoint = {
  __typename?: 'TimelineDataPoint';
  /** Extra data for the data point, i.e. tooltip data, will be specified here */
  extraData?: Maybe<Scalars['JObject']['output']>;
  formattedX: Scalars['String']['output'];
  label: Scalars['String']['output'];
  /** Optional override of the series marker for an individual data point */
  marker?: Maybe<TimelineMarker>;
  tooltip?: Maybe<TableContent>;
  x: Scalars['Int']['output'];
};

export type TimelineInterval = {
  __typename?: 'TimelineInterval';
  color: ThemeColor;
  name?: Maybe<Scalars['String']['output']>;
  x1: Scalars['Int']['output'];
  x2: Scalars['Int']['output'];
};

export type TimelineMarker = {
  __typename?: 'TimelineMarker';
  color: ThemeColor;
  isFilled: Scalars['Boolean']['output'];
  symbol: TimelineMarkerSymbol;
};

export enum TimelineMarkerSymbol {
  Circle = 'CIRCLE',
  Rhomb = 'RHOMB',
  Square = 'SQUARE',
  Triangle = 'TRIANGLE',
}

export type TimelinePhases = {
  __typename?: 'TimelinePhases';
  /** The timeline axis */
  axis: TimelineAxis;
  /** The timeline intervals */
  intervals?: Maybe<Array<TimelineInterval>>;
};

export type TimelineSeries = {
  __typename?: 'TimelineSeries';
  data?: Maybe<Array<TimelineDataPoint>>;
  /** The ID of the series, for example `enrollment` */
  id: Scalars['String']['output'];
  marker: TimelineMarker;
  /** The name of the series shown in the legend, tooltip etc., for example `Enrollment` */
  name: Scalars['String']['output'];
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

export enum TitleDisplayType {
  Above = 'ABOVE',
  Below = 'BELOW',
  Inline = 'INLINE',
}

export enum TooltipRangeFormat {
  Inline = 'INLINE',
  None = 'NONE',
  Split = 'SPLIT',
}

export type TreeFilterOptionResult = {
  __typename?: 'TreeFilterOptionResult';
  /** Additional filter option labels */
  additionalLabels?: Maybe<Array<Scalars['String']['output']>>;
  /** Sub options */
  children?: Maybe<Array<TreeFilterOptionResult>>;
  filterId?: Maybe<Scalars['Uuid']['output']>;
  /** Option unique ID. */
  id: Scalars['ID']['output'];
  /** Flag to indicate whether option is default for filter */
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  /** Option label. */
  label: Scalars['String']['output'];
  /** Filter Option Sentiment */
  sentiment?: Maybe<Sentiment>;
  /** Option value */
  value?: Maybe<Scalars['Int']['output']>;
};

export type TreeSelectFilter = Filter & {
  __typename?: 'TreeSelectFilter';
  /** Additional Labels */
  additionalLabels: Array<Maybe<Scalars['String']['output']>>;
  /** Filter ApiKey */
  filterCode?: Maybe<Scalars['String']['output']>;
  filterType: FilterType;
  id: Scalars['Uuid']['output'];
  label?: Maybe<Scalars['String']['output']>;
  /** Hierarchical structure of filters */
  options?: Maybe<Array<TreeFilterOptionResult>>;
  parentId?: Maybe<Scalars['Uuid']['output']>;
};

export type UserAnalytics = {
  __typename?: 'UserAnalytics';
  /** Filters */
  filterOptions?: Maybe<Array<Maybe<FilterOptionResult>>>;
  /** Metric header */
  headerMetrics?: Maybe<Array<Maybe<Metric>>>;
  /** Metric header */
  metricLists?: Maybe<Array<Maybe<MetricList>>>;
};

export type UserAnalyticsComponent = Component & {
  __typename?: 'UserAnalyticsComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  filters?: Maybe<Array<FilterComponent>>;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  viewType: ViewType;
};

export type UserAnalyticsInput = {
  filters?: InputMaybe<Array<InputMaybe<SelectFilterInput>>>;
  moduleId: Scalars['Uuid']['input'];
};

export type UserPrioritiesComponent = Component & {
  __typename?: 'UserPrioritiesComponent';
  /** Component code */
  code: Scalars['String']['output'];
  componentType: ComponentType;
  icon: ComponentIcon;
  id: Scalars['Uuid']['output'];
  viewType: ViewType;
};

export type UserSetting = {
  __typename?: 'UserSetting';
  defaultValue?: Maybe<Scalars['Int']['output']>;
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  options: Array<SettingOption>;
  userValue?: Maybe<Scalars['Int']['output']>;
};

export type UserSettingInput = {
  key: Scalars['String']['input'];
  value: Scalars['Int']['input'];
};

export type VarianceResult = {
  __typename?: 'VarianceResult';
  /** Actual Text content */
  actual?: Maybe<Scalars['String']['output']>;
  /** Label content */
  label?: Maybe<Scalars['String']['output']>;
  /** Sentiment for value */
  sentiment?: Maybe<Sentiment>;
  /** SubLabels content */
  subLabels?: Maybe<Array<Scalars['String']['output']>>;
  /** Target Text content */
  target?: Maybe<Scalars['String']['output']>;
  /**
   * Text content
   * @deprecated Will be removed in release/1.9
   */
  value?: Maybe<Scalars['String']['output']>;
};

export enum ViewType {
  Default = 'DEFAULT',
  Landscape = 'LANDSCAPE',
  Portrait = 'PORTRAIT',
}

export const isCardsComponent = (value?: any): value is CardsComponent =>
  value?.__typename === 'CardsComponent';

export const isCardsDataView = (value?: any): value is CardsDataView =>
  value?.__typename === 'CardsDataView';

export const isChartAxisCategoryViewResult = (value?: any): value is ChartAxisCategoryViewResult =>
  value?.__typename === 'ChartAxisCategoryViewResult';

export const isChartAxisPercentViewResult = (value?: any): value is ChartAxisPercentViewResult =>
  value?.__typename === 'ChartAxisPercentViewResult';

export const isChartAxisValueViewResult = (value?: any): value is ChartAxisValueViewResult =>
  value?.__typename === 'ChartAxisValueViewResult';

export const isChartComponent = (value?: any): value is ChartComponent =>
  value?.__typename === 'ChartComponent';

export const isChartDataViewResult = (value?: any): value is ChartDataViewResult =>
  value?.__typename === 'ChartDataViewResult';

export const isColorString = (value?: any): value is ColorString =>
  value?.__typename === 'ColorString';

export const isColumnLayoutComponent = (value?: any): value is ColumnLayoutComponent =>
  value?.__typename === 'ColumnLayoutComponent';

export const isDialsComponent = (value?: any): value is DialsComponent =>
  value?.__typename === 'DialsComponent';

export const isDialsDataView = (value?: any): value is DialsDataView =>
  value?.__typename === 'DialsDataView';

export const isDrillDownResult = (value?: any): value is DrillDownResult =>
  value?.__typename === 'DrillDownResult';

export const isFilterComponent = (value?: any): value is FilterComponent =>
  value?.__typename === 'FilterComponent';

export const isFiltersComponent = (value?: any): value is FiltersComponent =>
  value?.__typename === 'FiltersComponent';

export const isGridComponent = (value?: any): value is GridComponent =>
  value?.__typename === 'GridComponent';

export const isGridDataView = (value?: any): value is GridDataView =>
  value?.__typename === 'GridDataView';

export const isHeaderRow = (value?: any): value is HeaderRow => value?.__typename === 'HeaderRow';

export const isHeaderRowComponent = (value?: any): value is HeaderRowComponent =>
  value?.__typename === 'HeaderRowComponent';

export const isKpiCardResult = (value?: any): value is KpiCardResult =>
  value?.__typename === 'KpiCardResult';

export const isKpiComponent = (value?: any): value is KpiComponent =>
  value?.__typename === 'KpiComponent';

export const isKpiStatus = (value?: any): value is KpiStatus => value?.__typename === 'KpiStatus';

export const isKpiStatusOverview = (value?: any): value is KpiStatusOverview =>
  value?.__typename === 'KpiStatusOverview';

export const isListComponent = (value?: any): value is ListComponent =>
  value?.__typename === 'ListComponent';

export const isListDataView = (value?: any): value is ListDataView =>
  value?.__typename === 'ListDataView';

export const isLocationMarkerResult = (value?: any): value is LocationMarkerResult =>
  value?.__typename === 'LocationMarkerResult';

export const isMarkdownComponent = (value?: any): value is MarkdownComponent =>
  value?.__typename === 'MarkdownComponent';

export const isMicroChartCardResult = (value?: any): value is MicroChartCardResult =>
  value?.__typename === 'MicroChartCardResult';

export const isMicroChartComponent = (value?: any): value is MicroChartComponent =>
  value?.__typename === 'MicroChartComponent';

export const isMicroChartResult = (value?: any): value is MicroChartResult =>
  value?.__typename === 'MicroChartResult';

export const isNavigationTabsComponent = (value?: any): value is NavigationTabsComponent =>
  value?.__typename === 'NavigationTabsComponent';

export const isPlailistComponent = (value?: any): value is PlailistComponent =>
  value?.__typename === 'PlailistComponent';

export const isProgressBarResult = (value?: any): value is ProgressBarResult =>
  value?.__typename === 'ProgressBarResult';

export const isRecommenderComponent = (value?: any): value is RecommenderComponent =>
  value?.__typename === 'RecommenderComponent';

export const isRecommenderDataView = (value?: any): value is RecommenderDataView =>
  value?.__typename === 'RecommenderDataView';

export const isSelectFilter = (value?: any): value is SelectFilter =>
  value?.__typename === 'SelectFilter';

export const isSelectFilterValue = (value?: any): value is SelectFilterValue =>
  value?.__typename === 'SelectFilterValue';

export const isSelectGroupFilter = (value?: any): value is SelectGroupFilter =>
  value?.__typename === 'SelectGroupFilter';

export const isSelectGroupFilterValue = (value?: any): value is SelectGroupFilterValue =>
  value?.__typename === 'SelectGroupFilterValue';

export const isSimpleCardResult = (value?: any): value is SimpleCardResult =>
  value?.__typename === 'SimpleCardResult';

export const isStoryListComponent = (value?: any): value is StoryListComponent =>
  value?.__typename === 'StoryListComponent';

export const isSummaryInfoComponent = (value?: any): value is SummaryInfoComponent =>
  value?.__typename === 'SummaryInfoComponent';

export const isSummaryInfoDataView = (value?: any): value is SummaryInfoDataView =>
  value?.__typename === 'SummaryInfoDataView';

export const isTableComponent = (value?: any): value is TableComponent =>
  value?.__typename === 'TableComponent';

export const isTableDataView = (value?: any): value is TableDataView =>
  value?.__typename === 'TableDataView';

export const isTextLinkResult = (value?: any): value is TextLinkResult =>
  value?.__typename === 'TextLinkResult';

export const isTextResult = (value?: any): value is TextResult =>
  value?.__typename === 'TextResult';

export const isThemeColor = (value?: any): value is ThemeColor =>
  value?.__typename === 'ThemeColor';

export const isTimeline = (value?: any): value is Timeline => value?.__typename === 'Timeline';

export const isTimelineComponent = (value?: any): value is TimelineComponent =>
  value?.__typename === 'TimelineComponent';

export const isTimelineDataPoint = (value?: any): value is TimelineDataPoint =>
  value?.__typename === 'TimelineDataPoint';

export const isTimelinePhases = (value?: any): value is TimelinePhases =>
  value?.__typename === 'TimelinePhases';

export const isTreeSelectFilter = (value?: any): value is TreeSelectFilter =>
  value?.__typename === 'TreeSelectFilter';

export const isUserAnalyticsComponent = (value?: any): value is UserAnalyticsComponent =>
  value?.__typename === 'UserAnalyticsComponent';

export const isUserPrioritiesComponent = (value?: any): value is UserPrioritiesComponent =>
  value?.__typename === 'UserPrioritiesComponent';

export const isVarianceResult = (value?: any): value is VarianceResult =>
  value?.__typename === 'VarianceResult';
