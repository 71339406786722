import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet-async';

interface Props {
  title?: string;
}

const NotFoundPage: React.FC<Props> = ({ title = 'Error: Not Found' }) => {
  return (
    <>
      <Helmet>
        <title>
          {title} | {process.env.REACT_APP_SITE_NAME}
        </title>
      </Helmet>
      <Container maxWidth="xl" data-testid="NotFoundPage">
        <Box sx={{ py: 7.5 }}>
          <Typography variant="h4">{title}</Typography>
        </Box>
      </Container>
    </>
  );
};

export default NotFoundPage;
