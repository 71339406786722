import {
  GetPriorityFilterDocument,
  GetPriorityFilterQuery,
  GetPriorityFilterQueryResult,
  GetPriorityFilterQueryVariables,
} from 'graphql/__generated__/queries';
import { isSelectFilter, isTreeSelectFilter } from 'graphql/__generated__/types';
import { client } from 'graphql/client';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { moduleChanged } from 'store/modules/slices';

import {
  fetchPriorityFilterFailure,
  fetchPriorityFilterRequest,
  fetchPriorityFilterSuccess,
  getPriorityFilter,
} from './slices';

// Watchers
function* onFetchPriorityFilterRequest() {
  // It takes the latest fetch request, meaning it cannot load priority filters for multiple modules simultaneously
  yield takeLatest(fetchPriorityFilterRequest, handleFetchPriorityFilterRequest);
}

function* onModuleChanged() {
  yield takeLatest(moduleChanged, handleModuleChanged);
}

// Workers
function* handleFetchPriorityFilterRequest({
  payload: { moduleId },
}: ReturnType<typeof fetchPriorityFilterRequest>) {
  const query = client.query<GetPriorityFilterQuery, GetPriorityFilterQueryVariables>;
  try {
    const { data, error } = (yield call(query, {
      query: GetPriorityFilterDocument,
      variables: { moduleId },
    })) as GetPriorityFilterQueryResult;

    if (error) {
      yield put(fetchPriorityFilterFailure(error));
    } else if (
      !data?.priorityFilter ||
      (!isSelectFilter(data.priorityFilter) && !isTreeSelectFilter(data.priorityFilter))
    ) {
      yield put(fetchPriorityFilterFailure(new Error('Unable to fetch priority filter')));
    } else {
      yield put(fetchPriorityFilterSuccess({ moduleId, priorityFilter: data.priorityFilter }));
    }
  } catch (e) {
    yield put(fetchPriorityFilterFailure(e as Error));
  }
}

function* handleModuleChanged({ payload: { id: moduleId } }: ReturnType<typeof moduleChanged>) {
  const priorityFilter = (yield select(getPriorityFilter(moduleId))) as ReturnType<
    ReturnType<typeof getPriorityFilter>
  >;

  // If the priority filter is not already in the state, dispatch a fetch request action
  if (!priorityFilter) {
    yield put(fetchPriorityFilterRequest({ moduleId }));
  }
}

export default [onFetchPriorityFilterRequest, onModuleChanged];
