import { Typography } from '@mui/material';
import Markdown from 'markdown-to-jsx';
import React, { ComponentProps } from 'react';

import { AiInspiredText, StatusIcon, Variance } from './components';

interface Props extends ComponentProps<typeof Markdown> {}

export const MarkdownRenderer: React.FC<Props> = ({ children, options }) => {
  const { overrides, ...rest } = options ?? {};
  return (
    <Markdown
      options={{
        wrapper: React.Fragment,
        overrides: {
          h1: {
            component: Typography,
            props: { variant: 'h1', component: 'div' },
          },
          h2: {
            component: Typography,
            props: { variant: 'h2', component: 'div' },
          },
          h3: {
            component: Typography,
            props: { variant: 'h3', component: 'div' },
          },
          h4: {
            component: Typography,
            props: { variant: 'h4', component: 'div' },
          },
          h5: {
            component: Typography,
            props: { variant: 'h5', component: 'div' },
          },
          h6: {
            component: Typography,
            props: { variant: 'h6', component: 'div' },
          },
          StatusIcon: {
            component: StatusIcon,
          },
          Variance: {
            component: Variance,
          },
          AiInspiredText: {
            component: AiInspiredText,
          },
          ...overrides,
        },
        ...rest,
      }}
    >
      {children}
    </Markdown>
  );
};
