import { combineReducers } from '@reduxjs/toolkit';

import app from './app/slices';
import modals from './modals/slices';
import modules from './modules/slices';
import priorityFilter from './priorityFilter/slices';

const rootReducer = combineReducers({
  app,
  modals,
  modules,
  priorityFilter,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
