import { useMemo } from 'react';
import { useAuth } from 'react-oidc-context';

/**
 * Custom hook to get user email domain
 */
export function useUserEmailDomain(): string | undefined {
  const { user } = useAuth();

  return useMemo(() => user?.profile?.email?.split('@')[1]?.toLowerCase(), [user]);
}
