import { Components, Theme } from '@mui/material';
import Colors from 'theme/colors';

export const MuiTableCell: Components<Theme>['MuiTableCell'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderBottom: `1px solid ${Colors.grey2}`,
      height: 48,
      padding: theme.spacing(1.5, 2),
      ...theme.typography.body,
    }),
    head: ({ theme }) => ({
      padding: theme.spacing(0, 2),
      borderBottom: `2px solid ${Colors.grey2}`,
      color: theme.palette.text.primary,
      whiteSpace: 'nowrap',
    }),
    footer: ({ theme }) => ({
      position: 'sticky',
      zIndex: 1,
      bottom: 0,
      backgroundColor: theme.palette.background.default,
      borderTop: '1px solid #1d293f',
    }),
    stickyHeader: {
      zIndex: 200,
    },
  },
};
