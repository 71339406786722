import { gql } from '@apollo/client';

import * as Types from './types';
export type ChartAxisFields_ChartAxisCategoryViewResult_Fragment = {
  __typename?: 'ChartAxisCategoryViewResult';
} & Pick<Types.ChartAxisCategoryViewResult, 'categories' | 'renderer' | 'axisType'>;

export type ChartAxisFields_ChartAxisPercentViewResult_Fragment = {
  __typename?: 'ChartAxisPercentViewResult';
} & Pick<Types.ChartAxisPercentViewResult, 'renderer' | 'axisType'>;

export type ChartAxisFields_ChartAxisValueViewResult_Fragment = {
  __typename?: 'ChartAxisValueViewResult';
} & Pick<Types.ChartAxisValueViewResult, 'renderer' | 'axisType'>;

export type ChartAxisFieldsFragment =
  | ChartAxisFields_ChartAxisCategoryViewResult_Fragment
  | ChartAxisFields_ChartAxisPercentViewResult_Fragment
  | ChartAxisFields_ChartAxisValueViewResult_Fragment;

export type ChartSeriesFieldsFragment = { __typename?: 'ChartSeries' } & Pick<
  Types.ChartSeries,
  | 'id'
  | 'seriesType'
  | 'linkedTo'
  | 'name'
  | 'color'
  | 'lineStyle'
  | 'showInLegend'
  | 'showInTooltip'
  | 'showLastPointMarker'
  | 'tooltipRangeFormat'
  | 'tooltipTitleLow'
  | 'tooltipTitleHigh'
  | 'renderer'
> & {
    data?: Types.Maybe<
      Array<
        { __typename?: 'ChartSeriesDataPoint' } & Pick<Types.ChartSeriesDataPoint, 'x'> & {
            y?: Types.Maybe<
              { __typename?: 'ChartSeriesDataPointValue' } & {
                value?: Types.Maybe<
                  { __typename?: 'FormattedValue' } & Pick<
                    Types.FormattedValue,
                    'raw' | 'formatted'
                  >
                >;
                high?: Types.Maybe<
                  { __typename?: 'FormattedValue' } & Pick<
                    Types.FormattedValue,
                    'raw' | 'formatted'
                  >
                >;
                low?: Types.Maybe<
                  { __typename?: 'FormattedValue' } & Pick<
                    Types.FormattedValue,
                    'raw' | 'formatted'
                  >
                >;
                variance?: Types.Maybe<
                  { __typename?: 'VarianceResult' } & Pick<
                    Types.VarianceResult,
                    'actual' | 'target' | 'sentiment' | 'label'
                  >
                >;
              }
            >;
          }
      >
    >;
  };

export type ComponentFields_CardsComponent_Fragment = { __typename?: 'CardsComponent' } & Pick<
  Types.CardsComponent,
  'id' | 'code' | 'componentType' | 'icon'
>;

export type ComponentFields_ChartComponent_Fragment = { __typename?: 'ChartComponent' } & Pick<
  Types.ChartComponent,
  'id' | 'code' | 'componentType' | 'icon'
>;

export type ComponentFields_ColumnLayoutComponent_Fragment = {
  __typename?: 'ColumnLayoutComponent';
} & Pick<Types.ColumnLayoutComponent, 'id' | 'code' | 'componentType' | 'icon'>;

export type ComponentFields_DialsComponent_Fragment = { __typename?: 'DialsComponent' } & Pick<
  Types.DialsComponent,
  'id' | 'code' | 'componentType' | 'icon'
>;

export type ComponentFields_FilterComponent_Fragment = { __typename?: 'FilterComponent' } & Pick<
  Types.FilterComponent,
  'id' | 'code' | 'componentType' | 'icon'
>;

export type ComponentFields_FiltersComponent_Fragment = { __typename?: 'FiltersComponent' } & Pick<
  Types.FiltersComponent,
  'id' | 'code' | 'componentType' | 'icon'
>;

export type ComponentFields_GridComponent_Fragment = { __typename?: 'GridComponent' } & Pick<
  Types.GridComponent,
  'id' | 'code' | 'componentType' | 'icon'
>;

export type ComponentFields_HeaderRowComponent_Fragment = {
  __typename?: 'HeaderRowComponent';
} & Pick<Types.HeaderRowComponent, 'id' | 'code' | 'componentType' | 'icon'>;

export type ComponentFields_KpiComponent_Fragment = { __typename?: 'KpiComponent' } & Pick<
  Types.KpiComponent,
  'id' | 'code' | 'componentType' | 'icon'
>;

export type ComponentFields_ListComponent_Fragment = { __typename?: 'ListComponent' } & Pick<
  Types.ListComponent,
  'id' | 'code' | 'componentType' | 'icon'
>;

export type ComponentFields_MarkdownComponent_Fragment = {
  __typename?: 'MarkdownComponent';
} & Pick<Types.MarkdownComponent, 'id' | 'code' | 'componentType' | 'icon'>;

export type ComponentFields_MicroChartComponent_Fragment = {
  __typename?: 'MicroChartComponent';
} & Pick<Types.MicroChartComponent, 'id' | 'code' | 'componentType' | 'icon'>;

export type ComponentFields_NavigationTabsComponent_Fragment = {
  __typename?: 'NavigationTabsComponent';
} & Pick<Types.NavigationTabsComponent, 'id' | 'code' | 'componentType' | 'icon'>;

export type ComponentFields_PlailistComponent_Fragment = {
  __typename?: 'PlailistComponent';
} & Pick<Types.PlailistComponent, 'id' | 'code' | 'componentType' | 'icon'>;

export type ComponentFields_RecommenderComponent_Fragment = {
  __typename?: 'RecommenderComponent';
} & Pick<Types.RecommenderComponent, 'id' | 'code' | 'componentType' | 'icon'>;

export type ComponentFields_StoryListComponent_Fragment = {
  __typename?: 'StoryListComponent';
} & Pick<Types.StoryListComponent, 'id' | 'code' | 'componentType' | 'icon'>;

export type ComponentFields_SummaryInfoComponent_Fragment = {
  __typename?: 'SummaryInfoComponent';
} & Pick<Types.SummaryInfoComponent, 'id' | 'code' | 'componentType' | 'icon'>;

export type ComponentFields_TableComponent_Fragment = { __typename?: 'TableComponent' } & Pick<
  Types.TableComponent,
  'id' | 'code' | 'componentType' | 'icon'
>;

export type ComponentFields_TimelineComponent_Fragment = {
  __typename?: 'TimelineComponent';
} & Pick<Types.TimelineComponent, 'id' | 'code' | 'componentType' | 'icon'>;

export type ComponentFields_UserAnalyticsComponent_Fragment = {
  __typename?: 'UserAnalyticsComponent';
} & Pick<Types.UserAnalyticsComponent, 'id' | 'code' | 'componentType' | 'icon'>;

export type ComponentFields_UserPrioritiesComponent_Fragment = {
  __typename?: 'UserPrioritiesComponent';
} & Pick<Types.UserPrioritiesComponent, 'id' | 'code' | 'componentType' | 'icon'>;

export type ComponentFieldsFragment =
  | ComponentFields_CardsComponent_Fragment
  | ComponentFields_ChartComponent_Fragment
  | ComponentFields_ColumnLayoutComponent_Fragment
  | ComponentFields_DialsComponent_Fragment
  | ComponentFields_FilterComponent_Fragment
  | ComponentFields_FiltersComponent_Fragment
  | ComponentFields_GridComponent_Fragment
  | ComponentFields_HeaderRowComponent_Fragment
  | ComponentFields_KpiComponent_Fragment
  | ComponentFields_ListComponent_Fragment
  | ComponentFields_MarkdownComponent_Fragment
  | ComponentFields_MicroChartComponent_Fragment
  | ComponentFields_NavigationTabsComponent_Fragment
  | ComponentFields_PlailistComponent_Fragment
  | ComponentFields_RecommenderComponent_Fragment
  | ComponentFields_StoryListComponent_Fragment
  | ComponentFields_SummaryInfoComponent_Fragment
  | ComponentFields_TableComponent_Fragment
  | ComponentFields_TimelineComponent_Fragment
  | ComponentFields_UserAnalyticsComponent_Fragment
  | ComponentFields_UserPrioritiesComponent_Fragment;

export type DataViewFields_CardsDataView_Fragment = { __typename?: 'CardsDataView' } & Pick<
  Types.CardsDataView,
  'id' | 'dataViewType'
> & {
    metadata?: Types.Maybe<
      Array<{ __typename?: 'MetaData' } & Pick<Types.MetaData, 'key' | 'label' | 'value'>>
    >;
    availableUserSettings?: Types.Maybe<
      Array<
        { __typename?: 'UserSetting' } & Pick<
          Types.UserSetting,
          'key' | 'label' | 'defaultValue' | 'userValue'
        > & {
            options: Array<
              { __typename?: 'SettingOption' } & Pick<Types.SettingOption, 'label' | 'value'>
            >;
          }
      >
    >;
  };

export type DataViewFields_ChartDataViewResult_Fragment = {
  __typename?: 'ChartDataViewResult';
} & Pick<Types.ChartDataViewResult, 'id' | 'dataViewType'> & {
    metadata?: Types.Maybe<
      Array<{ __typename?: 'MetaData' } & Pick<Types.MetaData, 'key' | 'label' | 'value'>>
    >;
    availableUserSettings?: Types.Maybe<
      Array<
        { __typename?: 'UserSetting' } & Pick<
          Types.UserSetting,
          'key' | 'label' | 'defaultValue' | 'userValue'
        > & {
            options: Array<
              { __typename?: 'SettingOption' } & Pick<Types.SettingOption, 'label' | 'value'>
            >;
          }
      >
    >;
  };

export type DataViewFields_DialsDataView_Fragment = { __typename?: 'DialsDataView' } & Pick<
  Types.DialsDataView,
  'id' | 'dataViewType'
> & {
    metadata?: Types.Maybe<
      Array<{ __typename?: 'MetaData' } & Pick<Types.MetaData, 'key' | 'label' | 'value'>>
    >;
    availableUserSettings?: Types.Maybe<
      Array<
        { __typename?: 'UserSetting' } & Pick<
          Types.UserSetting,
          'key' | 'label' | 'defaultValue' | 'userValue'
        > & {
            options: Array<
              { __typename?: 'SettingOption' } & Pick<Types.SettingOption, 'label' | 'value'>
            >;
          }
      >
    >;
  };

export type DataViewFields_GridDataView_Fragment = { __typename?: 'GridDataView' } & Pick<
  Types.GridDataView,
  'id' | 'dataViewType'
> & {
    metadata?: Types.Maybe<
      Array<{ __typename?: 'MetaData' } & Pick<Types.MetaData, 'key' | 'label' | 'value'>>
    >;
    availableUserSettings?: Types.Maybe<
      Array<
        { __typename?: 'UserSetting' } & Pick<
          Types.UserSetting,
          'key' | 'label' | 'defaultValue' | 'userValue'
        > & {
            options: Array<
              { __typename?: 'SettingOption' } & Pick<Types.SettingOption, 'label' | 'value'>
            >;
          }
      >
    >;
  };

export type DataViewFields_HeaderRow_Fragment = { __typename?: 'HeaderRow' } & Pick<
  Types.HeaderRow,
  'id' | 'dataViewType'
> & {
    metadata?: Types.Maybe<
      Array<{ __typename?: 'MetaData' } & Pick<Types.MetaData, 'key' | 'label' | 'value'>>
    >;
    availableUserSettings?: Types.Maybe<
      Array<
        { __typename?: 'UserSetting' } & Pick<
          Types.UserSetting,
          'key' | 'label' | 'defaultValue' | 'userValue'
        > & {
            options: Array<
              { __typename?: 'SettingOption' } & Pick<Types.SettingOption, 'label' | 'value'>
            >;
          }
      >
    >;
  };

export type DataViewFields_KpiStatusOverview_Fragment = { __typename?: 'KpiStatusOverview' } & Pick<
  Types.KpiStatusOverview,
  'id' | 'dataViewType'
> & {
    metadata?: Types.Maybe<
      Array<{ __typename?: 'MetaData' } & Pick<Types.MetaData, 'key' | 'label' | 'value'>>
    >;
    availableUserSettings?: Types.Maybe<
      Array<
        { __typename?: 'UserSetting' } & Pick<
          Types.UserSetting,
          'key' | 'label' | 'defaultValue' | 'userValue'
        > & {
            options: Array<
              { __typename?: 'SettingOption' } & Pick<Types.SettingOption, 'label' | 'value'>
            >;
          }
      >
    >;
  };

export type DataViewFields_ListDataView_Fragment = { __typename?: 'ListDataView' } & Pick<
  Types.ListDataView,
  'id' | 'dataViewType'
> & {
    metadata?: Types.Maybe<
      Array<{ __typename?: 'MetaData' } & Pick<Types.MetaData, 'key' | 'label' | 'value'>>
    >;
    availableUserSettings?: Types.Maybe<
      Array<
        { __typename?: 'UserSetting' } & Pick<
          Types.UserSetting,
          'key' | 'label' | 'defaultValue' | 'userValue'
        > & {
            options: Array<
              { __typename?: 'SettingOption' } & Pick<Types.SettingOption, 'label' | 'value'>
            >;
          }
      >
    >;
  };

export type DataViewFields_MicroChartResult_Fragment = { __typename?: 'MicroChartResult' } & Pick<
  Types.MicroChartResult,
  'id' | 'dataViewType'
> & {
    metadata?: Types.Maybe<
      Array<{ __typename?: 'MetaData' } & Pick<Types.MetaData, 'key' | 'label' | 'value'>>
    >;
    availableUserSettings?: Types.Maybe<
      Array<
        { __typename?: 'UserSetting' } & Pick<
          Types.UserSetting,
          'key' | 'label' | 'defaultValue' | 'userValue'
        > & {
            options: Array<
              { __typename?: 'SettingOption' } & Pick<Types.SettingOption, 'label' | 'value'>
            >;
          }
      >
    >;
  };

export type DataViewFields_RecommenderDataView_Fragment = {
  __typename?: 'RecommenderDataView';
} & Pick<Types.RecommenderDataView, 'id' | 'dataViewType'> & {
    metadata?: Types.Maybe<
      Array<{ __typename?: 'MetaData' } & Pick<Types.MetaData, 'key' | 'label' | 'value'>>
    >;
    availableUserSettings?: Types.Maybe<
      Array<
        { __typename?: 'UserSetting' } & Pick<
          Types.UserSetting,
          'key' | 'label' | 'defaultValue' | 'userValue'
        > & {
            options: Array<
              { __typename?: 'SettingOption' } & Pick<Types.SettingOption, 'label' | 'value'>
            >;
          }
      >
    >;
  };

export type DataViewFields_SummaryInfoDataView_Fragment = {
  __typename?: 'SummaryInfoDataView';
} & Pick<Types.SummaryInfoDataView, 'id' | 'dataViewType'> & {
    metadata?: Types.Maybe<
      Array<{ __typename?: 'MetaData' } & Pick<Types.MetaData, 'key' | 'label' | 'value'>>
    >;
    availableUserSettings?: Types.Maybe<
      Array<
        { __typename?: 'UserSetting' } & Pick<
          Types.UserSetting,
          'key' | 'label' | 'defaultValue' | 'userValue'
        > & {
            options: Array<
              { __typename?: 'SettingOption' } & Pick<Types.SettingOption, 'label' | 'value'>
            >;
          }
      >
    >;
  };

export type DataViewFields_TableDataView_Fragment = { __typename?: 'TableDataView' } & Pick<
  Types.TableDataView,
  'id' | 'dataViewType'
> & {
    metadata?: Types.Maybe<
      Array<{ __typename?: 'MetaData' } & Pick<Types.MetaData, 'key' | 'label' | 'value'>>
    >;
    availableUserSettings?: Types.Maybe<
      Array<
        { __typename?: 'UserSetting' } & Pick<
          Types.UserSetting,
          'key' | 'label' | 'defaultValue' | 'userValue'
        > & {
            options: Array<
              { __typename?: 'SettingOption' } & Pick<Types.SettingOption, 'label' | 'value'>
            >;
          }
      >
    >;
  };

export type DataViewFieldsFragment =
  | DataViewFields_CardsDataView_Fragment
  | DataViewFields_ChartDataViewResult_Fragment
  | DataViewFields_DialsDataView_Fragment
  | DataViewFields_GridDataView_Fragment
  | DataViewFields_HeaderRow_Fragment
  | DataViewFields_KpiStatusOverview_Fragment
  | DataViewFields_ListDataView_Fragment
  | DataViewFields_MicroChartResult_Fragment
  | DataViewFields_RecommenderDataView_Fragment
  | DataViewFields_SummaryInfoDataView_Fragment
  | DataViewFields_TableDataView_Fragment;

export type DialItemFieldsFragment = { __typename?: 'DialItem' } & Pick<
  Types.DialItem,
  'label' | 'sentiment' | 'progress' | 'target'
> & {
    progressText?: Types.Maybe<{ __typename?: 'TextResult' } & Pick<Types.TextResult, 'value'>>;
    targetText?: Types.Maybe<{ __typename?: 'TextResult' } & Pick<Types.TextResult, 'value'>>;
    actualText?: Types.Maybe<{ __typename?: 'TextResult' } & Pick<Types.TextResult, 'value'>>;
    varianceText?: Types.Maybe<{ __typename?: 'TextResult' } & Pick<Types.TextResult, 'value'>>;
    link?: Types.Maybe<
      { __typename?: 'TextLinkResult' } & Pick<
        Types.TextLinkResult,
        'value' | 'label' | 'subLabels' | 'linkDirection'
      > & {
          linkResult?: Types.Maybe<
            { __typename?: 'Link' } & Pick<
              Types.Link,
              'pageId' | 'absolutePath' | 'drillId' | 'drillIds' | 'target'
            > & {
                filters?: Types.Maybe<
                  Array<
                    | ({ __typename?: 'SelectFilterValue' } & Pick<
                        Types.SelectFilterValue,
                        'id' | 'value'
                      >)
                    | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                        Types.SelectGroupFilterValue,
                        'id'
                      > & {
                          values: Array<
                            Types.Maybe<
                              { __typename?: 'FilterCodeValue' } & Pick<
                                Types.FilterCodeValue,
                                'filterCode' | 'value'
                              >
                            >
                          >;
                        })
                  >
                >;
              }
          >;
        }
    >;
  };

export type FilterComponentFieldsFragment = { __typename?: 'FilterComponent' } & Pick<
  Types.FilterComponent,
  | 'defaultValue'
  | 'alignment'
  | 'isHidden'
  | 'displayMode'
  | 'id'
  | 'code'
  | 'componentType'
  | 'icon'
>;

export type FilterFields_SelectFilter_Fragment = { __typename?: 'SelectFilter' } & Pick<
  Types.SelectFilter,
  'id' | 'label' | 'filterType'
>;

export type FilterFields_SelectGroupFilter_Fragment = { __typename?: 'SelectGroupFilter' } & Pick<
  Types.SelectGroupFilter,
  'id' | 'label' | 'filterType'
>;

export type FilterFields_TreeSelectFilter_Fragment = { __typename?: 'TreeSelectFilter' } & Pick<
  Types.TreeSelectFilter,
  'id' | 'label' | 'filterType'
>;

export type FilterFieldsFragment =
  | FilterFields_SelectFilter_Fragment
  | FilterFields_SelectGroupFilter_Fragment
  | FilterFields_TreeSelectFilter_Fragment;

export type FilterOptionFieldsFragment = { __typename?: 'FilterOptionResult' } & Pick<
  Types.FilterOptionResult,
  'id' | 'label' | 'value' | 'isDefault' | 'additionalLabels' | 'sentiment'
>;

export type FilterValueFields_SelectFilterValue_Fragment = {
  __typename?: 'SelectFilterValue';
} & Pick<Types.SelectFilterValue, 'id' | 'value'>;

export type FilterValueFields_SelectGroupFilterValue_Fragment = {
  __typename?: 'SelectGroupFilterValue';
} & Pick<Types.SelectGroupFilterValue, 'id'> & {
    values: Array<
      Types.Maybe<
        { __typename?: 'FilterCodeValue' } & Pick<Types.FilterCodeValue, 'filterCode' | 'value'>
      >
    >;
  };

export type FilterValueFieldsFragment =
  | FilterValueFields_SelectFilterValue_Fragment
  | FilterValueFields_SelectGroupFilterValue_Fragment;

export type FormattedValueFieldsFragment = { __typename?: 'FormattedValue' } & Pick<
  Types.FormattedValue,
  'raw' | 'formatted'
>;

export type KpiCardFieldsFragment = { __typename?: 'KpiCardResult' } & Pick<
  Types.KpiCardResult,
  'id' | 'code' | 'cardType' | 'title'
> & {
    items?: Types.Maybe<
      Array<
        { __typename?: 'KpiStatus' } & Pick<
          Types.KpiStatus,
          'actual' | 'target' | 'sentiment' | 'icon'
        >
      >
    >;
    linkResult?: Types.Maybe<
      { __typename?: 'Link' } & Pick<
        Types.Link,
        'pageId' | 'absolutePath' | 'drillId' | 'drillIds' | 'target'
      > & {
          filters?: Types.Maybe<
            Array<
              | ({ __typename?: 'SelectFilterValue' } & Pick<
                  Types.SelectFilterValue,
                  'id' | 'value'
                >)
              | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                  Types.SelectGroupFilterValue,
                  'id'
                > & {
                    values: Array<
                      Types.Maybe<
                        { __typename?: 'FilterCodeValue' } & Pick<
                          Types.FilterCodeValue,
                          'filterCode' | 'value'
                        >
                      >
                    >;
                  })
            >
          >;
        }
    >;
  };

export type KpiStatusFieldsFragment = { __typename?: 'KpiStatus' } & Pick<
  Types.KpiStatus,
  'actual' | 'target' | 'sentiment' | 'icon'
>;

export type KpiStatusOverviewFieldsFragment = { __typename?: 'KpiStatusOverview' } & {
  items?: Types.Maybe<
    Array<
      { __typename?: 'KpiStatus' } & Pick<
        Types.KpiStatus,
        'actual' | 'target' | 'sentiment' | 'icon'
      >
    >
  >;
  tooltip: { __typename?: 'TableContent' } & Pick<Types.TableContent, 'title'> & {
      columns?: Types.Maybe<
        Array<
          { __typename?: 'TableColumnResult' } & Pick<
            Types.TableColumnResult,
            'columnId' | 'dataKey' | 'title'
          > & {
              options?: Types.Maybe<
                { __typename?: 'TableColumnOptionsResult' } & Pick<
                  Types.TableColumnOptionsResult,
                  | 'alignment'
                  | 'selectDefault'
                  | 'selectDisplayDefault'
                  | 'selectDisplayGroupId'
                  | 'selectGroupId'
                  | 'toggleDefault'
                  | 'toggleGroup'
                  | 'width'
                >
              >;
            }
        >
      >;
      rows?: Types.Maybe<
        Array<
          { __typename?: 'TableRowResult' } & Pick<Types.TableRowResult, 'rowId' | 'rowType'> & {
              cells?: Types.Maybe<
                Array<
                  { __typename?: 'TableCellResult' } & Pick<
                    Types.TableCellResult,
                    'columnDataKey'
                  > & {
                      cellContent?: Types.Maybe<
                        | { __typename?: 'DrillDownResult' }
                        | ({ __typename?: 'KpiStatus' } & Pick<
                            Types.KpiStatus,
                            'text' | 'icon' | 'sentiment'
                          >)
                        | { __typename?: 'KpiStatusOverview' }
                        | { __typename?: 'LocationMarkerResult' }
                        | { __typename?: 'MicroChartResult' }
                        | { __typename?: 'ProgressBarResult' }
                        | { __typename?: 'TextLinkResult' }
                        | ({ __typename?: 'TextResult' } & Pick<Types.TextResult, 'value'>)
                        | { __typename?: 'Timeline' }
                        | { __typename?: 'TimelineDataPoint' }
                        | { __typename?: 'TimelinePhases' }
                        | { __typename?: 'VarianceResult' }
                      >;
                    }
                >
              >;
            }
        >
      >;
    };
};

export type LinkFieldsFragment = { __typename?: 'Link' } & Pick<
  Types.Link,
  'pageId' | 'absolutePath' | 'drillId' | 'drillIds' | 'target'
> & {
    filters?: Types.Maybe<
      Array<
        | ({ __typename?: 'SelectFilterValue' } & Pick<Types.SelectFilterValue, 'id' | 'value'>)
        | ({ __typename?: 'SelectGroupFilterValue' } & Pick<Types.SelectGroupFilterValue, 'id'> & {
              values: Array<
                Types.Maybe<
                  { __typename?: 'FilterCodeValue' } & Pick<
                    Types.FilterCodeValue,
                    'filterCode' | 'value'
                  >
                >
              >;
            })
      >
    >;
  };

export type MetaDataFieldsFragment = { __typename?: 'MetaData' } & Pick<
  Types.MetaData,
  'key' | 'label' | 'value'
>;

export type MetricFieldsFragment = { __typename?: 'Metric' } & Pick<
  Types.Metric,
  'id' | 'name' | 'value' | 'sentiment'
> & {
    variances?: Types.Maybe<
      Array<
        Types.Maybe<
          { __typename?: 'VarianceResult' } & Pick<
            Types.VarianceResult,
            'actual' | 'target' | 'sentiment' | 'label'
          >
        >
      >
    >;
  };

export type MetricListFieldsFragment = { __typename?: 'MetricList' } & Pick<
  Types.MetricList,
  'name'
> & {
    metrics: Array<
      Types.Maybe<
        { __typename?: 'Metric' } & Pick<Types.Metric, 'id' | 'name' | 'value' | 'sentiment'> & {
            variances?: Types.Maybe<
              Array<
                Types.Maybe<
                  { __typename?: 'VarianceResult' } & Pick<
                    Types.VarianceResult,
                    'actual' | 'target' | 'sentiment' | 'label'
                  >
                >
              >
            >;
          }
      >
    >;
  };

export type MicroChartCardFieldsFragment = { __typename?: 'MicroChartCardResult' } & Pick<
  Types.MicroChartCardResult,
  'id' | 'code' | 'cardType' | 'title'
> & {
    actual?: Types.Maybe<
      { __typename?: 'TextResult' } & Pick<Types.TextResult, 'value' | 'subLabels'>
    >;
    variance?: Types.Maybe<
      { __typename?: 'VarianceResult' } & Pick<
        Types.VarianceResult,
        'actual' | 'target' | 'sentiment' | 'label'
      >
    >;
    microChart?: Types.Maybe<
      { __typename?: 'MicroChartResult' } & Pick<
        Types.MicroChartResult,
        'seriesData' | 'trendlineData' | 'sentiment'
      >
    >;
    linkResult: { __typename?: 'Link' } & Pick<
      Types.Link,
      'pageId' | 'absolutePath' | 'drillId' | 'drillIds' | 'target'
    > & {
        filters?: Types.Maybe<
          Array<
            | ({ __typename?: 'SelectFilterValue' } & Pick<Types.SelectFilterValue, 'id' | 'value'>)
            | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                Types.SelectGroupFilterValue,
                'id'
              > & {
                  values: Array<
                    Types.Maybe<
                      { __typename?: 'FilterCodeValue' } & Pick<
                        Types.FilterCodeValue,
                        'filterCode' | 'value'
                      >
                    >
                  >;
                })
          >
        >;
      };
  };

export type MicroChartFieldsFragment = { __typename?: 'MicroChartResult' } & Pick<
  Types.MicroChartResult,
  'seriesData' | 'trendlineData' | 'sentiment'
>;

export type ModuleFieldsFragment = { __typename?: 'Module' } & Pick<
  Types.Module,
  'id' | 'name' | 'moduleCode' | 'path'
> & {
    pages?: Types.Maybe<
      Array<
        { __typename?: 'Page' } & Pick<
          Types.Page,
          | 'id'
          | 'title'
          | 'pageType'
          | 'menuTitle'
          | 'menuIcon'
          | 'path'
          | 'absolutePath'
          | 'isMenuHidden'
        > & {
            children?: Types.Maybe<
              Array<
                { __typename?: 'Page' } & Pick<
                  Types.Page,
                  | 'id'
                  | 'title'
                  | 'pageType'
                  | 'menuTitle'
                  | 'menuIcon'
                  | 'path'
                  | 'absolutePath'
                  | 'isMenuHidden'
                > & {
                    children?: Types.Maybe<
                      Array<
                        { __typename?: 'Page' } & Pick<
                          Types.Page,
                          | 'id'
                          | 'title'
                          | 'pageType'
                          | 'menuTitle'
                          | 'menuIcon'
                          | 'path'
                          | 'absolutePath'
                          | 'isMenuHidden'
                        >
                      >
                    >;
                  }
              >
            >;
          }
      >
    >;
  };

export type NavigationTabsComponentFieldsFragment = {
  __typename?: 'NavigationTabsComponent';
} & Pick<Types.NavigationTabsComponent, 'id' | 'code' | 'componentType' | 'icon'> & {
    tabs?: Types.Maybe<
      Array<{ __typename?: 'Tab' } & Pick<Types.Tab, 'title' | 'path' | 'pageId'>>
    >;
  };

export type PageFieldsFragment = { __typename?: 'Page' } & Pick<
  Types.Page,
  'id' | 'title' | 'pageType' | 'menuTitle' | 'menuIcon' | 'path' | 'absolutePath' | 'isMenuHidden'
>;

export type PlailistFieldsFragment = { __typename?: 'Plailist' } & Pick<
  Types.Plailist,
  'plailistId' | 'title' | 'summary'
> & {
    colorStart?: Types.Maybe<
      | ({ __typename?: 'ColorString' } & Pick<Types.ColorString, 'color'>)
      | { __typename?: 'ThemeColor' }
    >;
    colorEnd?: Types.Maybe<
      | ({ __typename?: 'ColorString' } & Pick<Types.ColorString, 'color'>)
      | { __typename?: 'ThemeColor' }
    >;
    stories?: Types.Maybe<
      Array<
        Types.Maybe<
          { __typename?: 'Story' } & Pick<
            Types.Story,
            | 'id'
            | 'storyType'
            | 'headline'
            | 'recommenderHeadline'
            | 'caption'
            | 'sentiment'
            | 'publishDate'
            | 'isRead'
            | 'tag'
          > & {
              content?: Types.Maybe<
                Array<
                  Types.Maybe<
                    | { __typename?: 'CardsComponent' }
                    | ({ __typename?: 'ChartComponent' } & Pick<
                        Types.ChartComponent,
                        'id' | 'code' | 'componentType' | 'icon'
                      > & {
                          filters?: Types.Maybe<
                            Array<
                              { __typename?: 'FilterComponent' } & Pick<
                                Types.FilterComponent,
                                | 'defaultValue'
                                | 'alignment'
                                | 'isHidden'
                                | 'displayMode'
                                | 'id'
                                | 'code'
                                | 'componentType'
                                | 'icon'
                              >
                            >
                          >;
                        })
                    | { __typename?: 'ColumnLayoutComponent' }
                    | { __typename?: 'DialsComponent' }
                    | { __typename?: 'FilterComponent' }
                    | { __typename?: 'FiltersComponent' }
                    | { __typename?: 'GridComponent' }
                    | ({ __typename?: 'HeaderRowComponent' } & Pick<
                        Types.HeaderRowComponent,
                        'variant' | 'id' | 'code' | 'componentType' | 'icon'
                      > & {
                          filters?: Types.Maybe<
                            Array<
                              { __typename?: 'FilterComponent' } & Pick<
                                Types.FilterComponent,
                                | 'defaultValue'
                                | 'alignment'
                                | 'isHidden'
                                | 'displayMode'
                                | 'id'
                                | 'code'
                                | 'componentType'
                                | 'icon'
                              >
                            >
                          >;
                        })
                    | { __typename?: 'KpiComponent' }
                    | { __typename?: 'ListComponent' }
                    | ({ __typename?: 'MarkdownComponent' } & Pick<
                        Types.MarkdownComponent,
                        'content' | 'id' | 'code' | 'componentType' | 'icon'
                      >)
                    | { __typename?: 'MicroChartComponent' }
                    | { __typename?: 'NavigationTabsComponent' }
                    | { __typename?: 'PlailistComponent' }
                    | { __typename?: 'RecommenderComponent' }
                    | { __typename?: 'StoryListComponent' }
                    | { __typename?: 'SummaryInfoComponent' }
                    | ({ __typename?: 'TableComponent' } & Pick<
                        Types.TableComponent,
                        'id' | 'code' | 'componentType' | 'icon'
                      > & {
                          filters?: Types.Maybe<
                            Array<
                              { __typename?: 'FilterComponent' } & Pick<
                                Types.FilterComponent,
                                | 'defaultValue'
                                | 'alignment'
                                | 'isHidden'
                                | 'displayMode'
                                | 'id'
                                | 'code'
                                | 'componentType'
                                | 'icon'
                              >
                            >
                          >;
                        })
                    | { __typename?: 'TimelineComponent' }
                    | { __typename?: 'UserAnalyticsComponent' }
                    | { __typename?: 'UserPrioritiesComponent' }
                  >
                >
              >;
              link?: Types.Maybe<
                { __typename?: 'Link' } & Pick<
                  Types.Link,
                  'pageId' | 'absolutePath' | 'drillId' | 'drillIds' | 'target'
                > & {
                    filters?: Types.Maybe<
                      Array<
                        | ({ __typename?: 'SelectFilterValue' } & Pick<
                            Types.SelectFilterValue,
                            'id' | 'value'
                          >)
                        | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                            Types.SelectGroupFilterValue,
                            'id'
                          > & {
                              values: Array<
                                Types.Maybe<
                                  { __typename?: 'FilterCodeValue' } & Pick<
                                    Types.FilterCodeValue,
                                    'filterCode' | 'value'
                                  >
                                >
                              >;
                            })
                      >
                    >;
                  }
              >;
            }
        >
      >
    >;
  };

export type PlailistCategoryFieldsFragment = { __typename?: 'PlailistCategory' } & Pick<
  Types.PlailistCategory,
  'categoryId' | 'name'
> & {
    plailists?: Types.Maybe<
      Array<
        Types.Maybe<
          { __typename?: 'Plailist' } & Pick<Types.Plailist, 'plailistId' | 'title' | 'summary'> & {
              colorStart?: Types.Maybe<
                | ({ __typename?: 'ColorString' } & Pick<Types.ColorString, 'color'>)
                | { __typename?: 'ThemeColor' }
              >;
              colorEnd?: Types.Maybe<
                | ({ __typename?: 'ColorString' } & Pick<Types.ColorString, 'color'>)
                | { __typename?: 'ThemeColor' }
              >;
              stories?: Types.Maybe<
                Array<
                  Types.Maybe<
                    { __typename?: 'Story' } & Pick<
                      Types.Story,
                      | 'id'
                      | 'storyType'
                      | 'headline'
                      | 'recommenderHeadline'
                      | 'caption'
                      | 'sentiment'
                      | 'publishDate'
                      | 'isRead'
                      | 'tag'
                    > & {
                        content?: Types.Maybe<
                          Array<
                            Types.Maybe<
                              | { __typename?: 'CardsComponent' }
                              | ({ __typename?: 'ChartComponent' } & Pick<
                                  Types.ChartComponent,
                                  'id' | 'code' | 'componentType' | 'icon'
                                > & {
                                    filters?: Types.Maybe<
                                      Array<
                                        { __typename?: 'FilterComponent' } & Pick<
                                          Types.FilterComponent,
                                          | 'defaultValue'
                                          | 'alignment'
                                          | 'isHidden'
                                          | 'displayMode'
                                          | 'id'
                                          | 'code'
                                          | 'componentType'
                                          | 'icon'
                                        >
                                      >
                                    >;
                                  })
                              | { __typename?: 'ColumnLayoutComponent' }
                              | { __typename?: 'DialsComponent' }
                              | { __typename?: 'FilterComponent' }
                              | { __typename?: 'FiltersComponent' }
                              | { __typename?: 'GridComponent' }
                              | ({ __typename?: 'HeaderRowComponent' } & Pick<
                                  Types.HeaderRowComponent,
                                  'variant' | 'id' | 'code' | 'componentType' | 'icon'
                                > & {
                                    filters?: Types.Maybe<
                                      Array<
                                        { __typename?: 'FilterComponent' } & Pick<
                                          Types.FilterComponent,
                                          | 'defaultValue'
                                          | 'alignment'
                                          | 'isHidden'
                                          | 'displayMode'
                                          | 'id'
                                          | 'code'
                                          | 'componentType'
                                          | 'icon'
                                        >
                                      >
                                    >;
                                  })
                              | { __typename?: 'KpiComponent' }
                              | { __typename?: 'ListComponent' }
                              | ({ __typename?: 'MarkdownComponent' } & Pick<
                                  Types.MarkdownComponent,
                                  'content' | 'id' | 'code' | 'componentType' | 'icon'
                                >)
                              | { __typename?: 'MicroChartComponent' }
                              | { __typename?: 'NavigationTabsComponent' }
                              | { __typename?: 'PlailistComponent' }
                              | { __typename?: 'RecommenderComponent' }
                              | { __typename?: 'StoryListComponent' }
                              | { __typename?: 'SummaryInfoComponent' }
                              | ({ __typename?: 'TableComponent' } & Pick<
                                  Types.TableComponent,
                                  'id' | 'code' | 'componentType' | 'icon'
                                > & {
                                    filters?: Types.Maybe<
                                      Array<
                                        { __typename?: 'FilterComponent' } & Pick<
                                          Types.FilterComponent,
                                          | 'defaultValue'
                                          | 'alignment'
                                          | 'isHidden'
                                          | 'displayMode'
                                          | 'id'
                                          | 'code'
                                          | 'componentType'
                                          | 'icon'
                                        >
                                      >
                                    >;
                                  })
                              | { __typename?: 'TimelineComponent' }
                              | { __typename?: 'UserAnalyticsComponent' }
                              | { __typename?: 'UserPrioritiesComponent' }
                            >
                          >
                        >;
                        link?: Types.Maybe<
                          { __typename?: 'Link' } & Pick<
                            Types.Link,
                            'pageId' | 'absolutePath' | 'drillId' | 'drillIds' | 'target'
                          > & {
                              filters?: Types.Maybe<
                                Array<
                                  | ({ __typename?: 'SelectFilterValue' } & Pick<
                                      Types.SelectFilterValue,
                                      'id' | 'value'
                                    >)
                                  | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                                      Types.SelectGroupFilterValue,
                                      'id'
                                    > & {
                                        values: Array<
                                          Types.Maybe<
                                            { __typename?: 'FilterCodeValue' } & Pick<
                                              Types.FilterCodeValue,
                                              'filterCode' | 'value'
                                            >
                                          >
                                        >;
                                      })
                                >
                              >;
                            }
                        >;
                      }
                  >
                >
              >;
            }
        >
      >
    >;
  };

export type PriorityCategoryFieldsFragment = { __typename?: 'PriorityCategoryResult' } & Pick<
  Types.PriorityCategoryResult,
  'categoryName'
> & {
    priorities: Array<
      { __typename?: 'PriorityResult' } & Pick<
        Types.PriorityResult,
        'priorityId' | 'priorityName' | 'isFollowed' | 'sortOrder'
      >
    >;
  };

export type PriorityFieldsFragment = { __typename?: 'PriorityResult' } & Pick<
  Types.PriorityResult,
  'priorityId' | 'priorityName' | 'isFollowed' | 'sortOrder'
>;

export type ProgressBarFieldsFragment = { __typename?: 'ProgressBarResult' } & Pick<
  Types.ProgressBarResult,
  'progressValue' | 'baseValue' | 'min' | 'max' | 'sentiment' | 'inverted' | 'variant'
>;

export type SelectFilterFieldsFragment = { __typename?: 'SelectFilter' } & Pick<
  Types.SelectFilter,
  'filterCode' | 'additionalLabels'
> & {
    options?: Types.Maybe<
      Array<
        { __typename?: 'FilterOptionResult' } & Pick<
          Types.FilterOptionResult,
          'id' | 'label' | 'value' | 'isDefault' | 'additionalLabels' | 'sentiment'
        >
      >
    >;
  };

export type SelectGroupFilterFieldsFragment = { __typename?: 'SelectGroupFilter' } & Pick<
  Types.SelectGroupFilter,
  'filterCode' | 'additionalLabels'
> & {
    filters: Array<
      { __typename?: 'SelectFilter' } & Pick<Types.SelectFilter, 'id' | 'filterCode' | 'label'> & {
          options?: Types.Maybe<
            Array<
              { __typename?: 'FilterOptionResult' } & Pick<
                Types.FilterOptionResult,
                'id' | 'label' | 'value' | 'isDefault' | 'additionalLabels' | 'sentiment'
              >
            >
          >;
        }
    >;
  };

export type SimpleCardFieldsFragment = { __typename?: 'SimpleCardResult' } & Pick<
  Types.SimpleCardResult,
  'id' | 'code' | 'cardType' | 'title'
> & {
    textResult?: Types.Maybe<
      { __typename?: 'TextResult' } & Pick<Types.TextResult, 'value' | 'subLabels'>
    >;
    linkResult?: Types.Maybe<
      { __typename?: 'Link' } & Pick<
        Types.Link,
        'pageId' | 'absolutePath' | 'drillId' | 'drillIds' | 'target'
      > & {
          filters?: Types.Maybe<
            Array<
              | ({ __typename?: 'SelectFilterValue' } & Pick<
                  Types.SelectFilterValue,
                  'id' | 'value'
                >)
              | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                  Types.SelectGroupFilterValue,
                  'id'
                > & {
                    values: Array<
                      Types.Maybe<
                        { __typename?: 'FilterCodeValue' } & Pick<
                          Types.FilterCodeValue,
                          'filterCode' | 'value'
                        >
                      >
                    >;
                  })
            >
          >;
        }
    >;
  };

export type StoryFieldsFragment = { __typename?: 'Story' } & Pick<
  Types.Story,
  | 'id'
  | 'storyType'
  | 'headline'
  | 'recommenderHeadline'
  | 'caption'
  | 'sentiment'
  | 'publishDate'
  | 'isRead'
  | 'tag'
> & {
    content?: Types.Maybe<
      Array<
        Types.Maybe<
          | { __typename?: 'CardsComponent' }
          | ({ __typename?: 'ChartComponent' } & Pick<
              Types.ChartComponent,
              'id' | 'code' | 'componentType' | 'icon'
            > & {
                filters?: Types.Maybe<
                  Array<
                    { __typename?: 'FilterComponent' } & Pick<
                      Types.FilterComponent,
                      | 'defaultValue'
                      | 'alignment'
                      | 'isHidden'
                      | 'displayMode'
                      | 'id'
                      | 'code'
                      | 'componentType'
                      | 'icon'
                    >
                  >
                >;
              })
          | { __typename?: 'ColumnLayoutComponent' }
          | { __typename?: 'DialsComponent' }
          | { __typename?: 'FilterComponent' }
          | { __typename?: 'FiltersComponent' }
          | { __typename?: 'GridComponent' }
          | ({ __typename?: 'HeaderRowComponent' } & Pick<
              Types.HeaderRowComponent,
              'variant' | 'id' | 'code' | 'componentType' | 'icon'
            > & {
                filters?: Types.Maybe<
                  Array<
                    { __typename?: 'FilterComponent' } & Pick<
                      Types.FilterComponent,
                      | 'defaultValue'
                      | 'alignment'
                      | 'isHidden'
                      | 'displayMode'
                      | 'id'
                      | 'code'
                      | 'componentType'
                      | 'icon'
                    >
                  >
                >;
              })
          | { __typename?: 'KpiComponent' }
          | { __typename?: 'ListComponent' }
          | ({ __typename?: 'MarkdownComponent' } & Pick<
              Types.MarkdownComponent,
              'content' | 'id' | 'code' | 'componentType' | 'icon'
            >)
          | { __typename?: 'MicroChartComponent' }
          | { __typename?: 'NavigationTabsComponent' }
          | { __typename?: 'PlailistComponent' }
          | { __typename?: 'RecommenderComponent' }
          | { __typename?: 'StoryListComponent' }
          | { __typename?: 'SummaryInfoComponent' }
          | ({ __typename?: 'TableComponent' } & Pick<
              Types.TableComponent,
              'id' | 'code' | 'componentType' | 'icon'
            > & {
                filters?: Types.Maybe<
                  Array<
                    { __typename?: 'FilterComponent' } & Pick<
                      Types.FilterComponent,
                      | 'defaultValue'
                      | 'alignment'
                      | 'isHidden'
                      | 'displayMode'
                      | 'id'
                      | 'code'
                      | 'componentType'
                      | 'icon'
                    >
                  >
                >;
              })
          | { __typename?: 'TimelineComponent' }
          | { __typename?: 'UserAnalyticsComponent' }
          | { __typename?: 'UserPrioritiesComponent' }
        >
      >
    >;
    link?: Types.Maybe<
      { __typename?: 'Link' } & Pick<
        Types.Link,
        'pageId' | 'absolutePath' | 'drillId' | 'drillIds' | 'target'
      > & {
          filters?: Types.Maybe<
            Array<
              | ({ __typename?: 'SelectFilterValue' } & Pick<
                  Types.SelectFilterValue,
                  'id' | 'value'
                >)
              | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                  Types.SelectGroupFilterValue,
                  'id'
                > & {
                    values: Array<
                      Types.Maybe<
                        { __typename?: 'FilterCodeValue' } & Pick<
                          Types.FilterCodeValue,
                          'filterCode' | 'value'
                        >
                      >
                    >;
                  })
            >
          >;
        }
    >;
  };

export type SummaryInfoItemFieldsFragment = { __typename?: 'SummaryInfoItem' } & {
  header:
    | { __typename?: 'DrillDownResult' }
    | { __typename?: 'KpiStatus' }
    | { __typename?: 'KpiStatusOverview' }
    | { __typename?: 'LocationMarkerResult' }
    | { __typename?: 'MicroChartResult' }
    | { __typename?: 'ProgressBarResult' }
    | ({ __typename?: 'TextLinkResult' } & Pick<
        Types.TextLinkResult,
        'value' | 'label' | 'subLabels' | 'linkDirection'
      > & {
          linkResult?: Types.Maybe<
            { __typename?: 'Link' } & Pick<
              Types.Link,
              'pageId' | 'absolutePath' | 'drillId' | 'drillIds' | 'target'
            > & {
                filters?: Types.Maybe<
                  Array<
                    | ({ __typename?: 'SelectFilterValue' } & Pick<
                        Types.SelectFilterValue,
                        'id' | 'value'
                      >)
                    | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                        Types.SelectGroupFilterValue,
                        'id'
                      > & {
                          values: Array<
                            Types.Maybe<
                              { __typename?: 'FilterCodeValue' } & Pick<
                                Types.FilterCodeValue,
                                'filterCode' | 'value'
                              >
                            >
                          >;
                        })
                  >
                >;
              }
          >;
        })
    | ({ __typename?: 'TextResult' } & Pick<Types.TextResult, 'value'>)
    | { __typename?: 'Timeline' }
    | { __typename?: 'TimelineDataPoint' }
    | { __typename?: 'TimelinePhases' }
    | { __typename?: 'VarianceResult' };
  content:
    | { __typename?: 'DrillDownResult' }
    | { __typename?: 'KpiStatus' }
    | { __typename?: 'KpiStatusOverview' }
    | { __typename?: 'LocationMarkerResult' }
    | { __typename?: 'MicroChartResult' }
    | { __typename?: 'ProgressBarResult' }
    | { __typename?: 'TextLinkResult' }
    | ({ __typename?: 'TextResult' } & Pick<Types.TextResult, 'value'>)
    | { __typename?: 'Timeline' }
    | { __typename?: 'TimelineDataPoint' }
    | { __typename?: 'TimelinePhases' }
    | { __typename?: 'VarianceResult' };
};

export type TabFieldsFragment = { __typename?: 'Tab' } & Pick<
  Types.Tab,
  'title' | 'path' | 'pageId'
>;

export type TableCellContentFields_DrillDownResult_Fragment = {
  __typename?: 'DrillDownResult';
} & Pick<
  Types.DrillDownResult,
  'value' | 'subLabels' | 'drillUpId' | 'drillDownId' | 'drillUpIds' | 'drillDownIds'
>;

export type TableCellContentFields_KpiStatus_Fragment = { __typename?: 'KpiStatus' };

export type TableCellContentFields_KpiStatusOverview_Fragment = {
  __typename?: 'KpiStatusOverview';
} & {
  items?: Types.Maybe<
    Array<
      { __typename?: 'KpiStatus' } & Pick<
        Types.KpiStatus,
        'actual' | 'target' | 'sentiment' | 'icon'
      >
    >
  >;
  tooltip: { __typename?: 'TableContent' } & Pick<Types.TableContent, 'title'> & {
      columns?: Types.Maybe<
        Array<
          { __typename?: 'TableColumnResult' } & Pick<
            Types.TableColumnResult,
            'columnId' | 'dataKey' | 'title'
          > & {
              options?: Types.Maybe<
                { __typename?: 'TableColumnOptionsResult' } & Pick<
                  Types.TableColumnOptionsResult,
                  | 'alignment'
                  | 'selectDefault'
                  | 'selectDisplayDefault'
                  | 'selectDisplayGroupId'
                  | 'selectGroupId'
                  | 'toggleDefault'
                  | 'toggleGroup'
                  | 'width'
                >
              >;
            }
        >
      >;
      rows?: Types.Maybe<
        Array<
          { __typename?: 'TableRowResult' } & Pick<Types.TableRowResult, 'rowId' | 'rowType'> & {
              cells?: Types.Maybe<
                Array<
                  { __typename?: 'TableCellResult' } & Pick<
                    Types.TableCellResult,
                    'columnDataKey'
                  > & {
                      cellContent?: Types.Maybe<
                        | { __typename?: 'DrillDownResult' }
                        | ({ __typename?: 'KpiStatus' } & Pick<
                            Types.KpiStatus,
                            'text' | 'icon' | 'sentiment'
                          >)
                        | { __typename?: 'KpiStatusOverview' }
                        | { __typename?: 'LocationMarkerResult' }
                        | { __typename?: 'MicroChartResult' }
                        | { __typename?: 'ProgressBarResult' }
                        | { __typename?: 'TextLinkResult' }
                        | ({ __typename?: 'TextResult' } & Pick<Types.TextResult, 'value'>)
                        | { __typename?: 'Timeline' }
                        | { __typename?: 'TimelineDataPoint' }
                        | { __typename?: 'TimelinePhases' }
                        | { __typename?: 'VarianceResult' }
                      >;
                    }
                >
              >;
            }
        >
      >;
    };
};

export type TableCellContentFields_LocationMarkerResult_Fragment = {
  __typename?: 'LocationMarkerResult';
} & Pick<Types.LocationMarkerResult, 'text'> & {
    position?: Types.Maybe<
      { __typename?: 'GeolocationPositionResult' } & {
        coordinates?: Types.Maybe<
          { __typename?: 'GeolocationCoordinatesResult' } & Pick<
            Types.GeolocationCoordinatesResult,
            'latitude' | 'longitude'
          >
        >;
      }
    >;
  };

export type TableCellContentFields_MicroChartResult_Fragment = {
  __typename?: 'MicroChartResult';
} & Pick<Types.MicroChartResult, 'seriesData' | 'trendlineData' | 'sentiment'> & {
    linkResult?: Types.Maybe<
      { __typename?: 'Link' } & Pick<
        Types.Link,
        'pageId' | 'absolutePath' | 'drillId' | 'drillIds' | 'target'
      > & {
          filters?: Types.Maybe<
            Array<
              | ({ __typename?: 'SelectFilterValue' } & Pick<
                  Types.SelectFilterValue,
                  'id' | 'value'
                >)
              | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                  Types.SelectGroupFilterValue,
                  'id'
                > & {
                    values: Array<
                      Types.Maybe<
                        { __typename?: 'FilterCodeValue' } & Pick<
                          Types.FilterCodeValue,
                          'filterCode' | 'value'
                        >
                      >
                    >;
                  })
            >
          >;
        }
    >;
  };

export type TableCellContentFields_ProgressBarResult_Fragment = {
  __typename?: 'ProgressBarResult';
} & Pick<
  Types.ProgressBarResult,
  'progressValue' | 'baseValue' | 'min' | 'max' | 'sentiment' | 'inverted' | 'variant'
>;

export type TableCellContentFields_TextLinkResult_Fragment = {
  __typename?: 'TextLinkResult';
} & Pick<Types.TextLinkResult, 'value' | 'label' | 'subLabels' | 'linkDirection'> & {
    linkResult?: Types.Maybe<
      { __typename?: 'Link' } & Pick<
        Types.Link,
        'pageId' | 'absolutePath' | 'drillId' | 'drillIds' | 'target'
      > & {
          filters?: Types.Maybe<
            Array<
              | ({ __typename?: 'SelectFilterValue' } & Pick<
                  Types.SelectFilterValue,
                  'id' | 'value'
                >)
              | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                  Types.SelectGroupFilterValue,
                  'id'
                > & {
                    values: Array<
                      Types.Maybe<
                        { __typename?: 'FilterCodeValue' } & Pick<
                          Types.FilterCodeValue,
                          'filterCode' | 'value'
                        >
                      >
                    >;
                  })
            >
          >;
        }
    >;
  };

export type TableCellContentFields_TextResult_Fragment = { __typename?: 'TextResult' } & Pick<
  Types.TextResult,
  'value' | 'subLabels'
>;

export type TableCellContentFields_Timeline_Fragment = { __typename?: 'Timeline' } & {
  axis: { __typename?: 'TimelineAxis' } & Pick<Types.TimelineAxis, 'minX' | 'maxX' | 'categories'>;
  intervals?: Types.Maybe<
    Array<
      { __typename?: 'TimelineInterval' } & Pick<Types.TimelineInterval, 'name' | 'x1' | 'x2'> & {
          color: { __typename?: 'ThemeColor' } & Pick<Types.ThemeColor, 'color'>;
        }
    >
  >;
  series?: Types.Maybe<
    Array<
      { __typename?: 'TimelineSeries' } & Pick<
        Types.TimelineSeries,
        'id' | 'name' | 'sortOrder'
      > & {
          marker: { __typename?: 'TimelineMarker' } & Pick<
            Types.TimelineMarker,
            'symbol' | 'isFilled'
          > & { color: { __typename?: 'ThemeColor' } & Pick<Types.ThemeColor, 'color'> };
          data?: Types.Maybe<
            Array<
              { __typename?: 'TimelineDataPoint' } & Pick<
                Types.TimelineDataPoint,
                'x' | 'formattedX' | 'label'
              > & {
                  marker?: Types.Maybe<
                    { __typename?: 'TimelineMarker' } & Pick<
                      Types.TimelineMarker,
                      'symbol' | 'isFilled'
                    > & { color: { __typename?: 'ThemeColor' } & Pick<Types.ThemeColor, 'color'> }
                  >;
                  tooltip?: Types.Maybe<
                    { __typename?: 'TableContent' } & Pick<Types.TableContent, 'title'> & {
                        columns?: Types.Maybe<
                          Array<
                            { __typename?: 'TableColumnResult' } & Pick<
                              Types.TableColumnResult,
                              'columnId' | 'dataKey' | 'title'
                            > & {
                                options?: Types.Maybe<
                                  { __typename?: 'TableColumnOptionsResult' } & Pick<
                                    Types.TableColumnOptionsResult,
                                    | 'alignment'
                                    | 'selectDefault'
                                    | 'selectDisplayDefault'
                                    | 'selectDisplayGroupId'
                                    | 'selectGroupId'
                                    | 'toggleDefault'
                                    | 'toggleGroup'
                                    | 'width'
                                  >
                                >;
                              }
                          >
                        >;
                        rows?: Types.Maybe<
                          Array<
                            { __typename?: 'TableRowResult' } & Pick<
                              Types.TableRowResult,
                              'rowId' | 'rowType'
                            > & {
                                cells?: Types.Maybe<
                                  Array<
                                    { __typename?: 'TableCellResult' } & Pick<
                                      Types.TableCellResult,
                                      'columnDataKey'
                                    > & {
                                        cellContent?: Types.Maybe<
                                          | { __typename?: 'DrillDownResult' }
                                          | ({ __typename?: 'KpiStatus' } & Pick<
                                              Types.KpiStatus,
                                              'text' | 'icon' | 'sentiment'
                                            >)
                                          | { __typename?: 'KpiStatusOverview' }
                                          | { __typename?: 'LocationMarkerResult' }
                                          | { __typename?: 'MicroChartResult' }
                                          | { __typename?: 'ProgressBarResult' }
                                          | { __typename?: 'TextLinkResult' }
                                          | ({ __typename?: 'TextResult' } & Pick<
                                              Types.TextResult,
                                              'value'
                                            >)
                                          | { __typename?: 'Timeline' }
                                          | { __typename?: 'TimelineDataPoint' }
                                          | { __typename?: 'TimelinePhases' }
                                          | { __typename?: 'VarianceResult' }
                                        >;
                                      }
                                  >
                                >;
                              }
                          >
                        >;
                      }
                  >;
                }
            >
          >;
        }
    >
  >;
};

export type TableCellContentFields_TimelineDataPoint_Fragment = {
  __typename?: 'TimelineDataPoint';
};

export type TableCellContentFields_TimelinePhases_Fragment = { __typename?: 'TimelinePhases' } & {
  axis: { __typename?: 'TimelineAxis' } & Pick<Types.TimelineAxis, 'minX' | 'maxX' | 'categories'>;
  intervals?: Types.Maybe<
    Array<
      { __typename?: 'TimelineInterval' } & Pick<Types.TimelineInterval, 'name' | 'x1' | 'x2'> & {
          color: { __typename?: 'ThemeColor' } & Pick<Types.ThemeColor, 'color'>;
        }
    >
  >;
};

export type TableCellContentFields_VarianceResult_Fragment = {
  __typename?: 'VarianceResult';
} & Pick<Types.VarianceResult, 'actual' | 'target' | 'sentiment' | 'label'>;

export type TableCellContentFieldsFragment =
  | TableCellContentFields_DrillDownResult_Fragment
  | TableCellContentFields_KpiStatus_Fragment
  | TableCellContentFields_KpiStatusOverview_Fragment
  | TableCellContentFields_LocationMarkerResult_Fragment
  | TableCellContentFields_MicroChartResult_Fragment
  | TableCellContentFields_ProgressBarResult_Fragment
  | TableCellContentFields_TextLinkResult_Fragment
  | TableCellContentFields_TextResult_Fragment
  | TableCellContentFields_Timeline_Fragment
  | TableCellContentFields_TimelineDataPoint_Fragment
  | TableCellContentFields_TimelinePhases_Fragment
  | TableCellContentFields_VarianceResult_Fragment;

export type TableCellFieldsFragment = { __typename?: 'TableCellResult' } & Pick<
  Types.TableCellResult,
  'columnDataKey'
> & {
    cellContent?: Types.Maybe<
      | ({ __typename?: 'DrillDownResult' } & Pick<
          Types.DrillDownResult,
          'value' | 'subLabels' | 'drillUpId' | 'drillDownId' | 'drillUpIds' | 'drillDownIds'
        >)
      | { __typename?: 'KpiStatus' }
      | ({ __typename?: 'KpiStatusOverview' } & {
          items?: Types.Maybe<
            Array<
              { __typename?: 'KpiStatus' } & Pick<
                Types.KpiStatus,
                'actual' | 'target' | 'sentiment' | 'icon'
              >
            >
          >;
          tooltip: { __typename?: 'TableContent' } & Pick<Types.TableContent, 'title'> & {
              columns?: Types.Maybe<
                Array<
                  { __typename?: 'TableColumnResult' } & Pick<
                    Types.TableColumnResult,
                    'columnId' | 'dataKey' | 'title'
                  > & {
                      options?: Types.Maybe<
                        { __typename?: 'TableColumnOptionsResult' } & Pick<
                          Types.TableColumnOptionsResult,
                          | 'alignment'
                          | 'selectDefault'
                          | 'selectDisplayDefault'
                          | 'selectDisplayGroupId'
                          | 'selectGroupId'
                          | 'toggleDefault'
                          | 'toggleGroup'
                          | 'width'
                        >
                      >;
                    }
                >
              >;
              rows?: Types.Maybe<
                Array<
                  { __typename?: 'TableRowResult' } & Pick<
                    Types.TableRowResult,
                    'rowId' | 'rowType'
                  > & {
                      cells?: Types.Maybe<
                        Array<
                          { __typename?: 'TableCellResult' } & Pick<
                            Types.TableCellResult,
                            'columnDataKey'
                          > & {
                              cellContent?: Types.Maybe<
                                | { __typename?: 'DrillDownResult' }
                                | ({ __typename?: 'KpiStatus' } & Pick<
                                    Types.KpiStatus,
                                    'text' | 'icon' | 'sentiment'
                                  >)
                                | { __typename?: 'KpiStatusOverview' }
                                | { __typename?: 'LocationMarkerResult' }
                                | { __typename?: 'MicroChartResult' }
                                | { __typename?: 'ProgressBarResult' }
                                | { __typename?: 'TextLinkResult' }
                                | ({ __typename?: 'TextResult' } & Pick<Types.TextResult, 'value'>)
                                | { __typename?: 'Timeline' }
                                | { __typename?: 'TimelineDataPoint' }
                                | { __typename?: 'TimelinePhases' }
                                | { __typename?: 'VarianceResult' }
                              >;
                            }
                        >
                      >;
                    }
                >
              >;
            };
        })
      | ({ __typename?: 'LocationMarkerResult' } & Pick<Types.LocationMarkerResult, 'text'> & {
            position?: Types.Maybe<
              { __typename?: 'GeolocationPositionResult' } & {
                coordinates?: Types.Maybe<
                  { __typename?: 'GeolocationCoordinatesResult' } & Pick<
                    Types.GeolocationCoordinatesResult,
                    'latitude' | 'longitude'
                  >
                >;
              }
            >;
          })
      | ({ __typename?: 'MicroChartResult' } & Pick<
          Types.MicroChartResult,
          'seriesData' | 'trendlineData' | 'sentiment'
        > & {
            linkResult?: Types.Maybe<
              { __typename?: 'Link' } & Pick<
                Types.Link,
                'pageId' | 'absolutePath' | 'drillId' | 'drillIds' | 'target'
              > & {
                  filters?: Types.Maybe<
                    Array<
                      | ({ __typename?: 'SelectFilterValue' } & Pick<
                          Types.SelectFilterValue,
                          'id' | 'value'
                        >)
                      | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                          Types.SelectGroupFilterValue,
                          'id'
                        > & {
                            values: Array<
                              Types.Maybe<
                                { __typename?: 'FilterCodeValue' } & Pick<
                                  Types.FilterCodeValue,
                                  'filterCode' | 'value'
                                >
                              >
                            >;
                          })
                    >
                  >;
                }
            >;
          })
      | ({ __typename?: 'ProgressBarResult' } & Pick<
          Types.ProgressBarResult,
          'progressValue' | 'baseValue' | 'min' | 'max' | 'sentiment' | 'inverted' | 'variant'
        >)
      | ({ __typename?: 'TextLinkResult' } & Pick<
          Types.TextLinkResult,
          'value' | 'label' | 'subLabels' | 'linkDirection'
        > & {
            linkResult?: Types.Maybe<
              { __typename?: 'Link' } & Pick<
                Types.Link,
                'pageId' | 'absolutePath' | 'drillId' | 'drillIds' | 'target'
              > & {
                  filters?: Types.Maybe<
                    Array<
                      | ({ __typename?: 'SelectFilterValue' } & Pick<
                          Types.SelectFilterValue,
                          'id' | 'value'
                        >)
                      | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                          Types.SelectGroupFilterValue,
                          'id'
                        > & {
                            values: Array<
                              Types.Maybe<
                                { __typename?: 'FilterCodeValue' } & Pick<
                                  Types.FilterCodeValue,
                                  'filterCode' | 'value'
                                >
                              >
                            >;
                          })
                    >
                  >;
                }
            >;
          })
      | ({ __typename?: 'TextResult' } & Pick<Types.TextResult, 'value' | 'subLabels'>)
      | ({ __typename?: 'Timeline' } & {
          axis: { __typename?: 'TimelineAxis' } & Pick<
            Types.TimelineAxis,
            'minX' | 'maxX' | 'categories'
          >;
          intervals?: Types.Maybe<
            Array<
              { __typename?: 'TimelineInterval' } & Pick<
                Types.TimelineInterval,
                'name' | 'x1' | 'x2'
              > & { color: { __typename?: 'ThemeColor' } & Pick<Types.ThemeColor, 'color'> }
            >
          >;
          series?: Types.Maybe<
            Array<
              { __typename?: 'TimelineSeries' } & Pick<
                Types.TimelineSeries,
                'id' | 'name' | 'sortOrder'
              > & {
                  marker: { __typename?: 'TimelineMarker' } & Pick<
                    Types.TimelineMarker,
                    'symbol' | 'isFilled'
                  > & { color: { __typename?: 'ThemeColor' } & Pick<Types.ThemeColor, 'color'> };
                  data?: Types.Maybe<
                    Array<
                      { __typename?: 'TimelineDataPoint' } & Pick<
                        Types.TimelineDataPoint,
                        'x' | 'formattedX' | 'label'
                      > & {
                          marker?: Types.Maybe<
                            { __typename?: 'TimelineMarker' } & Pick<
                              Types.TimelineMarker,
                              'symbol' | 'isFilled'
                            > & {
                                color: { __typename?: 'ThemeColor' } & Pick<
                                  Types.ThemeColor,
                                  'color'
                                >;
                              }
                          >;
                          tooltip?: Types.Maybe<
                            { __typename?: 'TableContent' } & Pick<Types.TableContent, 'title'> & {
                                columns?: Types.Maybe<
                                  Array<
                                    { __typename?: 'TableColumnResult' } & Pick<
                                      Types.TableColumnResult,
                                      'columnId' | 'dataKey' | 'title'
                                    > & {
                                        options?: Types.Maybe<
                                          { __typename?: 'TableColumnOptionsResult' } & Pick<
                                            Types.TableColumnOptionsResult,
                                            | 'alignment'
                                            | 'selectDefault'
                                            | 'selectDisplayDefault'
                                            | 'selectDisplayGroupId'
                                            | 'selectGroupId'
                                            | 'toggleDefault'
                                            | 'toggleGroup'
                                            | 'width'
                                          >
                                        >;
                                      }
                                  >
                                >;
                                rows?: Types.Maybe<
                                  Array<
                                    { __typename?: 'TableRowResult' } & Pick<
                                      Types.TableRowResult,
                                      'rowId' | 'rowType'
                                    > & {
                                        cells?: Types.Maybe<
                                          Array<
                                            { __typename?: 'TableCellResult' } & Pick<
                                              Types.TableCellResult,
                                              'columnDataKey'
                                            > & {
                                                cellContent?: Types.Maybe<
                                                  | { __typename?: 'DrillDownResult' }
                                                  | ({ __typename?: 'KpiStatus' } & Pick<
                                                      Types.KpiStatus,
                                                      'text' | 'icon' | 'sentiment'
                                                    >)
                                                  | { __typename?: 'KpiStatusOverview' }
                                                  | { __typename?: 'LocationMarkerResult' }
                                                  | { __typename?: 'MicroChartResult' }
                                                  | { __typename?: 'ProgressBarResult' }
                                                  | { __typename?: 'TextLinkResult' }
                                                  | ({ __typename?: 'TextResult' } & Pick<
                                                      Types.TextResult,
                                                      'value'
                                                    >)
                                                  | { __typename?: 'Timeline' }
                                                  | { __typename?: 'TimelineDataPoint' }
                                                  | { __typename?: 'TimelinePhases' }
                                                  | { __typename?: 'VarianceResult' }
                                                >;
                                              }
                                          >
                                        >;
                                      }
                                  >
                                >;
                              }
                          >;
                        }
                    >
                  >;
                }
            >
          >;
        })
      | { __typename?: 'TimelineDataPoint' }
      | ({ __typename?: 'TimelinePhases' } & {
          axis: { __typename?: 'TimelineAxis' } & Pick<
            Types.TimelineAxis,
            'minX' | 'maxX' | 'categories'
          >;
          intervals?: Types.Maybe<
            Array<
              { __typename?: 'TimelineInterval' } & Pick<
                Types.TimelineInterval,
                'name' | 'x1' | 'x2'
              > & { color: { __typename?: 'ThemeColor' } & Pick<Types.ThemeColor, 'color'> }
            >
          >;
        })
      | ({ __typename?: 'VarianceResult' } & Pick<
          Types.VarianceResult,
          'actual' | 'target' | 'sentiment' | 'label'
        >)
    >;
  };

export type TableColumnOptionsFieldsFragment = { __typename?: 'TableColumnOptionsResult' } & Pick<
  Types.TableColumnOptionsResult,
  | 'alignment'
  | 'selectDefault'
  | 'selectDisplayDefault'
  | 'selectDisplayGroupId'
  | 'selectGroupId'
  | 'toggleDefault'
  | 'toggleGroup'
  | 'width'
>;

export type TableColumnFieldsFragment = { __typename?: 'TableColumnResult' } & Pick<
  Types.TableColumnResult,
  'columnId' | 'dataKey' | 'title'
> & {
    options?: Types.Maybe<
      { __typename?: 'TableColumnOptionsResult' } & Pick<
        Types.TableColumnOptionsResult,
        | 'alignment'
        | 'selectDefault'
        | 'selectDisplayDefault'
        | 'selectDisplayGroupId'
        | 'selectGroupId'
        | 'toggleDefault'
        | 'toggleGroup'
        | 'width'
      >
    >;
  };

export type TableComponentFieldsFragment = { __typename?: 'TableComponent' } & Pick<
  Types.TableComponent,
  'id' | 'code' | 'componentType' | 'icon'
> & {
    filters?: Types.Maybe<
      Array<
        { __typename?: 'FilterComponent' } & Pick<
          Types.FilterComponent,
          | 'defaultValue'
          | 'alignment'
          | 'isHidden'
          | 'displayMode'
          | 'id'
          | 'code'
          | 'componentType'
          | 'icon'
        >
      >
    >;
  };

export type TableRowFieldsFragment = { __typename?: 'TableRowResult' } & Pick<
  Types.TableRowResult,
  'rowId' | 'rowType' | 'groupFirstChild'
> & {
    cells?: Types.Maybe<
      Array<
        { __typename?: 'TableCellResult' } & Pick<Types.TableCellResult, 'columnDataKey'> & {
            cellContent?: Types.Maybe<
              | ({ __typename?: 'DrillDownResult' } & Pick<
                  Types.DrillDownResult,
                  | 'value'
                  | 'subLabels'
                  | 'drillUpId'
                  | 'drillDownId'
                  | 'drillUpIds'
                  | 'drillDownIds'
                >)
              | { __typename?: 'KpiStatus' }
              | ({ __typename?: 'KpiStatusOverview' } & {
                  items?: Types.Maybe<
                    Array<
                      { __typename?: 'KpiStatus' } & Pick<
                        Types.KpiStatus,
                        'actual' | 'target' | 'sentiment' | 'icon'
                      >
                    >
                  >;
                  tooltip: { __typename?: 'TableContent' } & Pick<Types.TableContent, 'title'> & {
                      columns?: Types.Maybe<
                        Array<
                          { __typename?: 'TableColumnResult' } & Pick<
                            Types.TableColumnResult,
                            'columnId' | 'dataKey' | 'title'
                          > & {
                              options?: Types.Maybe<
                                { __typename?: 'TableColumnOptionsResult' } & Pick<
                                  Types.TableColumnOptionsResult,
                                  | 'alignment'
                                  | 'selectDefault'
                                  | 'selectDisplayDefault'
                                  | 'selectDisplayGroupId'
                                  | 'selectGroupId'
                                  | 'toggleDefault'
                                  | 'toggleGroup'
                                  | 'width'
                                >
                              >;
                            }
                        >
                      >;
                      rows?: Types.Maybe<
                        Array<
                          { __typename?: 'TableRowResult' } & Pick<
                            Types.TableRowResult,
                            'rowId' | 'rowType'
                          > & {
                              cells?: Types.Maybe<
                                Array<
                                  { __typename?: 'TableCellResult' } & Pick<
                                    Types.TableCellResult,
                                    'columnDataKey'
                                  > & {
                                      cellContent?: Types.Maybe<
                                        | { __typename?: 'DrillDownResult' }
                                        | ({ __typename?: 'KpiStatus' } & Pick<
                                            Types.KpiStatus,
                                            'text' | 'icon' | 'sentiment'
                                          >)
                                        | { __typename?: 'KpiStatusOverview' }
                                        | { __typename?: 'LocationMarkerResult' }
                                        | { __typename?: 'MicroChartResult' }
                                        | { __typename?: 'ProgressBarResult' }
                                        | { __typename?: 'TextLinkResult' }
                                        | ({ __typename?: 'TextResult' } & Pick<
                                            Types.TextResult,
                                            'value'
                                          >)
                                        | { __typename?: 'Timeline' }
                                        | { __typename?: 'TimelineDataPoint' }
                                        | { __typename?: 'TimelinePhases' }
                                        | { __typename?: 'VarianceResult' }
                                      >;
                                    }
                                >
                              >;
                            }
                        >
                      >;
                    };
                })
              | ({ __typename?: 'LocationMarkerResult' } & Pick<
                  Types.LocationMarkerResult,
                  'text'
                > & {
                    position?: Types.Maybe<
                      { __typename?: 'GeolocationPositionResult' } & {
                        coordinates?: Types.Maybe<
                          { __typename?: 'GeolocationCoordinatesResult' } & Pick<
                            Types.GeolocationCoordinatesResult,
                            'latitude' | 'longitude'
                          >
                        >;
                      }
                    >;
                  })
              | ({ __typename?: 'MicroChartResult' } & Pick<
                  Types.MicroChartResult,
                  'seriesData' | 'trendlineData' | 'sentiment'
                > & {
                    linkResult?: Types.Maybe<
                      { __typename?: 'Link' } & Pick<
                        Types.Link,
                        'pageId' | 'absolutePath' | 'drillId' | 'drillIds' | 'target'
                      > & {
                          filters?: Types.Maybe<
                            Array<
                              | ({ __typename?: 'SelectFilterValue' } & Pick<
                                  Types.SelectFilterValue,
                                  'id' | 'value'
                                >)
                              | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                                  Types.SelectGroupFilterValue,
                                  'id'
                                > & {
                                    values: Array<
                                      Types.Maybe<
                                        { __typename?: 'FilterCodeValue' } & Pick<
                                          Types.FilterCodeValue,
                                          'filterCode' | 'value'
                                        >
                                      >
                                    >;
                                  })
                            >
                          >;
                        }
                    >;
                  })
              | ({ __typename?: 'ProgressBarResult' } & Pick<
                  Types.ProgressBarResult,
                  | 'progressValue'
                  | 'baseValue'
                  | 'min'
                  | 'max'
                  | 'sentiment'
                  | 'inverted'
                  | 'variant'
                >)
              | ({ __typename?: 'TextLinkResult' } & Pick<
                  Types.TextLinkResult,
                  'value' | 'label' | 'subLabels' | 'linkDirection'
                > & {
                    linkResult?: Types.Maybe<
                      { __typename?: 'Link' } & Pick<
                        Types.Link,
                        'pageId' | 'absolutePath' | 'drillId' | 'drillIds' | 'target'
                      > & {
                          filters?: Types.Maybe<
                            Array<
                              | ({ __typename?: 'SelectFilterValue' } & Pick<
                                  Types.SelectFilterValue,
                                  'id' | 'value'
                                >)
                              | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                                  Types.SelectGroupFilterValue,
                                  'id'
                                > & {
                                    values: Array<
                                      Types.Maybe<
                                        { __typename?: 'FilterCodeValue' } & Pick<
                                          Types.FilterCodeValue,
                                          'filterCode' | 'value'
                                        >
                                      >
                                    >;
                                  })
                            >
                          >;
                        }
                    >;
                  })
              | ({ __typename?: 'TextResult' } & Pick<Types.TextResult, 'value' | 'subLabels'>)
              | ({ __typename?: 'Timeline' } & {
                  axis: { __typename?: 'TimelineAxis' } & Pick<
                    Types.TimelineAxis,
                    'minX' | 'maxX' | 'categories'
                  >;
                  intervals?: Types.Maybe<
                    Array<
                      { __typename?: 'TimelineInterval' } & Pick<
                        Types.TimelineInterval,
                        'name' | 'x1' | 'x2'
                      > & { color: { __typename?: 'ThemeColor' } & Pick<Types.ThemeColor, 'color'> }
                    >
                  >;
                  series?: Types.Maybe<
                    Array<
                      { __typename?: 'TimelineSeries' } & Pick<
                        Types.TimelineSeries,
                        'id' | 'name' | 'sortOrder'
                      > & {
                          marker: { __typename?: 'TimelineMarker' } & Pick<
                            Types.TimelineMarker,
                            'symbol' | 'isFilled'
                          > & {
                              color: { __typename?: 'ThemeColor' } & Pick<
                                Types.ThemeColor,
                                'color'
                              >;
                            };
                          data?: Types.Maybe<
                            Array<
                              { __typename?: 'TimelineDataPoint' } & Pick<
                                Types.TimelineDataPoint,
                                'x' | 'formattedX' | 'label'
                              > & {
                                  marker?: Types.Maybe<
                                    { __typename?: 'TimelineMarker' } & Pick<
                                      Types.TimelineMarker,
                                      'symbol' | 'isFilled'
                                    > & {
                                        color: { __typename?: 'ThemeColor' } & Pick<
                                          Types.ThemeColor,
                                          'color'
                                        >;
                                      }
                                  >;
                                  tooltip?: Types.Maybe<
                                    { __typename?: 'TableContent' } & Pick<
                                      Types.TableContent,
                                      'title'
                                    > & {
                                        columns?: Types.Maybe<
                                          Array<
                                            { __typename?: 'TableColumnResult' } & Pick<
                                              Types.TableColumnResult,
                                              'columnId' | 'dataKey' | 'title'
                                            > & {
                                                options?: Types.Maybe<
                                                  {
                                                    __typename?: 'TableColumnOptionsResult';
                                                  } & Pick<
                                                    Types.TableColumnOptionsResult,
                                                    | 'alignment'
                                                    | 'selectDefault'
                                                    | 'selectDisplayDefault'
                                                    | 'selectDisplayGroupId'
                                                    | 'selectGroupId'
                                                    | 'toggleDefault'
                                                    | 'toggleGroup'
                                                    | 'width'
                                                  >
                                                >;
                                              }
                                          >
                                        >;
                                        rows?: Types.Maybe<
                                          Array<
                                            { __typename?: 'TableRowResult' } & Pick<
                                              Types.TableRowResult,
                                              'rowId' | 'rowType'
                                            > & {
                                                cells?: Types.Maybe<
                                                  Array<
                                                    { __typename?: 'TableCellResult' } & Pick<
                                                      Types.TableCellResult,
                                                      'columnDataKey'
                                                    > & {
                                                        cellContent?: Types.Maybe<
                                                          | { __typename?: 'DrillDownResult' }
                                                          | ({ __typename?: 'KpiStatus' } & Pick<
                                                              Types.KpiStatus,
                                                              'text' | 'icon' | 'sentiment'
                                                            >)
                                                          | { __typename?: 'KpiStatusOverview' }
                                                          | { __typename?: 'LocationMarkerResult' }
                                                          | { __typename?: 'MicroChartResult' }
                                                          | { __typename?: 'ProgressBarResult' }
                                                          | { __typename?: 'TextLinkResult' }
                                                          | ({ __typename?: 'TextResult' } & Pick<
                                                              Types.TextResult,
                                                              'value'
                                                            >)
                                                          | { __typename?: 'Timeline' }
                                                          | { __typename?: 'TimelineDataPoint' }
                                                          | { __typename?: 'TimelinePhases' }
                                                          | { __typename?: 'VarianceResult' }
                                                        >;
                                                      }
                                                  >
                                                >;
                                              }
                                          >
                                        >;
                                      }
                                  >;
                                }
                            >
                          >;
                        }
                    >
                  >;
                })
              | { __typename?: 'TimelineDataPoint' }
              | ({ __typename?: 'TimelinePhases' } & {
                  axis: { __typename?: 'TimelineAxis' } & Pick<
                    Types.TimelineAxis,
                    'minX' | 'maxX' | 'categories'
                  >;
                  intervals?: Types.Maybe<
                    Array<
                      { __typename?: 'TimelineInterval' } & Pick<
                        Types.TimelineInterval,
                        'name' | 'x1' | 'x2'
                      > & { color: { __typename?: 'ThemeColor' } & Pick<Types.ThemeColor, 'color'> }
                    >
                  >;
                })
              | ({ __typename?: 'VarianceResult' } & Pick<
                  Types.VarianceResult,
                  'actual' | 'target' | 'sentiment' | 'label'
                >)
            >;
          }
      >
    >;
  };

export type TextFieldsFragment = { __typename?: 'TextResult' } & Pick<
  Types.TextResult,
  'value' | 'subLabels'
>;

export type TextLinkFieldsFragment = { __typename?: 'TextLinkResult' } & Pick<
  Types.TextLinkResult,
  'value' | 'label' | 'subLabels' | 'linkDirection'
> & {
    linkResult?: Types.Maybe<
      { __typename?: 'Link' } & Pick<
        Types.Link,
        'pageId' | 'absolutePath' | 'drillId' | 'drillIds' | 'target'
      > & {
          filters?: Types.Maybe<
            Array<
              | ({ __typename?: 'SelectFilterValue' } & Pick<
                  Types.SelectFilterValue,
                  'id' | 'value'
                >)
              | ({ __typename?: 'SelectGroupFilterValue' } & Pick<
                  Types.SelectGroupFilterValue,
                  'id'
                > & {
                    values: Array<
                      Types.Maybe<
                        { __typename?: 'FilterCodeValue' } & Pick<
                          Types.FilterCodeValue,
                          'filterCode' | 'value'
                        >
                      >
                    >;
                  })
            >
          >;
        }
    >;
  };

export type TimelineAxisFieldsFragment = { __typename?: 'TimelineAxis' } & Pick<
  Types.TimelineAxis,
  'minX' | 'maxX' | 'categories'
>;

export type TimelineIntervalFieldsFragment = { __typename?: 'TimelineInterval' } & Pick<
  Types.TimelineInterval,
  'name' | 'x1' | 'x2'
> & { color: { __typename?: 'ThemeColor' } & Pick<Types.ThemeColor, 'color'> };

export type TreeFilterOptionFieldsFragment = { __typename?: 'TreeFilterOptionResult' } & Pick<
  Types.TreeFilterOptionResult,
  'id' | 'label' | 'value' | 'isDefault' | 'additionalLabels' | 'sentiment'
>;

export type TreeSelectFilterFieldsFragment = { __typename?: 'TreeSelectFilter' } & Pick<
  Types.TreeSelectFilter,
  'filterCode' | 'additionalLabels'
> & {
    options?: Types.Maybe<
      Array<
        { __typename?: 'TreeFilterOptionResult' } & Pick<
          Types.TreeFilterOptionResult,
          'id' | 'label' | 'value' | 'isDefault' | 'additionalLabels' | 'sentiment'
        > & {
            children?: Types.Maybe<
              Array<
                { __typename?: 'TreeFilterOptionResult' } & Pick<
                  Types.TreeFilterOptionResult,
                  'id' | 'label' | 'value' | 'isDefault' | 'additionalLabels' | 'sentiment'
                > & {
                    children?: Types.Maybe<
                      Array<
                        { __typename?: 'TreeFilterOptionResult' } & Pick<
                          Types.TreeFilterOptionResult,
                          'id' | 'label' | 'value' | 'isDefault' | 'additionalLabels' | 'sentiment'
                        >
                      >
                    >;
                  }
              >
            >;
          }
      >
    >;
  };

export type UserSettingFieldsFragment = { __typename?: 'UserSetting' } & Pick<
  Types.UserSetting,
  'key' | 'label' | 'defaultValue' | 'userValue'
> & {
    options: Array<{ __typename?: 'SettingOption' } & Pick<Types.SettingOption, 'label' | 'value'>>;
  };

export type VarianceFieldsFragment = { __typename?: 'VarianceResult' } & Pick<
  Types.VarianceResult,
  'actual' | 'target' | 'sentiment' | 'label'
>;

export type SettingOptionFieldsFragment = { __typename?: 'SettingOption' } & Pick<
  Types.SettingOption,
  'label' | 'value'
>;

export const ChartAxisFieldsFragmentDoc = gql`
  fragment ChartAxisFields on ChartAxis {
    ... on ChartAxisCategoryViewResult {
      categories
      renderer
      axisType
    }
    ... on ChartAxisValueViewResult {
      renderer
      axisType
    }
    ... on ChartAxisPercentViewResult {
      renderer
      axisType
    }
  }
`;
export const FormattedValueFieldsFragmentDoc = gql`
  fragment FormattedValueFields on FormattedValue {
    raw
    formatted
  }
`;
export const VarianceFieldsFragmentDoc = gql`
  fragment VarianceFields on VarianceResult {
    actual
    target
    sentiment
    label
  }
`;
export const ChartSeriesFieldsFragmentDoc = gql`
  fragment ChartSeriesFields on ChartSeries {
    id
    seriesType
    linkedTo
    name
    color
    lineStyle
    showInLegend
    showInTooltip
    showLastPointMarker
    tooltipRangeFormat
    tooltipTitleLow
    tooltipTitleHigh
    renderer
    data {
      x
      y {
        value {
          ...FormattedValueFields
        }
        high {
          ...FormattedValueFields
        }
        low {
          ...FormattedValueFields
        }
        variance {
          ...VarianceFields
        }
      }
    }
  }
  ${FormattedValueFieldsFragmentDoc}
  ${VarianceFieldsFragmentDoc}
`;
export const MetaDataFieldsFragmentDoc = gql`
  fragment MetaDataFields on MetaData {
    key
    label
    value
  }
`;
export const SettingOptionFieldsFragmentDoc = gql`
  fragment SettingOptionFields on SettingOption {
    label
    value
  }
`;
export const UserSettingFieldsFragmentDoc = gql`
  fragment UserSettingFields on UserSetting {
    key
    label
    options {
      ...SettingOptionFields
    }
    defaultValue
    userValue
  }
  ${SettingOptionFieldsFragmentDoc}
`;
export const DataViewFieldsFragmentDoc = gql`
  fragment DataViewFields on IDataView {
    id
    dataViewType
    metadata {
      ...MetaDataFields
    }
    availableUserSettings {
      ...UserSettingFields
    }
  }
  ${MetaDataFieldsFragmentDoc}
  ${UserSettingFieldsFragmentDoc}
`;
export const FilterValueFieldsFragmentDoc = gql`
  fragment FilterValueFields on FilterValue {
    ... on SelectFilterValue {
      id
      value
    }
    ... on SelectGroupFilterValue {
      id
      values {
        filterCode
        value
      }
    }
  }
`;
export const LinkFieldsFragmentDoc = gql`
  fragment LinkFields on Link {
    pageId
    absolutePath
    drillId
    drillIds
    filters {
      ...FilterValueFields
    }
    target
  }
  ${FilterValueFieldsFragmentDoc}
`;
export const TextLinkFieldsFragmentDoc = gql`
  fragment TextLinkFields on TextLinkResult {
    value
    label
    subLabels
    linkResult {
      ...LinkFields
    }
    linkDirection
  }
  ${LinkFieldsFragmentDoc}
`;
export const DialItemFieldsFragmentDoc = gql`
  fragment DialItemFields on DialItem {
    label
    sentiment
    progress
    progressText {
      value
    }
    target
    targetText {
      value
    }
    actualText {
      value
    }
    varianceText {
      value
    }
    link {
      ...TextLinkFields
    }
  }
  ${TextLinkFieldsFragmentDoc}
`;
export const FilterFieldsFragmentDoc = gql`
  fragment FilterFields on Filter {
    id
    label
    filterType
  }
`;
export const KpiStatusFieldsFragmentDoc = gql`
  fragment KpiStatusFields on KpiStatus {
    actual
    target
    sentiment
    icon
  }
`;
export const KpiCardFieldsFragmentDoc = gql`
  fragment KpiCardFields on KpiCardResult {
    id
    code
    cardType
    title
    items {
      ...KpiStatusFields
    }
    linkResult {
      ...LinkFields
    }
  }
  ${KpiStatusFieldsFragmentDoc}
  ${LinkFieldsFragmentDoc}
`;
export const MetricFieldsFragmentDoc = gql`
  fragment MetricFields on Metric {
    id
    name
    value
    sentiment
    variances {
      ...VarianceFields
    }
  }
  ${VarianceFieldsFragmentDoc}
`;
export const MetricListFieldsFragmentDoc = gql`
  fragment MetricListFields on MetricList {
    name
    metrics {
      ...MetricFields
    }
  }
  ${MetricFieldsFragmentDoc}
`;
export const TextFieldsFragmentDoc = gql`
  fragment TextFields on TextResult {
    value
    subLabels
  }
`;
export const MicroChartFieldsFragmentDoc = gql`
  fragment MicroChartFields on MicroChartResult {
    seriesData
    trendlineData
    sentiment
  }
`;
export const MicroChartCardFieldsFragmentDoc = gql`
  fragment MicroChartCardFields on MicroChartCardResult {
    id
    code
    cardType
    title
    actual {
      ...TextFields
    }
    variance {
      ...VarianceFields
    }
    microChart {
      ...MicroChartFields
    }
    linkResult {
      ...LinkFields
    }
  }
  ${TextFieldsFragmentDoc}
  ${VarianceFieldsFragmentDoc}
  ${MicroChartFieldsFragmentDoc}
  ${LinkFieldsFragmentDoc}
`;
export const PageFieldsFragmentDoc = gql`
  fragment PageFields on Page {
    id
    title
    pageType
    menuTitle
    menuIcon
    path
    absolutePath
    isMenuHidden
  }
`;
export const ModuleFieldsFragmentDoc = gql`
  fragment ModuleFields on Module {
    id
    name
    moduleCode
    path
    pages {
      ...PageFields
      children {
        ...PageFields
        children {
          ...PageFields
        }
      }
    }
  }
  ${PageFieldsFragmentDoc}
`;
export const ComponentFieldsFragmentDoc = gql`
  fragment ComponentFields on Component {
    id
    code
    componentType
    icon
  }
`;
export const TabFieldsFragmentDoc = gql`
  fragment TabFields on Tab {
    title
    path
    pageId
  }
`;
export const NavigationTabsComponentFieldsFragmentDoc = gql`
  fragment NavigationTabsComponentFields on NavigationTabsComponent {
    ...ComponentFields
    tabs {
      ...TabFields
    }
  }
  ${ComponentFieldsFragmentDoc}
  ${TabFieldsFragmentDoc}
`;
export const FilterComponentFieldsFragmentDoc = gql`
  fragment FilterComponentFields on FilterComponent {
    ...ComponentFields
    defaultValue
    alignment
    isHidden
    displayMode
  }
  ${ComponentFieldsFragmentDoc}
`;
export const TableComponentFieldsFragmentDoc = gql`
  fragment TableComponentFields on TableComponent {
    ...ComponentFields
    filters {
      ...FilterComponentFields
    }
  }
  ${ComponentFieldsFragmentDoc}
  ${FilterComponentFieldsFragmentDoc}
`;
export const StoryFieldsFragmentDoc = gql`
  fragment StoryFields on Story {
    id
    storyType
    headline
    recommenderHeadline
    caption
    content {
      ... on MarkdownComponent {
        ...ComponentFields
        content
      }
      ... on TableComponent {
        ...TableComponentFields
      }
      ... on ChartComponent {
        ...ComponentFields
        filters {
          ...FilterComponentFields
        }
      }
      ... on HeaderRowComponent {
        ...ComponentFields
        filters {
          ...FilterComponentFields
        }
        variant
      }
    }
    sentiment
    publishDate
    isRead
    tag
    link {
      ...LinkFields
    }
  }
  ${ComponentFieldsFragmentDoc}
  ${TableComponentFieldsFragmentDoc}
  ${FilterComponentFieldsFragmentDoc}
  ${LinkFieldsFragmentDoc}
`;
export const PlailistFieldsFragmentDoc = gql`
  fragment PlailistFields on Plailist {
    plailistId
    title
    summary
    colorStart {
      ... on ColorString {
        color
      }
    }
    colorEnd {
      ... on ColorString {
        color
      }
    }
    stories {
      ...StoryFields
    }
  }
  ${StoryFieldsFragmentDoc}
`;
export const PlailistCategoryFieldsFragmentDoc = gql`
  fragment PlailistCategoryFields on PlailistCategory {
    categoryId
    name
    plailists {
      ...PlailistFields
    }
  }
  ${PlailistFieldsFragmentDoc}
`;
export const PriorityFieldsFragmentDoc = gql`
  fragment PriorityFields on PriorityResult {
    priorityId
    priorityName
    isFollowed
    sortOrder
  }
`;
export const PriorityCategoryFieldsFragmentDoc = gql`
  fragment PriorityCategoryFields on PriorityCategoryResult {
    categoryName
    priorities {
      ...PriorityFields
    }
  }
  ${PriorityFieldsFragmentDoc}
`;
export const FilterOptionFieldsFragmentDoc = gql`
  fragment FilterOptionFields on FilterOptionResult {
    id
    label
    value
    isDefault
    additionalLabels
    sentiment
  }
`;
export const SelectFilterFieldsFragmentDoc = gql`
  fragment SelectFilterFields on SelectFilter {
    filterCode
    additionalLabels
    options {
      ...FilterOptionFields
    }
  }
  ${FilterOptionFieldsFragmentDoc}
`;
export const SelectGroupFilterFieldsFragmentDoc = gql`
  fragment SelectGroupFilterFields on SelectGroupFilter {
    filterCode
    additionalLabels
    filters {
      ... on SelectFilter {
        id
        filterCode
        label
        options {
          ...FilterOptionFields
        }
      }
    }
  }
  ${FilterOptionFieldsFragmentDoc}
`;
export const SimpleCardFieldsFragmentDoc = gql`
  fragment SimpleCardFields on SimpleCardResult {
    id
    code
    cardType
    title
    textResult {
      ...TextFields
    }
    linkResult {
      ...LinkFields
    }
  }
  ${TextFieldsFragmentDoc}
  ${LinkFieldsFragmentDoc}
`;
export const SummaryInfoItemFieldsFragmentDoc = gql`
  fragment SummaryInfoItemFields on SummaryInfoItem {
    header {
      ... on TextResult {
        value
      }
      ... on TextLinkResult {
        ...TextLinkFields
      }
    }
    content {
      ... on TextResult {
        value
      }
    }
  }
  ${TextLinkFieldsFragmentDoc}
`;
export const ProgressBarFieldsFragmentDoc = gql`
  fragment ProgressBarFields on ProgressBarResult {
    progressValue
    baseValue
    min
    max
    sentiment
    inverted
    variant
  }
`;
export const TableColumnOptionsFieldsFragmentDoc = gql`
  fragment TableColumnOptionsFields on TableColumnOptionsResult {
    alignment
    selectDefault
    selectDisplayDefault
    selectDisplayGroupId
    selectGroupId
    toggleDefault
    toggleGroup
    width
  }
`;
export const TableColumnFieldsFragmentDoc = gql`
  fragment TableColumnFields on TableColumnResult {
    columnId
    dataKey
    title
    options {
      ...TableColumnOptionsFields
    }
  }
  ${TableColumnOptionsFieldsFragmentDoc}
`;
export const KpiStatusOverviewFieldsFragmentDoc = gql`
  fragment KpiStatusOverviewFields on KpiStatusOverview {
    items {
      ...KpiStatusFields
    }
    tooltip {
      title
      columns {
        ...TableColumnFields
      }
      rows {
        rowId
        rowType
        cells {
          columnDataKey
          cellContent {
            ... on KpiStatus {
              text
              icon
              sentiment
            }
            ... on TextResult {
              value
            }
          }
        }
      }
    }
  }
  ${KpiStatusFieldsFragmentDoc}
  ${TableColumnFieldsFragmentDoc}
`;
export const TimelineAxisFieldsFragmentDoc = gql`
  fragment TimelineAxisFields on TimelineAxis {
    minX
    maxX
    categories
  }
`;
export const TimelineIntervalFieldsFragmentDoc = gql`
  fragment TimelineIntervalFields on TimelineInterval {
    name
    x1
    x2
    color {
      color
    }
  }
`;
export const TableCellContentFieldsFragmentDoc = gql`
  fragment TableCellContentFields on TableCellContent {
    ... on TextResult {
      value
      subLabels
    }
    ... on VarianceResult {
      ...VarianceFields
    }
    ... on DrillDownResult {
      value
      subLabels
      drillUpId
      drillDownId
      drillUpIds
      drillDownIds
    }
    ... on MicroChartResult {
      ...MicroChartFields
      linkResult {
        ...LinkFields
      }
    }
    ... on ProgressBarResult {
      ...ProgressBarFields
    }
    ... on TextLinkResult {
      ...TextLinkFields
    }
    ... on KpiStatusOverview {
      ...KpiStatusOverviewFields
    }
    ... on LocationMarkerResult {
      text
      position {
        coordinates {
          latitude
          longitude
        }
      }
    }
    ... on Timeline {
      axis {
        ...TimelineAxisFields
      }
      intervals {
        ...TimelineIntervalFields
      }
      series {
        id
        name
        sortOrder
        marker {
          symbol
          isFilled
          color {
            color
          }
        }
        data {
          x
          formattedX
          label
          marker {
            symbol
            isFilled
            color {
              color
            }
          }
          tooltip {
            title
            columns {
              ...TableColumnFields
            }
            rows {
              rowId
              rowType
              cells {
                columnDataKey
                cellContent {
                  ... on KpiStatus {
                    text
                    icon
                    sentiment
                  }
                  ... on TextResult {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
    ... on TimelinePhases {
      axis {
        ...TimelineAxisFields
      }
      intervals {
        ...TimelineIntervalFields
      }
    }
  }
  ${VarianceFieldsFragmentDoc}
  ${MicroChartFieldsFragmentDoc}
  ${LinkFieldsFragmentDoc}
  ${ProgressBarFieldsFragmentDoc}
  ${TextLinkFieldsFragmentDoc}
  ${KpiStatusOverviewFieldsFragmentDoc}
  ${TimelineAxisFieldsFragmentDoc}
  ${TimelineIntervalFieldsFragmentDoc}
  ${TableColumnFieldsFragmentDoc}
`;
export const TableCellFieldsFragmentDoc = gql`
  fragment TableCellFields on TableCellResult {
    columnDataKey
    cellContent {
      ...TableCellContentFields
    }
  }
  ${TableCellContentFieldsFragmentDoc}
`;
export const TableRowFieldsFragmentDoc = gql`
  fragment TableRowFields on TableRowResult {
    rowId
    rowType
    cells {
      ...TableCellFields
    }
    groupFirstChild
  }
  ${TableCellFieldsFragmentDoc}
`;
export const TreeFilterOptionFieldsFragmentDoc = gql`
  fragment TreeFilterOptionFields on TreeFilterOptionResult {
    id
    label
    value
    isDefault
    additionalLabels
    sentiment
  }
`;
export const TreeSelectFilterFieldsFragmentDoc = gql`
  fragment TreeSelectFilterFields on TreeSelectFilter {
    filterCode
    additionalLabels
    options {
      ...TreeFilterOptionFields
      children {
        ...TreeFilterOptionFields
        children {
          ...TreeFilterOptionFields
        }
      }
    }
  }
  ${TreeFilterOptionFieldsFragmentDoc}
`;
