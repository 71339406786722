import { FilterSearchParams } from 'constants/filters';
import * as T from 'graphql/__generated__/types';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { normalizeAbsolutePath } from 'store/modules/slices';
import { getPriorityFilter } from 'store/priorityFilter/slices';

import { useCurrentModule } from './useCurrentModule';
import { useNavigateSearch } from './useNavigateSearch';

export function useHandleLink() {
  const moduleId = useCurrentModule()?.id ?? '';
  // Get the priority filter for the current module. If the link points to a different module,
  // the translation of the priority filter value into the search parameters will be inaccurate.
  const priorityFilter = useSelector(getPriorityFilter(moduleId));
  const navigateSearch = useNavigateSearch();

  return useCallback(
    (link: T.Link, backLink?: T.Link) => {
      const pathname = normalizeAbsolutePath(link.absolutePath);
      const searchParams = new URLSearchParams();

      if (priorityFilter) {
        // Find the filter value in the link filters that matches the priority filter ID
        const priorityFilterValue = link.filters?.find(({ id }) => id === priorityFilter.id);

        // If the priority filter value is found add it to the search parameters
        if (T.isSelectFilterValue(priorityFilterValue)) {
          searchParams.set(FilterSearchParams.PRIORITY_ID, String(priorityFilterValue.value));
        }
      }

      // If the link contains drill IDs add them to the search parameters
      if (link.drillIds) {
        searchParams.set(FilterSearchParams.DRILL_ID, link.drillIds.join('.'));
      }

      navigateSearch(pathname, searchParams, { state: { link, backLink } });
    },
    [priorityFilter],
  );
}
