import * as T from 'graphql/__generated__/types';
import { useMemo } from 'react';

export function useLocationMarkers(dataView?: T.TableDataView | null) {
  const locationMarkers = useMemo(
    () =>
      dataView?.rows?.reduce((markers: T.LocationMarkerResult[], row) => {
        row?.cells?.forEach((cell) => {
          if (cell && T.isLocationMarkerResult(cell?.cellContent)) {
            markers.push(cell.cellContent);
          }
        });

        return markers;
      }, []) ?? [],
    [dataView?.rows],
  );

  const hasLocationMarkers = useMemo(() => locationMarkers.length > 0, [locationMarkers]);

  return { locationMarkers, hasLocationMarkers } as const;
}
