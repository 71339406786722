import { Button, ButtonProps, styled } from '@mui/material';
import React from 'react';
import Colors from 'theme/colors';

export interface Props extends ButtonProps {
  active?: boolean;
}

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'active',
})<Props>(({ active }) => ({
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 104,
  height: 104,
  borderRadius: 13,
  background: active ? Colors.aqua : Colors.darkAqua,
  color: active ? Colors.white : Colors.black,
  fontFamily: 'Ubuntu, Helvetica, Arial, sans-serif',
  fontSize: 28,
  fontWeight: 500,
  lineHeight: '32px',
  letterSpacing: 0,
  textTransform: 'none',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: '50%',
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,0.06)',
    transform: 'skewX(-45deg)',
    pointerEvents: 'none',
  },
  '&:hover': {
    background: Colors.aqua,
    color: Colors.white,
  },
}));

const ModuleSelectItem: React.FC<Props> = ({ children, active, ...rest }) => (
  <StyledButton active={active} {...rest}>
    {children}
  </StyledButton>
);

export default ModuleSelectItem;
