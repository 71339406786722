import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { moduleChanged } from 'store/modules/slices';

import { useCurrentModule } from './useCurrentModule';

/**
 * Custom hook to dispatch the moduleChanged action when the currentModule changes
 */
export function useModuleChangedDispatcher() {
  const currentModule = useCurrentModule();
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentModule) {
      dispatch(moduleChanged(currentModule));
    }
  }, [currentModule]);
}
