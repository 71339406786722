import { Pathname } from 'history';
import {
  createSearchParams,
  NavigateOptions,
  URLSearchParamsInit,
  useNavigate,
} from 'react-router-dom';

interface NavigateSearchFunction {
  (pathname: Pathname, params?: URLSearchParamsInit, options?: NavigateOptions): void;
}

export function useNavigateSearch(): NavigateSearchFunction {
  const navigate = useNavigate();
  return (pathname, params, options) =>
    navigate({ pathname, search: params ? `?${createSearchParams(params)}` : '' }, options);
}
