import { alpha, Components, Theme } from '@mui/material';

export const MuiTabs: Components<Theme>['MuiTabs'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      boxShadow: `inset 0 -1px ${alpha(theme.palette.text.primary, 0.1)}`,
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing(2),
    }),
    indicator: ({ theme }) => ({
      backgroundColor: theme.palette.primary.main,
    }),
  },
};
